import { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

//componenst
import {
  FormWrapper,
  InputText,
  InputNumber,
  InputDateRange,
  InputArea,
  InputImage,
} from "@components/formComponent";
import AddProductBundle from "../AddProduk";
import Breadcrumbs from "@layout/breadcrumb";

import {
  updateBundle,
  getDetailBundle,
  deleteBundleImages,
  deleteBundleProduct,
} from "@action/promoAction";

import { validateDateRange } from "@constant/param";

const EditBundle = () => {
  const dispatch = useDispatch();

  const [disableSubmit, setDisableSubmit] = useState(false);
  const [formBundleName, setFormBundleName] = useState("");
  const [formStartDate, setStartDate] = useState("");
  const [formEndDate, setEndDate] = useState("");
  const [formDataProduct, setFormDataProduct] = useState([]);
  const [formBundlePrice, setFormBundlePrice] = useState(0);
  const [formImages, setFormImages] = useState([]);
  const [formDeskripsi, setFormDeskripsi] = useState("");

  const history = useHistory();
  const { id } = useParams();

  useEffect(() => {
    dispatch(getDetailBundle(id)).then((resp) => {
      if (resp.status !== 200) return;

      const formData = resp.data.data;

      setFormBundleName(formData.name);
      setStartDate(formData.start_date);
      setEndDate(formData.end_date);
      setFormBundlePrice(formData.price);
      setFormDeskripsi(formData.deskripsi);

      const newArr = formData.detail.map((item) => ({
        id: item.id,
        product_id: item.product.id,
        sku_code: item.product.sku_code,
        product_name: item.product.name,
        harga: item.product.price,
        qty: item.qty,
      }));
      setFormDataProduct(newArr);

      const newPics = formData.thumbnail.map((item) => ({
        id: item.id,
        src: item.thumbnail,
      }));
      setFormImages(newPics);
    });
  }, []);

  function handleValidateForm() {
    if (
      formBundleName === "" ||
      formStartDate === "" ||
      formEndDate === "" ||
      formDataProduct.length < 1 ||
      formBundlePrice === 0 ||
      formImages.length < 1 ||
      formDeskripsi === ""
    )
      return false;
    return true;
  }

  function handleDeleteProductRow(id) {
    setFormDataProduct((prevData) => {
      const newData = prevData.filter((dataItem) => dataItem.id !== id);
      return newData;
    });
    dispatch(deleteBundleProduct(id));
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateDateRange(formStartDate, formEndDate)) return;
	
    if (!handleValidateForm()) {
      toast.error("Please fill all fields.");
      return;
    }

    setDisableSubmit(true);

    let formData = new FormData();
    formData.append("id", id);
    formData.append("name", formBundleName);
    formData.append("start_date", formStartDate);
    formData.append("end_date", formEndDate);
    formData.append("price", formBundlePrice);
    formData.append("deskripsi", formDeskripsi);

    if (formDataProduct.length > 0) {
      for (let x of formDataProduct) {
        formData.append("product_id[]", x.product_id);
        formData.append("qty[]", x.qty);
      }
    }

    if (formImages.some((x) => x.file)) {
      for (let x of formImages) {
        formData.append("thumbnail[]", x.file);
      }
    }

    dispatch(updateBundle(formData)).then((resp) => {
      if (resp.status !== 200) return;

      setTimeout(() => {
        setDisableSubmit(false);
        history.push("/dashboard/promo/bundle");
      }, 650);
    });

	console.log('formDataProduct', formDataProduct);
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <Breadcrumbs
          parent="Bundle"
          title="Edit Bundle"
          urlParent={"/dashboard/promo/bundle"}
        />
      </div>
      <FormWrapper
        disableSubmit={disableSubmit}
        onSubmit={handleSubmit}
        onCancel={() => history.push("/dashboard/promo/bundle")}
      >
        <InputText
          label="Bundle Name"
          value={formBundleName}
          onChange={(e) => setFormBundleName(e.target.value)}
          isRequired
        />
        <InputDateRange
          label="Periode Bundle"
          startDate={formStartDate}
          endDate={formEndDate}
          onChangeStartDate={(val) => setStartDate(val)}
          onChangeEndDate={(val) => setEndDate(val)}
          isRequired
        />
        <AddProductBundle
          label="Daftar Product"
          dataProduct={formDataProduct}
          onUpdatedData={(val) => setFormDataProduct(val)}
          onDispatchDeleteRow={(id) => handleDeleteProductRow(id)}
          isRequired
        />
        <InputNumber
          label="Harga Bundle"
          value={formBundlePrice}
          onChange={(e) => setFormBundlePrice(parseInt(e.target.value))}
          isRequired
        />
        <InputImage
          label="Foto Thumbnail"
          value={formImages}
          onChange={(images) => setFormImages(images)}
          onDispatchRemove={(id) => dispatch(deleteBundleImages(id))}
          isRequired
        />
        <InputArea
          label="Deskripsi"
          value={formDeskripsi}
          onChange={(e) => setFormDeskripsi(e.target.value)}
          isRequired
        />
      </FormWrapper>
    </>
  );
};

export default EditBundle;
