import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import Breadcrumb from "@layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";
import { FormWrapper } from "@components/formComponent";

import { updateRowLevel, getLevelDetail } from "@action/levelAction";

const LevelEdit = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();

  const { levelDetail } = useSelector((state) => state.LevelReducer);
  const [formPayload, setFormPayload] = useState({
    title: "",
    start_point: "",
    end_point: "",
  });
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    dispatch(getLevelDetail(id));
  }, []);

  useEffect(() => {
    if (levelDetail.data && !levelDetail.loading) {
      let resp = levelDetail.data;
      setFormPayload({
        ...formPayload,
        title: resp.title,
        start_point: resp.start_xp,
        end_point: resp.end_xp,
      });
    }
  }, [levelDetail]);

  const validateForm = (data) => {
    if (data.title === "" || data.start_point === "" || data.end_point === "") {
      return true;
    }
    return false;
  };

  const compareMinMaxNumber = (data) => {
    const num1 = parseInt(data.start_point);
    const num2 = parseInt(data.end_point);
    const result = num1 < num2;
    if (result) return true;

    toast.error("Omset akhir tidak bisa lebih kecil dari Omset awal.");
    return false;
  };

  const handleSubmitForm = () => {
    if (!validateForm(formPayload)) {
      if (compareMinMaxNumber(formPayload)) {
        let formData = new FormData();
        formData.append("title", formPayload.title);
        formData.append("start_xp", formPayload.start_point);
        formData.append("end_xp", formPayload.end_point);

        dispatch(updateRowLevel(id, formData)).then((resp) => {
          if (resp.status !== 200) return;

          setDisableSubmit(false);
          history.push("/dashboard/level");
        });
      }
    }
  };

  const handleCancelButton = () => {
    history.push("/dashboard/level");
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Level"
        title="Edit Level"
        urlParent={"/dashboard/level"}
      />
      <FormWrapper
        disableSubmit={disableSubmit}
        onSubmit={handleSubmitForm}
        onCancel={() => history.push("/dashboard/level")}
      >
        <FormGroup row>
          <Label for="formNamaBroadcast" sm={3}>
            <b>Title</b>
          </Label>
          <Col sm={9}>
            <Input
              placeholder="Enter Title Name"
              id="formNamaBroadcast"
              value={formPayload.title}
              onChange={(e) =>
                setFormPayload({
                  ...formPayload,
                  title: e.target.value,
                })
              }
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="formStartPoint" sm={3}>
            <b>Omset per tahun</b>
          </Label>
          <Col className="d-flex flex-row justify-content-between" sm={9}>
            <Input
              placeholder="0"
              type="number"
              id="formStartPoint"
              min={0}
              value={formPayload.start_point}
              onChange={(e) =>
                setFormPayload({
                  ...formPayload,
                  start_point: e.target.value,
                })
              }
            />

            <span className="d-flex flex-row my-auto mx-2">
              <i className="fa fa-minus" aria-hidden="true"></i>
              <i className="fa fa-minus" aria-hidden="true"></i>
            </span>

            <Input
              placeholder="0"
              type="number"
              id="formStartPoint"
              className="ml-2"
              min={0}
              value={formPayload.end_point}
              onChange={(e) =>
                setFormPayload({
                  ...formPayload,
                  end_point: e.target.value,
                })
              }
            />
          </Col>
        </FormGroup>
      </FormWrapper>
    </Fragment>
  );
};

export default LevelEdit;
