import { GET_LIST_TABLE_FLASHSALE, GET_LIST_PRODUK_FLASHSALE, GET_DETAIL_FLASHSALE } from "../../actionTypes";

const initialState = {
  listTableFlashsale: {},
  listProdukFlashsale: {},
  detailFlashsale: {}
};

const FlashsaleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_TABLE_FLASHSALE:
      state.listTableFlashsale = action.payload;
      return { ...state, listTableFlashsale: state.listTableFlashsale };
    case GET_LIST_PRODUK_FLASHSALE:
      state.listProdukFlashsale = action.payload;
      return { ...state, listProdukFlashsale: state.listProdukFlashsale };
    case GET_DETAIL_FLASHSALE:
      state.detailFlashsale = action.payload;
      return { ...state, detailFlashsale: state.detailFlashsale };

    default:
      return { ...state };
  }
};

export default FlashsaleReducer;
