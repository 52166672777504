import React, { Fragment, useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Breadcrumb from "@layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Badge,
} from "reactstrap";
import CarouselImage from "@components/carousel";
import BadgeStatus from "@components/badgeStatus";
import TableDropdown from "@components/customDropdown";

import { getDetailProduct } from "@action/productAction";

const ProductDetail = () => {
  const urlParams = useParams();
  const location = useLocation();
  const [payload, setPayload] = useState({});
  const [images, setImages] = useState([]);

  const dispatch = useDispatch();
  const detailProduct = useSelector(
    (state) => state.ProductReducer.detailProduct
  );

  useEffect(() => {
    dispatch(getDetailProduct(urlParams?.id));
  }, []);

  useEffect(() => {
    if (detailProduct?.data) {
      const row = {
        id: detailProduct?.data?.id,
        name: detailProduct?.data?.name,
        sku_code: detailProduct?.data?.sku_code,
        category: detailProduct?.data?.category?.name,
        brand: detailProduct?.data?.brand?.name,
        price: detailProduct?.data?.price,
        status: detailProduct?.data?.active,
        blocked: detailProduct?.data?.blocked === 0 ? "Non-blocked" : "Blocked",
        stock: detailProduct?.data?.stock,
        video_url: detailProduct?.data?.video_url,
        description: detailProduct?.data?.description,
        category_id: detailProduct?.data?.category?.id,
        brand_id: detailProduct?.data?.brand?.id,
      };

      setPayload(row);
      setImages(detailProduct?.data?.images);
    }
  }, [detailProduct.data]);

  return (
    <Fragment>
      <Breadcrumb
        parent="Product"
        title="Detail Product"
        urlParent={"/dashboard/product"}
      />
      <Container fluid>
        <Row>
          <Col className="order-xl-first order-sm-last" xl={4} sm={12}>
            <Card>
              <CardTitle
                tag="h6"
                className="font-weight-bold d-flex flex-column p-3 my-0"
              >
                {payload?.name || "-"}
                <span className="my-2" style={{ color: "blue" }}>
                  {" "}
                  {payload?.price
                    ? payload?.price.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                      })
                    : "Rp 0"}
                </span>
              </CardTitle>

              <CardBody className="px-3 py-0">
                <div className="d-flex">
                  <Link
                    className="flex-fill mr-1 my-auto"
                    to={{
                      pathname: `/dashboard/product/edit/${urlParams?.id}`,
                      data: location?.data,
                    }}
                  >
                    <Button
                      className="btn-block btn-sm text-dark border-dark hover-primary"
                      color="primary"
                      outline
                    >
                      Edit
                    </Button>
                  </Link>
                  <Link
                    className="flex-fill mx-2 my-auto"
                    to={{
                      pathname: `/dashboard/product/logs/${urlParams?.id}`,
                    }}
                  >
                    <Button
                      className="btn-block btn-sm text-dark border-dark hover-primary"
                      color="primary"
                      outline
                    >
                      Logs
                    </Button>
                  </Link>

                  <TableDropdown
                    dropdownContent={[
                      {
                        label: "Price",
                        type: "link",
                        link: `/dashboard/product/price/${urlParams?.id}`,
                      },
                      {
                        label: "Images",
                        type: "link",
                        link: `/dashboard/product/images/${urlParams?.id}`,
                      },
                      {
                        label: "Discount",
                        type: "link",
                        link: `/dashboard/product/discount/${urlParams?.id}`,
                      },
                    ]}
                    onToggleStatus={() => {}}
                    row={payload}
                  />
                </div>

                <div className="my-2 py-2">
                  <Row className="my-2">
                    <Col sm={4}>
                      <span
                        className="font-weight-bold"
                        style={{ color: "gray" }}
                      >
                        Status
                      </span>
                    </Col>
                    <Col>
                      <BadgeStatus
                        status={payload.status ? payload.status : ""}
                      />
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col sm={4}>
                      <span
                        className="font-weight-bold"
                        style={{ color: "gray" }}
                      >
                        SKU Code
                      </span>
                    </Col>
                    <Col>
                      <p>
                        {`: `}
                        {payload?.sku_code || "-"}
                      </p>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col sm={4}>
                      <span
                        className="font-weight-bold"
                        style={{ color: "gray" }}
                      >
                        Category
                      </span>
                    </Col>
                    <Col>
                      <p>
                        {`: `}
                        {payload?.category || "-"}
                      </p>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col sm={4}>
                      <span
                        className="font-weight-bold"
                        style={{ color: "gray" }}
                      >
                        Brand
                      </span>
                    </Col>
                    <Col>
                      <p>
                        {`: `}
                        {payload?.brand || "-"}
                      </p>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col sm={4}>
                      <span
                        className="font-weight-bold"
                        style={{ color: "gray" }}
                      >
                        Weight
                      </span>
                    </Col>
                    <Col>
                      <p>
                        {`: `}
                        {payload?.weight || "0"} Gram
                      </p>
                    </Col>
                  </Row>
                  <Row className="my-2">
                    <Col sm={4}>
                      <span
                        className="font-weight-bold"
                        style={{ color: "gray" }}
                      >
                        Max Qty
                      </span>
                    </Col>
                    <Col>
                      <p>
                        {`: `}
                        {payload?.max_qty || "0"}
                      </p>
                    </Col>
                  </Row>
                  <div className="my-2">
                    <span
                      className="font-weight-bold"
                      style={{ color: "gray" }}
                    >
                      Description
                    </span>
                    <p>{payload?.description || ""}</p>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col className="order-xl-last order-sm-first" xl={8} sm={12}>
            <Card>
              <CardBody className="p-3">
                <CarouselImage images={images} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ProductDetail;
