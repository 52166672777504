import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useParams, useHistory, useLocation } from "react-router-dom";

import {
  getPointDetail,
  updateRowPoint,
} from "../../../../../redux/action/pointAction";

import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

const PointEdit = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { detailPoint } = useSelector((state) => state.PointReducer);
  const { alertResponse } = useSelector((state) => state.AlertReducer);
  const [formPayload, setFormPayload] = useState({
    min: "",
    max: "",
    point: "",
  });

  useEffect(() => {
    dispatch(getPointDetail(id));
  }, []);

  useEffect(() => {
    if (alertResponse.status === 200 && alertResponse.to === location.key) {
      history.push("/dashboard/point");
    }
  }, [alertResponse]);

  useEffect(() => {
    if (detailPoint.data && !detailPoint.loading) {
      let respData = detailPoint.data;

      setFormPayload({
        ...formPayload,
        min: respData.minimum_purchase,
        max: respData.maximum_purchase,
        point: respData.poin,
      });
    }
  }, [detailPoint]);

  const validateForm = (data) => {
    if (data.min === "" || data.max === "" || data.point === "") {
      toast.error("Please Fill all Fields");
      return true;
    }
    return false;
  };

  const compareMinMaxNumber = (data) => {
    let num1 = data.min;
    let num2 = data.max;
    let result = num1 < num2;
    if (result) {
      return true;
    }
    toast.error("Minimum Purchase cannot bigger than maximum purchase");
    return false;
  };

  const handleSubmitForm = () => {
    if (!validateForm(formPayload)) {
      if (compareMinMaxNumber(formPayload)) {
        let formData = new FormData();
        formData.append("minimum_purchase", formPayload.min);
        formData.append("maximum_purchase", formPayload.max);
        formData.append("poin", formPayload.point);

		const locationKey = location.key

        dispatch(updateRowPoint(id, formData, locationKey));
      }
    }
  };

  const handleCancel = () => {
    history.push("/dashboard/point");
  };

  return (
    <Fragment>
      <Breadcrumb parent="Point" title="Edit Point" urlParent={'/dashboard/point'} />
      <Container fluid>
        <Row>
          <Col xl={8}>
            <Card>
              <CardBody className="p-3">
                <Form
                  className="m-2"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FormGroup row>
                    <Label for="minPurchase" sm={3}>
                      <b>Minimum Purchase</b>
                    </Label>
                    <Col sm={9}>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <span
                            className="p-2"
                            style={{ border: "1px solid grey" }}
                          >
                            Rp
                          </span>
                        </InputGroupAddon>
                        <Input
                          type="number"
                          placeholder="0"
                          id="minPurchase"
                          value={formPayload.min}
                          onChange={(e) =>
                            setFormPayload({
                              ...formPayload,
                              min: e.target.value,
                            })
                          }
                        />
                      </InputGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="maxPurchase" sm={3}>
                      <b>Maximum Purchase</b>
                    </Label>
                    <Col sm={9}>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <span
                            className="p-2"
                            style={{ border: "1px solid grey" }}
                          >
                            Rp
                          </span>
                        </InputGroupAddon>
                        <Input
                          type="number"
                          placeholder="0"
                          id="maxPurchase"
                          value={formPayload.max}
                          onChange={(e) =>
                            setFormPayload({
                              ...formPayload,
                              max: e.target.value,
                            })
                          }
                        />
                      </InputGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="formPoint" sm={3}>
                      <b>Point</b>
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="number"
                        placeholder="0"
                        id="formPoint"
                        value={formPayload.point}
                        onChange={(e) =>
                          setFormPayload({
                            ...formPayload,
                            point: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup check row className="my-3">
                    <div className="d-flex justify-content-end">
                      <Button
                        onClick={handleCancel}
                        className="mx-1"
                        color="primary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        className="mx-1"
                        color="primary"
                        onClick={handleSubmitForm}
                      >
                        Submit
                      </Button>
                    </div>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PointEdit;
