import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  updateUserAdmin,
  getDetailAdmin,
} from "../../../../../redux/action/userAdminAction";

import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Input,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import styled from "styled-components";
import SpinnerLoader from "../../../../../Component/spinnerLoading";

const BtnBlue = styled.button`
  background-color: blue;
  color: white;
  border-radius: 4px;
  border: 1px solid transparent;
  min-width: 100px;
`;

const BtnWhite = styled.button`
  background-color: white;
  color: black;
  border-radius: 4px;
  border: 1px solid grey;
  min-width: 100px;
`;

const AdminEdit = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { detailAdmin } = useSelector((state) => state.UserAdminReducer);
  const [formPayload, setFormPayload] = useState({
	id: id,
    username: "",
    role: "",
    password: "",
    password_confirmation: "",
  });
  const [isSubmit, SetIsSubmit] = useState(false);

  useEffect(() => {
    dispatch(getDetailAdmin(id));
  }, []);

  useEffect(() => {
    if (detailAdmin.data && !detailAdmin.loading) {
      const dat = detailAdmin.data.data;
      setFormPayload({
        ...formPayload,
        username: dat.username,
        role: dat.role,
      });
    }
  }, [detailAdmin]);

  const validateForm = (data) => {
    if (
      data.username === ""
    ) {
      toast.error("Please Fill all Fields");
      return true;
    }
    return false;
  };

  const handleSubmitForm = () => {
    if (!validateForm(formPayload)) {
      SetIsSubmit(true);
	  
      let dataForm = new URLSearchParams(Object.entries(formPayload)).toString();
      dispatch(updateUserAdmin(dataForm)).then((resp) => {
        if (resp.status !== 200) return;

        SetIsSubmit(false);
        history.push("/dashboard/admin");
      });
    }
  };

  const handleCancel = () => {
    history.push("/dashboard/admin");
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Admin"
        title="Edit Admin"
        urlParent="/dashboard/admin"
      />
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <CardBody className="p-3">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FormGroup row>
                    <Label for="formUsername" sm={4}>
                      <b>Username</b>
                    </Label>
                    <Col sm={8}>
                      <Input
                        placeholder="Enter Username"
                        id="formUsername"
                        value={formPayload.username}
                        onChange={(e) =>
                          setFormPayload({
                            ...formPayload,
                            username: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="formPassword" sm={4}>
                      <b>Password</b>
                    </Label>
                    <Col sm={8}>
                      <Input
                        placeholder="Enter Password"
                        id="formPassword"
                        type="password"
                        value={formPayload.password}
                        onChange={(e) =>
                          setFormPayload({
                            ...formPayload,
                            password: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="formConfirmPassword" sm={4}>
                      <b>Confirm Password</b>
                    </Label>
                    <Col sm={8}>
                      <Input
                        placeholder="Confirm Password"
                        id="formConfirmPassword"
                        type="password"
                        value={formPayload.password_confirmation}
                        onChange={(e) =>
                          setFormPayload({
                            ...formPayload,
                            password_confirmation: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>

                  <div className="d-flex justify-content-end">
                    <BtnWhite className="mx-1 px-3 py-2" onClick={handleCancel}>
                      Cancel
                    </BtnWhite>
                    <BtnBlue
                      className="mx-1 px-3 py-2"
                      onClick={handleSubmitForm}
                      disabled={isSubmit}
                    >
                      {isSubmit ? <SpinnerLoader /> : "Submit"}
                    </BtnBlue>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AdminEdit;
