import { Fragment } from "react";

import { FormGroup, Input } from "reactstrap";

const PaginationRow = ({ onChangePaginationRow }) => {
  const handleOnChangeRow = (e) => {
    onChangePaginationRow(e.target.value);
  };

  return (
    <Fragment>
      <div className="d-flex ">
        <span className="text-muted my-2">Show</span>
        <FormGroup className="mx-2">
          <Input
            type="select"
            name="paginationRow"
            id="selectDataRow"
            onChange={handleOnChangeRow}
          >
            <option value={10}>10</option>
            <option value={25}>25</option>
            <option value={50}>50</option>
          </Input>
        </FormGroup>
        <span className="text-muted my-2">rows</span>
      </div>
    </Fragment>
  );
};

export default PaginationRow;
