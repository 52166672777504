import { useState, useEffect, Fragment } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

//components
import {
  Button,
  InputGroup,
  Input,
  InputGroupAddon,
} from "reactstrap";
import Breadcrumbs from "@layout/breadcrumb";
import TableLayout from "@layout/tableLayout";
import TableCSI from "@components/table";
import BadgeStatus from "@components/badgeStatus";
import TableDropdown from "@components/customDropdown";
import DiskonModal from "./diskonModal";
import { InputSwitch } from "@components/formComponent";

import iconSearch from "@assets/images/input-search.png";

//action
import { getListHargaCoret, updateHargaStatus } from "@action/hargaCoretAction";

const HargaCoret = () => {
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [column, setColumn] = useState([]);
  const [data, setData] = useState([]);
  const [diskonModal, setDiskonModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [searchVal, setSearchVal] = useState("");
  const [syncSwitch, setSyncSwitch] = useState(false);

  const dispatch = useDispatch();
  const { listHargaCoret } = useSelector((state) => state.HargaCoretReducer);

  useEffect(() => {
    if (listHargaCoret.data === undefined || listHargaCoret.data === null)
      return;

    const res = listHargaCoret.data.data;
    const dataresp = res.data;
    const mappedData = dataresp.map((item) => ({
      id: item.id,
      name: item.name,
      category: item.category.name,
      brand: item.brand.name,
      price: item.harga_coret,
      priceOrigin: item.price,
      promo_price_id: item.product_promo_price?.id,
      status: item.product_promo_price?.active || 0,
      start_date: item.product_promo_price?.start_date,
      end_date: item.product_promo_price?.end_date,
      diskon: item.product_promo_price?.discount || null,
      diskon_type: item.product_promo_price?.discount_type,
    }));

    setData(mappedData);
    setMaxPageIndex(res.last_page);
  }, [listHargaCoret.data]);

  useEffect(() => {
    fetchTable();
  }, []);

  function fetchTable() {
    dispatch(getListHargaCoret(1, ""));
    setColumn([
      {
        Header: () => null,
        id: "hargaCoret",
        columns: [
          {
            Header: "ID",
            accessor: "id",
            sortAble: true,
          },
          {
            Header: "Product Name",
            accessor: "name",
            sortAble: true,
          },
          {
            Header: "Category",
            accessor: "category",
            sortAble: true,
          },
          {
            Header: "Brand",
            accessor: "brand",
            sortAble: true,
          },
          {
            Header: "Price",
            accessor: "price",
            sortAble: true,
            Cell: ({ row }) => (
              <div>
                {row.original.status === 1 ? (
                  <div className="d-flex">
                    <p>{row.original.price}</p>
                    <span
                      className="mx-1"
                      style={{
                        fontSize: "10px",
                        textDecoration: "line-through",
                      }}
                    >
                      {row.original.priceOrigin}
                    </span>
                  </div>
                ) : (
                  <p>{row.original.priceOrigin}</p>
                )}
              </div>
            ),
          },
          {
            Header: "Status",
            accessor: "status",
            sortAble: true,
            Cell: ({ row }) => (
              <BadgeStatus
                status={row.original.status ? row.original.status : 0}
              />
            ),
            sortType: (rowA, rowB) => {
              let val1 = parseInt(rowA.original.status);
              let val2 = parseInt(rowB.original.status);
              if (val1 > val2) return 1;
              if (val1 < val2) return -1;
              return 0;
            },
          },
          {
            Header: "Start Date",
            accessor: "start_date",
            sortAble: true,
            Cell: ({ row }) => (
              <span>
                {row.original.status === 1 && row.original.start_date !== "-"
                  ? moment.utc(row.original.start_date).format("DD/MM/YYYY")
                  : ""}
              </span>
            ),
            sortType: (rowA, rowB, columnId, desc) => {
              let startDate = Date.parse(rowA.values[columnId]);
              let endDate = Date.parse(rowB.values[columnId]);
              if (startDate > endDate) return 1;
              if (startDate < endDate) return -1;
              return 0;
            },
          },
          {
            Header: "End Date",
            accessor: "end_date",
            sortAble: true,
            Cell: ({ row }) => (
              <span>
                {row.original.status === 1 && row.original.end_date !== "-"
                  ? moment.utc(row.original.end_date).format("DD/MM/YYYY")
                  : ""}
              </span>
            ),
            sortType: (rowA, rowB, columnId, desc) => {
              let startDate = Date.parse(rowA.values[columnId]);
              let endDate = Date.parse(rowB.values[columnId]);
              if (startDate > endDate) return 1;
              if (startDate < endDate) return -1;
              return 0;
            },
          },
          {
            Header: "Diskon",
            accessor: "diskon",
            sortAble: true,
            Cell: ({ row }) => (
              <span>
                {row.original.status === 1 ? row.original.diskon : ""}
              </span>
            ),
          },
          {
            accessor: "action",
            Cell: ({ row }) => (
              <TableDropdown
                dropdownContent={[
                  // {
                  //   label: "Detail",
                  //   type: "link",
                  //   link: `/dashboard/promo/harga-coret/${row.original.id}`
                  // },
                  {
                    label: "Tetapkan diskon",
                    type: "button",
                    onclick: () => handleSetDiskon(row.original),
                  },
                ]}
                onToggleStatus={onToggleStatusHarga}
                row={row.original}
              />
            ),
            disableSortBy: true,
          },
        ],
      },
    ]);
  }

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListHargaCoret(page, ""));
  };

  function handleSetDiskon(data) {
    setDiskonModal(true);
    setModalData(data);
  }

  const onToggleStatusHarga = (arg) => {
    console.log("🚀 ~ onToggleStatusHarga ~ ID:", arg)
    dispatch(updateHargaStatus(arg.id.promo_price_id, arg.toggle)).then((resp) => {
      if (!resp.status === 200) return false;

      fetchTable();
      toast.success("Update Success");
    });
  };

  const handleSearch = (input) => {
    const val = input.target.value;
    setSearchVal(val);
    setTimeout(() => {
      dispatch(getListHargaCoret(1, val));
    }, 450);
  };

  function handleOnCloseDiskon() {
    setDiskonModal(!diskonModal);
    fetchTable();
  }

  const tableHeader = (
    <Fragment>
      <InputGroup style={{ maxWidth: "280px" }}>
        <Input
          className="auto"
          placeholder="Search"
          value={searchVal}
          onChange={handleSearch}
        />
        <InputGroupAddon addonType="prepend">
          <Button className="rounded p-2" color="primary">
            <img src={iconSearch} width={18} />
          </Button>
        </InputGroupAddon>
      </InputGroup>
      <div className="my-2">
        <InputSwitch
          value={syncSwitch}
          onChange={(val) => setSyncSwitch(val.target.checked)}
        />
      </div>
    </Fragment>
  );

  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <Breadcrumbs
          parent="Promotion"
          title="Harga Coret"
          urlParent={"/dashboard/promo"}
        />
      </div>
      <TableLayout
        handleSearch={handleSearch}
        pageCount={maxPageIndex}
        handleChangePage={handlePageClick}
        handleChangeRow={(val) => dispatch(getListHargaCoret(1, "", val))}
        isCustomHeader
        customBarHeader={tableHeader}
      >
        <TableCSI columns={column} data={data} key={data} />
      </TableLayout>
      <DiskonModal
        modalData={modalData}
        isVisible={diskonModal}
        onClose={handleOnCloseDiskon}
      />
    </>
  );
};

export default HargaCoret;
