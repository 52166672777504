import React, { useState, Fragment } from "react";
import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import {useDispatch} from "react-redux";
import {addDataSection} from "../../../../../redux/action/sectionAction";

const defaultPayload = {
  title: "",
  type: "product"
}

const SectionAdd = () => {
  const [data, setData] = useState(defaultPayload);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    setDisableSubmit(true);
    const dataForm = new URLSearchParams(
        Object.entries(data)
    ).toString();
    dispatch(addDataSection(dataForm));

    setTimeout(() => {
      setDisableSubmit(false);
    }, 500);
  }

  return (
    <Fragment>
      <Breadcrumb parent="Section" title="Add New Section" urlParent={'/dashboard/section'} />
      <Container fluid>
        <Row>
          <Col sm={8}>
            <Card>
              <CardBody className="p-3">
                <Form>
                  <FormGroup row>
                    <Label for="formTitle" sm={2}>
                      Title
                    </Label>
                    <Col sm={10}>
                      <Input placeholder="Enter Title" id="formTitle" onChange={(event) => setData({...data, title: event.target.value})} />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="selectStartDate" sm={2}>
                      Type
                    </Label>
                    <Col sm={10}>
                      <Input type="select" name="select" id="selectStartDate" placeholder={"Type"} onChange={(event) => setData({...data, type: event.target.value})}>
                        <option key={1} value={"product"}>Product</option>
                        <option key={2} value={"catgory"}>Category</option>
                        <option key={3} value={"brand"}>Brand</option>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup check row>
                    <Col>
                      <Button type="button" onClick={() => handleSubmit()} disabled={disableSubmit}>Submit</Button>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SectionAdd;
