import apiConfig from "../../api";
import {
  GET_INDEX,
  GET_KEYWORDS_ANALYTICS,
  GET_PRODUCTS_ANALYTICS,
  GET_USAGE_ANALYTICS,
} from "../actionTypes";

export const getIndexAnalytics = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_INDEX,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .get(
        `/api/admin/analytics?start_date=${payload.start_date}&end_date=${payload.end_date}`
      )
      .then((resp) => {
        dispatch({
          type: GET_INDEX,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_INDEX,
          payload: {
            loading: false,
            data: err,
            err: true,
          },
        });
      });
  };
};

export const getKeywordsAnalytics = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_KEYWORDS_ANALYTICS,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .get(
        `/api/admin/analytics/keywords?start_date=${payload.start_date}&end_date=${payload.end_date}`
      )
      .then((resp) => {
        dispatch({
          type: GET_KEYWORDS_ANALYTICS,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_KEYWORDS_ANALYTICS,
          payload: {
            loading: false,
            data: err,
            err: true,
          },
        });
      });
  };
};

export const getProductsAnalytics = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_PRODUCTS_ANALYTICS,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .get(
        `/api/admin/analytics/products?start_date=${payload.start_date}&end_date=${payload.end_date}`
      )
      .then((resp) => {
        dispatch({
          type: GET_PRODUCTS_ANALYTICS,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_PRODUCTS_ANALYTICS,
          payload: {
            loading: false,
            data: err,
            err: true,
          },
        });
      });
  };
};

export const getUsageAnalytics = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_USAGE_ANALYTICS,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .get(
        `/api/admin/analytics/usage?start_date=${payload.start_date}&end_date=${payload.end_date}`
      )
      .then((resp) => {
        dispatch({
          type: GET_USAGE_ANALYTICS,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_USAGE_ANALYTICS,
          payload: {
            loading: false,
            data: err,
            err: true,
          },
        });
      });
  };
};
