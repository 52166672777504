import apiConfig from "../../api";
import {
  GET_LIST_LEVEL,
  WATCH_RESPONSE_ALERT,
  GET_LEVEL_DETAIL,
} from "../actionTypes";

export const getListLevel = (page, param, row) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(
        `/api/admin/loyalty/level?page=${page}&search=${param}&perPage=${
          row ? row : 10
        }`
      );
      dispatch({
        type: GET_LIST_LEVEL,
        payload: {
          loading: false,
          data: resp.data.data,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });
    }
  };
};

export const createLevel = (payload, locationKey) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post("/api/admin/loyalty/level", payload);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: true,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });
    }
  };
};

export const getListLevelBySearch = (page, param, row) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_LEVEL,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(
        `/api/admin/loyalty/level?page=${page}&search=${param}&perPage=${
          row ? row : 10
        }`
      )
      .then((resp) => {
        dispatch({
          type: GET_LIST_LEVEL,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LIST_LEVEL,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const updateRowLevel = (id, payload, locationKey) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(
        `/api/admin/loyalty/level/${id}`,
        payload
      );
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: false,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });

      return err;
    }
  };
};

export const deleteRowLevel = (id) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.delete(`/api/admin/loyalty/level/${id}`);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: false,
          err: false,
        },
      });

	  return resp
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });
    }
  };
};

export const getLevelDetail = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_LEVEL_DETAIL,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/loyalty/level/${id}`)
      .then((resp) => {
        dispatch({
          type: GET_LEVEL_DETAIL,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LEVEL_DETAIL,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};
