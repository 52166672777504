import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Badge,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import moment from "moment";

import {
  fetchDetailCampaign,
  fetchProductCampaign,
  fetchParticipantCampaign,
  resetState,
  deactivateCampaign,
} from "../../../../../redux/action/campaignAction";

import CampaignProduct from "./detailProduct";
import CampaignParticipant from "./detailParticipant";
import CampaignReward from "./detailReward";
import CurrencyFormatter from "../../../../../Component/currencyFormatter";

const BtnDeactivate = styled.button`
  color: red;
  border: none;
  cursor: pointer;
  background: transparent;
  font-size: 14px;
`;

const customDropdown = {
  position: "relative",
  display: "inline-block",
};

const customDropbtn = {
  backgroundColor: "white",
  color: "black",
  padding: "5px 6px",
  cursor: "pointer",
  border: "1px solid #e0e0e0",
  borderRadius: "4px",
};

const customDropdownContent = {
  position: "absolute",
  overflow: "auto",
  zIndex: "1",
  minWidth: "150px",
  padding: "6px",
  backgroundColor: "white",
  textAlign: "center",
  boxShadow: "1px 1px 1px 1px #e0e0e0",
  borderRadius: "4px",
};

const CampaignDetail = (props) => {
  let uriParam = useParams();
  const dispatch = useDispatch();
  const [activeTabTable, setActiveTabTable] = useState(1);
  const [campaignDetail, setCampaignDetail] = useState({});
  const { detailCampaign, listCampaignParticipant } =
    useSelector((state) => state.CampaignReducer);
  const { alertResponse } = useSelector((state) => state.AlertReducer);
  const [tableColumnsProduct, setTableColumnsProduct] = useState([]);
  const [tableDataProduct, setTableDataProduct] = useState([]);
  const [tableDataParticipant, setTableDataParticipant] = useState([]);
  const [typeProduct, setTypeProduct] = useState("");
  const [dropdownOpen, setOpen] = useState(false);

  useEffect(() => {
    dispatch(fetchDetailCampaign(uriParam.id));

	let abortController = new AbortController(); 
    return () => {
	  abortController.abort()
    };
  }, []);

  //hook for when data updated
  useEffect(() => {
    if (detailCampaign.data && !detailCampaign.loading) {
      setCampaignDetail(detailCampaign.data);
      setTypeProduct(detailCampaign.data.type);
    }
  }, [detailCampaign]);

  useEffect(() => {
    return () => {
      setTypeProduct("");
      setTableColumnsProduct([]);
      setTableDataProduct([]);
      setTableDataParticipant([]);
      dispatch(resetState());
    };
  }, []);

  //hook watcher for alert
  useEffect(() => {
    if (alertResponse && alertResponse.status === 200) {
      dispatch(fetchDetailCampaign(uriParam.id));
    }
  }, [alertResponse]);

  const onHandleDeactivate = (id, status) => {
    dispatch(deactivateCampaign(id, status));
    setOpen(false);
  };

  const toggleTabTable = (tab) => {
    if (activeTabTable !== tab) {
      setActiveTabTable(tab);
    }
  };

  const onRenderDataAfterDelete = () => {
    //dispatch(fetchProductCampaign(uriParam.id, 1));
  };

  const onToggleDropdown = (e) => {
    setOpen(!dropdownOpen);
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Campaign"
        title="Campaign Detail"
        urlParent="/dashboard/campaign"
      />
      <Container fluid>
        <Row>
          <Col sm="4">
            <Card style={{minHeight: '500px'}}>
              <CardTitle tag="h5" className="p-3">
                {campaignDetail.title}
              </CardTitle>
              <CardBody className="p-3">
                <div className="d-flex">
                  <Link
                    to={`/dashboard/campaign/edit/${uriParam.id}`}
                    style={{ width: "100%" }}
                  >
                    <Button outline block color="primary">
                      Edit
                    </Button>
                  </Link>
                  <div className="mx-2" style={customDropdown}>
                    <button onClick={onToggleDropdown} style={customDropbtn}>
                      ...
                    </button>
                    {dropdownOpen ? (
                      <div
                        id="myDropdown"
                        className="d-flex flex-column"
                        style={customDropdownContent}
                      >
                        <div className="my-1">
                          <Link to="#">Delete</Link>
                        </div>
                        <div className="my-1">
                          {campaignDetail.active_string === 'active' ? (
                            <BtnDeactivate
                              onClick={() =>
                                onHandleDeactivate(uriParam.id, "nonactive")
                              }
                            >
                              Deactivate
                            </BtnDeactivate>
                          ) : (
                            <BtnDeactivate
                              onClick={() =>
                                onHandleDeactivate(uriParam.id, "active")
                              }
                              style={{ color: "green" }}
                            >
                              Activate
                            </BtnDeactivate>
                          )}
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="my-2">
                  <div className="d-flex my-2">
                    <span>Status :</span>
                    <p className="mx-2">
                      {campaignDetail.active_string === 'active' ? (
                        <Badge color="success">Active</Badge>
                      ) : (
                        <Badge color="secondary">Non-active</Badge>
                      )}
                    </p>
                  </div>
                  <div className="d-flex my-2">
                    <span>Target :</span>
                    <p className="mx-2">
                      {campaignDetail.type_target === "qty" ? (
                        <CurrencyFormatter
                          digit={campaignDetail.target}
                          typeDigit={"qty"}
                        />
                      ) : (
                        <CurrencyFormatter
                          digit={campaignDetail.target}
                          typeDigit={"amount"}
                        />
                      )}
                    </p>
                  </div>
                  <div className="d-flex my-2">
                    <span>Start Date :</span>
                    <p className="mx-2">
                      {moment
                        .utc(campaignDetail.start_date)
                        .format("DD MMM YYYY")}
                    </p>
                  </div>
                  <div className="d-flex my-2">
                    <span>End Date :</span>
                    <p className="mx-2">
                      {moment
                        .utc(campaignDetail.end_date)
                        .format("DD MMM YYYY")}
                    </p>
                  </div>
                  <div className="my-2">
                    <span>Content</span>
                    <div
                      className="my-2"
                      dangerouslySetInnerHTML={{
                        __html: campaignDetail.content,
                      }}
                    ></div>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col sm="8">
            <Card style={{minHeight: '500px'}}>
              <CardBody className="p-auto">
                <img
                  className="m-auto"
                  src={campaignDetail.banner_url}
                  alt="CSI Promo"
				  style={{width: '100%'}}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={`${activeTabTable === 1 ? "active" : ""}`}
                      href="#"
                      onClick={() => toggleTabTable(1)}
                      style={{ cursor: "Pointer" }}
                    >
                      Products
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`${activeTabTable === 2 ? "active" : ""}`}
                      href="#"
                      onClick={() => toggleTabTable(2)}
                      style={{ cursor: "Pointer" }}
                    >
                      Participants
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`${activeTabTable === 3 ? "active" : ""}`}
                      href="#"
                      onClick={() => toggleTabTable(3)}
                      style={{ cursor: "Pointer" }}
                    >
                      Reward
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={`${activeTabTable}`}>
                  <TabPane tabId="1">
                    <CampaignProduct
                      typeProduct={typeProduct}
					  urlParam={uriParam.id}
                    />
                  </TabPane>
                  <TabPane tabId="2">
                    <CampaignParticipant
                      urlParam={uriParam.id}
                    />
                  </TabPane>
                  <TabPane tabId="3">
                    <CampaignReward
                      urlParam={uriParam.id}
                    />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CampaignDetail;
