import {
    GET_LIST_SECTION,
    GET_LIST_SECTION_ITEM
} from "../../actionTypes";

const initialState = {
    listSection: {},
    listSectionItem: {},
};

const SectionReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_SECTION:
            state.listSection = action.payload;
            return { ...state, listSection: state.listSection };
        case GET_LIST_SECTION_ITEM:
            state.listSectionItem = action.payload;
            return { ...state, listSectionItem: state.listSectionItem };
        default:
            return { ...state };
    }
};

export default SectionReducer;
