import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

//components
import { Button } from "reactstrap";
import Breadcrumbs from "@layout/breadcrumb";
import TableLayout from "@layout/tableLayout";
import TableCSI from "@components/table";
import BadgeStatus from "@components/badgeStatus";
import TableDropdown from "@components/customDropdown";

//action
import {
  getListPromoBundle,
  updateStatusBundle,
} from "@action/promoAction";

const BundlePromo = () => {
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [data, setData] = useState([]);
  const [totalEntry, setTotalEntry] = useState(0);
  const [totalEntryPerPage, setTotalEntryPerPage] = useState(0);

  const dispatch = useDispatch();
  const { listPromoBundle } = useSelector((state) => state.PromoReducer);

  const tableColumn = [
    {
      Header: () => null,
      id: "promo",
      columns: [
        {
          Header: "ID",
          accessor: "id",
          sortAble: true,
        },
        {
          Header: "Name",
          accessor: "name",
          sortAble: true,
        },
        {
          Header: "Harga",
          accessor: "harga",
          sortAble: true,
        },
        {
          Header: "Start Date",
          accessor: "start_date",
          sortAble: true,
          Cell: ({ row }) => (
            <span>
              {row.original.start_date !== "-"
                ? moment.utc(row.original.start_date).format("DD/MM/YYYY")
                : "-"}
            </span>
          ),
          sortType: (rowA, rowB, columnId, desc) => {
            let startDate = Date.parse(rowA.values[columnId]);
            let endDate = Date.parse(rowB.values[columnId]);
            if (startDate > endDate) return 1;
            if (startDate < endDate) return -1;
            return 0;
          },
        },
        {
          Header: "End Date",
          accessor: "end_date",
          sortAble: true,
          Cell: ({ row }) => (
            <span>
              {row.original.end_date !== "-"
                ? moment.utc(row.original.end_date).format("DD/MM/YYYY")
                : "-"}
            </span>
          ),
          sortType: (rowA, rowB, columnId, desc) => {
            let startDate = Date.parse(rowA.values[columnId]);
            let endDate = Date.parse(rowB.values[columnId]);
            if (startDate > endDate) return 1;
            if (startDate < endDate) return -1;
            return 0;
          },
        },
        {
          Header: "Status",
          accessor: "status",
          sortAble: true,
          Cell: ({ row }) => <BadgeStatus status={row.original.status} />,
          sortType: (rowA, rowB) => {
            let val1 = parseInt(rowA.original.status);
            let val2 = parseInt(rowB.original.status);
            if (val1 > val2) return 1;
            if (val1 < val2) return -1;
            return 0;
          },
        },
        {
          Header: "Action",
          accessor: "action",
          Cell: ({ row }) => (
            <TableDropdown
              dropdownContent={[
                {
                  label: "Edit",
                  type: "link",
                  link: `/dashboard/promo/bundle/edit/${row.original.id}`,
                },
              ]}
              onToggleStatus={onToggleStatus}
              row={row.original}
            />
          ),
          disableSortBy: true,
        },
      ],
    },
  ];

  const headerAction = (
    <Link to={`/dashboard/promo/bundle/add`}>
      <Button className="shadow-sm border-0" color="primary">
        Add New Bundle
      </Button>
    </Link>
  );

  useEffect(() => {
    if (listPromoBundle.data === undefined || listPromoBundle.data === null)
      return;
    const res = listPromoBundle.data.data;
    const dataresp = res.data;
    const mappedData = dataresp.map((item) => ({
      id: item.id,
      name: item.name,
      harga: item.price,
      start_date: item.start_date,
      end_date: item.end_date,
      status: item.active,
    }));

    setTotalEntry(res.total);
    setTotalEntryPerPage(res.per_page);
    setData(mappedData);
    setMaxPageIndex(res.last_page);
  }, [listPromoBundle.data]);

  useEffect(() => {
    dispatch(getListPromoBundle(1, ""));
  }, []);

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListPromoBundle(page, ""));
  };

  const onToggleStatus = (arg) => {
    dispatch(updateStatusBundle(arg.id.id, arg.toggle)).then((resp) => {
      if (!resp.status === 200) return false;

      const updatedData = data.map((item) => {
        if (item.id === arg.id.id) {
          return { ...item, status: arg.toggle === "active" ? 1 : 0 };
        }
        return item;
      });

      setData(updatedData);

      toast.success("Update Success");
    });
  };

  const handleSearch = (input) => {
    dispatch(getListPromoBundle(1, input));
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <Breadcrumbs
          parent="Promotion"
          title="Bundle"
          urlParent={"/dashboard/promo"}
        />
      </div>
      <TableLayout
        headerAction={headerAction}
        handleSearch={handleSearch}
        totalEntry={totalEntry}
        totalEntryPerPage={totalEntryPerPage}
        pageCount={maxPageIndex}
        handleChangePage={handlePageClick}
		handleChangeRow={(val) => dispatch(getListPromoBundle(1, "", val))}
      >
        <TableCSI columns={tableColumn} data={data} key={data} />
      </TableLayout>
    </>
  );
};

export default BundlePromo;
