import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getListProductLogs } from "../../../../redux/action/productAction";
import CustomPagination from "../../../../Component/customPagination";
import TableCSI from "../../../../Component/table";
import PaginationRow from "../../../../Component/paginationDataPerPage";
import iconSearch from "../../../../assets/images/input-search.png";
import Breadcrumb from "../../../../layout/breadcrumb";

const ProductLogs = (props) => {
  const urlParam = useParams();
  const [data, setData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [isFetchedData, setIsFetchedData] = useState(true);
  const [isAfterSearch, setIsAfterSearch] = useState(false);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [totalResult, setTotalResult] = useState(4);
  const [perPage, setPerPage] = useState(10);
  const [totalPerPage, setTotalPerPage] = useState(1);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const dispatch = useDispatch();
  const { listProductLogs } = useSelector((state) => state.ProductReducer);

  useEffect(() => {
    dispatch(getListProductLogs(urlParam.id));
    setTableColumn([
      {
        Header: () => null,
        id: "ProductLogs",
        columns: [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: "Value Name",
            accessor: "value_name",
          },
          {
            Header: "New Value",
            accessor: "new_value",
          },
          {
            Header: "Event",
            accessor: "event",
          },
        ],
      },
    ]);
  }, []);

  useEffect(() => {
    if (listProductLogs.data && !listProductLogs.loading) {
      let dataLog = listProductLogs?.data?.data?.diffs;
	  let mappedListData = dataLog.map((item) => ({
        id: item?.id,
        value_name: Object.keys(item?.new_values)[0],
        new_value: item?.new_values[Object.keys(item?.new_values)[0]],
        event: item?.event,
      }));
	  setData(mappedListData)

      setIsFetchedData(true);
    }
  }, [listProductLogs]);

  return (
    <Fragment>
      <Breadcrumb parent="Product" title="Log On Product Xyz" urlParent={'/dashboard/product'} />
      <Container fluid={true}>
        <Row>
          <Col sm={12}>
            <Card className="p-0">
              <CardBody className="p-3">

                {isFetchedData ? (
                  <TableCSI
                    data={data}
                    columns={tableColumn}
                    key={data}
                    initialSortBy={initialSortBy}
                  />
                ) : null}

              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ProductLogs;
