import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Row,
  Badge,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
  Label,
} from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import CurrencyFormatter from "../../../../../Component/currencyFormatter";

//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  clearDataList,
  fetchAdminProduct,
  searchAdminProduct,
} from "../../../../../redux/action/chatAction";

const BtnClose = styled.div`
  cursor: pointer;
  font-weight: bold;
  :hover {
    background: #bbb;
  }
`;

const AttachProduk = (props) => {
  const [showProdukDetail, setShowDetail] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [isEnableClearSearch, setIsEnableClearSearch] = useState(false);
  const [productPage, setProductPage] = useState(1);
  const [listProduk, setListProduk] = useState([]);
  const [listProdukTemp, setListProdukTemp] = useState([]);
  const [selectedProduk, setSelectedProduk] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [productDetail, setProductDetail] = useState({});
  const { listAdminProduct, listAdminProductSearch } = useSelector(
    (state) => state.ChatReducer
  );
  const dispatch = useDispatch();

  const toggleShowDetail = (data) => {
    setShowDetail(!showProdukDetail);
    setProductDetail(data);
  };

  const toggleCloseDetail = () => {
    setShowDetail(!showProdukDetail);
  };

  const toggleCloseProduct = (param) => {
    props.onClose(param);
  };

  const handleTypedSearch = (e) => {
    setSearchInput(e.target.value);
    if (searchInput !== "") {
      dispatch(searchAdminProduct(searchInput, 1));
      setIsEnableClearSearch(true);
    }
  };

  const handleOnEnterPress = () => {
    if (searchInput !== "") {
      setListProdukTemp(listProduk);
      dispatch(searchAdminProduct(searchInput, 1));
      setIsEnableClearSearch(true);
    }
  };

  const handleClearSearchInput = () => {
    if (isEnableClearSearch) {
      setSearchInput("");
      setIsEnableClearSearch(false);
    } else {
      setSearchInput("");
    }
  };

  const handleSelectedProduk = (item, e) => {
    if (e.target.checked) {
      setSelectedProduk([...selectedProduk, item]);
    } else {
      setSelectedProduk(selectedProduk.filter((arr) => arr.id !== item.id));
    }
  };

  const handleSendButton = () => {
    if (selectedProduk.length > 0) {
      document
        .querySelectorAll("input[type=checkbox]")
        .forEach((el) => (el.checked = false));
      props.handleUpdateAttachment(selectedProduk);
      props.setTypeAttachment("product");
      setSelectedProduk([]);
    }
    return;
  };

  const loadMore = () => {
    setProductPage(productPage + 1);
    setTimeout(() => {
      dispatch(fetchAdminProduct(productPage + 1));
    }, 300);
  };

  const fetchListProduct = (data) => {
    setListProduk(data);
  };

  useEffect(() => {
    if (listAdminProduct && listAdminProduct.length > 0) {
      fetchListProduct(listAdminProduct);
      setIsLoadingList(false);
    } else {
      fetchListProduct([]);
      setIsLoadingList(false);
    }
  }, [listAdminProduct]);

  useEffect(() => {
    if (listAdminProductSearch.data) {
      let searchResult = listAdminProductSearch.data.data;
      setListProduk(searchResult);
      setIsLoadingList(false);
    }
  }, [listAdminProductSearch]);

  useEffect(() => {
    dispatch(fetchAdminProduct(productPage));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearDataList());
    };
  }, []);

  return (
    <Fragment>
      {!showProdukDetail ? (
        <>
          <div>
            <div className="d-flex justify-content-between py-1">
              <span>
                <b>Pilih Produk</b>
              </span>
              <BtnClose onClick={() => toggleCloseProduct("")}>X</BtnClose>
            </div>
            <InputGroup>
              <Input
                placeholder="Cari Produk"
                value={searchInput}
                onChange={handleTypedSearch}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleOnEnterPress();
                }}
              />
              {searchInput !== "" ? (
                <InputGroupAddon addonType="prepend">
                  <Button
                    className="m-1"
                    onClick={() => handleClearSearchInput()}
                    close
                  />
                </InputGroupAddon>
              ) : null}
            </InputGroup>
          </div>

          <div className="my-3 att_prod_list" id="att_prod_list_id">
            {!isLoadingList && listProduk.length > 0 ? (
              <InfiniteScroll
                dataLength={listProduk.length}
                next={() => loadMore()}
                hasMore={hasMore}
                scrollThreshold={0.9}
                loader={
                  listProduk && listProduk.length < 1 ? <p>Loading...</p> : null
                }
                scrollableTarget="att_prod_list_id"
              >
                {listProduk.map((item, id) => (
                  <div className="d-flex my-3" key={id}>
                    <img
                      src={
                        item.images && item.images.length > 0
                          ? item.images[0].thumbnail_image_url
                          : null
                      }
                      alt=""
                      className="produk_detail_image"
                    />
                    <div
                      onClick={() => toggleShowDetail(item)}
                      className="mx-2 produk_list_detail"
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <p className="m-0 produk_name">{item.name}</p>
                        <p className="m-0 produk_price">
                          <b>
                            <CurrencyFormatter
                              digit={item.price}
                              typeDigit={"amount"}
                            />
                          </b>
                        </p>
                        <span className="produk_stock">
                          Stock: {item.stock}
                        </span>
                      </div>
                    </div>
                    <div className="mx-2">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            id={`checkbox-${id}`}
                            onChange={(e) => handleSelectedProduk(item, e)}
                          />
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            ) : isLoadingList ? (
              <div className="m-auto loader"></div>
            ) : null}
          </div>

          <div className="clearfix p-2 att_produk_btn">
            <div className="d-flex justify-content-between">
              <span className="text_produk_terpilih">Produk terpilih:</span>
              <Button
                onClick={() => handleSendButton()}
                className="my-auto btn_kirim"
                color="primary"
                block
                disabled={selectedProduk.length == 0}
              >
                Kirim
              </Button>
            </div>
            <div>
              <span className="text_qty_item">
                {selectedProduk.length} item
              </span>
            </div>
          </div>
        </>
      ) : (
        <div>
          <div className="d-flex my-1">
            <Button onClick={() => toggleCloseDetail()} close />
            <p className="mx-2 my-auto">
              <b>Detail Barang</b>
            </p>
          </div>
          <div className="att_produk_detail">
            <Card className="p-0">
              <CardBody className="p-2">
                <img
                  className="m-auto"
                  src={
                    productDetail.images.length > 0
                      ? productDetail.images[0].image_url
                      : ""
                  }
                  alt=""
                  width={200}
                />
                <div>
                  <p className="my-2">{productDetail.name}</p>
                  {productDetail.stock > 0 ? (
                    <Badge className="my-1" color="primary">
                      Stok Tersedia
                    </Badge>
                  ) : (
                    <Badge className="my-1" color="danger">
                      Stok Tidak Tersedia
                    </Badge>
                  )}

                  <p className="my-2">
                    <b>
                      <CurrencyFormatter
                        digit={productDetail.price}
                        typeDigit={"amount"}
                      />
                    </b>
                  </p>
                </div>
              </CardBody>
            </Card>
            <Card className="p-0">
              <CardBody className="p-2">
                <b>Informasi Produk</b>
                <div className="d-flex justify-content-between">
                  <span>Berat</span>
                  <span>{productDetail.weight} gram</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span>Brand</span>
                  <span>{productDetail.brand.name}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span>SKU</span>
                  <span>{productDetail.sku_code}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span>Category</span>
                  <span>{productDetail.category.name}</span>
                </div>
              </CardBody>
            </Card>
          </div>
          <Button className="my-2 att_produk_btn" color="primary" block>
            Lampirkan
          </Button>
        </div>
      )}
    </Fragment>
  );
};

export default AttachProduk;
