import { Fragment, useState, useEffect } from "react";

import { Container, Row, Col, Card, CardBody } from "reactstrap";
import Breadcrumbs from "@layout/breadcrumb";

const DetailHargaCoret = () => {
  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <Breadcrumbs
          parent="Promo Harga Coret"
          title="Detail Promo Harga Coret"
          urlParent={"/dashboard/promo/harga-coret"}
        />
      </div>
      <Container fluid>
        <Row>
          <Col>
            <Card>
              <CardBody></CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default DetailHargaCoret;