import React, { Fragment, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import {
    Container
} from "reactstrap";
import TableMutasi from "./table";

const MutasiLayout = (props) => {
    useEffect(() => {
        return () => {};
    }, []);

    return (
        <Fragment>
            <Breadcrumb parent="Dashboard" title="Mutasi BCA" urlParent={'/dashboard/analytics'} />
            <Container fluid={true}>
                <TableMutasi />
            </Container>
        </Fragment>
    );
};

export default MutasiLayout;
