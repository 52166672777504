import apiConfig from "../../api";
import {
  GET_LIST_CATEGORY,
  DELETE_CATEGORY,
  GET_PARENT_CATEGORY,
  GET_CHILD_CATEGORY,
  CHANGE_STATUS_CATEGORY,
  WATCH_RESPONSE_ALERT,
  GET_CATEGORY_DETAIL,
} from "../actionTypes";

export const getListCategory = (perPage, page = 1, param = "") => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(
        `/api/admin/category?page=${page}&search=${param}&perPage=${perPage}`
      );
      dispatch({
        type: GET_LIST_CATEGORY,
        payload: {
          loading: false,
          data: resp.data.data,
          err: false,
        },
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });
    }
  };
};

export const getCategoryDetail = (id) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(`/api/admin/category/detail/${id}`);
      dispatch({
        type: GET_CATEGORY_DETAIL,
        payload: {
          loading: false,
          data: resp.data.data,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });

      return err;
    }
  };
};

export const addDataCategory = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(`/api/admin/category`, payload);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          err: false,
        },
      });
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });
    }
  };
};

export const updateDataCategory = (payload, id) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(
        `/api/admin/category/update/${id}`,
        payload
      );
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });

      return err;
    }
  };
};

export const deleteDataCategory = (id) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_CATEGORY,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .delete(`/api/admin/category/${id}`)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: true,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const getParentCategory = (perPage, page = 1, param = "") => {
  return (dispatch) => {
    dispatch({
      type: GET_PARENT_CATEGORY,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(
        `/api/admin/category/parent?page=${page}&search=${param}&perPage=${perPage}`
      )
      .then((resp) => {
        dispatch({
          type: GET_PARENT_CATEGORY,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            err: true,
          },
        });
      });
  };
};

export const getChildCategory = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_CHILD_CATEGORY,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/categories/${id}/childs`)
      .then((resp) => {
        dispatch({
          type: GET_CHILD_CATEGORY,
          payload: {
            loading: false,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            err: true,
          },
        });
      });
  };
};

export const changeStatusCategory = (id) => {
  return (dispatch) => {
    dispatch({
      type: CHANGE_STATUS_CATEGORY,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/category/status/${id}`)
      .then((resp) => {
        dispatch({
          type: CHANGE_STATUS_CATEGORY,
          payload: {
            loading: false,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            err: true,
          },
        });
      });
  };
};
