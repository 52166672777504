import apiConfig from "../../api";
import {
  LIST_CAMPAIGN,
  LIST_PRODUCT_SEARCH,
  LIST_CATEGORY_SEARCH,
  LIST_BRAND_SEARCH,
  LIST_PARTICIPANT_SEARCH,
  DETAIL_CAMPAIGN,
  LIST_CAMPAIGN_PRODUCT,
  LIST_CAMPAIGN_PARTICIPANT,
  WATCH_RESPONSE_ALERT,
  LIST_PRODUCT_CAMPAIGN_ALL,
} from "../actionTypes";

export const fetchListCampaign = (page = 1, param = '', row = 10) => {
  return (dispatch) => {
    dispatch({
      type: LIST_CAMPAIGN,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/campaign?page=${page}&search=${param}&perPage=${row}`)
      .then((resp) => {
        dispatch({
          type: LIST_CAMPAIGN,
          payload: {
            loading: false,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: LIST_CAMPAIGN,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const createCampaign = (data) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });
    apiConfig
      .post("/api/admin/campaign", data)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: true,
            err: false,
          },
        });
        if (process.env.NODE_ENV === "development") {
          window.location.href = `/dashboard/campaign`;
        } else {
          window.location.href = `/campaign`;
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const searchProduct = (param) => {
  return (dispatch) => {
    dispatch({
      type: LIST_PRODUCT_SEARCH,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/products?search=${param}&page=${1}`)
      .then((resp) => {
        dispatch({
          type: LIST_PRODUCT_SEARCH,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: LIST_PRODUCT_SEARCH,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const searchCategory = (param) => {
  return (dispatch) => {
    dispatch({
      type: LIST_CATEGORY_SEARCH,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/category?search=${param}&page=${1}`)
      .then((resp) => {
        dispatch({
          type: LIST_CATEGORY_SEARCH,
          payload: {
            loading: false,
            data: resp.data.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: LIST_CATEGORY_SEARCH,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const searchBrand = (param) => {
  return (dispatch) => {
    dispatch({
      type: LIST_BRAND_SEARCH,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/brand?search=${param}&page=${1}`)
      .then((resp) => {
        dispatch({
          type: LIST_BRAND_SEARCH,
          payload: {
            loading: false,
            data: resp.data.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: LIST_BRAND_SEARCH,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const searchParticipant = (param) => {
  return (dispatch) => {
    dispatch({
      type: LIST_PARTICIPANT_SEARCH,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/users?search=${param}&page=${1}`)
      .then((resp) => {
        dispatch({
          type: LIST_PARTICIPANT_SEARCH,
          payload: {
            loading: false,
            data: resp.data.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: LIST_PARTICIPANT_SEARCH,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const fetchDetailCampaign = (param) => {
  return (dispatch) => {
    dispatch({
      type: DETAIL_CAMPAIGN,
      payload: {
        loading: true,
        data: {},
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/campaign/${param}`)
      .then((resp) => {
        dispatch({
          type: DETAIL_CAMPAIGN,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: DETAIL_CAMPAIGN,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const fetchProductCampaign = (param, page) => {
  return (dispatch) => {
    dispatch({
      type: LIST_CAMPAIGN_PRODUCT,
      payload: {
        loading: true,
        data: [],
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/campaign/items/${param}?page=${page}`)
      .then((resp) => {
        dispatch({
          type: LIST_CAMPAIGN_PRODUCT,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: LIST_CAMPAIGN_PRODUCT,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const fetchParticipantCampaign = (param, page) => {
  return (dispatch) => {
    dispatch({
      type: LIST_CAMPAIGN_PARTICIPANT,
      payload: {
        loading: true,
        data: [],
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/campaign/participants/${param}?page=${page}`)
      .then((resp) => {
        dispatch({
          type: LIST_CAMPAIGN_PARTICIPANT,
          payload: {
            loading: false,
            data: resp.data.data.data,
            total: resp.data.data.total,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: LIST_CAMPAIGN_PARTICIPANT,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const addProductCampaign = (data) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post("/api/admin/campaign/items", data)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const addParticipantCampaign = (data) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post("/api/admin/campaign/participants", data)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const editCampaign = (data) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post("/api/admin/campaign/update", data)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: true,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const deleteItems = (id) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .delete(`/api/admin/campaign/items/${id}`)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const deactivateCampaign = (id, status) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/campaign/${id}/${status}`)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            reload: true,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const deleteCampaign = (id) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.delete(`/api/admin/campaign/${id}`);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: false,
          err: false,
        },
      });

	  return resp
    } catch (err) {
      console.log(err);
    }
  };
};

export const pushNotificationCampaign = (data) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post(`/api/admin/campaign/notification`, data)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const getAllProductCampaign = (param) => {
  return (dispatch) => {
    dispatch({
      type: LIST_PRODUCT_CAMPAIGN_ALL,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(
        `/api/admin/products?${
          param !== "" ? `search=${param}&` : ``
        }perPage=all`
      )
      .then((resp) => {
        dispatch({
          type: LIST_PRODUCT_CAMPAIGN_ALL,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: LIST_PRODUCT_CAMPAIGN_ALL,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const getAllCategoryCampaign = (param) => {
  return (dispatch) => {
    dispatch({
      type: LIST_PRODUCT_CAMPAIGN_ALL,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(
        `/api/admin/category?${
          param !== "" ? `search=${param}&` : ``
        }perPage=all`
      )
      .then((resp) => {
        dispatch({
          type: LIST_PRODUCT_CAMPAIGN_ALL,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: LIST_PRODUCT_CAMPAIGN_ALL,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const getAllBrandCampaign = (param) => {
  return (dispatch) => {
    dispatch({
      type: LIST_PRODUCT_CAMPAIGN_ALL,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(
        `/api/admin/brand?${param !== "" ? `search=${param}&` : ``}perPage=all`
      )
      .then((resp) => {
        dispatch({
          type: LIST_PRODUCT_CAMPAIGN_ALL,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: LIST_PRODUCT_CAMPAIGN_ALL,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const handleEditCampaignReward = (payload) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post(`/api/admin/campaign/participants/update`, payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const setReminderCampaign = (payload) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post(`/api/admin/campaign/notification`, payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const resetState = () => {
  return (dispatch) => {
    dispatch({
      type: DETAIL_CAMPAIGN,
      payload: {
        loading: false,
        data: {},
        err: false,
      },
    });
    dispatch({
      type: LIST_CAMPAIGN_PRODUCT,
      payload: {
        loading: false,
        data: [],
        err: false,
      },
    });
    dispatch({
      type: LIST_CAMPAIGN_PARTICIPANT,
      payload: {
        loading: false,
        data: [],
        err: false,
      },
    });
  };
};
