import LevelLayout from "../../pages/dashboard/master/level";
import LevelAdd from "../../pages/dashboard/master/level/add";
import LevelEdit from "../../pages/dashboard/master/level/edit";

export const RouteLevel = [
  {
    path: `/dashboard/level`,
    Component: LevelLayout,
  },
  {
    path: `/dashboard/level/add`,
    Component: LevelAdd,
  },
  {
    path: `/dashboard/level/edit/:id`,
    Component: LevelEdit,
  },
];
