import News from "../../pages/dashboard/master/news";
import NewsAdd from "../../pages/dashboard/master/news/add";
import NewsEdit from "../../pages/dashboard/master/news/edit";
import NewsDetail from "../../pages/dashboard/master/news/detail";

export const RouteNews = [
    {
        path: `/dashboard/news`,
        Component: News,
    },
    {
        path: `/dashboard/news/add`,
        Component: NewsAdd,
    },
    {
        path: `/dashboard/news/edit/:id`,
        Component: NewsEdit,
    },
    {
        path: `/dashboard/news/detail/:id`,
        Component: NewsDetail,
    },
];
