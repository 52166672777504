import { styled } from "@mui/material/styles";
import {
  DialogTitle,
  Dialog,
  DialogContent,
  Box,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
  "& .MuiDialog-paper": {
    borderRadius: "10px",
  },
}));

export const ModalDialog = (props) => {
  const { open, onClose, title, children, backdropClose, maxHeight, maxWidth } =
    props;
  return (
    <BootstrapDialog
      onClose={backdropClose ? onClose : null}
      aria-labelledby="modal dialog"
      open={open}
      BackdropProps={{ style: { background: "rgba(18, 24, 38, 0.6)" } }}
      sx={{
        zIndex: 9999999999999,
      }}
    >
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Typography
          style={{ fontWeight: "bold", fontSize: "14px", width: "90%" }}
        >
          {title}
        </Typography>
        <Typography
          style={{
            fontWeight: "normal",
            fontSize: "12px",
            width: "10%",
            textAlign: "right",
            cursor: "pointer",
          }}
          onClick={onClose}
        >
          X
        </Typography>
      </DialogTitle>
      <DialogContent className="overflow-y-auto" xs={{ width: "100%" }}>
        <Box
          width={{ xs: 280, sm: 400, md: 500 }}
          style={{
            maxHeight: maxHeight === 0 ? "auto" : maxHeight,
            maxWidth: maxWidth === 0 ? "auto" : maxWidth,
          }}
        >
          {children}
        </Box>
      </DialogContent>
    </BootstrapDialog>
  );
};

ModalDialog.defaultProps = {
  backdropClose: true,
  disabled: false,
  footer: true,
  maxHeight: 0,
  open: false,
  onSubmit: () => console.log("Submit Modal"), // eslint-disable-line no-console
};

ModalDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  onSubmit: PropTypes.func,
  footer: PropTypes.bool,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  children: PropTypes.node,
  backdropClose: PropTypes.bool,
  maxHeight: PropTypes.number,
  submitType: PropTypes.string,
  form: PropTypes.string,
};
