import React, { useEffect, useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { createUserAdmin } from "../../../../../redux/action/userAdminAction";

import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import styled from "styled-components";

const BtnBlue = styled.button`
  background-color: blue;
  color: white;
  border-radius: 4px;
  border: 1px solid transparent;
  min-width: 100px;
`;

const BtnWhite = styled.button`
  background-color: white;
  color: black;
  border-radius: 4px;
  border: 1px solid grey;
  min-width: 100px;
`;

const AdminAdd = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  const [formPayload, setFormPayload] = useState({
    username: "",
    role: "",
    password: "",
    password_confirmation: "",
  });

  const validateForm = (data) => {
    if (
      data.username === "" ||
      data.role === "" ||
      data.password === "" ||
      data.password_confirmation === ""
    ) {
      toast.error("Please Fill all Fields");
      return true;
    }
    return false;
  };

  const handleSubmitForm = () => {
    if (!validateForm(formPayload)) {
      let dataForm = new FormData();
      dataForm.append("username", formPayload.username);
      dataForm.append("role", formPayload.role);
      dataForm.append("password", formPayload.password);
      dataForm.append(
        "password_confirmation",
        formPayload.password_confirmation
      );
      dispatch(createUserAdmin(dataForm));
	  history.push("/dashboard/admin");
    }
  };

  const handleCancel = () => {
    history.push("/dashboard/admin");
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Admin"
        title="Add New Admin"
        urlParent="/dashboard/admin"
      />
      <Container fluid>
        <Row>
          <Col sm={8}>
            <Card>
              <CardBody className="p-3">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FormGroup row>
                    <Label for="formUsername" sm={4}>
                      <b>Username</b>
                    </Label>
                    <Col sm={8}>
                      <Input
                        placeholder="Enter Username"
                        id="formUsername"
                        value={formPayload.username}
                        onChange={(e) =>
                          setFormPayload({
                            ...formPayload,
                            username: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="formPassword" sm={4}>
                      <b>Password</b>
                    </Label>
                    <Col sm={8}>
                      <Input
                        placeholder="Enter Password"
                        id="formPassword"
                        type="password"
                        value={formPayload.password}
                        onChange={(e) =>
                          setFormPayload({
                            ...formPayload,
                            password: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="formConfirmPassword" sm={4}>
                      <b>Confirm Password</b>
                    </Label>
                    <Col sm={8}>
                      <Input
                        placeholder="Confirm Password"
                        id="formConfirmPassword"
                        type="password"
                        value={formPayload.password_confirmation}
                        onChange={(e) =>
                          setFormPayload({
                            ...formPayload,
                            password_confirmation: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="formRole" sm={4}>
                      <b>Role</b>
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="select"
                        name="select"
                        id="formRole"
                        value={formPayload.role}
                        onChange={(e) =>
                          setFormPayload({
                            ...formPayload,
                            role: e.target.value,
                          })
                        }
                      >
                        <option value="">Select Role</option>
                        <option value="2">Admin</option>
                        <option value="3">Superadmin</option>
                        <option value="4">IT Staff</option>
                        <option value="5">Customer Service</option>
                      </Input>
                    </Col>
                  </FormGroup>
                  <div className="d-flex justify-content-end">
                    <BtnWhite className="py-2 px-3 mx-1" onClick={handleCancel}>
                      Cancel
                    </BtnWhite>
                    <BtnBlue
                      className="py-2 px-3 mx-1"
                      onClick={handleSubmitForm}
                    >
                      Save
                    </BtnBlue>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AdminAdd;
