import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import SelectList from "@components/selectList";

import { getListUserAdmin } from "@action/userAdminAction";
import {
  getListRoleApproval,
  createMasterApproval,
} from "@action/masterAprovalAction";

const PopupAdd = ({ isOpen, togglePopup, index }) => {
  const dispatch = useDispatch();
  const { listUserAdmin } = useSelector((state) => state.UserAdminReducer);
  const { listRoleApproval } = useSelector(
    (state) => state.MasterApprovalReducer
  );

  const [adminList, setAdminList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [userID, setUserID] = useState("");
  const [role, setRole] = useState("");

  useEffect(() => {
    if (isOpen) {
      dispatch(getListUserAdmin(1, "", 500));
      dispatch(getListRoleApproval());
    }
  }, [isOpen]);

  useEffect(() => {
    if (listRoleApproval.data && !listRoleApproval.loading) {
      const list = listRoleApproval.data.data;
      const mapped = list.map((item) => ({
        id: item.id,
        name: item.level_name,
        val: item.level,
      }));
      setRoleList(mapped);
    }
  }, [listRoleApproval]);

  useEffect(() => {
    if (listUserAdmin.data && !listUserAdmin.loading) {
      const list = listUserAdmin.data?.data.data;
      const mapped = list.map((item) => ({
        id: item.id,
        name: item.username,
        val: item.id,
      }));

      setAdminList(mapped);
    }
  }, [listUserAdmin]);

  function handleOnSubmit() {
    if (userID === "" || role === "") {
      toast.error("User or Role cannot empty.");
      return;
    }

    let form = new FormData();
    form.append("user_id", userID);
    form.append("role", role);

    dispatch(createMasterApproval(form)).then((res) => {
      if (res.status !== 200) return;

      togglePopup();
    });
  }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={togglePopup} centered autoFocus>
        <ModalHeader toggle={togglePopup} charCode="X">
          Add Approver
        </ModalHeader>
        <ModalBody className="d-flex flex-column">
          <p>
            <b>Approver</b>
          </p>
          <div className="my-3">
            <span>
              <b>Role</b>
              <span style={{ color: "red" }}>*</span>
            </span>
            <SelectList
              values={roleList}
              onChange={(val) => setRole(val.target.value)}
              placeholder="Select Role"
            />
          </div>
          <div className="my-3">
            <span>
              <b>User</b>
              <span style={{ color: "red" }}>*</span>
            </span>
            <SelectList
              values={adminList}
              onChange={(val) => setUserID(val.target.value)}
              placeholder="Select User"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={togglePopup} outline>
            Cancel
          </Button>
          <Button color="primary" onClick={handleOnSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PopupAdd;
