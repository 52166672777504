import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../../../layout/breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import TableCSI from "../../../Component/table";
import CustomPagination from "../../../Component/customPagination";
import EntryCounter from "../../../Component/entryCounter";
import iconSearch from "../../../assets/images/input-search.png";

import {
  getListBroadcast,
  getListBroadcastBySearch,
} from "../../../redux/action/broadcastAction";

const ButtonAction = styled.button`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 8px;
  background: #ffffff;
  border: 1px solid #d1d5dc;
  border-radius: 6px;
  text-align: center;
`;

const StatusKirim = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2px 10px;
  background: #f9fafb;
  border: 1px solid #d1d5dc;
  border-radius: 6px;
  max-width: 120px;
  font-size: 12px;
`;

const BroadcastDetailModal = (props) => {
  const handleCloseDetailModal = () => {
    props.handleCloseDetailModal(false);
  };
  return (
    <Modal isOpen={props.isOpenDetailModal} toggle={handleCloseDetailModal}>
      <ModalHeader toggle={handleCloseDetailModal}>
        Detail Broadcast
      </ModalHeader>
      <ModalBody>
        <div className="my-2">
          <p className="my-1">
            <b>Nama Broadcast</b>
          </p>
          <span>{props.data.name}</span>
        </div>
        <div className="my-2">
          <p className="my-1">
            <b>Created at</b>
          </p>
          <span>{props.data.sendTime}</span>
        </div>
        <div className="my-2">
          <p className="my-1">
            <b>Segment</b>
          </p>
          <span>{props.data.segment}</span>
        </div>
        <div className="my-2">
          <p className="my-1">
            <b>Content</b>
          </p>
          <span>{props.data.content}</span>
        </div>
        <div className="my-2">
          <p className="my-1">
            <b>Voucher</b>
          </p>
          <span>{props.data.voucher}</span>
        </div>
      </ModalBody>
    </Modal>
  );
};

const BroadcastLayout = () => {
  const [isFetchedData, setIsFetchedData] = useState(false);
  const [mappedListData, setMappedListData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [isAfterSearch, setIsAfterSearch] = useState(false);
  const [totalResult, setTotalResult] = useState(0);
  const [totalPerPage, setTotalPerPage] = useState(0);
  const [isOpenDetailModal, setOpenDetailModal] = useState(false);
  const [dataDetailModal, setDataDetailModal] = useState({});

  const dispatch = useDispatch();
  const { listBroadcast } = useSelector((state) => state.BroadcastReducer);

  useEffect(() => {
    setTableColumn([
      {
        Header: () => null,
        id: "promo",
        columns: [
          {
            Header: "Nama Broadcast",
            accessor: "name",
            sortAble: true,
          },
          {
            Header: "Waktu Dikirim",
            accessor: "sendTime",
            sortAble: true,
          },
          {
            Header: "Status",
            accessor: "status",
            sortAble: true,
            Cell: ({ row }) => <StatusKirim>{"Terkirim"}</StatusKirim>,
          },
          {
            Header: "Action",
            accessor: "action",
            Cell: ({ row }) => (
              <ButtonAction onClick={() => handleOpenDetailModal(row.original)}>
                ...
              </ButtonAction>
            ),
            disableSortBy: true,
            sortAble: false,
          },
        ],
      },
    ]);

    dispatch(getListBroadcast(1));
  }, []);

  useEffect(() => {
    if (listBroadcast.data && !listBroadcast.loading) {
      const dataBC = listBroadcast.data?.data?.data;
      if (dataBC === undefined || dataBC === null) return false;

      setMaxPageIndex(listBroadcast.data?.data?.last_page);
      const mappedData = dataBC.map((item) => ({
        id: item.id,
        name: item.nama_broadcast,
        sendTime: item.created_at,
        status: item.status ? item.status : "null",
        read: item.read ? item.read : "null",
        segment: item.segment_type,
        voucher: item.voucher,
        content: item.content,
        sender_type: item.sender_type,
      }));

      setIsFetchedData(true);
      setMappedListData(mappedData);
    }
  }, [listBroadcast]);

  const handleOpenDetailModal = (data) => {
    setOpenDetailModal(!isOpenDetailModal);
    setDataDetailModal(data);
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListBroadcast(page));
    setIsAfterSearch(true);
  };

  const handleOnEnter = (e) => {
    if (inputSearchValue !== "") {
      dispatch(getListBroadcastBySearch(1, inputSearchValue));
      setIsAfterSearch(true);
    }
  };

  const handleSubmitSearch = () => {
    if (inputSearchValue !== "") {
      dispatch(getListBroadcastBySearch(1, inputSearchValue));
      setIsAfterSearch(true);
    }
  };

  const handleCloseDetailModal = (val) => {
    setOpenDetailModal(val);
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Dashboard"
        title="Broadcast"
        urlParent={"/dashboard/analytics"}
      />
      <Container fluid={true}>
        <Row>
          <Col>
            <Card className="p-0">
              <CardBody className="p-3">
                <div className="d-flex justify-content-between my-2">
                  <div className="d-flex">
                    <InputGroup style={{ maxWidth: "280px" }}>
                      <Input
                        placeholder="Search"
                        value={inputSearchValue}
                        onChange={(e) => setInputSearchValue(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") handleOnEnter(e);
                        }}
                      />
                      <InputGroupAddon addonType="prepend">
                        <Button
                          className="p-2"
                          color="primary"
                          onClick={() => handleSubmitSearch()}
                        >
                          <img src={iconSearch} width={15} height={15} />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>

                    <EntryCounter
                      totalPerPage={totalPerPage}
                      totalResult={totalResult}
                    />
                  </div>
                  <Link to="/dashboard/broadcast/add">
                    <Button color="primary" outline>
                      Add new Broadcast
                    </Button>
                  </Link>
                </div>

                {isFetchedData ? (
                  <TableCSI
                    data={mappedListData}
                    columns={tableColumn}
                    key={mappedListData}
                    initialSortBy={initialSortBy}
                  />
                ) : null}

                <CustomPagination
                  handlePageClick={handlePageClick}
                  pageCount={maxPageIndex}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={2}
                />
              </CardBody>
            </Card>
            <BroadcastDetailModal
              isOpenDetailModal={isOpenDetailModal}
              data={dataDetailModal}
              handleCloseDetailModal={handleCloseDetailModal}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BroadcastLayout;
