import { Fragment, useEffect, useState } from "react";
import Breadcrumb from "@layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { useParams, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  createTransactionDocument,
  getDetailTransactionDocument,
  updateTransactionDocument,
} from "@action/transactionAction";
import TableCSI from "@components/table";

const defaultPayload = {
  pick_ticket_no: "",
  si_no: "",
  do_no: "",
  qty: 0,
};

const AddDocumentTransaction = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const urlParam = useParams();
  const { transactionDetail, transactionDetailDocument } = useSelector(
    (state) => state.TransactionReducer
  );
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [payload, setPayload] = useState(defaultPayload);
  const [data, setData] = useState([]);
  const [isFetchedData, setIsFetchedData] = useState(false);
  const [tableColumnItems, setTableColumnItems] = useState([]);

  useEffect(() => {
    if (urlParam?.id_item) {
      dispatch(getDetailTransactionDocument(urlParam?.id, urlParam?.id_item));
    }
  }, []);

  useEffect(() => {
    if (transactionDetail) {
      let dataTable = transactionDetail?.data?.transactionItems?.map(
        (item) => ({
          id: item?.product?.id,
          id_items: item?.id,
          sku_code: item?.product?.sku_code,
          product_name: item?.product?.name,
          qty: item?.deliver_qty,
        })
      );
      setData(dataTable);
      setIsFetchedData(true);
      setTimeout(() => {
        setTableColumnItems([
          {
            Header: () => null,
            id: "tableTransItem",
            columns: [
              {
                Header: "SKU Code",
                accessor: "sku_code",
                sortAble: false,
              },
              {
                Header: "Product Name",
                accessor: "product_name",
                sortAble: false,
              },
              {
                Header: "Qty Picked",
                accessor: "qty",
                sortAble: false,
                center: true,
                Cell: ({ row }) => {
                  let rawData = dataTable;
                  let datas = data?.length > 0 ? data : rawData;
                  return (
                    <div className="d-flex">
                      <Input
                        placeholder={rawData[row?.index]?.qty}
                        id="formOrdering"
                        type={"number"}
                        readOnly={location?.type === "Detail"}
                        onChange={(event) => {
                          datas[row?.index] = {
                            ...datas[row?.index],
                            qty:
                              event.target.value === ""
                                ? rawData[row?.index]?.qty
                                : Number(event.target.value),
                          };
                          setData(datas);
                        }}
                      />
                    </div>
                  );
                },
              },
            ],
          },
        ]);
      }, 500);
    } else setData([]);
  }, [transactionDetail]);

  useEffect(() => {
    if (
      urlParam?.id_item &&
      transactionDetailDocument?.data &&
      !transactionDetailDocument?.loading
    ) {
      let payloads = {
        pick_ticket_no:
          transactionDetailDocument?.data?.transactionDocuments?.pick_ticket_no,
        si_no: transactionDetailDocument?.data?.transactionDocuments?.si_no,
        do_no: transactionDetailDocument?.data?.transactionDocuments?.do_no,
        details: transactionDetailDocument?.data?.transactionDocuments?.details,
      };
      setPayload(payloads);
    }
  }, [transactionDetailDocument]);

  const handleSubmit = () => {
    setDisableSubmit(true);
    let payloads = payload;
    const dataForm = new FormData();
    dataForm.append("pick_ticket_no", payload?.pick_ticket_no);
    dataForm.append("do_no", payload?.do_no);
    dataForm.append("si_no", payload?.si_no);
    data &&
      data?.map((item, index) => {
        let ids = urlParam?.id_item
          ? payload?.details[index]?.id
          : item?.id_items;
        payloads = {
          ...payloads,
          [`qty[${ids}]`]: item?.qty,
        };
        delete payloads?.details;
        dataForm.append(`qty[${item.id_items}]`, item?.qty);
      });
    if (urlParam?.id_item) {
      const dataFormUpdate = new URLSearchParams(
        Object.entries(payloads)
      ).toString();
      dispatch(
        updateTransactionDocument(
          urlParam?.id,
          urlParam?.id_item,
          dataFormUpdate
        )
      );
    } else dispatch(createTransactionDocument(urlParam?.id, dataForm));

    setTimeout(() => {
      setDisableSubmit(false);
    }, 500);
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Transaction"
        title={`${location?.type || "Create"} Transaction Document`}
        urlParent={"/dashboard/analytics"}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <h5>Transaction Document Details</h5>
                <div className="my-3">
                  <Form>
                    <FormGroup row>
                      <Label for="ticketNo" sm={2}>
                        Invoice No.
                      </Label>
                      <Col sm={4}>
                        <Input
                          readOnly={true}
                          name="invoiceNo"
                          id="invoiceNo"
                          value={
                            transactionDetail?.data?.transaction?.invoice_no
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="ticketNo" sm={2}>
                        SO Number
                      </Label>
                      <Col sm={4}>
                        <Input
                          readOnly={true}
                          name="soNumber"
                          id="soNumber"
                          value={transactionDetail?.data?.transaction?.nav_id}
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="ticketNo" sm={2}>
                        Pick Ticket No.
                      </Label>
                      <Col sm={4}>
                        <Input
                          type="text"
                          name="ticketNo"
                          id="ticketNo"
                          readOnly={location?.type === "Detail"}
                          value={payload?.pick_ticket_no}
                          onChange={(event) =>
                            setPayload({
                              ...payload,
                              pick_ticket_no: event.target.value,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="doNumber" sm={2}>
                        DO Number
                      </Label>
                      <Col sm={4}>
                        <Input
                          type="text"
                          name="doNumber"
                          id="doNumber"
                          readOnly={location?.type === "Detail"}
                          value={payload?.do_no}
                          onChange={(event) =>
                            setPayload({
                              ...payload,
                              do_no: event.target.value,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="siNumber" sm={2}>
                        SI Number
                      </Label>
                      <Col sm={4}>
                        <Input
                          type="text"
                          name="siNumber"
                          id="siNumber"
                          readOnly={location?.type === "Detail"}
                          value={payload?.si_no}
                          onChange={(event) =>
                            setPayload({
                              ...payload,
                              si_no: event.target.value,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                  </Form>
                </div>
                <div>
                  {isFetchedData ? (
                    <TableCSI
                      data={data}
                      columns={tableColumnItems}
                      key={data}
                    />
                  ) : null}
                </div>
                <div className="my-2 d-flex justify-content-end">
                  {location?.type === "Detail" ? null : (
                    <Button
                      color="primary"
                      type={"button"}
                      onClick={() => handleSubmit()}
                      disabled={disableSubmit}
                    >
                      Submit
                    </Button>
                  )}
                  <Link to={`/dashboard/transaction/detail/${urlParam?.id}`}>
                    <Button
                      className="mx-2"
                      outline
                      color="secondary"
                      type="button"
                    >
                      Cancel
                    </Button>
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddDocumentTransaction;
