import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Button, Input, Label, FormGroup, Col } from "reactstrap";

const InputMultiSelectProduct = ({
  options,
  onInputChange,
  onSelectChange,
  currentValue,
  label,
  isRequired,
}) => {
  const [isDisableSelect, setIsDisableSelect] = useState(true);
  const [inputVal, setInputVal] = useState("");
  const [selectedData, setSelectedData] = useState([]);

  function handleSearchInput(val) {
    setInputVal(val);
    onInputChange(val);
  }

  function handleSelectedProduct(item) {
    setSelectedData((prevState) => {
      const itemExists = prevState.some(
        (selectedItem) => selectedItem.id === item.id
      );

      if (!itemExists) {
        const updatedData = [...prevState, item];
        return updatedData;
      }
      return prevState;
    });
    setIsDisableSelect(true);
  }

  function removeSelected(e, item) {
    e.stopPropagation();
    const newArr = selectedData.filter((arr) => arr.id !== item.id);
    setSelectedData(newArr);
  }

  useEffect(() => {
    onSelectChange(selectedData);
  }, [selectedData]);

  useEffect(() => {
    if (currentValue && currentValue.length > 0) {
	  setSelectedData(currentValue)
    }
  }, [currentValue]);

  return (
    <Fragment>
      <FormGroup row>
        <Label className="font-weight-bold" for={`form${label}`} sm={2}>
          {label}
          {isRequired ? (
            <span className="mx-1" style={{ color: "red" }}>
              *
            </span>
          ) : null}
        </Label>
        <Col id={`form${label}`} sm={10}>
          <div
            className="d-flex flex-row border rounded overflow-auto p-2"
            onClick={() => setIsDisableSelect(!isDisableSelect)}
            style={{ cursor: "pointer", maxWidth: "100%", zIndex: "0" }}
          >
            {selectedData.length < 1 ? (
              <span className="my-2">Search {label}</span>
            ) : null}

            {selectedData.map((item) => (
              <div
                key={item.id}
                className="d-flex flex-row border rounded justify-content-between p-1 ml-1"
                style={{ backgroundColor: "#F0F2F5", zIndex: "2" }}
              >
                <span>{item.name}</span>
                <button
                  className="border-0 bg-transparent"
                  onClick={(e) => removeSelected(e, item)}
                >
                  <i
                    className="fa fa-times"
                    aria-hidden="true"
                    style={{ color: "#9AA2B1" }}
                  ></i>
                </button>
              </div>
            ))}

            <i className="fa fa-caret-down ml-auto my-2" aria-hidden="true"></i>
          </div>
          <div
            className={`${
              isDisableSelect ? "d-none" : "d-flex flex-column"
            } position-absolute w-100 bg-light`}
            style={{ zIndex: "999" }}
          >
            <Input
              className="my-1"
              type="text"
              placeholder={`Search ${label}...`}
              value={inputVal}
              onChange={(e) => handleSearchInput(e.target.value)}
            ></Input>
            <div
              className="d-flex flex-column overflow-auto"
              style={{ maxHeight: "40dvh" }}
            >
              {options &&
                options.map((opt) => {
                  return (
                    <Button
                      key={opt.id}
                      className="my-1"
                      type="button"
                      color="light"
                      outline
                      onClick={() => handleSelectedProduct(opt)}
                      style={{ border: "none" }}
                    >
                      {opt.name}
                    </Button>
                  );
                })}
            </div>
          </div>
        </Col>
      </FormGroup>
    </Fragment>
  );
};

InputMultiSelectProduct.propTypes = {
  options: PropTypes.array.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  currentValue: PropTypes.any,
  label: PropTypes.string,
};

export default InputMultiSelectProduct;
