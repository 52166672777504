import { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

import { Button, Input, Label, FormGroup, Col } from "reactstrap";

import "./styleForm.css";

const InputSelectSearch = ({
  options,
  onInputChange,
  onSelectChange,
  currentValue,
  label,
}) => {
  const [isDisableSelect, setIsDisableSelect] = useState(true);
  const [inputVal, setInputVal] = useState("");
  const [selectedData, setSelectedData] = useState({ id: "", value: "" });

  const handleSelect = (opt) => {
    onSelectChange(opt.id);
    setSelectedData({ ...selectedData, id: opt.id, value: opt.value });
    setIsDisableSelect(!isDisableSelect);
  };

  //useEffect for debouncing method
  useEffect(() => {
    const getDebounceData = setTimeout(() => {
      onInputChange(inputVal);
    }, 300);

    return () => clearTimeout(getDebounceData);
  }, [inputVal]);

  return (
    <Fragment>
      <FormGroup row>
        <Label className="font-weight-bold" for={`form${label}`} sm={2}>
          {label}
        </Label>
        <Col
          className="d-flex flex-column position-relative"
          id={`form${label}`}
          sm={10}
        >
          <Button
            className="border-dark text-dark hover-primary"
            type="button"
            outline
            block
            color="primary"
            onClick={() => setIsDisableSelect(!isDisableSelect)}
          >
            <span>
              {selectedData.value
                ? selectedData.value
                : currentValue
                ? currentValue
                : `Select ${label}`}
              <i className="fa fa-caret-down mx-1" aria-hidden="true"></i>
            </span>
          </Button>
          <div
            className={`${
              isDisableSelect ? "d-none" : "d-flex flex-column"
            } position-absolute w-100 bg-light`}
            style={{ top: "35px", zIndex: "9999", maxHeight: "240px" }}
          >
            <Input
              className="my-1"
              type="text"
              placeholder={`Search ${label}...`}
              value={inputVal}
              onChange={(e) => setInputVal(e.target.value)}
            ></Input>
            <div className="d-flex flex-column overflow-auto">
              {options &&
                options.map((opt) => {
                  return (
                    <Button
                      key={opt.id}
                      className="my-1"
                      type="button"
                      color="light"
                      outline
                      onClick={() => handleSelect(opt)}
                      style={{ border: "none" }}
                    >
                      {opt.value}
                    </Button>
                  );
                })}
            </div>
          </div>
        </Col>
      </FormGroup>
    </Fragment>
  );
};

InputSelectSearch.propTypes = {
  options: PropTypes.array.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onSelectChange: PropTypes.func.isRequired,
  currentValue: PropTypes.any,
  label: PropTypes.string,
};

export default InputSelectSearch;
