import React, { useEffect, useState, Fragment } from "react";
import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {updateDataBrand} from "../../../../../redux/action/brandAction";
import {toast} from "react-toastify";

const defaultPayload = {
  name: "",
  banner: ""
}

const BrandEdit = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [data, setData] = useState(location ? location?.data : defaultPayload);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [imgThumbnailSrc, setImgThumbnailSrc] = useState("");

  const handleSubmit = () => {
    setDisableSubmit(true);
    const dataForm = new FormData();
    dataForm.append("id", data?.id);
    dataForm.append("name", data?.name);
    dataForm.append("banner", data?.banner);
    dispatch(updateDataBrand(dataForm));

    setTimeout(() => {
      setDisableSubmit(false);
    }, 500);
  }

  const isImage = (imgType) => {
    switch (imgType) {
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
        return true;
    }
    return false;
  };

  const handleOnChangeInputBanner = (e) => {
    if (e.target.files.length > 0) {
      const filesImg = e.target.files[0];
      if (filesImg.size > 2000000) {
        toast.error(`Max file size is 2MB`);
        document.getElementById("formFile").value = "";
      } else {
        if (!isImage(filesImg.type)) {
          toast.error(`Select appropiate image file.`);
          document.getElementById("formFile").value = "";
        } else {
          const imgSrc = URL.createObjectURL(filesImg);
          setImgThumbnailSrc(imgSrc);
          setData({ ...data, banner: filesImg });
        }
      }
    }
  };

  return (
    <Fragment>
      <Breadcrumb parent="Brand" title="Edit Brand" urlParent={'/dashboard/brand'} />
      <Container fluid>
        <Row>
          <Col sm={7}>
            <Card>
              <CardBody className="p-3">
                <Form>
                  <FormGroup row>
                    <Label for="formBrandName" sm={2}>
                      Brand Name
                    </Label>
                    <Col sm={10}>
                      <Input placeholder="Enter Brand Name" id="formBrandName" onChange={(event) => setData({...data, name: event.target.value})} value={data?.name} />
                    </Col>
                  </FormGroup>
				  <FormGroup row>
                    <Label for="formFile" sm={2}>
                      Banner
                    </Label>
                    <Col sm={10}>
                      <Input type="file" name="file" id="formFile" onChange={handleOnChangeInputBanner} />
                    </Col>
                  </FormGroup>
                  <FormGroup row style={{display: "none"}}>
                    <Label for="selectStatus" sm={2}>
                      Status
                    </Label>
                    <Col sm={10}>
                      <Input type="select" name="select" id="selectStatus">
                        <option>Active</option>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup check row>
                    <Col>
                      <Button type="button" onClick={() => handleSubmit()} disabled={disableSubmit}>Save</Button>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BrandEdit;
