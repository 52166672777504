import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { createVoucher } from "@action/voucherAction";

import Breadcrumb from "@layout/breadcrumb";
import {
  FormWrapper,
  InputText,
  InputArea,
  InputDate,
  InputRadio,
  InputNumber,
  InputSelectProduct,
  InputMultiSelectProduct,
} from "@components/formComponent";
import { Col, Input, Label, FormGroup } from "reactstrap";
import InputMinPembelian from "../inputMinPembelian";
import InputJenisVoucher from "../InputJenisVoucher";
import InputPilihanProduct from "../InputPilihanProduct";
import InputPenawaran from "../InputPenawaran";

const VoucherAdd = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [formPayload, setFormPayload] = useState({
    name: "",
    start_date: "",
    end_date: "",
    deskripsi: "",
    image: "",
    jenis: "",
    product_type: "",
    kode: "",
    kuota: 1,
    isMin: false,
    amountMin: 0,
    penawaran: "",
  });
  const [imgPreviewSrc, setImgPreviewSrc] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);


  const isImage = (imgType) => {
    switch (imgType) {
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
        return true;
    }
    return false;
  };

  const handleFileInput = (e) => {
    if (e.target.files.length > 0) {
      const filesImg = e.target.files[0];
      if (filesImg.size > 2000000) {
        toast.error(`Max file size is 2MB`);
        document.getElementById("formFile").value = "";
      } else {
        if (!isImage(filesImg.type)) {
          toast.error(`Select appropiate image file.`);
          document.getElementById("formFile").value = "";
        } else {
          const imgSrc = URL.createObjectURL(filesImg);
          setImgPreviewSrc(imgSrc);
          setFormPayload({ ...formPayload, image: filesImg });
        }
      }
    }
  };

  const validateForm = (data) => {
    if (
      data.name === "" ||
      data.deskripsi === "" ||
      data.image === "" ||
      data.start_date === "" ||
      data.end_date === "" ||
      data.jenis === "" ||
      data.product_type === "" ||
      data.kode === "" ||
      data.kuota === 0 ||
      data.penawaran === ""
    ) {
      toast.error("Please Fill all Fields");
      return false;
    }
    return true;
  };

  const handleSubmitForm = () => {
    if (!validateForm(formPayload)) return;

    let dataForm = new FormData();
    dataForm.append("name", formPayload.name);
    dataForm.append("deskripsi", formPayload.deskripsi);
    dataForm.append("image", formPayload.image);
    dispatch(createVoucher(dataForm));
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Voucher"
        title="Add New Voucher"
        urlParent={"/dashboard/promo/voucher"}
      />
      <FormWrapper
        disableSubmit={disableSubmit}
        onSubmit={handleSubmitForm}
        onCancel={() => history.push("/dashboard/promo/voucher")}
      >
        <InputText
          label="Voucher Name"
          value={formPayload.name}
          onChange={(e) =>
            setFormPayload({
              ...formPayload,
              name: e.target.value,
            })
          }
          isRequired
        />

        <InputDate
          label="Start Date"
          value={formPayload.start_date}
          onChange={(e) =>
            setFormPayload({
              ...formPayload,
              start_date: e.target.value,
            })
          }
          isRequired
        />

        <InputDate
          label="End Date"
          value={formPayload.end_date}
          onChange={(e) =>
            setFormPayload({
              ...formPayload,
              end_date: e.target.value,
            })
          }
          isRequired
        />

        <InputJenisVoucher
          onChangeVoucher={() => {}}
          onChangeCustomer={() => {}}
          onSelectUser={() => {}}
          onChangePoint={() => {}}
        />

        <InputPilihanProduct
          onChangeProduct={() => {}}
          onSelectKategori={() => {}}
          onSelectBrand={() => {}}
          onSelectSKU={() => {}}
        />

        <InputText
          label="Kode Voucher"
          value={formPayload.kode}
          onChange={(e) =>
            setFormPayload({
              ...formPayload,
              kode: e.target.value,
            })
          }
          isRequired
        />

        <InputNumber
          label="Kuota Pemakaian Per User"
          value={formPayload.kuota}
          onChange={(e) =>
            setFormPayload({
              ...formPayload,
              kuota: e.target.value,
            })
          }
          isRequired
        />

        <InputMinPembelian />

        <InputPenawaran />

        <InputArea
          label="Deskripsi"
          value={formPayload.deskripsi}
          onChange={(e) =>
            setFormPayload({
              ...formPayload,
              deskripsi: e.target.value,
            })
          }
          isRequired
        />

        <FormGroup row>
          <Label for="formFile" sm={2}>
            <b>
              Gambar (720 x 480) <sup style={{ color: "red" }}>*</sup>
            </b>
          </Label>
          <Col sm={10}>
            <Input
              className="p-2"
              type="file"
              name="file"
              id="formFile"
              accept="image/*"
              onChange={handleFileInput}
              style={{
                border: "1px solid #d2d2d4",
                borderRadius: "5px",
              }}
            />
          </Col>
        </FormGroup>

        <FormGroup row>
          <Col sm={2}>
            <span>
              <b>Gambar Preview</b>
            </span>
          </Col>
          <Col sm={10}>
            <img
              className="mx-auto"
              src={imgPreviewSrc}
              alt=""
              height={360}
              style={{ objectFit: "contain", width: "100%" }}
            />
          </Col>
        </FormGroup>
      </FormWrapper>
    </Fragment>
  );
};

export default VoucherAdd;
