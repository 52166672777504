import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
} from "reactstrap";
import { toast } from "react-toastify";

import TableConfig from "./customTableConfig";
import Breadcrumb from "../../../../layout/breadcrumb";
import iconSearch from "../../../../assets/images/input-search.png";
import {
  getConfigAdmin,
  updateConfigValue,
} from "../../../../redux/action/configAction";

const Btn = styled.button`
  color: black;
  font-size: 1em;
  border: 2px solid #ebebeb;
  border-radius: 5px;
  cursor: pointer;
`;

const ConfigLayout = () => {
  const [isDataReady, setIsDataReady] = useState(false);
  const [tableColumn, setTableColumn] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [pageIndex, setPageIndex] = useState(1);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [searchVal, setSearchVal] = useState("");

  const dispatch = useDispatch();
  const { listConfig } = useSelector((state) => state.ConfigReducer);

  const toastId = React.useRef(null);

  useEffect(() => {
    fetchData();

    let abortController = new AbortController();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (listConfig.data && !listConfig.loading) {
      const dat = listConfig.data;
      if (dat === undefined || dat === null) return false;
      let mappedData = dat.data.map((items) => ({
        key: items.key,
        value: items.value,
      }));
      setIsDataReady(true);
      setTableData(mappedData);
    }
  }, [listConfig]);

  useEffect(() => {
    if (searchVal !== "") return false;

    fetchData();
  }, [searchVal]);

  const fetchData = () => {
    dispatch(getConfigAdmin());

    setTableColumn([
      {
        Header: () => null,
        id: "config1",
        columns: [
          {
            Header: "Key",
            accessor: "key",
            minWidth: 100,
            sortAble: true,
          },
          {
            Header: "Value",
            accessor: "value",
            disableSortBy: true,
            minWidth: 150,
            sortAble: true,
          },
        ],
      },
    ]);
  };

  const handleChangePagination = () => {};

  const handleNextPagination = () => {
    setPageIndex(pageIndex + 1);
    //dispatch(fetchListCampaign(pageIndex + 1));
  };

  const handlePrevPagination = () => {
    setPageIndex(pageIndex - 1);
    //dispatch(fetchListCampaign(pageIndex - 1));
  };

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setTableData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );

    switch (columnId) {
      case "chat_config":
        if (value === "false" || value === "true") {
          let dataForm = {
            key: columnId,
            value: value,
          };
          dispatch(updateConfigValue(dataForm));
        } else {
          toastId.current = toast("Please input false or true value.", {
            position: "top-center",
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            autoClose: 2500,
            type: toast.TYPE.ERROR,
          });
        }
        break;

      default:
        let dataForm = new FormData();
        dataForm.append("key", columnId);
        dataForm.append("value", value);
        dispatch(updateConfigValue(dataForm));
        break;
    }
  };

  const handleOnSearchInput = (e) => {
    let words = e.target.value;
    setSearchVal(words);
    const filtered = tableData.filter((item) => item.key.includes(words));
    setTableData(filtered);
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Dashboard"
        title="Config"
        urlParent={"/dashboard/analytics"}
      />
      <Container fluid>
        <Row>
          <Col>
            <Card className="p-0">
              <CardBody className="p-3">
                <div className="d-flex justify-content-between my-2">
                  <InputGroup style={{ maxWidth: "280px" }}>
                    <Input
                      placeholder="Search"
                      value={searchVal}
                      onChange={(e) => handleOnSearchInput(e)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleOnSearchInput(e);
                      }}
                    />
                    <InputGroupAddon addonType="prepend">
                      <Button
                        className="p-2"
                        color="primary"
                        onClick={() => {}}
                      >
                        <img src={iconSearch} width={15} height={15} />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </div>

                <TableConfig
                  data={tableData}
                  columns={tableColumn}
                  setData={setTableData}
                  updateMyData={updateMyData}
                  skipPageReset={skipPageReset}
                />

                <div className="text-right my-2">
                  <Btn
                    onClick={() => handlePrevPagination()}
                    disabled={pageIndex === 1 ? true : false}
                  >
                    {"<"}
                  </Btn>
                  <span>
                    Page <b>{pageIndex}</b> of <b>{maxPageIndex}</b>
                  </span>
                  <Btn
                    onClick={() => handleNextPagination()}
                    disabled={maxPageIndex > pageIndex ? false : true}
                  >
                    {">"}
                  </Btn>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ConfigLayout;
