import { getCommonRoles, getAdminRoles } from "../constant/param";
// dashboard
import Default from "../pages/dashboard/default";
import Account from "../pages/dashboard/account";
import Chat from "../pages/dashboard/chat";
import { RouteFlashsale } from "./flashSale";
import { RouteTransaction } from "./transaction";
import { RouteProduct } from "./product";
import { RouteUser } from "./user";
import { RouteLevel } from "./level";
import { RouteVoucher } from "./voucher";
import { RoutePromo } from "./promo";
import { RouteCampaign } from "./campaign";
import { RoutePoint } from "./point";
import { RouteBrand } from "./brand";
import { RouteCategory } from "./category";
import { RouteSection } from "./section";
import { RouteBroadcast } from "./broadcast";
import { RouteConfig } from "./Config";
import { RouteAdmin } from "./admin";
import { RouteMutasi } from "./mutasi";
import { RouteNews } from "./news";
import { RouteApproval } from "./masterApproval";

export const routes = [
  {
    path: `/dashboard/analytics`,
    Component: Default,
    roles: getCommonRoles(),
  },
  {
    path: `/dashboard/account`,
    Component: Account,
    roles: getAdminRoles(),
  },
  {
    path: `/dashboard/chat`,
    Component: Chat,
    roles: getCommonRoles(),
  },
].concat(
  // Routes with common roles
  ...[RouteTransaction, RouteUser].flatMap((routeArray) =>
    routeArray.map((route) => ({
      ...route,
      roles: getCommonRoles(),
    }))
  ),
  // Routes with admin roles
  ...[
    RouteAdmin,
    RouteProduct,
    RouteLevel,
    RouteVoucher,
    RoutePromo,
    RouteCampaign,
    RoutePoint,
    RouteBrand,
    RouteCategory,
    RouteSection,
    RouteFlashsale,
    RouteBroadcast,
    RouteConfig,
    RouteMutasi,
    RouteNews,
	RouteApproval
  ].flatMap((routeArray) =>
    routeArray.map((route) => ({
      ...route,
      roles: getAdminRoles(),
    }))
  )
);