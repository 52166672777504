import { Fragment, useState } from "react";

import { InputRadio, InputNumber, InputText } from "@components/formComponent";
import {
  Col,
  Label,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";

const InputPenawaran = ({ value, onChangePenawaran }) => {
  const [type, setType] = useState("free");
  const [typePotongan, setTypePotongan] = useState("persentase");

  const InputPotonganHarga = () => {
    return (
      <Fragment>
        <FormGroup row tag="fieldset">
          <Label for="formFile" sm={2}>
            <b>
              Jenis <sup style={{ color: "red" }}>*</sup>
            </b>
          </Label>
          <Col className="d-flex flex-row py-2" sm={10}>
            <InputRadio
              label="Persentase"
              name="radioCustomer"
              checked={typePotongan === "persentase" ? true : false}
              onChange={(val) => setTypePotongan("persentase")}
            />
            <InputRadio
              label="Nominal"
              name="radioCustomer"
              checked={typePotongan === "nominal" ? true : false}
              onChange={(val) => setTypePotongan("nominal")}
            />
          </Col>
        </FormGroup>
        <InputNumber
          label="Besar Penawaran"
          value={0}
          onChange={(e) => {}}
          isRequired
        />
        {typePotongan === "persentase" ? (
          <FormGroup row tag="fieldset">
            <Label sm={2}>
              <span>
                <b>
                  Maksimum Penawaran <sup style={{ color: "red" }}>*</sup>
                </b>
              </span>
            </Label>
            <Col className="d-flex flex-row py-2" sm={10}>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText className="bg-white">Rp</InputGroupText>
                </InputGroupAddon>
                <Input placeholder="0" type="number" min={0} />
              </InputGroup>
            </Col>
          </FormGroup>
        ) : (
          ""
        )}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <FormGroup row tag="fieldset">
        <Label for="formFile" sm={2}>
          <b>
            Jenis Penawaran <sup style={{ color: "red" }}>*</sup>
          </b>
        </Label>
        <Col className="d-flex flex-row py-2" sm={10}>
          <InputRadio
            label="Free Gift"
            name="radioPenawaran"
            checked={type === "free" ? true : false}
            onChange={(val) => setType("free")}
          />
          <InputRadio
            label="Potongan Harga"
            name="radioPenawaran"
            checked={type === "potongan" ? true : false}
            onChange={(val) => setType("potongan")}
          />
          <InputRadio
            label="Undian"
            name="radioPenawaran"
            checked={type === "undian" ? true : false}
            onChange={(val) => setType("undian")}
          />
        </Col>
      </FormGroup>

      {type === "free" ? (
        <InputText label="Free Gift" onChange={() => {}} value="" isRequired />
      ) : type === "potongan" ? (
        <InputPotonganHarga />
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default InputPenawaran;
