import { useState, Fragment, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

//component
import { Container, Row, Col } from "reactstrap";
import Breadcrumb from "@layout/breadcrumb";
import AddKondisiCustomer from "./addKondisiCustomer";
import AddKondisiProduct from "./addKondisiProduct";
import BenefitPromo from "../component/BenefitPromo";
import PreviewInformation from "./PreviewInformation";
import PromoInformation from "./PromoInformation";
import { ModalDialog } from "@components/modal/ModalDialog";
import ModalContent from "@components/modal/ModalContent";
import moment from "moment";
import { useParams } from "react-router-dom";
import { getDetailPromoNew } from "@action/promoAction";

const NewPromotionEdit = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [namaPromo, setNamaPromo] = useState("");
  const [kodePromo, setKodePromo] = useState("");
  const [kuotaPromo, setKuotaPromo] = useState("");
  const [tipePromo, setTipePromo] = useState("other");
  const [image, setImage] = useState(null);
  const [formStartDate, setStartDate] = useState("");
  const [formEndDate, setEndDate] = useState("");
  const [deskripsi, setDeskripsi] = useState("");
  const [benefitData, setBenefitData] = useState([]);
  const [benefitSuperTier, setBenefitSuperTier] = useState(null);
  const [benefitKelipatan, setBenefitKelipatan] = useState(null);
  const [exitDialog, setExitDialog] = useState(false);

  //** -------- Product -------- */
  const [productOn, setProductOn] = useState(true);
  const [productCondition, setProductCondition] = useState("and");
  const [productConditionKC, setProductConditionKC] = useState("and");
  const [isAddKondisiProduct, setAddKondisiProduct] = useState([]);
  //manufactur
  const [isManufaktur, setManufaktur] = useState(false);
  const [manufakturData, setManufakturData] = useState({
    value: "",
    type: "manufactur",
  });
  const [selectedManufactur, setSelectedManufactur] = useState([]);
  // Kategori
  const [isKategori, setKategori] = useState(false);
  const [kategoriData, setKategoriData] = useState({
    value: "",
    type: "kategori",
  });
  const [selectedKategori, setSelectedKategori] = useState([]);
  //SKU
  const [isSKU, setSKU] = useState(false);
  const [skuData, setSkuData] = useState({
    value: "",
    type: "sku",
    group: "kelipatan",
  });
  const [selectedSKU, setSelectedSKU] = useState([]);
  // Supertier
  const [supertier, setSupertier] = useState({ is_active: 0, data: [] });
  //** -------- End Product -------- */

  //** -------- Customer -------- */
  const [customerOn, setCustomerOn] = useState(true);
  const [customerCondition, setCustomerCondition] = useState("and");
  const [customerConditionKC, setCustomerConditionKC] = useState("and");
  const [isAddKondisiCustomer, setAddKondisiCustomer] = useState([]);
  //customer
  const [isCustomer, setCustomer] = useState(false);
  const [customerData, setCustomerData] = useState({
    value: "",
    type: "customers",
  });
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  //sales
  const [isSales, setSales] = useState(false);
  const [salesData, setSalesData] = useState({
    value: "",
    type: "sales",
  });
  const [selectedSales, setSelectedSales] = useState([]);
  //WL area
  const [isWLarea, setWLarea] = useState(false);
  const [wlAreaData, setWlAreaData] = useState({
    value: "",
    type: "wl_area",
  });
  const [selectedWlArea, setSelectedWlArea] = useState([]);
  //Jenis Customer
  const [isUser, setUser] = useState(false);
  const [userData, setUserData] = useState({
    value: "",
    type: "jenis_customer",
  });
  const [selectedUser, setSelectedUser] = useState([]);
  //** -------- End Customer -------- */

  const onSubmit = async () => {
    console.log("onotrak benefitSuperTier", benefitSuperTier);
    const customerLevel1 = {
      condition: customerCondition,
      type: "customers",
      level: 1,
      data: [
        {
          ...customerData,
          items: filterDataId(selectedCustomer),
        },
        {
          ...salesData,
          items: filterDataId(selectedSales),
        },
        {
          ...wlAreaData,
          items: filterDataId(selectedWlArea),
        },
        {
          ...userData,
          items: filterDataId(selectedUser),
        },
      ],
    };
    const customerLevel2 = {
      condition: customerConditionKC,
      type: "customers",
      level: 2,
      data: filterDataAddCondition(isAddKondisiCustomer),
    };
    const superTierData = filterDataQty(supertier.data);
    const productLevel1 = {
      condition: productCondition,
      type: "products",
      level: 1,
      data: [
        {
          ...manufakturData,
          items: filterDataId(selectedManufactur),
        },
        {
          ...kategoriData,
          items: filterDataId(selectedKategori),
        },
        {
          ...skuData,
          items: filterDataSku(selectedSKU),
          super_tier: supertier.is_active ? [...superTierData] : [],
        },
      ],
    };
    const productLevel2 = {
      condition: productConditionKC,
      type: "products",
      level: 2,
      data: filterDataAddCondition(isAddKondisiProduct),
    };

    const benefitST = [{ ...benefitSuperTier, is_super_tier: 1 }];
    let benefit = benefitSuperTier
      ? [...benefitData, ...benefitST]
      : benefitData;
    benefit = benefitKelipatan ? [...benefit, benefitKelipatan] : benefit;
    benefit = filterBenefit(benefit);

    const customerLvlData = customerOn
      ? [deleteEmptyData(customerLevel1), deleteEmptyData(customerLevel2)]
      : [];
    const productLvlData = productOn
      ? [deleteEmptyData(productLevel1), deleteEmptyData(productLevel2)]
      : [];
    const data = {
      data: [...customerLvlData, ...productLvlData],
      benefit,
    };
    const params = {
      nama: namaPromo,
      kode: kodePromo,
      kuota: kuotaPromo,
      banner: image,
      deskripsi: deskripsi,
      start_at: formStartDate ? moment(formStartDate).format("YYYY-MM-DD") : "",
      end_at: formEndDate ? moment(formEndDate).format("YYYY-MM-DD") : "",
      items_condition: JSON.stringify(data),
    };
    console.log("onotrak data", data);
    console.log("onotrak params", params);

    const formData = new FormData();
    formData.append("nama", namaPromo);
    formData.append("kode", kodePromo);
    formData.append("kuota", kuotaPromo);
    formData.append("banner", image?.file ?? "");
    formData.append("deskripsi", deskripsi);
    formData.append(
      "start_at",
      formStartDate ? moment(formStartDate).format("YYYY-MM-DD") : ""
    );
    formData.append(
      "end_at",
      formEndDate ? moment(formEndDate).format("YYYY-MM-DD") : ""
    );
    formData.append("items_condition", JSON.stringify(data));

    // dispatch(createNewPromo(formData)).then((res) => {
    //   console.log("onotrak res add promo", res);
    //   if (res.status !== 200) return false;
    //   history.push("/dashboard/promotion/");
    // });
  };
  const filterBenefit = (dataArray) => {
    return dataArray.map((obj) => ({
      type_benefit: obj.type_benefit || "",
      type: obj.type || "",
      value: obj.value || "",
      maks_benefit: obj.maks_benefit || "",
      is_super_tier: obj.is_super_tier || "0",
    }));
  };
  const filterDataId = (dataArray) => {
    return dataArray.map((obj) => obj.id.toString());
  };
  const filterDataQty = (dataArray) => {
    return dataArray.map((obj) => ({
      qty: obj.value || "",
    }));
  };
  const filterDataAddCondition = (dataArray) => {
    return dataArray.reduce((acc, curr) => {
      if (Array.isArray(curr.data)) {
        return acc.concat(curr.data);
      }
      return acc;
    }, []);
  };
  const filterDataSku = (dataArray) => {
    return dataArray.map((obj) => ({
      relation_id: obj.relation_id || "",
      data: obj?.data?.length ? obj.data : [],
    }));
  };

  const deleteEmptyData = (data) => {
    return {
      ...data,
      data: data.data.filter((item) => item.value !== ""),
    };
  };

  const addBenefitData = () => {
    setBenefitData([
      {
        type_benefit: "potongan-harga",
        type: "percentage",
        value: "0",
        maks_benefit: "0",
      },
    ]);
  };

  const deleteDefaultBenefitData = () => {
    const data = benefitData.filter((item) => item.hasOwnProperty("name"));
    setBenefitData(data);
  };

  useEffect(() => {
    if (
      (isCustomer ||
        isSales ||
        isWLarea ||
        isUser ||
        isManufaktur ||
        isKategori) &&
      !isSKU
    ) {
      addBenefitData();
    } else {
      deleteDefaultBenefitData();
    }
  }, [isCustomer, isSales, isWLarea, isUser, isManufaktur, isKategori, isSKU]);

  useEffect(() => {
    console.log("onotrak id", id);
    dispatch(getDetailPromoNew(id)).then((res) => {
      console.log("onotrak res detail promo", res);
      if (res.status !== 200) return false;
      if (!res?.data?.data) return false;
      // functionChangeResponse(res.data.data);
    });
  }, [id]);

  const functionChangeResponse = (promoData) => {
    if (promoData) {
      setNamaPromo(promoData.nama || "");
      setKodePromo(promoData.kode || "");
      setKuotaPromo(promoData.kuota || "");
      setImage({ src: promoData.banner } || null);
      setDeskripsi(promoData.deskripsi || "");
      setStartDate(
        promoData.start_at
          ? moment(promoData.start_at).format("YYYY-MM-DD")
          : ""
      );
      setEndDate(
        promoData.end_at ? moment(promoData.end_at).format("YYYY-MM-DD") : ""
      );

      const itemsCondition = promoData.promo_condition;

      //** Mengupdate state untuk customers level 1 */
      const customersData1 = itemsCondition.find(
        (item) => item.type === "customers" && item.level === 1
      );
      console.log("[onotrak LOG]: customers 1", customersData1);

      const customers = customersData1.promo_value.find(
        (item) => item.type === "customers"
      );
      const sales = customersData1.promo_value.find(
        (item) => item.type === "sales"
      );
      const wl_area = customersData1.promo_value.find(
        (item) => item.type === "wl_area"
      );
      const jenis_customer = customersData1.promo_value.find(
        (item) => item.type === "jenis_customer"
      );

      setCustomerCondition(customersData1.condition);
      if (customers?.promo_value_detail?.length) {
        setCustomerData({ value: customers.value, type: "customers" });
        setSelectedCustomer(
          transformSelectedData(customers?.promo_value_detail) || []
        );
        setCustomer(true);
      }
      if (sales?.promo_value_detail?.length) {
        setSalesData({ value: sales.value, type: "sales" });
        setSelectedSales(
          transformSelectedData(sales?.promo_value_detail) || []
        );
        setSales(true);
      }
      if (wl_area?.promo_value_detail?.length) {
        setWlAreaData({ value: wl_area.value, type: "wl_area" });
        setSelectedWlArea(
          transformSelectedData(wl_area?.promo_value_detail) || []
        );
        setWLarea(true);
      }
      if (jenis_customer?.promo_value_detail?.length) {
        setUserData({ value: jenis_customer.value, type: "jenis_customer" });
        setSelectedUser(
          transformSelectedData(jenis_customer?.promo_value_detail) || []
        );
        setUser(true);
      }

      //** Mengupdate state untuk customers level 1 */
      let addKondisiCustomer = []
      const customersData2 = itemsCondition.filter(
        (item) => item.type === "customers" && item.level >= 2
      );
      setCustomerConditionKC(customersData2.condition);
      console.log("[onotrak LOG]: customers 2", customersData2);
      

      // const customers2 = customersData2.promo_value.find(
      //   (item) => item.type === "customers"
      // );
      // const sales2 = customersData2.promo_value.find(
      //   (item) => item.type === "sales"
      // );
      // const wl_area2 = customersData2.promo_value.find(
      //   (item) => item.type === "wl_area"
      // );
      // const jenis_customer2 = customersData2.promo_value.find(
      //   (item) => item.type === "jenis_customer"
      // );

      // console.log('onotrak customers2 ===', customers2)
      // if (customers2?.promo_value_detail?.length) {
      //   const 
      //   // const data = [
      //   //   {
      //   //     data: [
      //   //       {

      //   //       }
      //   //     ]
      //   //   }
      //   // ]
      //   // setAddKondisiCustomer()
      // }
      // if (sales2?.promo_value_detail?.length) {
      //   // setSalesData({ value: sales2.value, type: "sales" });
      //   // setSelectedSales(
      //   //   transformSelectedData(sales2?.promo_value_detail) || []
      //   // );
      //   // setSales(true);
      // }
      // if (wl_area2?.promo_value_detail?.length) {
      //   // setWlAreaData({ value: wl_area2.value, type: "wl_area" });
      //   // setSelectedWlArea(
      //   //   transformSelectedData(wl_area2?.promo_value_detail) || []
      //   // );
      //   // setWLarea(true);
      // }
      // if (jenis_customer2?.promo_value_detail?.length) {
      //   // setUserData({ value: jenis_customer2.value, type: "jenis_customer" });
      //   // setSelectedUser(
      //   //   transformSelectedData(jenis_customer2?.promo_value_detail) || []
      //   // );
      //   // setUser(true);
      // }

      //** Mengupdate state untuk product level 1 */
      const productsData1 = itemsCondition.find(
        (item) => item.type === "products" && item.level === 1
      );
      console.log("[onotrak LOG]: products 1", productsData1);

      const manufactur = productsData1.promo_value.find(
        (item) => item.type === "manufactur"
      );
      const kategori = productsData1.promo_value.find(
        (item) => item.type === "kategori"
      );
      const sku = productsData1.promo_value.find((item) => item.type === "sku");

      setProductCondition(productsData1.condition);
      if (manufactur?.promo_value_detail?.length) {
        setManufakturData({ value: manufactur.value, type: "manufactur" });
        setSelectedManufactur(
          transformSelectedData(manufactur?.promo_value_detail)
        );
        setManufaktur(true);
      }
      if (kategori?.promo_value_detail?.length) {
        setKategoriData({ value: kategori.value, type: "kategori" });
        setSelectedKategori(
          transformSelectedData(kategori?.promo_value_detail)
        );
        setKategori(true);
      }
      if (sku?.promo_value_detail?.length) {
        const group =
          sku?.promo_value_detail[0]?.promo_tier[0]?.type_condition || "";
        setSkuData({ value: sku.value, type: "sku", group });
        setSelectedSKU(transformSelectedDataSKU(sku?.promo_value_detail));
        setSKU(true);
      }

      //** Mengupdate state untuk benefit */
      const benefit = promoData?.benefit?.length
        ? promoData.benefit.map((item) => {
            return {
              is_super_tier: item.is_super_tier,
              maks_benefit: item.maks_benefit,
              type: item.type,
              type_benefit: item.type_benefit,
              value: item.value,
            };
          })
        : [];
      setBenefitData(benefit);

      // ----------------------------------------------------------------------------

      // // Mengupdate state untuk product
      // const productData = itemsCondition.filter(
      //   (item) => item.type === "products"
      // );
      // console.log("onotrak productData", productData);
      // if (productData?.length) {
      //   const level1 = productData.find((item) => item.level === 1) || null;
      //   console.log("onotrak level1", level1);
      //   if (level1) {
      //     const manufactur = level1.promo_value.find(
      //       (item) => item.type === "manufactur"
      //     );
      //     const kategori = level1.promo_value.find(
      //       (item) => item.type === "kategori"
      //     );
      //     const sku = level1.promo_value.find((item) => item.type === "sku");

      //     console.log("onotrak manufactur", manufactur);
      //     console.log("onotrak kategori", kategori);
      //     console.log("onotrak sku", sku);

      //     setProductCondition(level1?.condition || "and");
      //     console.log(
      //       "onotrak manufactur ====",
      //       transformSelectedData(manufactur?.promo_value_detail)
      //     );

      //     if (manufactur) {
      //       setManufakturData({ value: manufactur?.value, type: "manufactur" });
      //       setSelectedManufactur(
      //         transformSelectedData(manufactur?.promo_value_detail)
      //       );
      //       setManufaktur(true);
      //     }
      //     if (kategori) {
      //       setKategoriData({ value: kategori.value, type: "kategori" });
      //       setSelectedKategori(
      //         transformSelectedData(kategori.promo_value_detail)
      //       );
      //       setKategori(true);
      //     }
      //     if (sku) {
      //       setSkuData({
      //         value: sku.value,
      //         type: "sku",
      //         group: "kelipatan",
      //       });
      //       setSelectedSKU(transformSelectedData(sku.promo_value_detail));
      //       setSKU(true);
      //     }

      //     // setSupertier({
      //     //   is_active:
      //     //     level1.super_tier && level1.super_tier.length > 0 ? 1 : 0,
      //     //   data: level1.super_tier || [],
      //     // });
      //   }
      // }

      // ================================================
      // const customerData = itemsCondition.filter(
      //   (item) => item.type === "customers"
      // );
      // console.log("onotrak customerData", customerData);
      // if (customerData?.length) {
      //   const level1 = customerData.find((item) => item.level === 1) || null;
      //   console.log("onotrak level1", level1);
      //   if (level1) {
      //     const customer = level1.promo_value.find(
      //       (item) => item.type === "customers"
      //     );
      //     const sales = level1.promo_value.find(
      //       (item) => item.type === "sales"
      //     );
      //     const wlArea = level1.promo_value.find((item) => item.type === "wl_area");
      //     const jenisCustomer = level1.promo_value.find((item) => item.type === "jenis_customer");

      //     console.log("onotrak customer", customer);
      //     console.log("onotrak sales", sales);
      //     console.log("onotrak wlArea", wlArea);
      //     console.log("onotrak jenisCustomer", jenisCustomer);

      //     setCustomerCondition(level1?.condition || "and");

      //     if (customer) {
      //       setCustomerData({ value: customer.value, type: "customers" });
      //       setSelectedCustomer(
      //         transformSelectedData(customer.promo_value_detail)
      //       );
      //       setCustomer(true);
      //     }
      //     // if (sales) {
      //     //   setSalesData({ value: sales.value, type: "sales" });
      //     //   setSelectedSales(
      //     //     transformSelectedData(sales.promo_value_detail)
      //     //   );
      //     //   setSales(true);
      //     // }
      //     // if (wlArea) {
      //     //   setWlAreaData({ value: wlArea.value, type: "wl_area" });
      //     //   setSelectedWlArea(
      //     //     transformSelectedData(wlArea.promo_value_detail)
      //     //   );
      //     //   setWLarea(true);
      //     // }
      //     // if (jenisCustomer) {
      //     //   setUserData({ value: jenisCustomer.value, type: "jenis_customer" });
      //     //   setSelectedUser(
      //     //     transformSelectedData(jenisCustomer.promo_value_detail)
      //     //   );
      //     //   setUser(true);
      //     // }

      //     // setSupertier({
      //     //   is_active:
      //     //     level1.super_tier && level1.super_tier.length > 0 ? 1 : 0,
      //     //   data: level1.super_tier || [],
      //     // });
      //   }
      // }

      // const productDataLevel2 = productData ? productData.promo_value_detail[1] : null;
      // if (productDataLevel2.length) {
      //   setAddKondisiProduct(filterDataAddCondition(productDataLevel2));
      //   setProductConditionKC(productData.condition || "and");
      // }

      // // Mengupdate state untuk customer
      // const customerData = itemsCondition.find(
      //   (item) => item.type === "customers"
      // );
      // if (customerData) {
      //   const customerLevel1 = customerData.data[0] || {};
      //   setCustomerCondition(customerData.condition || "and");
      //   setCustomerData({
      //     value: customerLevel1.value,
      //     type: customerLevel1.type,
      //   });
      //   setSelectedCustomer(filterDataId(customerLevel1.items));
      //   setSalesData({
      //     value: customerLevel1.value,
      //     type: customerLevel1.type,
      //   });
      //   setSelectedSales(filterDataId(customerLevel1.items));
      //   setWlAreaData({
      //     value: customerLevel1.value,
      //     type: customerLevel1.type,
      //   });
      //   setSelectedWlArea(filterDataId(customerLevel1.items));
      //   setUserData({ value: customerLevel1.value, type: customerLevel1.type });
      //   setSelectedUser(filterDataId(customerLevel1.items));
      // }

      // const customerDataLevel2 = customerData ? customerData.data[1] : null;
      // if (customerDataLevel2) {
      //   setAddKondisiCustomer(filterDataAddCondition(customerDataLevel2));
      //   setCustomerConditionKC(customerData.condition || "and");
      // }

      // // Mengupdate state untuk benefit
      // if (promoData.benefit) {
      //   const superTierBenefit = promoData.benefit.find(
      //     (b) => b.is_super_tier === "1"
      //   );
      //   const kelipatanBenefit = promoData.benefit.find(
      //     (b) => b.type === "kelipatan"
      //   );

      //   setBenefitSuperTier(superTierBenefit || null);
      //   setBenefitKelipatan(kelipatanBenefit || null);
      //   setBenefitData(
      //     promoData.benefit.filter(
      //       (b) => !b.is_super_tier && b.type !== "kelipatan"
      //     )
      //   );
      // }
    }
  };
  const transformSelectedData = (data) => {
    if (!data?.length) return [];
    return data.map((item) => {
      if (item?.detail) {
        return {
          ...item.detail,
          name: item?.detail?.name || "-",
          id: item?.detail?.id || 0,
        };
      }
      return { name: "-", id: 0 };
    });
  };
  const transformSelectedDataSKU = (data) => {
    if (!data?.length) return [];
    return data.map((item) => {
      if (item?.detail) {
        return {
          ...item.detail,
          name: item?.detail?.name || "-",
          id: item?.detail?.id || 0,
          data: item?.promo_tier?.length
            ? item.promo_tier.map((tier) => {
                return {
                  is_active: tier.is_active,
                  type: tier.type,
                  type_condition: tier.type_condition,
                  value: tier.value,
                };
              })
            : [],
        };
      }
      return { name: "-", id: 0 };
    });
  };

  return (
		<Fragment>
			<Breadcrumb
				parent="Promotion"
				title="Edit New Promotion"
				urlParent={"/dashboard/promo"}
			/>
			<Container fluid>
				<Row>
					<Col className="my-sm-2" sm={12} lg={8}>
						<PromoInformation
							namaPromo={namaPromo}
							setNamaPromo={setNamaPromo}
							kodePromo={kodePromo}
							setKodePromo={setKodePromo}
							kuotaPromo={kuotaPromo}
							setKuotaPromo={setKuotaPromo}
							image={image}
							setImage={setImage}
							deskripsi={deskripsi}
							setDeskripsi={setDeskripsi}
							tipePromo={tipePromo}
							setTipePromo={setTipePromo}
							formStartDate={formStartDate}
							setStartDate={setStartDate}
							formEndDate={formEndDate}
							setEndDate={setEndDate}
						/>

						<div className="d-flex flex-column bg-white shadow-sm my-3 p-3 border rounded-lg">
							<h4>Kondisi</h4>
							<div className="p-2">
								<AddKondisiCustomer
									customerOn={customerOn}
									setCustomerOn={setCustomerOn}
									customerCondition={customerCondition}
									setCustomerCondition={setCustomerCondition}
									isAddKondisi={isAddKondisiCustomer}
									setAddKondisi={setAddKondisiCustomer}
									isCustomer={isCustomer}
									setCustomer={setCustomer}
									customerData={customerData}
									setCustomerData={setCustomerData}
									selectedCustomer={selectedCustomer}
									setSelectedCustomer={setSelectedCustomer}
									isSales={isSales}
									setSales={setSales}
									salesData={salesData}
									setSalesData={setSalesData}
									selectedSales={selectedSales}
									setSelectedSales={setSelectedSales}
									isWLarea={isWLarea}
									setWLarea={setWLarea}
									wlAreaData={wlAreaData}
									setWlAreaData={setWlAreaData}
									selectedWlArea={selectedWlArea}
									setSelectedWlArea={setSelectedWlArea}
									isUser={isUser}
									setUser={setUser}
									userData={userData}
									setUserData={setUserData}
									selectedUser={selectedUser}
									setSelectedUser={setSelectedUser}
									customerConditionKC={customerConditionKC}
									setCustomerConditionKC={setCustomerConditionKC}
								/>
								<AddKondisiProduct
									productOn={productOn}
									setProductOn={setProductOn}
									productCondition={productCondition}
									setProductCondition={setProductCondition}
									isAddKondisi={isAddKondisiProduct}
									setAddKondisi={setAddKondisiProduct}
									isManufaktur={isManufaktur}
									setManufaktur={setManufaktur}
									manufakturData={manufakturData}
									setManufakturData={setManufakturData}
									selectedManufactur={selectedManufactur}
									setSelectedManufactur={setSelectedManufactur}
									isKategori={isKategori}
									setKategori={setKategori}
									kategoriData={kategoriData}
									setKategoriData={setKategoriData}
									selectedKategori={selectedKategori}
									setSelectedKategori={setSelectedKategori}
									isSKU={isSKU}
									setSKU={setSKU}
									skuData={skuData}
									setSkuData={setSkuData}
									selectedSKU={selectedSKU}
									setSelectedSKU={setSelectedSKU}
									supertier={supertier}
									setSupertier={setSupertier}
									setBenefitData={setBenefitData}
									setBenefitSuperTier={setBenefitSuperTier}
									setBenefitKelipatan={setBenefitKelipatan}
									productConditionKC={productConditionKC}
									setProductConditionKC={setProductConditionKC}
									isBenefit={
										isCustomer ||
										isSales ||
										isWLarea ||
										isUser ||
										isManufaktur ||
										isKategori
									}
								/>
							</div>
						</div>
						<BenefitPromo
							benefitData={benefitData}
							setBenefitData={setBenefitData}
							benefitSuperTier={benefitSuperTier}
							setBenefitSuperTier={setBenefitSuperTier}
							benefitKelipatan={benefitKelipatan}
							setBenefitKelipatan={setBenefitKelipatan}
							supertier={supertier}
						/>
					</Col>
					<PreviewInformation
						//** Promo Information */
						namaPromo={namaPromo}
						kodePromo={kodePromo}
						tipePromo={tipePromo}
						formStartDate={formStartDate}
						formEndDate={formEndDate}
						//** End */

						//** Customer */
						isCustomer={isCustomer}
						selectedCustomer={selectedCustomer}
						isSales={isSales}
						selectedSales={selectedSales}
						isWLarea={isWLarea}
						selectedWlArea={selectedWlArea}
						isUser={isUser}
						selectedUser={selectedUser}
						//** End */

						//** Product */
						isManufaktur={isManufaktur}
						selectedManufactur={selectedManufactur}
						isKategori={isKategori}
						selectedKategori={selectedKategori}
						isSKU={isSKU}
						selectedSKU={selectedSKU}
						supertier={supertier?.data?.length ? supertier.data : []}
						superTierIsActive={supertier?.is_active}
						skuData={skuData}
						//** End */

						// Benefit
						benefitData={benefitData}
						benefitSuperTier={benefitSuperTier}
						benefitKelipatan={benefitKelipatan}
						// Button
						setExitDialog={setExitDialog}
						onSubmit={onSubmit}
					/>
				</Row>

				<Fragment>
					<ModalDialog
						open={exitDialog}
						footer={false}
						title={"Unsaved Changes"}
						titleFontSize={18}
						backdropClose={true}
						onClose={() => {
							setExitDialog(false);
						}}
						maxWidth={384}
					>
						<ModalContent
							onClose={() => {
								setExitDialog(false);
							}}
							onSubmit={() => history.push("/dashboard/promotion/")}
							description={`You have unsaved changes. If you go back now, you will lose all your changes.`}
							descClose={"Cancel"}
							descSubmit={"Back To Promo List"}
						/>
					</ModalDialog>
				</Fragment>
			</Container>
		</Fragment>
	);
};

export default NewPromotionEdit;
