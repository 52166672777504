import { getCommonRoles, getAdminRoles } from "../../constant/param";

//Icon
import ChatIcon from "@assets/images/sidebar/sidebar-chat.png";
import AnalyticIcon from "@assets/images/sidebar/sidebar-analytics.png";
import MasterIcon from "@assets/images/sidebar/sidebar-master.png";
import ProductIcon from "@assets/images/sidebar/sidebar-product.png";
import SettingIcon from "@assets/images/sidebar/sidebar-setting.png";
import TransactionIcon from "@assets/images/sidebar/sidebar-transaction.png";
import UserIcon from "@assets/images/sidebar/sidebar-user.png";
import FlashSaleIcon from "@assets/images/sidebar/csi-flash-sale.svg";
import MutasiIcon from "@assets/images/sidebar/sidebar-circle.png";
import PromoIcon from "@assets/images/sidebar/nav-promo-icon.svg";

const items = [
  {
    menutitle: "Analytics",
    menucontent: "",
    Items: [
      {
        title: "Analytics",
        icon: AnalyticIcon,
        type: "link",
        active: false,
        path: `/dashboard/analytics`,
      },
    ],
    roles: getCommonRoles(),
  },
  {
    menutitle: "Chat",
    menucontent: "",
    Items: [
      {
        title: "Chat",
        icon: ChatIcon,
        type: "link",
        active: false,
        path: `/dashboard/chat`,
      },
    ],
    roles: getCommonRoles(),
  },
  {
    menutitle: "Transaction",
    menucontent: "",
    Items: [
      {
        title: "Transaction",
        icon: TransactionIcon,
        type: "link",
        active: false,
        path: `/dashboard/transaction`,
      },
    ],
    roles: getCommonRoles(),
  },
  {
    menutitle: "Product",
    menucontent: "",
    Items: [
      {
        title: "Product",
        icon: ProductIcon,
        type: "link",
        active: false,
        path: `/dashboard/product`,
      },
    ],
    roles: getAdminRoles(),
  },
  {
    menutitle: "User",
    menucontent: "",
    Items: [
      {
        title: "User",
        icon: UserIcon,
        type: "link",
        active: false,
        path: `/dashboard/user`,
      },
    ],
    roles: getCommonRoles(),
  },
  {
    menutitle: "Mutasi BCA",
    menucontent: "",
    Items: [
      {
        title: "Mutasi BCA",
        icon: MutasiIcon,
        type: "link",
        active: false,
        path: `/dashboard/mutasi-bca`,
      },
    ],
    roles: getAdminRoles(),
  },
  {
    menutitle: "Promotion",
    menucontent: "",
    Items: [
      {
        title: "Promotion",
        icon: PromoIcon,
        type: "link",
        active: false,
        path: `/dashboard/promotion`,
      },
    ],
    roles: getAdminRoles(),
  },
  {
    menutitle: "Master",
    menucontent: "",
    roles: getAdminRoles(),
    Items: [
      {
        title: "Master",
        icon: MasterIcon,
        type: "sub",
        active: false,
        children: [
          {
            path: `/dashboard/category`,
            title: "Category",
            type: "link",
          },
          {
            path: `/dashboard/brand`,
            title: "Brand",
            type: "link",
          },
          {
            path: `/dashboard/news`,
            title: "News",
            type: "link",
          },
          {
            path: `/dashboard/section`,
            title: "Section",
            type: "link",
          },
          {
            path: `/dashboard/level`,
            title: "Level Customer",
            type: "link",
          },
        ],
      },
    ],
  },
  {
    menutitle: "Konfigurasi",
    menucontent: "",
    roles: getAdminRoles(),
    Items: [
      {
        title: "Konfigurasi",
        icon: SettingIcon,
        type: "sub",
        active: false,
        children: [
          {
            path: `/dashboard/admin`,
            title: "Admin",
            type: "link",
          },
          {
            path: `/dashboard/master-approval`,
            title: "Approval",
            type: "link",
          },
          {
            path: `/dashboard/configs`,
            title: "Configs",
            type: "link",
          },
          {
            path: `/dashboard/navlog`,
            title: "Navlog",
            type: "link",
          },
          {
            path: `/dashboard/point`,
            title: "Poin",
            type: "link",
          },
        ],
      },
    ],
  },
];

export const MENUITEMS = items;
