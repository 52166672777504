import apiConfig from "../../api";
import { CometChat } from "@cometchat-pro/chat";
import {
  SET_USER_UID,
  SET_USER_DATA,
  WATCH_RESPONSE_ALERT,
  SET_USER_ROLE,
  SET_USER_TOKEN,
} from "../actionTypes";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const getLogin = (loginPayload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post("/api/login/admin", loginPayload);

      const dataResp = resp.data.data;
      const dataUser = dataResp.data.user;
      const authToken = dataResp.data.api_token;
      const role_id = dataUser.role;

      cookies.set("api_token", authToken, { path: "/" });
      cookies.set("role", role_id, { path: "/" });
      dispatch({
        type: SET_USER_ROLE,
        payload: role_id,
      });
      dispatch({
        type: SET_USER_TOKEN,
        payload: authToken,
      });

      dispatch(userData(dataUser));

      if (dataUser.uuid !== null) {
        cookies.set("uid", dataUser.uuid, { path: "/" });
      } else {
        dispatch(createUUID(dataUser));
      }

      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: false,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });

      return err;
    }
  };
};

export const getLoginConfigs = () => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get("/api/chats/configs");

      return resp;
    } catch (err) {
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });

      return err;
    }
  };
};

export const createUUID = () => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get("/api/chats/check-uid");
      const respUUID = resp.data?.data?.uuid;
      cookies.set("uid", respUUID, { path: "/" });

      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          err: false,
        },
      });
    } catch (error) {
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: error,
          err: true,
        },
      });
    }
  };
};

export const userData = (dataUser) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
      payload: {
        loading: false,
        data: dataUser,
        err: false,
      },
    });
  };
};

export const toLogout = (err) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
      payload: {
        loading: false,
        auth: false,
        data: {},
        err: false,
      },
    });

    let listenerID = cookies.get("listenerID");
    let isConnectedChat = cookies.get("isConnectedChat");

    if (isConnectedChat) {
      CometChat.logout().then(
        () => {
          CometChat.removeMessageListener(listenerID);
          console.log("Online chat is logged out.");
        },
        (error) => {
          console.log("Logout failed with exception:", { error });
        }
      );
    }

    cookies.remove("api_token", { path: "/" });
    cookies.remove("listenerID", { path: "/" });
    cookies.remove("uid", { path: "/" });
    cookies.remove("role", { path: "/" });
  };
};

export const setUserChat = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_UID,
      payload: {
        loading: false,
        auth: true,
        data: data,
        err: false,
      },
    });
  };
};
