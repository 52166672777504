import React, { Fragment, useEffect, useState } from "react";
import { useParams, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Badge,
  Input,
  Button,
} from "reactstrap";
import Breadcrumb from "../../../../../layout/breadcrumb";
import CurrencyFormatter from "../../../../../Component/currencyFormatter";

import {
  getTransactionDetail,
  handleSetComplete
} from "../../../../../redux/action/transactionAction";
import BadgeStatus from "../../../../../Component/badgeStatus";

const TransactionCompleteForm = (props) => {
  const [isFetchedData, setIsFetchedData] = useState(false);
  const [detailTransaction, setDetailTransaction] = useState({});
  const [formInput, setFormInput] = useState({
    points: "",
  });

  const history = useHistory()
  const urlParam = useParams();
  const dispatch = useDispatch();
  const { transactionDetail } = useSelector(
    (state) => state.TransactionReducer
  );

  useEffect(() => {
    dispatch(getTransactionDetail(urlParam.id));
  }, []);

  useEffect(() => {
    if (transactionDetail.data && !transactionDetail.loading) {
      let detail = transactionDetail.data.transaction;

      setDetailTransaction(detail);
      setIsFetchedData(true);
    }
  }, [transactionDetail]);

  const handleSubmit = () => {
    if (formInput.ticket_no === "" || formInput.do_no === "") {
      toast.error("Please fill all fields.");
      return;
    }

    let formData = new FormData();
    formData.append("points", formInput.points);

    dispatch(handleSetComplete(urlParam.id, formData));
	history.goBack()
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Transaction"
        title="Transaction Complete Form"
        urlParent="/dashboard/transaction"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="4">
            {isFetchedData ? (
              <Card>
                <CardHeader className="d-flex justify-content-between p-3">
                  <div>
                    <h5>{`Order #${detailTransaction.invoice_no}`}</h5>
                    <span>
                      Status:{" "}
                      <Badge color="light">{`${detailTransaction.state}`}</Badge>
                      <span>{`Created at: ${detailTransaction.created_at}`}</span>
                    </span>
                  </div>
                </CardHeader>
                <CardBody className="p-3">
                  <Row>
                    <Col>
                      <div className="my-1">
                        <b className="m-0">Invoice No:</b>
                        <span>{`${detailTransaction.invoice_no}`}</span>
                      </div>
                      <div className="my-1">
                        {" "}
                        <b className="m-0">SO Number:</b>
                        <span>{`${detailTransaction.nav_id}`}</span>
                      </div>
                      <div className="my-1">
                        {" "}
                        <b className="m-0">Reciever Address:</b>
                        <span>{`${detailTransaction.receiver_address}`}</span>
                      </div>
                      <div className="my-1">
                        <b className="m-0">Shop Name:</b>
                        <span>{`${detailTransaction.user.shop_name}`}</span>
                      </div>
                      <div className="my-1">
                        <b className="m-0">Total Price:</b>
                        <span>
                          <CurrencyFormatter
                            digit={detailTransaction.total_price}
                            typeDigit={"amount"}
                          />
                        </span>
                      </div>
                      <div className="my-1">
                        <b className="m-0">Paid Status:</b>
                        <BadgeStatus
                          status={detailTransaction.paid}
                          label={
                            detailTransaction.paid === 0 ? "Unpaid" : "Paid"
                          }
                        />
                      </div>
                    </Col>
                    <Col>
                      <div className="my-1">
                        <b className="m-0">Catatan:</b>
                        <span>{`${detailTransaction.note}`}</span>
                      </div>
                      <div className="my-1">
                        <b className="m-0">Pembayaran:</b>
                        <span>{`${detailTransaction?.payment_type}`}</span>
                      </div>
                      <div className="my-1">
                        <b className="m-0">Nomor Virtual:</b>
                        <span
                          style={{ cursor: "pointer", color: "#2647E6" }}
                          onClick={() =>
                            navigator.clipboard.writeText(
                              `${detailTransaction?.virtual_account_id}`
                            )
                          }
                        >{`${
                          detailTransaction?.payment_type === "BCA"
                            ? detailTransaction?.virtual_account_id
                            : "-"
                        }`}</span>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            ) : null}
          </Col>

          <Col sm={8}>
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between my-1">
                  <p>Loyalty Points</p>
                  <Input
                    value={formInput.points}
                    onChange={(e) =>
                      setFormInput({
                        ...formInput,
                        points: e.target.value,
                      })
                    }
                    style={{ maxWidth: "500px" }}
                  />
                </div>
              </CardBody>
              <div className="d-flex justify-content-end my-2 p-2">
                <Button className="mx-1" color="primary" outline>
                  <Link to="/dashboard/transaction">Cancel</Link>
                </Button>
                <Button className="mx-1" color="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TransactionCompleteForm;
