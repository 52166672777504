import AdminAdd from "../../pages/dashboard/konfigurasi/admin/add";
import AdminEdit from "../../pages/dashboard/konfigurasi/admin/edit";

export const RouteAdmin = [
  {
    path: `/dashboard/admin/add`,
    Component: AdminAdd,
  },
  {
    path: `/dashboard/admin/edit/:id`,
    Component: AdminEdit,
  },
];
