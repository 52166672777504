import apiConfig from "../../api";
import {
  GET_LIST_TRANSACTION_CONFIRM,
  GET_LIST_TRANSACTION_SHIPPING,
  GET_LIST_TRANSACTION_DELIVERED,
  GET_LIST_TRANSACTION_COMPLETE,
  GET_TRANSACTION_DETAIL,
  WATCH_RESPONSE_ALERT,
  SEND_REMINDER,
  ADD_TRANSACTION_DOCUMENT,
  UPDATE_TRANSACTION_DOCUMENT,
  GET_DETAIL_TRANSACTION_DOCUMENT,
  DELETE_TRANSACTION_DOCUMENT,
  SYNCED_TRANSACTION,
  DO_NUMBER,
} from "../actionTypes";

export const getListTransaction = (state, page, param, row) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_TRANSACTION_CONFIRM,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(
        "/api/admin/transaction?state=" +
          state +
          "&page=" +
          page +
          "&search=" +
          param +
		  "&per_page=" +
		  row
      )
      .then((resp) => {
        dispatch({
          type: GET_LIST_TRANSACTION_CONFIRM,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LIST_TRANSACTION_CONFIRM,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const getListTransactionShipping = (page, param) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_TRANSACTION_SHIPPING,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get("/api/admin/transaction?state=1&page=" + page + "&search=" + param)
      .then((resp) => {
        dispatch({
          type: GET_LIST_TRANSACTION_SHIPPING,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LIST_TRANSACTION_SHIPPING,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const getListTransactionDelivered = (page, param) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_TRANSACTION_DELIVERED,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get("/api/admin/transaction?state=2&page=" + page + "&search=" + param)
      .then((resp) => {
        dispatch({
          type: GET_LIST_TRANSACTION_DELIVERED,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LIST_TRANSACTION_DELIVERED,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const getListTransactionComplete = (page, param) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_TRANSACTION_COMPLETE,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get("/api/admin/transaction?state=3&page=" + page + "&search=" + param)
      .then((resp) => {
        dispatch({
          type: GET_LIST_TRANSACTION_COMPLETE,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LIST_TRANSACTION_COMPLETE,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const getTransactionDetail = (param) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(`/api/admin/transaction/${param}`);
      dispatch({
        type: GET_TRANSACTION_DETAIL,
        payload: {
          loading: false,
          data: resp.data.data,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: GET_TRANSACTION_DETAIL,
        payload: {
          loading: false,
          data: false,
          err: err.message,
        },
      });

      return err;
    }
  };
};

export const paidConfirmTransaction = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(
        `/api/admin/transaction/approve`,
        payload
      );
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: true,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });

      return err;
    }
  };
};

export const sendReminder = (id_transaction) => {
  return (dispatch) => {
    dispatch({
      type: SEND_REMINDER,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/transaction/${id_transaction}/send-reminder`)
      .then((resp) => {
        dispatch({
          type: SEND_REMINDER,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: SEND_REMINDER,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const handleAcceptTransaction = (transaction_id, payload) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post(`/api/admin/transaction/accept/${transaction_id}`, payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const createSRO = (payload) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post(`/api/admin/transaction/storeSro`, payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const handleSetComplete = (transaction_id, payload) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post(`/api/admin/transaction/${transaction_id}/complete`, payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const handleSetDelivery = (transaction_id, payload) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post(`/api/admin/transaction/${transaction_id}/deliver`, payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const createTransactionDocument = (id, payload) => {
  return (dispatch) => {
    dispatch({
      type: ADD_TRANSACTION_DOCUMENT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post(`/api/admin/transaction/${id}/transaction-document`, payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: true,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const updateTransactionDocument = (id, id_item, payload) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_TRANSACTION_DOCUMENT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .put(
        `/api/admin/transaction/${id}/transaction-document/${id_item}/update`,
        payload
      )
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: true,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const deleteTransactionDocument = (id, id_item) => {
  return (dispatch) => {
    dispatch({
      type: DELETE_TRANSACTION_DOCUMENT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .delete(
        `/api/admin/transaction/${id}/transaction-document/${id_item}/delete`
      )
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const getDetailTransactionDocument = (id, id_item) => {
  return (dispatch) => {
    dispatch({
      type: GET_DETAIL_TRANSACTION_DOCUMENT,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/transaction/${id}/transaction-document/${id_item}`)
      .then((resp) => {
        dispatch({
          type: GET_DETAIL_TRANSACTION_DOCUMENT,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_DETAIL_TRANSACTION_DOCUMENT,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const getSyncTransaction = (id) => {
  return (dispatch) => {
    dispatch({
      type: SYNCED_TRANSACTION,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/transaction/${id}/sync`)
      .then((resp) => {
        dispatch({
          type: SYNCED_TRANSACTION,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: SYNCED_TRANSACTION,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const getDOTransaction = (id) => {
  return (dispatch) => {
    dispatch({
      type: DO_NUMBER,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/transaction/document/${id}`)
      .then((resp) => {
        dispatch({
          type: DO_NUMBER,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: DO_NUMBER,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const updateTransItems = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(
        `/api/admin/transaction/update`,
        payload
      );
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });

      return err;
    }
  };
};
