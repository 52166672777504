import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Badge,
  Col,
  Card,
  CardTitle,
  CardBody,
  Media,
  Button,
  InputGroup,
  Input,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import styled from "styled-components";

const BtnClose = styled.div`
  cursor: pointer;
  font-weight: bold;
  :hover {
    background: #bbb;
  }
`;

const AttachTtb = (props) => {
  const [selectedCard, setSelectedCard] = useState({ id: "", name: "" });
  const [selected, setSelected] = useState(false);
  const dataInvoice = [
    {
      no: "SRNG-yymm-1234",
	  type: "MIYAKO - WD1869HY - 0912",
      status: 1,
      id: 1,
    },
    {
      no: "SRNG-yymm-1234",
	  type: "MIYAKO - WD1869HY - 0912",
      status: 2,
      id: 2,
    },
    {
      no: "SRNG-yymm-1234",
	  type: "MIYAKO - WD1869HY - 0912",
      status: 1,
      id: 3,
    },
  ];

  const toggleCloseProduct = (param) => {
    props.onClose(param);
  };
  const selectCardInvoice = (param) => {
    setSelectedCard((selectedCard) => ({
      ...selectedCard,
      id: param.id,
      name: param.productName,
    }));
    setSelected(true);
  };

  return (
    <Fragment>
      <div className="">
        <div className="d-flex justify-content-between my-1">
          <p><b>Pilih TTB</b></p>
          <BtnClose onClick={() => toggleCloseProduct("")}>X</BtnClose>
        </div>
        <InputGroup>
          <Input placeholder="Cari TTB" />
        </InputGroup>
      </div>
      <div className="att_ttb_list my-3">
        {dataInvoice.map((item) => (
          <Card
            className={`p-0 my-2 ${
              selected && selectedCard.id == item.id ? `card_selected` : ``
            }`}
            key={item.id}
            onClick={() => selectCardInvoice(item)}
            style={{ cursor: "pointer" }}
          >
            <CardBody className="p-2">
              <div className="d-flex justify-content-between my-1">
                {item.status === 1 ? (
                  <Badge color="success">
                    On Process
                  </Badge>
                ) : (
                  <Badge color="danger">
                    Failed
                  </Badge>
                )}
                <span>ID {item.id}</span>
              </div>
              <div className="d-flex my-1">
                <img className="my-auto" src="" alt="" width={30} height={30}/>
                <div className="mx-2">
                  <p>No.TTB</p>
                  <span><b>{item.no}</b></span>
                </div>
              </div>
              <div className="d-flex my-1">
                <img className="my-auto" src="" alt="" width={30} height={30}/>
                <div className="mx-2">
                  <p>Type SKU</p>
                  <span><b>{item.type}</b></span>
                </div>
              </div>
            </CardBody>
          </Card>
        ))}
      </div>
      <div className="btn-send-produk d-flex justify-content-between my-2 px-2">
        <Button color="primary" block disabled={selected ? false : true}>
          Lampirkan
        </Button>
      </div>
    </Fragment>
  );
};

export default AttachTtb;
