/* CUSTOMIZER */
export const ADD_COSTOMIZER ="ADD_COSTOMIZER";
export const ADD_LAYOUT = "ADD_LAYOUT";
export const ADD_SIDEBAR_TYPES = "ADD_SIDEBAR_TYPES";
export const ADD_SIDEBAR_SETTINGS ="ADD_SIDEBAR_SETTINGS";
export const ADD_COLOR = "ADD_COLOR";
export const ADD_MIX_BACKGROUND_LAYOUT = "ADD_MIX_BACKGROUND_LAYOUT";
export const ROUTER_ANIMATION = "ROUTER_ANIMATION";

//ALERT
export const WATCH_RESPONSE_ALERT = 'WATCH_RESPONSE_ALERT'

// Analytics
export const GET_INDEX = 'GET_INDEX'
export const GET_KEYWORDS_ANALYTICS = 'GET_KEYWORDS_ANALYTICS'
export const GET_PRODUCTS_ANALYTICS = 'GET_PRODUCTS_ANALYTICS'
export const GET_USAGE_ANALYTICS = 'GET_USAGE_ANALYTICS'

// LOGIN
export const WATCH_LOGIN = "WATCH_LOGIN";
export const GET_LOGIN = "GET_LOGIN";
export const GET_UUID = "GET_UUID";
export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_ROLE = "SET_USER_ROLE";
export const SET_USER_TOKEN = "SET_USER_TOKEN";

//Chat
export const GET_CHAT_CONFIG = "GET_CHAT_CONFIG";
export const GET_USER_UID = "GET_USER_UID";
export const SET_USER_UID = "SET_USER_UID";
export const SET_USER_CHAT = "SET_USER_CHAT";
export const GET_USER_CHAT = "GET_USER_CHAT";
export const GET_LIST_CHAT_USER = "GET_LIST_CHAT_USER";
export const GET_CHAT_HISTORY = "GET_CHAT_HISTORY";
export const GET_ADMIN_PRODUCT = "GET_ADMIN_PRODUCT";
export const GET_ADMIN_PROMO = "GET_ADMIN_PROMO";
export const GET_ADMIN_INVOICE = "GET_ADMIN_INVOICE";
export const CLEAR_DATA_LIST = "CLEAR_DATA_LIST";
export const SET_LATEST_MESSAGE = "SET_LATEST_MESSAGE";
export const GET_LATEST_MESSAGE = "GET_LATEST_MESSAGE";
export const SET_NOTIF_DATA = "SET_NOTIF_DATA";
export const GET_ADMIN_PROMO_SEARCH = "GET_ADMIN_PROMO_SEARCH";
export const GET_ADMIN_PRODUCT_SEARCH = "GET_ADMIN_PRODUCT_SEARCH";
export const SET_ENABLE_FETCH_CHAT = "SET_ENABLE_FETCH_CHAT";
export const SET_USER_INFO = "SET_USER_INFO";

//account
export const SET_UPDATE_ADMIN_PROFILE = "SET_UPDATE_ADMIN_PROFILE";

//master
//Campaign
export const LIST_CAMPAIGN = "LIST_CAMPAIGN";
export const DETAIL_CAMPAIGN = "DETAIL_CAMPAIGN";
export const LIST_PRODUCT_SEARCH = "LIST_PRODUCT_SEARCH";
export const LIST_CATEGORY_SEARCH = "LIST_CATEGORY_SEARCH";
export const LIST_BRAND_SEARCH = "LIST_BRAND_SEARCH";
export const LIST_PARTICIPANT_SEARCH = "LIST_PARTICIPANT_SEARCH";
export const LIST_CAMPAIGN_PRODUCT = "LIST_CAMPAIGN_PRODUCT";
export const LIST_CAMPAIGN_PARTICIPANT = "LIST_CAMPAIGN_PARTICIPANT";
export const LIST_PRODUCT_CAMPAIGN_ALL = "LIST_PRODUCT_CAMPAIGN_ALL";
export const LIST_CATEGORY_CAMPAIGN_ALL = "LIST_CATEGORY_CAMPAIGN_ALL";
export const LIST_BRAND_CAMPAIGN_ALL = "LIST_BRAND_CAMPAIGN_ALL";
//Config
export const GET_CONFIG = "GET_CONFIG";
//Promo
export const GET_LIST_PROMO = "GET_LIST_PROMO";
export const GET_LIST_PROMO_NEW = "GET_LIST_PROMO_NEW";
export const GET_LIST_PROMO_APPROVAL = "GET_LIST_PROMO_APPROVAL";
export const GET_DETAIL_PROMO = "GET_DETAIL_PROMO";
export const GET_DETAIL_BUNDLE = "GET_DETAIL_BUNDLE";
export const GET_LIST_PROMO_PRODUCT = "GET_LIST_PROMO_PRODUCT";
export const GET_LIST_PROMO_BUNDLE = "GET_LIST_PROMO_BUNDLE";
export const GET_LIST_PROMO_MASTER = "GET_LIST_PROMO_MASTER";
export const GET_LIST_APPROVAL = "GET_LIST_APPROVAL";
//Harga coret
export const GET_LIST_HARGACORET = "GET_LIST_HARGACORET";
//Free Barang
export const GET_LIST_FREE_BARANG = "GET_LIST_FREE_BARANG";
//Broadcast
export const GET_LIST_BROADCAST = "GET_LIST_BROADCAST"
export const GET_LIST_USER_UUID = "GET_LIST_USER_UUID"
//level
export const GET_LIST_LEVEL = 'GET_LIST_LEVEL'
export const GET_LEVEL_DETAIL = 'GET_LEVEL_DETAIL'
//voucher
export const GET_LIST_VOUCHER = 'GET_LIST_VOUCHER'
export const GET_VOUCHER_DETAIL = 'GET_VOUCHER_DETAIL'
//Point
export const GET_LIST_POINT = 'GET_LIST_POINT'
export const GET_POINT_DETAIL = 'GET_POINT_DETAIL'
//Transaction
export const GET_LIST_TRANSACTION_CONFIRM = "GET_LIST_TRANSACTION_CONFIRM"
export const GET_LIST_TRANSACTION_SHIPPING = "GET_LIST_TRANSACTION_SHIPPING"
export const GET_LIST_TRANSACTION_DELIVERED = "GET_LIST_TRANSACTION_DELIVERED"
export const GET_LIST_TRANSACTION_COMPLETE = "GET_LIST_TRANSACTION_COMPLETE"
export const GET_TRANSACTION_DETAIL = "GET_TRANSACTION_DETAIL"
export const ADD_TRANSACTION_DOCUMENT = "ADD_TRANSACTION_DOCUMENT"
export const UPDATE_TRANSACTION_DOCUMENT = "UPDATE_TRANSACTION_DOCUMENT"
export const DELETE_TRANSACTION_DOCUMENT = "DELETE_TRANSACTION_DOCUMENT"
export const GET_DETAIL_TRANSACTION_DOCUMENT = "GET_DETAIL_TRANSACTION_DOCUMENT"
export const SEND_REMINDER = "SEND_REMINDER"
export const SYNCED_TRANSACTION = "SYNCED_TRANSACTION"
export const DO_NUMBER = "DO_NUMBER"
//flashsale
export const GET_LIST_TABLE_FLASHSALE = "GET_LIST_TABLE_FLASHSALE"
export const GET_LIST_PRODUK_FLASHSALE = "GET_LIST_PRODUK_FLASHSALE"
export const GET_DETAIL_FLASHSALE = "GET_DETAIL_FLASHSALE"
//Mutasi
export const GET_LIST_MUTASI = "GET_LIST_MUTASI"
export const GET_DETAIL_MUTASI = "GET_DETAIL_MUTASI"
export const SYNC_MUTASI = "SYNC_MUTASI"
export const EXPORT_CSV_MUTASI = "EXPORT_CSV_MUTASI"
//User
export const GET_LIST_USER = "GET_LIST_USER"
export const GET_OTP_LOG = "GET_OTP_LOG"
export const UPDATE_USER = "UPDATE_USER"
export const DOWNLOAD_USER = "DOWNLOAD_USER"
export const GET_SYNC_USER = "GET_SYNC_USER"
export const CHANGE_STATUS_USER = "CHANGE_STATUS_USER"
export const RESET_PASSWORD_USER = "RESET_PASSWORD_USER"
export const GET_UPDATE_INFO = "GET_UPDATE_INFO"
export const PROCESS_UPDATE_INFO = "PROCESS_UPDATE_INFO"
//Product
export const GET_LIST_PRODUCT = "GET_LIST_PRODUCT"
export const ADD_PRODUCT = "ADD_PRODUCT"
export const UPDATE_PRODUCT = "UPDATE_PRODUCT"
export const GET_DETAIL_PRODUCT = "GET_DETAIL_PRODUCT"
export const GET_SYNC_PRODUCT = "GET_SYNC_PRODUCT"
export const DOWNLOAD_PRODUCT = "DOWNLOAD_PRODUCT"
export const UPLOAD_MASS_PRODUCT = "UPLOAD_MASS_PRODUCT"
export const BULK_PRODUCT = "BULK_PRODUCT"
export const GET_LIST_LOG = "GET_LIST_LOG"
export const GET_LIST_IMAGES = "GET_LIST_IMAGES"
export const GET_LIST_PRICE = "GET_LIST_PRICE"
export const GET_LIST_PRODUCT_DISCOUNT = "GET_LIST_PRODUCT_DISCOUNT"
export const ADD_PRODUCT_DISCOUNT = "ADD_PRODUCT_DISCOUNT"
export const UPDATE_PRODUCT_DISCOUNT = "UPDATE_PRODUCT_DISCOUNT"
export const DELETE_PRODUCT_DISCOUNT = "DELETE_PRODUCT_DISCOUNT"
//Category
export const GET_LIST_CATEGORY = "GET_LIST_CATEGORY"
export const ADD_CATEGORY = "ADD_CATEGORY"
export const UPDATE_CATEGORY = "UPDATE_CATEGORY"
export const DELETE_CATEGORY = "DELETE_CATEGORY"
export const GET_PARENT_CATEGORY = "GET_PARENT_CATEGORY"
export const GET_CHILD_CATEGORY = "GET_CHILD_CATEGORY"
export const CHANGE_STATUS_CATEGORY = "CHANGE_STATUS_CATEGORY"
export const GET_CATEGORY_DETAIL = "GET_CATEGORY_DETAIL"
//Brand
export const GET_LIST_BRAND = "GET_LIST_BRAND"
export const ADD_BRAND = "ADD_BRAND"
export const UPDATE_BRAND = "UPDATE_BRAND"
//News
export const GET_LIST_NEWS = "GET_LIST_NEWS"
export const ADD_NEWS = "ADD_NEWS"
export const UPDATE_NEWS = "UPDATE_NEWS"
export const GET_DETAIL_NEWS = "GET_DETAIL_NEWS"
//Section
export const GET_LIST_SECTION = "GET_LIST_SECTION"
export const ADD_SECTION = "ADD_SECTION"
export const UPDATE_SECTION = "UPDATE_SECTION"
export const DELETE_SECTION = "DELETE_SECTION"
export const DEACTIVE_SECTION = "DEACTIVE_SECTION"
export const GET_LIST_SECTION_ITEM = "GET_LIST_SECTION_ITEM"
export const ADD_SECTION_ITEM = "ADD_SECTION_ITEM"
export const ORDER_SECTION_ITEM = "ORDER_SECTION_ITEM"
export const DELETE_SECTION_ITEM = "DELETE_SECTION_ITEM"

//Konfigurasi
//Navlog
export const GET_LIST_NAVLOG = "GET_LIST_NAVLOG"
//User Admin
export const GET_LIST_ADMIN = "GET_LIST_ADMIN"
export const GET_DETAIL_ADMIN = "GET_DETAIL_ADMIN"

//Master aproval
export const GET_LIST_MASTER_APPROVAL = "GET_LIST_MASTER_APPROVAL"
export const GET_LIST_ROLE_APPROVAL = "GET_LIST_ROLE_APPROVAL"