import styled from "styled-components";

const BtnActivate = styled.div`
  color: green;
  border: none;
  cursor: pointer;
  background: transparent;
  font-size: 14px;
  margin: 0 11px;
  font-weight: 600;
`;

export default BtnActivate