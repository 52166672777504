import {
    GET_LIST_NAVLOG
} from "../../actionTypes";

const initialState = {
    listNavlog: {}
};

const NavlogReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_NAVLOG:
            state.listNavlog = action.payload;
            return { ...state, listNavlog: state.listNavlog };
        default:
            return { ...state };
    }
};

export default NavlogReducer;
