import {
  GET_LIST_PROMO,
  GET_DETAIL_PROMO,
  GET_LIST_PROMO_BUNDLE,
  GET_DETAIL_BUNDLE,
  GET_LIST_PROMO_MASTER,
  GET_LIST_PROMO_PRODUCT,
  GET_LIST_APPROVAL,
  GET_LIST_PROMO_NEW,
  GET_LIST_PROMO_APPROVAL,
} from "../../actionTypes";

const initialState = {
  listPromo: {},
  listPromoNew: {},
  listPromoApproval: {},
  listPromoMaster: {},
  listPromoBundle: {},
  detailPromo: {},
  detailBundle: {},
  listPromoProduct: {},
  listApproval: {},
};

const PromoReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_PROMO_NEW:
      return { ...state, listPromoNew: action.payload };
    case GET_LIST_PROMO_APPROVAL:
      return { ...state, listPromoApproval: action.payload };
    case GET_LIST_PROMO:
      return { ...state, listPromo: action.payload };
    case GET_LIST_PROMO_MASTER:
      return { ...state, listPromoMaster: action.payload };
    case GET_DETAIL_PROMO:
      return { ...state, detailPromo: action.payload };
    case GET_DETAIL_BUNDLE:
      return { ...state, detailBundle: action.payload };
    case GET_LIST_PROMO_BUNDLE:
      return { ...state, listPromoBundle: action.payload };
    case GET_LIST_PROMO_PRODUCT:
      return { ...state, listPromoProduct: action.payload };
    case GET_LIST_APPROVAL:
      return { ...state, listApproval: action.payload };
    default:
      return { ...state };
  }
};

export default PromoReducer;
