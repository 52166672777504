import React, { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { CometChat } from "@cometchat-pro/chat";
import Cookies from "universal-cookie";

import {
  Container,
  Row,
  Badge,
  Col,
  Card,
  CardBody,
  Media,
  Input,
  InputGroup,
  Button,
} from "reactstrap";
import {
  getListChatUsers,
  clearNotificationData,
  fetchUserInfo,
} from "../../../redux/action/chatAction";
import CurrencyFormatter from "../../../Component/currencyFormatter";
import { guid } from "../../../constant/param";
import Breadcrumb from "../../../layout/breadcrumb";
import ChatSidebar from "./chatSidebarList";

//attachment
import AttachProduk from "./attachment/product";
import AttachSales from "./attachment/salesorder";
import AttachSalesDetail from "./attachment/salesorder/salesorderdetail";
import AttachPromo from "./attachment/promo";
import AttachInvoice from "./attachment/invoice";
import AttachTtb from "./attachment/ttb";
import AttachOffer from "./attachment/offer";
import UserCustomerInfo from "./userInfo";

import produkExample from "../../../assets/images/product/image 4.png";
import produkExample2 from "../../../assets/images/product/image 3.png";
import add_icon from "../../../assets/images/add-icon.svg";
import send_icon from "../../../assets/images/send-icon.svg";
import icon_attach_produk from "../../../assets/images/attachment/icon-attach-produk.png";
import icon_attach_sales from "../../../assets/images/attachment/icon-attach-so.png";
import icon_attach_promo from "../../../assets/images/attachment/icon-attach-promo.png";
import icon_attach_invoice from "../../../assets/images/attachment/icon-attach-invoice.png";
import user_profile from "../../../assets/images/user/user-profile.svg";

const Chat = (props) => {
  const [messageInput, setMessageInput] = useState("");
  const [userSearchKeywords, setUserSearchKeywords] = useState("");
  const [activeChat, setActiveChat] = useState(0);
  const [toggleChat, setToggleChat] = useState(false);
  const [conversationWith, setConversationWith] = useState("");
  const [showMenuChat, setShowMenuChat] = useState(false);
  const [isFetchUser, setFetchUser] = useState(true);
  const [isDisableSend, setIsDisableSend] = useState(false);
  const [paramAttach, setParamAttach] = useState("");
  const [userChatDetail, setUserChatDetail] = useState({
    id: "",
    receiverID: "",
    name: "",
    status: "",
    lastActiveAt: "",
    image: "",
  });
  const [userChatList, setUserChatList] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [listAttachment, setListAttachment] = useState([]);
  const [isFetchMsg, setIsFetchMsg] = useState(true);
  const [typeAttachment, setTypeAttachment] = useState("");
  const [UID, setUID] = useState("");
  const [itemSalesOrderDetail, setItemSalesOrderDetail] = useState({});
  const [toggleSideWindow, setToggleSideWindow] = useState("default");
  const [userChatInfo, setUserChatInfo] = useState({});
  const [chatListTemp, setChatlistTemp] = useState([]);

  const {
    listConversationChat,
    chatLatestMessage,
    notifData,
    stateIsFetchChat,
    userInfoData,
  } = useSelector((state) => state.ChatReducer);
  const dispatch = useDispatch();
  const cookies = new Cookies();

  useEffect(() => {
    if (cookies.get("uid") !== undefined) {
      setUID(cookies.get("uid"));
    }

    return () => {
      setToggleChat(false);
      setConversationWith("");
      dispatch(clearNotificationData());
    };
  }, []);

  //check if coming from notif or not
  useEffect(() => {
    if (notifData.fromNotif) {
      onClickChat(notifData.data);
    }
  }, [notifData]);

  //check cometchat login
  useEffect(() => {
    if (stateIsFetchChat && stateIsFetchChat.fetch) {
      dispatch(getListChatUsers());
    } else {
      setFetchUser(false);
    }
  }, [stateIsFetchChat]);

  // fetch list conversation
  useEffect(() => {
    if (listConversationChat.data && listConversationChat.data.length > 0) {
      const newData = listConversationChat.data;
      setUserChatList(newData);
      setFetchUser(false);
    }
    setFetchUser(false);
  }, [listConversationChat.data]);

  //check messages from listener
  useEffect(() => {
    if (chatLatestMessage.data) {
      const lastMsg = chatLatestMessage.data;
      if (toggleChat && conversationWith === lastMsg.sender.uid) {
        fetchMessage(lastMsg);
      }
    }
  }, [chatLatestMessage.data]);

  useEffect(() => {
    if (userInfoData.data) {
      setUserChatInfo(userInfoData.data);
    }
  }, [userInfoData.data]);

  const handleMouseGrab = (e) => {
    const slider = document.querySelector(".container_item_attached");
    let isDown = false;
    let startX;
    let scrollLeft;
    if (e.type === "mousedown") {
      console.log("1.grabbed?");
      isDown = true;
      slider.classList.add("active");
      startX = e.pageX - slider.offsetLeft;
      scrollLeft = slider.scrollLeft;
    } else if (e.type === "mouseleave") {
      isDown = false;
      slider.classList.remove("active");
    } else if (e.type === "mouseup") {
      isDown = false;
      slider.classList.remove("active");
    } else if (e.type === "mousemove") {
      if (isDown) {
        console.log("2.grabbed move");
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3; //scroll-fast
        slider.scrollLeft = scrollLeft - walk;
        console.log("walk?", walk);
      }
    }
  };

  const transaksiList = [];

  const handleSendChat = () => {
    let receiverID = userChatDetail.receiverID;
    let messageText = messageInput;
    let receiverType = CometChat.RECEIVER_TYPE.USER;
    setIsDisableSend(true);

    //if message contain attachment
    if (listAttachment.length > 0) {
      if (typeAttachment === "salesorder") {
        let customData = { attachments: listAttachment, text: messageText };
        let customType = typeAttachment;
        let customMessage = new CometChat.CustomMessage(
          receiverID,
          receiverType,
          customType,
          customData
        );
        CometChat.sendCustomMessage(customMessage).then(
          (message) => {
            setChatHistory((chatHistory) => [...chatHistory, message]);
            setListAttachment([]);
            setIsDisableSend(false);
          },
          (error) => {
            console.log("custom message sending failed with error", error);
          }
        );
      } else {
        let customData = { attachments: listAttachment, text: messageText };
        let customType = typeAttachment;
        let customMessage = new CometChat.CustomMessage(
          receiverID,
          receiverType,
          customType,
          customData
        );
        CometChat.sendCustomMessage(customMessage).then(
          (message) => {
            setChatHistory((chatHistory) => [...chatHistory, message]);
            setListAttachment([]);
            setIsDisableSend(false);
          },
          (error) => {
            console.log("custom message sending failed with error", error);
          }
        );
      }
    }
    //if message is normal text
    else {
      if (messageText !== "") {
        let textMessage = new CometChat.TextMessage(
          receiverID,
          messageText,
          receiverType
        );
        CometChat.sendMessage(textMessage).then(
          (message) => {
            setChatHistory((chatHistory) => [...chatHistory, message]);
            setIsDisableSend(false);
          },
          (error) => {
            console.log("Message sending failed with error:", error);
          }
        );
      }
    }
    setMessageInput("");
  };

  const handleUpdateAttachment = (data) => {
    setListAttachment(data);
  };

  const toggleShowAttach = (param) => {
    if (param !== "") {
      setParamAttach(param);
      setShowMenuChat(false);
      setToggleSideWindow("attachment");
    } else {
      setToggleSideWindow("default");
    }
  };

  const toggleAttach = (param) => {
    switch (param) {
      case "product":
        return (
          <AttachProduk
            onClose={toggleShowAttach}
            handleUpdateAttachment={handleUpdateAttachment}
            setTypeAttachment={setTypeAttachment}
          />
        );
      case "sales":
        return (
          <AttachSales
            onClose={toggleShowAttach}
            handleUpdateAttachment={handleUpdateAttachment}
            setTypeAttachment={setTypeAttachment}
          />
        );
      case "promo":
        return (
          <AttachPromo
            onClose={toggleShowAttach}
            handleUpdateAttachment={handleUpdateAttachment}
            setTypeAttachment={setTypeAttachment}
          />
        );
      case "invoice":
        return (
          <AttachInvoice
            onClose={toggleShowAttach}
            handleUpdateAttachment={handleUpdateAttachment}
            setTypeAttachment={setTypeAttachment}
            receiverID={userChatDetail.receiverID}
          />
        );
      case "ttb":
        return <AttachTtb onClose={toggleShowAttach} />;
      case "offer":
        return <AttachOffer onClose={toggleShowAttach} />;
      case "salesorderdetail":
        return (
          <AttachSalesDetail
            onClose={toggleShowAttach}
            item={itemSalesOrderDetail}
          />
        );
      default:
        break;
    }
  };

  const onClickChat = (data) => {
    let recieverUID = data.conversationWith.uid;
    setConversationWith("");
    setActiveChat(recieverUID);
    setToggleChat(true);
    setIsFetchMsg(true);
    //setShowAttach(false);
    setToggleSideWindow("default");
    setConversationWith(recieverUID);
    setUserChatDetail((userChatDetail) => ({
      ...userChatDetail,
      receiverID: recieverUID,
      name: data.conversationWith.name,
      status: data.conversationWith.status,
      lastActiveAt: data.conversationWith.lastActiveAt,
      image: "",
      chat: [],
    }));

    getUserChatHistory(recieverUID);

    //Mark as read latest chat
    if (data.lastMessage) {
      CometChat.markAsRead(data.lastMessage);
    }
  };

  const getUserChatHistory = (recieverUID) => {
    let messagesRequest = new CometChat.MessagesRequestBuilder()
      .setUID(recieverUID)
      .hideDeletedMessages()
      .setLimit(30)
      .build();

    messagesRequest.fetchPrevious().then(
      (messages) => {
        setChatHistory([]);
        setChatHistory(messages);
        setIsFetchMsg(false);
      },
      (error) => {
        console.log("Message fetching failed with error:", error);
      }
    );
  };

  const fetchMessage = (msgData) => {
    let senderID = msgData.data.entities.sender.entity.uid;
    let newArr;
    let messagesRequest = new CometChat.MessagesRequestBuilder()
      .setUID(senderID)
      .setLimit(30)
      .build();
    messagesRequest.fetchPrevious().then(
      (messages) => {
        setChatHistory([]);
        newArr = messages;
        setChatHistory(newArr);
      },
      (error) => {
        console.log("Message fetching failed with error:", error);
      }
    );
  };

  const handleViewSODetail = (data) => {
    setItemSalesOrderDetail({});
    toggleShowAttach("salesorderdetail");
    setItemSalesOrderDetail(data);
  };

  const handleRemoveAttachment = (param) => {
    setListAttachment(listAttachment.filter((arr) => arr.id !== param));
  };

  const toggleShowUserInfo = (state, user) => {
    if (state === "show") {
      setToggleSideWindow("userinfo");
      dispatch(fetchUserInfo(user.receiverID));
    } else {
      setToggleSideWindow("default");
    }
  };

  const handleOnEnterUserSearch = () => {
    setChatlistTemp(userChatList);
    let newArr = userChatList.filter((item) =>
      item.conversationWith.name
        .toLowerCase()
        .includes(userSearchKeywords.toLowerCase())
    );
    setUserChatList(newArr);
    setUserSearchKeywords("");
  };

  const handleClearSearchInput = () => {
    setUserSearchKeywords("");
    setUserChatList(chatListTemp);
    setChatlistTemp([]);
  };

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Chat" urlParent={'/dashboard/analytics'} />
      <Container className="m-0 p-1 chat-container" fluid>
        <Row className="p-0">
          <Col className="call-chat-sidebar">
            <ChatSidebar
              userChatList={userChatList}
              isFetchUser={isFetchUser}
              toggleChat={toggleChat}
              activeChat={activeChat}
              onClickChat={onClickChat}
              userSearchKeywords={userSearchKeywords}
              setUserSearchKeywords={setUserSearchKeywords}
              handleOnEnterUserSearch={handleOnEnterUserSearch}
              handleClearSearchInput={handleClearSearchInput}
            />
          </Col>

          <Col className="call-chat-body">
            {toggleChat && userChatDetail.receiverID == activeChat ? (
              <Row className="chat-box">
                <Col className="chat-right-aside pr-0">
                  <div className="chat">
                    {/* Chat header */}
                    <div
                      onClick={() => {
                        toggleShowUserInfo("show", userChatDetail);
                      }}
                      className="clearfix chat-header"
                      style={{ cursor: "pointer" }}
                    >
                      <img
                        src={user_profile}
                        alt=""
                        className="my-auto mr-2 p-auto"
                      />
                      <div className="about">
                        <div className="name">{userChatDetail.name}</div>
                        <div className="digits status">
                          {userChatDetail.status}
                        </div>
                      </div>
                    </div>

                    {/* Chat Message Box */}
                    <div className="custom-scrollbar chat-history chat-msg-box">
                      {!isFetchMsg ? (
                        <ul>
                          {chatHistory.length > 0 ? (
                            chatHistory.map((data) =>
                              // normal message
                              data.category === "message" ? (
                                <li key={data.id + guid()} className="clearfix">
                                  <div
                                    className={`message my-message p-2 ${
                                      data.receiverId === UID
                                        ? ""
                                        : "float-right"
                                    }`}
                                    style={
                                      data.receiverId === UID
                                        ? {}
                                        : { background: "#E9EDFD" }
                                    }
                                  >
                                    {data.text}
                                    <div className="text-right message-data">
                                      <span className="message-data-time">
                                        Sent At{" "}
                                        {moment
                                          .unix(data.sentAt)
                                          .format("DD MMM HH:mm")}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              ) : //Action message
                              data.category === "action" ? (
                                <li key={data.id + guid()} className="clearfix">
                                  {data.action && data.action === "deleted" ? (
                                    <div
                                      className={`message my-message ${
                                        data.receiverId === UID
                                          ? ""
                                          : "float-right"
                                      }`}
                                      style={
                                        data.receiverId === UID
                                          ? {}
                                          : { background: "#E9EDFD" }
                                      }
                                    >
                                      <i>this message was deleted.</i>
                                    </div>
                                  ) : (
                                    <div
                                      className={`message my-message ${
                                        data.receiverId === UID
                                          ? ""
                                          : "float-right"
                                      }`}
                                      style={
                                        data.receiverId === UID
                                          ? {}
                                          : { background: "#E9EDFD" }
                                      }
                                    >
                                      {data.message}
                                      <div className="text-right message-data">
                                        <span className="message-data-time">
                                          Sent At{" "}
                                          {moment
                                            .unix(data.sentAt)
                                            .format("DD MMM HH:mm")}
                                        </span>
                                      </div>
                                    </div>
                                  )}
                                </li>
                              ) : //Custom message
                              data.category === "custom" &&
                                data.data.customData ? (
                                <li key={data.id + guid()} className="clearfix">
                                  <div
                                    className={`message my-message p-1 m-1 ${
                                      data.receiverId === UID
                                        ? ""
                                        : "float-right"
                                    }`}
                                    style={
                                      data.receiverId === UID
                                        ? {}
                                        : { background: "#E9EDFD" }
                                    }
                                    onClick={
                                      data.type !== "salesorder"
                                        ? () => {}
                                        : () =>
                                            handleViewSODetail(
                                              data.data.customData.attachments
                                            )
                                    }
                                  >
                                    <div className="d-flex justify-content-between msg_attach_list">
                                      {data.type === "promo"
                                        ? data.data.customData.attachments.map(
                                            (item) => (
                                              <div
                                                key={item.id}
                                                className="m-1 p-2 msg_attach"
                                              >
                                                <b>
                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html: item.content,
                                                    }}
                                                  ></div>
                                                </b>
                                                <div>
                                                  {moment
                                                    .utc(item.start_date)
                                                    .format("DD MMM YYYY")}{" "}
                                                  -{" "}
                                                  {moment
                                                    .utc(item.end_date)
                                                    .format("DD MMM YYYY")}
                                                </div>
                                              </div>
                                            )
                                          )
                                        : data.type === "product"
                                        ? data.data.customData.attachments.map(
                                            (item, id) => (
                                              <div
                                                key={id}
                                                className="d-flex justify-content-between m-1 p-2 msg_attach"
                                              >
                                                <img
                                                  src={item.images[0].image_url}
                                                  alt=""
                                                  width={60}
                                                  height={60}
                                                />
                                                <div className="m-1">
                                                  <p>{item.name}</p>
                                                  <span className="csi_price">
                                                    <CurrencyFormatter
                                                      digit={item.price}
                                                      typeDigit={"amount"}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            )
                                          )
                                        : data.type === "invoice"
                                        ? data.data.customData.attachments.map(
                                            (item, id) => (
                                              <div
                                                key={id}
                                                className="d-flex justify-content-between m-1 p-2 msg_attach"
                                              >
                                                <div className="my-1">
                                                  <div className="d-flex justify-content-start">
                                                    <div>
                                                      <div className="badge_blue badge_csi">
                                                        {item.document_type}
                                                      </div>
                                                      <p className="my-1">
                                                        {item.description}
                                                      </p>
                                                      <p
                                                        style={{
                                                          color: "#2647e6",
                                                        }}
                                                      >
                                                        <b>
                                                          Rp.{" "}
                                                          {
                                                            item.remaining_amount
                                                          }
                                                        </b>
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            )
                                          )
                                        : data.type === "salesorder"
                                        ? data.data.customData.attachments.map(
                                            (item, id) => (
                                              <div
                                                key={id}
                                                className="d-flex justify-content-between m-1 p-2 msg_attach"
                                              >
                                                <img
                                                  src={item.images[0].image_url}
                                                  alt=""
                                                  width={60}
                                                  height={60}
                                                />
                                                <div className="m-1">
                                                  <p>{item.name}</p>
                                                  <span className="csi_price">
                                                    <CurrencyFormatter
                                                      digit={item.price}
                                                      typeDigit={"amount"}
                                                    />
                                                  </span>
                                                </div>
                                              </div>
                                            )
                                          )
                                        : data.type === "Broadcast"
                                        ? data.data.customData.voucher.map(
                                            (item) => (
                                              <div
                                                key={item.id}
                                                className="d-flex justify-content-between m-1 p-2 msg_attach"
                                              >
                                                <img
                                                  src={`${item.image_url}${item.image}`}
                                                  alt={item.deskripsi}
                                                  width={120}
                                                  height={120}
                                                />
                                                <div className="m-1">
                                                  <p>
                                                    <b>{item.name}</b>
                                                  </p>
                                                  <span>{item.deskripsi}</span>
                                                </div>
                                              </div>
                                            )
                                          )
                                        : null}
                                    </div>

                                    <div className="my-2">
                                      {data.data.customData.text
                                        ? data.data.customData.text
                                        : data.data.text}
                                    </div>
                                    <div className="text-right message-data">
                                      <span className="message-data-time">
                                        Sent At{" "}
                                        {moment
                                          .unix(data.sentAt)
                                          .format("DD MMM HH:mm")}
                                      </span>
                                    </div>
                                  </div>
                                </li>
                              ) : null
                            )
                          ) : (
                            <div className="chat-empty">
                              <div className="m-auto loader"></div>
                            </div>
                          )}
                        </ul>
                      ) : (
                        <div className="chat-empty">
                          <div className="m-auto loader"></div>
                        </div>
                      )}
                    </div>

                    <div className="chat-message">
                      {/* Bottom Chat Menu */}
                      <Fragment>
                        {showMenuChat ? (
                          <div className="d-flex justify-content-start p-2 text-center attachment-menu">
                            <div className="m-2">
                              <div
                                className="attach-icon btn-produk"
                                onClick={() => toggleShowAttach("product")}
                              >
                                <img src={icon_attach_produk} alt="" />
                              </div>
                              <span className="my-1">Produk</span>
                            </div>
                            <div className="m-2">
                              <div
                                className="attach-icon btn-so"
                                onClick={() => toggleShowAttach("sales")}
                              >
                                <img src={icon_attach_sales} alt="" />
                              </div>
                              <span className="my-1">Sales Order</span>
                            </div>
                            <div className="m-2">
                              <div
                                className="attach-icon btn-promo"
                                onClick={() => toggleShowAttach("promo")}
                              >
                                <img src={icon_attach_promo} alt="" />
                              </div>
                              <span className="my-1">Promo</span>
                            </div>
                            {/* <div>
                            <div
                              className="attach-icon btn-gambar"
                              onClick={() => toggleShowAttach("gambar")}
                            >
                              <img src={icon_attach_gambar} alt="" />
                            </div>
                            <span className="my-1">Gambar</span>
                          </div> */}
                            <div className="m-2">
                              <div
                                className="attach-icon btn-invoice"
                                onClick={() => toggleShowAttach("invoice")}
                              >
                                <img src={icon_attach_invoice} />
                              </div>
                              <span className="my-1">Invoice</span>
                            </div>
                            {/* <div className="m-2">
                            <div
                              className="attach-icon btn-ttb"
                              onClick={() => toggleShowAttach("ttb")}
                            >
                              <img src={icon_attach_ttb} />
                            </div>
                            <span className="my-1">TTB</span>
                          </div> */}
                          </div>
                        ) : null}
                      </Fragment>

                      {/* Bottom Chat Attachment */}
                      <Fragment>
                        {typeAttachment === "product" ? (
                          listAttachment.length > 0 ? (
                            <div
                              className="d-flex justify-content-between m-2 container_item_attached"
                            >
                              {listAttachment.map((item, id) =>
                                item.images && item.images.length > 0 ? (
                                  <div
                                    key={id}
                                    className="d-flex justify-content-between m-1 wrapper_item_attached"
                                    onMouseMove={handleMouseGrab}
                                  >
                                    <img
                                      src={item.images[0].image_url}
                                      alt=""
                                      className="m-1 img_item_attached"
                                    />
                                    <div className="m-1 p-1 item_attached">
                                      <p>{item.name}</p>
                                      <p style={{ color: "#2647e6" }}>
                                        <b>
                                          <CurrencyFormatter
                                            digit={item.price}
                                            typeDigit={"amount"}
                                          />
                                        </b>
                                      </p>
                                    </div>
                                    <Button
                                      className="m-1"
                                      onClick={() =>
                                        handleRemoveAttachment(item.id)
                                      }
                                      close
                                    />
                                  </div>
                                ) : (
                                  <div
                                    key={id}
                                    className="d-flex justify-content-between m-1 wrapper_item_attached"
                                  >
                                    <div className="item_attached">
                                      <p>{item.name}</p>
                                      <p style={{ color: "#2647e6" }}>
                                        <b>
                                          <CurrencyFormatter
                                            digit={item.price}
                                            typeDigit={"amount"}
                                          />
                                        </b>
                                      </p>
                                    </div>
                                    <Button
                                      className="m-1"
                                      onClick={() => handleRemoveAttachment(id)}
                                      close
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          ) : null
                        ) : typeAttachment === "promo" ? (
                          listAttachment.length > 0 ? (
                            <div className="d-flex justify-content-between m-2 container_item_attached">
                              {listAttachment.map((item, id) => (
                                <div
                                  key={id}
                                  className="d-flex justify-content-between m-1 wrapper_item_attached"
                                >
                                  <div className="p-2 item_attached">
                                    <p>
                                      <b>{item.title}</b>
                                    </p>
                                    <p>
                                      {moment
                                        .utc(item.start_date)
                                        .format("DD MMM YYYY")}{" "}
                                      -{" "}
                                      {moment
                                        .utc(item.end_date)
                                        .format("DD MMM YYYY")}
                                    </p>
                                  </div>
                                  <Button
                                    className="m-1"
                                    onClick={() =>
                                      handleRemoveAttachment(item.id)
                                    }
                                    close
                                  />
                                </div>
                              ))}
                            </div>
                          ) : null
                        ) : typeAttachment === "invoice" ? (
                          listAttachment.length > 0 ? (
                            <div className="d-flex justify-content-between m-2 container_item_attached">
                              {listAttachment.map((item, id) => (
                                <div
                                  key={id}
                                  className="d-flex justify-content-between m-1 p-1 wrapper_item_attached"
                                >
                                  <div className="my-1">
                                    <div className="d-flex justify-content-start">
                                      <div>
                                        <div className="badge_blue badge_csi">
                                          {item.document_type}
                                        </div>
                                        <p className="my-1">
                                          {item.description}
                                        </p>
                                        <p style={{ color: "#2647e6" }}>
                                          <b>Rp. {item.remaining_amount}</b>
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                  <Button
                                    className="m-1"
                                    onClick={() =>
                                      handleRemoveAttachment(item.id)
                                    }
                                    close
                                  />
                                </div>
                              ))}
                            </div>
                          ) : null
                        ) : typeAttachment === "salesorder" ? (
                          listAttachment.length > 0 ? (
                            <div className="d-flex justify-content-between m-2 container_item_attached">
                              {listAttachment.map((item, id) =>
                                item.images && item.images.length > 0 ? (
                                  <div
                                    key={id}
                                    className="d-flex justify-content-between m-1 wrapper_item_attached"
                                    onMouseMove={handleMouseGrab}
                                  >
                                    <img
                                      src={item.images[0].image_url}
                                      alt=""
                                      className="m-1 img_item_attached"
                                    />
                                    <div className="m-1 p-1 item_attached">
                                      <span>
                                        <b>Sales Order</b>
                                      </span>
                                      <p className="m-0">{item.name}</p>
                                      <p
                                        className="m-0"
                                        style={{ color: "#2647e6" }}
                                      >
                                        <b>
                                          <CurrencyFormatter
                                            digit={item.price}
                                            typeDigit={"amount"}
                                          />
                                        </b>
                                      </p>
                                    </div>
                                    <Button
                                      className="m-1"
                                      onClick={() =>
                                        handleRemoveAttachment(item.id)
                                      }
                                      close
                                    />
                                  </div>
                                ) : (
                                  <div
                                    key={id}
                                    className="d-flex justify-content-between m-1 wrapper_item_attached"
                                  >
                                    <div className="item_attached">
                                      <span>
                                        <b>Sales Order</b>
                                      </span>
                                      <p className="m-0">{item.name}</p>
                                      <p
                                        className="m-0"
                                        style={{ color: "#2647e6" }}
                                      >
                                        <b>
                                          <CurrencyFormatter
                                            digit={item.price}
                                            typeDigit={"amount"}
                                          />
                                        </b>
                                      </p>
                                    </div>
                                    <Button
                                      className="m-1"
                                      onClick={() => handleRemoveAttachment(id)}
                                      close
                                    />
                                  </div>
                                )
                              )}
                            </div>
                          ) : null
                        ) : (
                          ""
                        )}
                      </Fragment>

                      {/* Text Input Box */}
                      <div className="d-flex justify-content-between m-0 chat_input_box">
                        <div
                          className="smiley-box"
                          onClick={() => setShowMenuChat(!showMenuChat)}
                        >
                          <Media
                            onClick={() => setShowMenuChat(!showMenuChat)}
                            src={add_icon}
                            alt=""
                          />
                        </div>
                        <InputGroup className="mx-2 text-box">
                          <Input
                            type="text"
                            className="form-control input-txt-bx"
                            placeholder="Type a message..."
                            value={messageInput}
                            onChange={(e) => setMessageInput(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") handleSendChat();
                            }}
                          />
                        </InputGroup>
                        <Button
                          color="primary"
                          onClick={() => handleSendChat()}
                          disabled={isDisableSend}
                        >
                          <Media src={send_icon} alt="" />
                        </Button>
                      </div>
                    </div>
                  </div>
                </Col>

                {/* Left Side Window */}
                {toggleSideWindow === "attachment" ? (
                  <Col className="p-3">{toggleAttach(paramAttach)}</Col>
                ) : toggleSideWindow === "detailriwayat" ? (
                  <Col className="p-3 invoice-detail">
                    <div className="d-flex justify-content-between my-2">
                      <h5 className="mt-1">Detail Transaksi</h5>
                      <Button
                        size="md"
                        close
                        onClick={() => setToggleSideWindow("detailriwayat")}
                      />
                    </div>
                    <div className="my-1 trans-detail">
                      <div className="d-flex justify-content-between">
                        <p>No. Invoice</p>
                        <span>INV/202202/IP/08929</span>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p>Status</p>
                        <span>
                          <Badge color="primary" outline="true">
                            Pesanan Baru
                          </Badge>
                        </span>
                      </div>
                    </div>
                    <div className="d-flex my-4 py-2 trans-detail-produk">
                      <Media
                        src={produkExample}
                        className="rounded-circle"
                        alt=""
                      />
                      <div className="mx-auto">
                        <p>Magic Comp Pressure Cooker</p>
                        <div className="d-flex justify-content-between">
                          <span>
                            <b>Rp.990.000</b>
                          </span>
                          <span>x1</span>
                        </div>
                      </div>
                    </div>
                    <div className="my-2">
                      <p>
                        <b>Ringkasan</b>
                      </p>
                      <div className="d-flex justify-content-between my-1">
                        <span>Sub Total</span>
                        <span>Rp. 2.600.000</span>
                      </div>
                      <div className="d-flex justify-content-between my-1">
                        <span>Total Diskon</span>
                        <span>Rp. 420.000</span>
                      </div>
                      <div className="d-flex justify-content-between my-1">
                        <span>Total Harga</span>
                        <span>Rp. 2.123.000</span>
                      </div>
                    </div>
                  </Col>
                ) : toggleSideWindow === "userinfo" ? (
                  <Col className="p-3 invoice-detail">
                    <UserCustomerInfo
                      data={userChatInfo}
                      key={userChatInfo}
                      toggleShowUserInfo={toggleShowUserInfo}
                    />
                  </Col>
                ) : (
                  <Col className="p-3 attach-detail">
                    <div className="detail-header">
                      <span>
                        <b>Riwayat Transaksi</b>
                      </span>
                    </div>
                    {transaksiList.length > 0 ? (
                      <div className="p-2 attach-transaksi-list">
                        {transaksiList.map((item, i) => (
                          <Card
                            className="p-0 attach-card attach-product-card"
                            key={i}
                          >
                            <CardBody className="p-2">
                              <div className="d-flex justify-content-between my-2">
                                {item.statusPesanan === 1 ? (
                                  <Badge color="success">Pesanan Baru</Badge>
                                ) : (
                                  <Badge color="success">Selesai</Badge>
                                )}
                                <span>{item.dateTrans}</span>
                              </div>
                              <div className="d-flex my-2">
                                <img
                                  className="my-auto"
                                  src={produkExample2}
                                  width={30}
                                  height={50}
                                />
                                <div className="mx-auto">
                                  <p
                                    className="m-0 p-0"
                                    style={{ color: "#092540" }}
                                  >
                                    <b>{item.prodName}</b>
                                  </p>
                                  <span>{item.qty} Barang</span>
                                </div>
                              </div>
                              <div
                                className="d-flex justify-content-between mt-3"
                              >
                                <div>
                                  <span>Total Belanja</span>
                                  <p
                                    className="m-0"
                                    style={{ color: "#092540" }}
                                  >
                                    <b>Rp. {item.totalPrice}</b>
                                  </p>
                                </div>
                                <Button
                                  onClick={() => setToggleSideWindow(item)}
                                  outline
                                  color="primary"
                                >
                                  Lihat Detail
                                </Button>
                              </div>
                            </CardBody>
                          </Card>
                        ))}
                      </div>
                    ) : (
                      <div className="p-2 attach-transaksi-list">
                        Tidak ada riwayat transaksi.
                      </div>
                    )}
                  </Col>
                )}
              </Row>
            ) : (
              <Row className="chat-box">
                <Col className="empty-chat"></Col>
              </Row>
            )}
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Chat;
