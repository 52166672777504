import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import SelectList from "@components/selectList";

import {
  getListRoleApproval,
  updateMasterApproval,
} from "@action/masterAprovalAction";

const PopEdit = ({ isOpen, togglePopup, index, data }) => {
  const dispatch = useDispatch();
  const { listRoleApproval } = useSelector(
    (state) => state.MasterApprovalReducer
  );

  const [roleList, setRoleList] = useState([]);
  const [role, setRole] = useState("");

  useEffect(() => {
    if (isOpen) {
      dispatch(getListRoleApproval());
    }
  }, [isOpen]);

  useEffect(() => {
    if (data && data.roleID) {
      setRole(data.roleID);
    }
  }, [data]);

  useEffect(() => {
    if (listRoleApproval.data && !listRoleApproval.loading) {
      const list = listRoleApproval.data.data;
      const mapped = list.map((item) => ({
        id: item.id,
        name: item.level_name,
        val: item.level,
      }));
      setRoleList(mapped);
    }
  }, [listRoleApproval]);

  function onSubmit() {
    if (role === "") return toast.error("Role cannot empty.");

    const form = new FormData();
    form.append("user_id", data.userID);
    form.append("role", role);
    form.append("id", data.id);

    dispatch(updateMasterApproval(form)).then((res) => {
      if (res.status !== 200) return;

	  togglePopup()
    });
  }

  return (
    <div>
      <Modal isOpen={isOpen} toggle={togglePopup} centered autoFocus>
        <ModalHeader toggle={togglePopup}>Edit {data && data.nama}</ModalHeader>
        <ModalBody className="d-flex flex-column">
          <span className="my-2">
            <b>Role</b>
            <span style={{ color: "red" }}>*</span>
          </span>
          <SelectList
            values={roleList}
            onChange={(val) => setRole(val.target.value)}
            placeholder="Select Role"
            currValue={role}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={togglePopup} outline>
            Cancel
          </Button>
          <Button color="primary" onClick={onSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PopEdit;
