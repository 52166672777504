import apiConfig from "../../api";
import { GET_LIST_HARGACORET, WATCH_RESPONSE_ALERT } from "../actionTypes";

export const getListHargaCoret = (page, param, perPage) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(
        `/api/admin/products/harga-coret?page=${page}&search=${
          param ? param : ""
        }&perPage=${perPage ? perPage : 10}`
      );
      dispatch({
        type: GET_LIST_HARGACORET,
        payload: {
          loading: false,
          data: resp.data,
          err: false,
        },
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });
    }
  };
};

export const updateHargaDiskon = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(
        `/api/admin/products/harga-coret`,
        payload
      );

      return resp;
    } catch (err) {
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });
    }
  };
};

export const updateHargaStatus = (id, status) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(
        `/api/admin/products/harga-coret/status/${id}?status=${status}`
      );

      return resp;
    } catch (err) {
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });
    }
  };
};
