import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Row,
  Badge,
  Col,
  Card,
  CardTitle,
  CardBody,
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import styled from "styled-components";

//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  clearDataList,
  fetchAdminPromo,
  searchAdminPromo,
} from "../../../../../redux/action/chatAction";

const BtnClose = styled.div`
  cursor: pointer;
  font-weight: bold;
  :hover {
    background: #bbb;
  }
`;

const AttachPromo = (props) => {
  const [showProdukDetail, setShowDetail] = useState(false);
  const [showSnkDetail, setSnkDetail] = useState(false);
  const [promoDetail, setPromoDetail] = useState({});
  const [onClickedPromo, setOnClickedPromo] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [isEnableClearSearch, setIsEnableClearSearch] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [selectedPromo, setSelectedPromo] = useState({});
  const [promoPage, setPromoPage] = useState(1);
  const [listPromo, setListPromo] = useState([]);
  const [listPromoTemp, setListPromoTemp] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const { listAdminPromo, listAdminPromoSearch } = useSelector(
    (state) => state.ChatReducer
  );
  const dispatch = useDispatch();

  const toggleCloseDetail = () => {
    setShowDetail(!showProdukDetail);
    setSnkDetail(!showSnkDetail);
  };

  const toggleCloseProduct = (param) => {
    props.onClose(param);
  };

  const toggleSnkDetail = (data) => {
    setSnkDetail(!showSnkDetail);
    setShowDetail(!showProdukDetail);
    setPromoDetail(data);
  };

  const onClickPromo = (param) => {
    setOnClickedPromo(true);
    setSelectedPromo(param);
  };

  const handleSendButton = () => {
    if (selectedPromo) {
      let dataArray = [];
      dataArray.push(selectedPromo);
      props.handleUpdateAttachment(dataArray);
      props.setTypeAttachment("promo");
      setSelectedPromo({});
    }
    return;
  };

  const handleTypedSearch = (e) => {
    setSearchInput(e.target.value);
    if (searchInput !== "") {
      dispatch(searchAdminPromo(searchInput, 1));
      setIsEnableClearSearch(true);
    }
  };

  const handleOnEnterPress = () => {
    if (searchInput !== "") {
      setListPromoTemp(listPromo);
      dispatch(searchAdminPromo(searchInput, 1));
      setIsEnableClearSearch(true);
    }
  };

  const handleClearSearchInput = () => {
    if (isEnableClearSearch) {
      setSearchInput("");
      setIsEnableClearSearch(false);
    } else {
      setSearchInput("");
    }
  };

  const loadMore = () => {
    setPromoPage(promoPage + 1);
    setTimeout(() => {
      dispatch(fetchAdminPromo(promoPage + 1));
    }, 300);
  };

  useEffect(() => {
    dispatch(fetchAdminPromo(promoPage));
  }, []);

  useEffect(() => {
    if (listAdminPromo && listAdminPromo.length) {
      setListPromo(listAdminPromo);
      setIsLoadingList(false);
    }
  }, [listAdminPromo]);

  useEffect(() => {
    if (listAdminPromoSearch.data) {
      let searchResult = listAdminPromoSearch.data.data;
      setListPromo(searchResult);
      setIsLoadingList(false);
    }
  }, [listAdminPromoSearch]);

  useEffect(() => {
    return () => {
      dispatch(clearDataList());
    };
  }, []);

  return (
    <Fragment>
      {!showProdukDetail && !showSnkDetail ? (
        <>
          <div className="">
            <div className="d-flex justify-content-between py-1">
              <p>
                <b>Pilih Promo</b>
              </p>
              <BtnClose onClick={() => toggleCloseProduct("")}>X</BtnClose>
            </div>
            <InputGroup>
              <Input
                placeholder="Cari Produk"
                value={searchInput}
                onChange={handleTypedSearch}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleOnEnterPress();
                }}
              />
              {searchInput !== "" ? (
                <InputGroupAddon addonType="prepend">
                  <Button
                    className="m-1"
                    onClick={() => handleClearSearchInput()}
                    close
                  />
                </InputGroupAddon>
              ) : null}
            </InputGroup>
          </div>

          <div
            className="attach_promo_list_card my-3 px-1"
            id="list_promo_list_id"
          >
            <ListGroup>
              <InfiniteScroll
                dataLength={listPromo.length}
                next={() => loadMore()}
                hasMore={hasMore}
                scrollThreshold={0.9}
                loader={
                  listPromo && listPromo.length < 1 ? <p>Loading...</p> : null
                }
                scrollableTarget="list_promo_list_id"
              >
                {listPromo.map((item, i) => (
                  <ListGroupItem
                    className="list_card_promo p-0"
                    key={i}
                    onClick={() => onClickPromo(item)}
                  >
                    <Card
                      style={{ cursor: "pointer" }}
                      className={`card_promo ${
                        selectedPromo.id === item.id && onClickedPromo
                          ? "card_promo_active"
                          : ""
                      }`}
                    >
                      <CardTitle className="card_promo_title p-2">{`${item.title}`}</CardTitle>
                      <CardBody className="text-wrap p-2">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: item.content,
                          }}
                        ></div>
                        <a href="#" onClick={() => toggleSnkDetail(item)}>
                          Baca Syarat & Ketentuan
                        </a>
                      </CardBody>
                    </Card>
                  </ListGroupItem>
                ))}
              </InfiniteScroll>
            </ListGroup>
          </div>

          <div className="att_produk_btn clearfix p-2">
            <div className="d-flex justify-content-between">
              <span className="text_produk_terpilih">Promo terpilih:</span>
              <Button
                onClick={() => handleSendButton()}
                className="btn_kirim my-auto"
                color="primary"
                block
              >
                Lampirkan
              </Button>
            </div>
            <div>
              <span className="text_qty_item">
                {Object.keys(promoDetail).length} item
              </span>
            </div>
          </div>
        </>
      ) : (
        <div className="detail_promo_page">
          <div className="d-flex justify-content-start">
            <Button onClick={() => toggleCloseDetail()} close />
            <p className="my-auto mx-2">
              <b>Detail Promo</b>
            </p>
          </div>
          <div>
            <img
              className="detail_promo_page_img"
              src={promoDetail.banner_url}
              alt=""
            />
            <b>
              <p>{promoDetail.title}</p>
            </b>
            {/* <Badge className="my-2">{promoDetail.end_date}</Badge> */}
            <div className="detail_promo_page_badge d-flex justify-content-center p-2 my-2">
              {moment.utc(promoDetail.start_date).format("DD MMM YYYY")} -{" "}
              {moment.utc(promoDetail.end_date).format("DD MMM YYYY")}
            </div>
            <p
              className="my-2 promo_detail_page_desc my-2"
              dangerouslySetInnerHTML={{
                __html: promoDetail.content,
              }}
            ></p>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AttachPromo;
