import {
  LIST_CAMPAIGN,
  LIST_PRODUCT_SEARCH,
  LIST_CATEGORY_SEARCH,
  LIST_BRAND_SEARCH,
  LIST_PARTICIPANT_SEARCH,
  DETAIL_CAMPAIGN,
  LIST_CAMPAIGN_PRODUCT,
  LIST_CAMPAIGN_PARTICIPANT,
  LIST_PRODUCT_CAMPAIGN_ALL,
  LIST_CATEGORY_CAMPAIGN_ALL,
  LIST_BRAND_CAMPAIGN_ALL
} from "../../actionTypes";

const initialState = {
  listCampaign: {},
  listProductSearch: {},
  listCategorySearch: {},
  listBrandSearch: {},
  listParticipantSearch: {},
  detailCampaign: {},
  listCampaignProduct: {},
  listCampaignParticipant: {},
  listSelectProductAllCampaign: {},
};

const CampaignReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIST_CAMPAIGN:
      state.listCampaign = action.payload;
      return { ...state, listCampaign: state.listCampaign };
    case LIST_PRODUCT_SEARCH:
      state.listProductSearch = action.payload;
      return { ...state, listProductSearch: state.listProductSearch };
    case LIST_CATEGORY_SEARCH:
      state.listCategorySearch = action.payload;
      return { ...state, listCategorySearch: state.listCategorySearch };
    case LIST_BRAND_SEARCH:
      state.listBrandSearch = action.payload;
      return { ...state, listBrandSearch: state.listBrandSearch };
    case LIST_PARTICIPANT_SEARCH:
      state.listParticipantSearch = action.payload;
      return { ...state, listParticipantSearch: state.listParticipantSearch };
    case DETAIL_CAMPAIGN:
      state.detailCampaign = action.payload;
      return { ...state, detailCampaign: state.detailCampaign };
    case LIST_CAMPAIGN_PRODUCT:
      state.listCampaignProduct = action.payload;
      return { ...state, listCampaignProduct: state.listCampaignProduct };
    case LIST_CAMPAIGN_PARTICIPANT:
      state.listCampaignParticipant = action.payload;
      return { ...state, listCampaignParticipant: state.listCampaignParticipant };
    case LIST_PRODUCT_CAMPAIGN_ALL:
      state.listSelectProductAllCampaign = action.payload;
      return { ...state, listSelectProductAllCampaign: state.listSelectProductAllCampaign };
    case LIST_CATEGORY_CAMPAIGN_ALL:
      state.listSelectProductAllCampaign = action.payload;
      return { ...state, listSelectProductAllCampaign: state.listSelectProductAllCampaign };
    case LIST_BRAND_CAMPAIGN_ALL:
      state.listSelectProductAllCampaign = action.payload;
      return { ...state, listSelectProductAllCampaign: state.listSelectProductAllCampaign };

    default:
      return { ...state };
  }
};

export default CampaignReducer;
