import BroadcastLayout from "../../pages/dashboard/broadcast";
import BroadcastAdd from "../../pages/dashboard/broadcast/broadcastAdd";

export const RouteBroadcast = [
  {
    path: `/dashboard/broadcast`,
    Component: BroadcastLayout,
  },
  {
    path: `/dashboard/broadcast/add`,
    Component: BroadcastAdd,
  },
];
