import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import BadgeStatus from "@components/badgeStatus";
import TableCSI from "@components/table";
import CustomPagination from "@components/customPagination";
import ModalContent from "@components/modal/ModalContent";
import { ModalDialog } from "@components/modal/ModalDialog";
import PaginationRow from "@components/paginationDataPerPage";
import TableDropdown from "@components/customDropdown";

import {
  changeStatusUser,
  downloadUser,
  getListUser,
  getSyncUser,
  resetPasswordUser,
} from "@action/userAction";

import iconSearch from "@assets/images/input-search.png";

const UserLayout = (props) => {
  const [data, setData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [isFetchedData, setIsFetchedData] = useState(true);
  const [isAfterSearch, setIsAfterSearch] = useState(false);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [totalResult, setTotalResult] = useState(4);
  const [totalPerPage, setTotalPerPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedFieldSearch, setSelectedFieldSearch] = useState("");
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [modalState, setModalState] = useState(null);

  const dispatch = useDispatch();
  const history = useHistory();
  const { listUser } = useSelector((state) => state.UserReducer);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (listUser.data && !listUser.loading) {
      let dataUser = listUser.data.data;
      let mappedListData = dataUser.map((item) => ({
        id: item?.id,
        nav_id: item?.nav_id,
        name: item?.owner_name,
        shop: item?.shop_name,
        phone: item?.phone_number,
        status: item?.active,
      }));
      setData(mappedListData);
      setMaxPageIndex(listUser.data.last_page);
      setTotalResult(listUser.data.total);
      setTotalPerPage(listUser.data.to);
      setIsFetchedData(true);
    }
  }, [listUser]);

  function fetchData() {
    dispatch(getListUser(1, inputSearchValue, selectedFieldSearch));
    setTableColumn(
      [
        {
          Header: () => null,
          id: "User",
          columns: [
            {
              Header: "ID",
              accessor: "id",
            },
            {
              Header: "NAV ID",
              accessor: "nav_id",
            },
            {
              Header: "Name",
              accessor: "name",
            },
            {
              Header: "Shop",
              accessor: "shop",
            },
            {
              Header: "Phone",
              accessor: "phone",
            },
            {
              Header: "Status",
              accessor: "status",
              sortAble: true,
              Cell: ({ row }) => <BadgeStatus status={row.original.status} />,
            },
            {
              Header: "Action",
              accessor: "action",
              Cell: ({ row }) => (
                <TableDropdown
                  dropdownContent={[
                    {
                      label: "Edit",
                      type: "link",
                      link: `/dashboard/user/edit/${row.original?.id}`,
                    },
                    {
                      label: "OTP Logs",
                      type: "link",
                      link: `/dashboard/user/otplog/${row.original?.id}`,
                    },
                    {
                      label: "Update Info",
                      type: "link",
                      link: `/dashboard/user/update-info/${row.original?.id}`,
                    },
                    {
                      label: "Sync",
                      type: "button",
                      onclick: () => handleAction("sync", row?.original),
                    },
                    {
                      label: "Reset Password",
                      type: "button",
                      onclick: () => handleAction("reset", row?.original),
                    },
                    {
                      label: `${
                        row?.original?.status === 0 ? "Activate" : "Deactivate"
                      }`,
                      type: "button",
                      onclick: () => showModal("Status", row?.original),
                    },
                  ]}
                />
              ),
            },
          ],
        },
      ],
    );
  }

  const handleSubmitSearch = () => {
    if (inputSearchValue !== "") {
      dispatch(getListUser(1, inputSearchValue, selectedFieldSearch, perPage));
      setIsAfterSearch(true);
    }
  };

  const handleInputSearch = (e) => {
    setInputSearchValue(e.target.value);
  };

  const handleOnEnter = (e) => {
    // if (inputSearchValue !== "") {
    dispatch(getListUser(1, inputSearchValue, selectedFieldSearch, perPage));
    setIsAfterSearch(true);
    // }
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListUser(page, inputSearchValue, selectedFieldSearch, perPage));
    setIsAfterSearch(true);
  };

  const onChangePaginationRow = (perPage) => {
    setIsFetchedData(false);
    setPerPage(perPage);
    dispatch(getListUser(1, inputSearchValue, selectedFieldSearch, perPage));
  };

  const handleAction = (type, data) => {
    switch (type) {
      case "reset":
        dispatch(resetPasswordUser(data?.id));
        history.push("/user");
        break;
      case "status":
        dispatch(changeStatusUser(data?.id));
        history.push("/user");
        break;
      case "sync":
        dispatch(getSyncUser("single", data?.id));
        history.push("/user");
        break;
      case "sync-all":
        dispatch(getSyncUser("all"));
        history.push("/user");
        break;
      case "download":
        dispatch(downloadUser());
        history.push("/dashboard/user");
        break;
      case "bulk":
        break;
      default:
        break;
    }
  };

  const showModal = (type, row) => {
    setModalState((prevState) => ({
      ...prevState,
      openModal: true,
      modalType: type,
      modalTitle: "Informasi",
      modalData: row,
      closeIcon: true,
    }));
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col>
            <Card className="p-0">
              <CardBody className="p-3">
                <div className="d-flex justify-content-between my-2">
                  <InputGroup style={{ maxWidth: "400px" }}>
                    <Input
                      type="select"
                      name="select"
                      id="selectStartDate"
                      placeholder={"Field"}
                      onChange={(event) =>
                        setSelectedFieldSearch(event.target.value)
                      }
                    >
                      <option key={1} value={""}>
                        All
                      </option>
                      <option key={2} value={"owner_name"}>
                        Owner Name
                      </option>
                      <option key={3} value={"shop_name"}>
                        Shop Name
                      </option>
                      <option key={4} value={"nav_id"}>
                        Nav ID
                      </option>
                      <option key={5} value={"phone_number"}>
                        Phone Number
                      </option>
                    </Input>
                    <Input
                      style={{ width: "50px" }}
                      placeholder="Search"
                      value={inputSearchValue}
                      onChange={(e) => handleInputSearch(e)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleOnEnter(e);
                      }}
                    />
                    <InputGroupAddon addonType="prepend">
                      <Button
                        className="p-2"
                        color="primary"
                        onClick={() => handleSubmitSearch()}
                      >
                        <img src={iconSearch} width={15} height={15} alt={""} />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
				  
                  <div className="d-flex">
                    <Button
                      className="mx-1"
                      color="primary"
                      outline
                      onClick={() => handleAction("sync-all")}
                    >
                      Sync All User
                    </Button>
                    <Button
                      className="mx-1"
                      color="primary"
                      outline
                      onClick={() => handleAction("download")}
                    >
                      Download
                    </Button>
                    <Button
                      className="mx-1"
                      color="primary"
                      outline
                      onClick={() => handleAction("bulk")}
                    >
                      Bulk Action
                    </Button>
                  </div>
                </div>
                {isFetchedData ? (
                  <TableCSI
                    data={data}
                    columns={tableColumn}
                    key={data}
                    initialSortBy={initialSortBy}
                  />
                ) : null}

                <div className="d-flex justify-content-between">
                  <PaginationRow
                    onChangePaginationRow={onChangePaginationRow}
                  />
                  <CustomPagination
                    handlePageClick={handlePageClick}
                    pageCount={maxPageIndex}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
      <ModalDialog
        open={modalState?.openModal ?? false}
        type={modalState?.modalType ?? ""}
        footer={false}
        title={modalState?.modalTitle ?? ""}
        titleFontSize={18}
        backdropClose={true}
        onClose={() => {
          setModalState(null);
        }}
        maxWidth={384}
      >
        <ModalContent
          onClose={() => {
            setModalState(null);
          }}
          onSubmit={() =>
            handleAction(
              modalState?.modalType?.toLowerCase(),
              modalState?.modalData
            )
          }
          data={modalState?.modalData ?? null}
          descClose={"Batal"}
          descSubmit={"Submit"}
          description={`${
            modalState?.modalData?.status === 0 ? "Activate" : "Deactive"
          } data ${modalState?.modalData?.name}?`}
        />
      </ModalDialog>
    </Fragment>
  );
};

export default UserLayout;
