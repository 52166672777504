import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from "reactstrap";
import BroadcastModal from "../broadcastModal";

const BroadcastDropdown = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isShowBroadcast, setIsShowBroadcast] = useState(false);

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  const doCloseBroadcast = (val) => {
    setIsShowBroadcast(val);
  };

  return (
    <Fragment>
      <ButtonDropdown
        isOpen={dropdownOpen}
        toggle={toggleDropdown}
        style={{ width: "100%" }}
      >
        <DropdownToggle color="primary">Broadcast</DropdownToggle>
        <DropdownMenu style={{ width: "100%", backgroundColor: "#ededed" }}>
          <DropdownItem onClick={() => setIsShowBroadcast(true)}>
            <b>Send promo broadcast</b>
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem>
            <Link to={`/dashboard/broadcast`}>Create Broadcast</Link>
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
      <BroadcastModal
        isShow={isShowBroadcast}
        doCloseBroadcast={doCloseBroadcast}
      />
    </Fragment>
  );
};

export default BroadcastDropdown;
