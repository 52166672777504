import axios from "axios";
import Cookies from "universal-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//Setup Base Url
const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});
const cookie = new Cookies();

instance.interceptors.request.use(
  (config) => {
    const token = cookie.get("api_token");
    if (token !== undefined) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  function (error) {
    const errData = error.response.data;
    const errMsg = errData.errors ? errData.errors[0].message : errData.message;

    toast.error(`${errMsg}`);

    const isAuthenticated = cookie.get("api_token") !== undefined;

    if (errMsg === "Token Expired.") {
      if (isAuthenticated) {
        cookie.remove("api_token", { path: "/" });
        window.location.replace("/login");
      }
      window.location.replace("/login");
    }

    return Promise.reject(error);
  }
);

export default instance;
