import React, { useEffect, useState, Fragment, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";

//components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import Breadcrumb from "@layout/breadcrumb";
import ModalContent from "@components/modal/ModalContent";
import { ModalDialog } from "@components/modal/ModalDialog";

//actions
import {
  updatePromo,
  getDetailPromo,
  updatePromoImage,
  deletePromoImage,
} from "@action/promoAction";

//css & media
import {
  wrapperInputThumbnail,
  styleInputThumbnail,
  styleInputbanner,
  btnUploadBanner,
  containerMutipleBanner,
  btnDeleteBanner,
  ImgButton,
} from "../../style/cssStyle";
import iconClose from "@assets/images/Close.svg";

const PromoEdit = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const { detailPromo } = useSelector((state) => state.PromoReducer);
  const [formDetail, setFormDetail] = useState({
    id: id,
    title: "",
    start_date: "",
    end_date: "",
    order: "",
    banner: "",
    content: "",
  });
  const [multipleImageSrc, setMultipleImageSrc] = useState([]);
  const imageBannerID = useRef(0);
  const [modalState, setModalState] = useState(null);
  const [imgThumbnailSrc, setImgThumbnailSrc] = useState("");

  useEffect(() => {
    dispatch(getDetailPromo(id));
  }, []);

  useEffect(() => {
    if (detailPromo.data && !detailPromo.loading) {
      let data = detailPromo.data.data;
      let startDate = moment.utc(data.start_date).format("YYYY-MM-DD");
      let endDate = moment.utc(data.end_date).format("YYYY-MM-DD");
      setFormDetail((prevValue) => ({
        ...prevValue,
        title: data.title,
        start_date: startDate,
        end_date: endDate,
        order: data.order,
        content: data.content,
      }));

      if (data.promo_images.length < 1) return false;
      const mappedImage = data.promo_images.map((item) => ({
        id: (imageBannerID.current = imageBannerID.current + 1),
        id_image: item.id,
        promoId: item.promo_id,
        src: item.imageUrl,
      }));
      setMultipleImageSrc(mappedImage);
    }
  }, [detailPromo]);

  const handleSubmit = () => {
    if (!validateForm(formDetail)) {
      if (validateDate() === 1) {
        toast.error("End Date cannot be earlier than Start Date or Today Date");
      } else {
        let formData = new FormData();
        formData.append("id", formDetail.id);
        formData.append("title", formDetail.title);
        formData.append("start_date", formDetail.start_date);
        formData.append("end_date", formDetail.end_date);
        formData.append("order", formDetail.order);
        formData.append("content", formDetail.content);
        if (formDetail.banner) formData.append("banner", formDetail.banner);

        dispatch(updatePromo(formData)).then((resp) => {
          if (resp.status !== 200) return false;

          history.push("/dashboard/promo/basic");
        });
      }
    }
  };

  const handleCancel = () => {
    history.push("/dashboard/promo");
  };

  const validateForm = (data) => {
    if (
      data.title === "" ||
      data.start_date === "" ||
      data.end_date === "" ||
      data.order === "" ||
      data.content === ""
    ) {
      toast.error(`Please fill all fields.`);
      return true;
    }
    return false;
  };

  const isImage = (imgType) => {
    switch (imgType) {
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
      case "image/jfif":
        return true;
    }
    return false;
  };

  const validateDate = () => {
    if (formDetail.start_date !== "" && formDetail.end_date) {
      const today = new Date();
      const todayDate = moment.utc(today).format("YYYY-MM-DD");
      if (formDetail.start_date > formDetail.end_date) return 1;
      if (todayDate > formDetail.end_date) return 1;
      if (formDetail.start_date < formDetail.end_date) return -1;
    }
    return 0;
  };

  const showModal = (row) => {
    setModalState((prevState) => ({
      ...prevState,
      openModal: true,
      modalType: "remind",
      modalTitle: "Delete Banner",
      modalData: row,
      closeIcon: true,
    }));
  };

  const handleRemoveBanner = (item) => {
    if (item.id_image !== "") dispatch(deletePromoImage(item.id_image));

    const newDeleted = multipleImageSrc.filter((arr) => arr.id !== item.id);
    setMultipleImageSrc(newDeleted);
    setModalState(null);
  };

  const handleUploadMultipleImg = (e) => {
    if (e.target.files.length > 0) {
      const filesImg = e.target.files[0];
      if (filesImg.size > 2000000) {
        toast.error(`Max file size is 2MB`);
        document.getElementById("selectBanner").value = "";
      } else {
        if (!isImage(filesImg.type)) {
          toast.error(`Select appropiate image file.`);
          document.getElementById("selectBanner").value = "";
        } else {
          const imgSrc = URL.createObjectURL(filesImg);
          const objImage = {
            id: (imageBannerID.current = imageBannerID.current + 1),
            src: imgSrc,
            file: filesImg,
          };
          setMultipleImageSrc((multipleImageSrc) => [
            ...multipleImageSrc,
            objImage,
          ]);

          let formImage = new FormData();
          formImage.append("id", id);
          formImage.append("image[]", objImage.file);
          dispatch(updatePromoImage(formImage));
        }
      }
    }
  };

  const handleOnChangeInputThumbnail = (e) => {
    if (e.target.files.length > 0) {
      const filesImg = e.target.files[0];
      if (filesImg.size > 2000000) {
        toast.error(`Max file size is 2MB`);
        document.getElementById("selectThumbnail").value = "";
      } else {
        if (!isImage(filesImg.type)) {
          toast.error(`Select appropiate image file.`);
          document.getElementById("selectThumbnail").value = "";
        } else {
          const imgSrc = URL.createObjectURL(filesImg);
          setImgThumbnailSrc(imgSrc);
          setFormDetail({ ...formDetail, banner: filesImg });
        }
      }
    }
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Promo"
        title="Edit Promo"
        urlParent={"/dashboard/promo"}
      />
      <Container fluid>
        <Row>
          <Col sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody className="p-3">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FormGroup row>
                    <Label for="formTitle" sm={12}>
                      <b>
                        Title <sup style={{ color: "red" }}>*</sup>
                      </b>
                    </Label>
                    <Col sm={9}>
                      <Input
                        placeholder="Enter Title"
                        id="formTitle"
                        value={formDetail.title}
                        onChange={(e) =>
                          setFormDetail({
                            ...formDetail,
                            title: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="selectStartDate" sm={12}>
                      <b>
                        Start Date <sup style={{ color: "red" }}>*</sup>
                      </b>
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="date"
                        name="selectStartDate"
                        id="selectStartDate"
                        placeholder="Start Date"
                        max="9999-12-31"
                        min="1700-12-31"
                        value={formDetail.start_date}
                        onChange={(e) =>
                          setFormDetail({
                            ...formDetail,
                            start_date: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="selectEndDate" sm={12}>
                      <b>
                        End Date <sup style={{ color: "red" }}>*</sup>
                      </b>
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="date"
                        name="selectEndDate"
                        id="selectEndDate"
                        placeholder="End Date"
                        max="9999-12-31"
                        min="1700-12-31"
                        value={formDetail.end_date}
                        onChange={(e) =>
                          setFormDetail({
                            ...formDetail,
                            end_date: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="formOrder" sm={12}>
                      <b>
                        Order <sup style={{ color: "red" }}>*</sup>
                      </b>
                    </Label>
                    <Col sm={9}>
                      <Input
                        placeholder="Enter Order"
                        id="formOrder"
                        type="number"
                        value={formDetail.order}
                        onChange={(e) =>
                          setFormDetail({
                            ...formDetail,
                            order: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row style={{ maxWidth: "25%" }}>
                    <Label for="selectThumbnail" sm={12}>
                      <b>Foto Banner</b>
                    </Label>
                    <Col sm={12}>
                      <div style={wrapperInputThumbnail}>
                        <button className="p-0" style={btnUploadBanner}>
                          <ImgButton
                            className="mx-auto"
                            src={imgThumbnailSrc}
                            width={240}
                            height={180}
                          />
                        </button>
                      </div>

                      <Input
                        type="file"
                        name="selectThumbnail"
                        id="selectThumbnail"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleOnChangeInputThumbnail}
                        style={styleInputThumbnail}
                      />
                      <p className="my-1" style={{ fontSize: "12px" }}>
                        Format file jpeg, jpg, png
                      </p>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="selectBanner" sm={12}>
                      <b>Foto Content</b>
                    </Label>
                    <Col sm={12}>
                      <Row>
                        <Col sm={4} style={wrapperInputThumbnail}>
                          <button style={btnUploadBanner}>Tambah Gambar</button>
                          <Input
                            type="file"
                            name="selectBanner"
                            id="selectBanner"
                            accept=".png, .jpg, .jpeg"
                            onChange={handleUploadMultipleImg}
                            style={styleInputbanner}
                          />
                        </Col>
                        <Col
                          className="py-2"
                          sm={8}
                          style={containerMutipleBanner}
                        >
                          {multipleImageSrc.length > 0
                            ? multipleImageSrc.map((data, i) => (
                                <div
                                  key={i}
                                  className="mx-2"
                                  style={{
                                    position: "relative",
                                    maxWidth: "240px",
                                    maxHeight: "180px",
                                    display: "inline-block",
                                  }}
                                >
                                  <img
                                    src={iconClose}
                                    onClick={() => showModal(data)}
                                    style={btnDeleteBanner}
                                  />
                                  <img
                                    src={data.src}
                                    style={{ height: "100%", width: "100%" }}
                                  ></img>
                                </div>
                              ))
                            : ""}
                        </Col>
                      </Row>
                      <span className="my-1" style={{ fontSize: "12px" }}>
                        Format file jpeg, jpg, png
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="formContent" sm={12}>
                      <b>
                        Content <sup style={{ color: "red" }}>*</sup>
                      </b>
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="textarea"
                        name="text"
                        id="formContent"
                        placeholder="Write content here"
                        value={formDetail.content}
                        onChange={(e) =>
                          setFormDetail({
                            ...formDetail,
                            content: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup check row className="my-3">
                    <div className="d-flex justify-content-end">
                      <Button
                        onClick={handleCancel}
                        className="mx-1"
                        color="primary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        className="mx-1"
                        color="primary"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </div>
                  </FormGroup>
                </Form>

                <Fragment>
                  <ModalDialog
                    open={modalState?.openModal ?? false}
                    type={modalState?.modalType ?? ""}
                    footer={false}
                    title={modalState?.modalTitle ?? ""}
                    titleFontSize={18}
                    backdropClose={true}
                    onClose={() => {
                      setModalState(null);
                    }}
                    maxWidth={384}
                  >
                    <ModalContent
                      onClose={() => {
                        setModalState(null);
                      }}
                      onSubmit={() => {
                        handleRemoveBanner(modalState?.modalData);
                        // handleDelete(modalState?.modalData);
                      }}
                      data={modalState?.modalData ?? null}
                      description={`Are you sure you want to delete this banner?`}
                      descClose={"Cancel"}
                      descSubmit={"Delete"}
                    />
                  </ModalDialog>
                </Fragment>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PromoEdit;
