import { Fragment, useState } from "react";

import {
  InputRadio,
  InputNumber,
  InputMultiSelectProduct,
} from "@components/formComponent";
import { Col, Label, FormGroup } from "reactstrap";

const InputJenisVoucher = ({
  value,
  onChangeVoucher,
  onChangeCustomer,
  onSelectUser,
  onChangePoint,
}) => {
  const [voucherType, setVoucherType] = useState("");

  const RadioCustomer = () => {
    return (
      <Fragment>
        <FormGroup row tag="fieldset">
          <Label for="formFile" sm={2}>
            <b>
              Tipe Customer <sup style={{ color: "red" }}>*</sup>
            </b>
          </Label>
          <Col className="d-flex flex-row py-2" sm={10}>
            <InputRadio
              label="Semua"
              name="radioCustomer"
              checked
              onChange={(val) => console.log(val)}
            />
            <InputRadio
              label="Silver"
              name="radioCustomer"
              checked
              onChange={(val) => console.log(val)}
            />
            <InputRadio
              label="Gold"
              name="radioCustomer"
              checked
              onChange={(val) => console.log(val)}
            />
            <InputRadio
              label="Platinum"
              name="radioCustomer"
              checked
              onChange={(val) => console.log(val)}
            />
          </Col>
        </FormGroup>
      </Fragment>
    );
  };

  return (
    <Fragment>
      <FormGroup row tag="fieldset">
        <Label for="formFile" sm={2}>
          <b>
            Jenis Voucher <sup style={{ color: "red" }}>*</sup>
          </b>
        </Label>
        <Col className="d-flex flex-row py-2" sm={10}>
          <InputRadio
            label="Public"
            name="radioVoucher"
            checked={voucherType === "public" ? true : false}
            onChange={(val) => setVoucherType("public")}
          />
          <InputRadio
            label="Private"
            name="radioVoucher"
            checked={voucherType === "private" ? true : false}
            onChange={(val) => setVoucherType("private")}
          />
          <InputRadio
            label="Loyalty"
            name="radioVoucher"
            checked={voucherType === "loyalty" ? true : false}
            onChange={(val) => setVoucherType("loyalty")}
          />
        </Col>
      </FormGroup>

      {voucherType === "public" ? (
        <RadioCustomer />
      ) : voucherType === "private" ? (
        <InputMultiSelectProduct
          label="User"
          options={[]}
          onInputChange={() => {}}
          onSelectChange={() => {}}
        />
      ) : voucherType === "loyalty" ? (
        <InputNumber
          label="Harga Voucher (Point)"
          value={0}
          onChange={(e) => {}}
          isRequired
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default InputJenisVoucher;
