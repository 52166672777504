import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table
} from "reactstrap";
import { useTable } from "react-table";
import {useDispatch, useSelector} from "react-redux";
import {getOtpLog} from "../../../../redux/action/userAction";
import {useParams} from "react-router-dom";
import M from "moment"

const UserOTP = (props) => {
  const [data, setData] = useState([]);
  const [isFetchedData, setIsFetchedData] = useState(true);
  const dispatch = useDispatch();
  const {otpLog} = useSelector(
      (state) => state.UserReducer
  );
  const urlParams = useParams()

  useEffect(() => {
    dispatch(getOtpLog(urlParams?.id));
  }, [])

  const columns = React.useMemo(
    () => [
      {
        Header: "OTP Request Logs",
        columns: [
          {
            Header: "OTP Code",
            accessor: "code",
          },
          {
            Header: "Requested At",
            accessor: "requested_time",
          },
          {
            Header: "Expired At",
            accessor: "expired_time",
          },
        ],
      },
    ],
    []
  );

  useEffect(() => {
    if (otpLog.data && !otpLog.loading) {
      let otpLogs = otpLog.data.data;
      let mappedListData = otpLogs?.map((item) => ({
        id: item.id,
        code: item.otp_code,
        requested_time: item.requested_at ? M(item.requested_at).format("DD/MM/YYYY HH:mm:ss") : "No Data",
        expired_time: item.expired_at ? M(item.expired_at).format("DD/MM/YYYY HH:mm:ss") : "No Data",
      }));
      setData(mappedListData);
      setIsFetchedData(true);
    }
  }, [otpLog]);

  const tableInstance = useTable({ columns, data });
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <Fragment>
      <Breadcrumb parent="User" title="OTP Requests Log" urlParent={'/dashboard/user'} />
      <Container fluid>
        <Row>
          <Col xl={8}>
            <Card>
              <CardBody className="p-3">
                <Table {...getTableProps()} className="text-left">
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th {...column.getHeaderProps()}>
                            {column.render("Header")}
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()} className="text-left">
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td {...cell.getCellProps()}>
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default UserOTP;
