import { GET_LIST_FREE_BARANG } from "../../actionTypes";

const initialState = {
  listFreeBarang: {},
};

const FreeBarangReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_FREE_BARANG:
      return { ...state, listFreeBarang: action.payload };

    default:
      return { ...state };
  }
};

export default FreeBarangReducer;
