import apiConfig from "../../api";
import { GET_CONFIG, WATCH_RESPONSE_ALERT } from "../actionTypes";

export const getConfigAdmin = (payload) => {
  return (dispatch) => {
    dispatch({
      type: GET_CONFIG,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get("/api/admin/configs")
      .then((resp) => {
        dispatch({
          type: GET_CONFIG,
          payload: {
            loading: false,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_CONFIG,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const updateConfigValue = (payload) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post("/api/admin/configs/update", payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};
