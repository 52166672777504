import { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { InputDateRange, InputSwitch } from "@components/formComponent";
import { validateDateRange } from "@constant/param";

import { updateHargaDiskon } from "@action/hargaCoretAction";

const DiskonModal = ({ modalData, isVisible, onClose }) => {
  const dispatch = useDispatch();
  const [formStartDate, setStartDate] = useState("");
  const [formEndDate, setEndDate] = useState("");
  const [inputNominal, setInputNominal] = useState(1);
  const [formActive, setFormActive] = useState(0);
  const [formDiskon, setFormDiskon] = useState(0);
  const [formDiskonType, setDiskonType] = useState("amount");
  const [updatedPriceNominal, setUpdatePriceNominal] = useState(0);
  const [potonganDiskon, setPotonganDiskon] = useState(0);
  const [updatedPricePercent, setUpdatePricePercent] = useState(0);

  useEffect(() => {
    if (isObjectEmpty(modalData)) return;

    setFormActive(modalData.status);
    setStartDate(modalData.start_date || '');
    setEndDate(modalData.end_date || '');
    setDiskonType(modalData.diskon_type || 'amount');
    setFormDiskon(modalData.diskon || 0);
    setUpdatePriceNominal(modalData.price || 0);
  }, [modalData]);

  function isObjectEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  function handleupdatePriceNominal(input) {
    if (input > modalData.priceOrigin) return;

    setFormDiskon(input);
    setUpdatePriceNominal(
      parseFloat(modalData.priceOrigin) - parseFloat(input)
    );
  }

  function handleSwitchNominal(param) {
    setFormDiskon(0);
    setUpdatePriceNominal(0);
    setPotonganDiskon(0);
    setUpdatePricePercent(0);
    switch (param) {
      case "amount":
        setInputNominal(1);
        setDiskonType("amount");
        break;
      case "persentage":
        setInputNominal(2);
        setDiskonType("persentage");
        break;
    }
  }

  function handleOnChangeDiskonPercent(input) {
    if (input > 100) return;

    setFormDiskon(input);
    const potongan =
      parseFloat(modalData.priceOrigin) * (parseFloat(input) / 100);
    setPotonganDiskon(potongan);
    setUpdatePricePercent(parseFloat(modalData.priceOrigin - potongan));
  }

  function handleSwitchStatus(status) {
    switch (status) {
      case true:
        setFormActive(1);
        break;

      default:
        setFormActive(0);
        break;
    }
  }

  const validateForm = () => {
    if (
      formStartDate === "" ||
      formEndDate === "" ||
      formDiskon === 0 ||
      formDiskonType === ""
    ) {
      toast.error(`Please fill the required fields.`);
      return false;
    }
    return true;
  };

  function handleSubmitSearch() {
    if (!validateForm()) return;
    if (!validateDateRange(formStartDate, formEndDate)) return;

    let formData = new FormData();

    formData.append("product_id", modalData.id);
    formData.append("start_date", formStartDate);
    formData.append("end_date", formEndDate);
    formData.append("discount", formDiskon);
    formData.append("discount_type", formDiskonType);
    formData.append("active", formActive);

    dispatch(updateHargaDiskon(formData)).then((resp) => {
      if (resp.status !== 200) return;

      toast.success(resp.data.message);
      onClose();
    });
  }

  return (
    <Fragment>
      <Modal isOpen={isVisible} backdrop={true} toggle={onClose}>
        <div className="d-flex flex-row justify-content-between p-3 border-bottom">
          <p className="my-2 font-weight-bolder">Tetapkan Diskon</p>
          <button
            type="button"
            onClick={onClose}
            className="border-0 bg-white p-auto"
          >
            <i className="fa-solid fa-x"></i>
          </button>
        </div>
        <ModalBody>
          <Form onSubmit={(e) => e.preventDefault()}>
            <div className="d-flex flex-row my-2">
              <InputSwitch
                value={formActive === 1 ? true : false}
                onChange={(e) => handleSwitchStatus(e.target.checked)}
              />
              <p className="mx-2 my-1">Active</p>
            </div>
            <FormGroup className="d-flex flex-row" tag="fieldset" row>
              <div className="d-flex flex-row justify-content-center ml-1">
                <input
                  type="radio"
                  name="radioSwitch"
                  className="mx-2"
                  checked={formDiskonType === "amount" ? true : false}
                  onChange={(e) => handleSwitchNominal("amount")}
                  style={{
                    width: "18px",
                    height: "18px",
                    accentColor: "blue",
                  }}
                />
                <label htmlFor="radioSwitch" className="my-auto p-0">
                  Nominal
                </label>
              </div>
              <div className="d-flex flex-row justify-content-center">
                <input
                  type="radio"
                  name="radioSwitch"
                  className="mx-2"
                  checked={formDiskonType === "persentage" ? true : false}
                  onChange={(e) => handleSwitchNominal("persentage")}
                  style={{
                    width: "18px",
                    height: "18px",
                    accentColor: "blue",
                  }}
                />
                <label htmlFor="radioSwitch" className="my-auto p-0">
                  persentase
                </label>
              </div>
            </FormGroup>

            <FormGroup>
              <Label>
                <b>
                  Besar Diskon <span style={{ color: "red" }}>*</span>
                </b>
              </Label>
              {inputNominal === 1 ? (
                <div>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <button
                        className="px-3 border-right-0 rounded-left"
                        disabled
                      >
                        Rp
                      </button>
                    </InputGroupAddon>
                    <Input
                      type="number"
                      min={0}
                      max={modalData.priceOrigin}
                      value={formDiskon}
                      onChange={(e) => handleupdatePriceNominal(e.target.value)}
                    />
                  </InputGroup>

                  <div className="d-flex flex-row">
                    <div>
                      <p className="my-1">Harga Asli</p>
                      <p className="my-0">{modalData.priceOrigin}</p>
                    </div>
                    <div className="mx-auto">
                      <p className="my-1">Harga setelah Diskon</p>
                      <p className="my-0">
                        <b>{updatedPriceNominal}</b>
                      </p>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <InputGroup>
                    <Input
                      type="number"
                      min={0}
                      max={100}
                      value={formDiskon}
                      onChange={(e) =>
                        handleOnChangeDiskonPercent(e.target.value)
                      }
                    />
                    <InputGroupAddon addonType="prepend">
                      <button
                        className="px-3 border-left-0 rounded-right"
                        disabled
                      >
                        %
                      </button>
                    </InputGroupAddon>
                  </InputGroup>
                  <div className="d-flex flex-row">
                    <div>
                      <p className="my-1">Harga Asli</p>
                      <p className="my-0">{modalData.priceOrigin}</p>
                    </div>
                    <div className="mx-auto">
                      <p className="my-1">Besar potongan{`(${formDiskon}%)`}</p>
                      <p className="my-0">{potonganDiskon}</p>
                    </div>
                    <div className="mx-auto">
                      <p className="my-1">Harga setelah Diskon</p>
                      <p className="my-0">
                        <b>{updatedPricePercent}</b>
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </FormGroup>

            <FormGroup>
              <Label>
                <b>
                  Periode Diskon <span style={{ color: "red" }}>*</span>
                </b>
              </Label>
              <InputDateRange
                startDate={formStartDate}
                endDate={formEndDate}
                onChangeStartDate={(val) => setStartDate(val)}
                onChangeEndDate={(val) => setEndDate(val)}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button className="border" color="white" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSubmitSearch}>
            Simpan
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default DiskonModal;
