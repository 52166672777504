import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { useTable, useSortBy, useRowSelect } from "react-table";
import styled from "styled-components";

import {
  searchProduct,
  searchCategory,
  searchBrand,
  addProductCampaign,
} from "../../../../../../redux/action/campaignAction";

const TableContainer = styled.div`
  overflow-y: auto;
  max-height: 250px;
`;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const ModalAdd = (props) => {
  const dispatch = useDispatch();
  const [productSkuCode, setProductSkuCode] = useState("");
  const [productCategory, setProductCategory] = useState("");
  const [productBrand, setProductBrand] = useState("");
  const [radioProductType, setRadioProductType] = useState("");
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isDisableSubmit, setIsDisableSubmit] = useState(true);

  const { listProductSearch, listCategorySearch, listBrandSearch } =
    useSelector((state) => state.CampaignReducer);

  useEffect(() => {
    if (props.showAddProduct) {
      setRadioProductType(props.typeProduct);
    }
  }, [props]);

  useEffect(() => {
    if (radioProductType !== "") {
      switch (radioProductType) {
        case "product":
          setColumns([
            {
              Header: "Product",
              columns: [
                {
                  Header: "ID",
                  accessor: "id",
                },
                {
                  Header: "Product Name",
                  accessor: "name",
                },
                {
                  Header: "SKU Code",
                  accessor: "sku_code",
                },
              ],
            },
          ]);
          break;
        case "category":
          setColumns([
            {
              Header: "Category",
              columns: [
                {
                  Header: "ID",
                  accessor: "id",
                },
                {
                  Header: "Category Name",
                  accessor: "name",
                },
              ],
            },
          ]);
          break;
        case "brand":
          setColumns([
            {
              Header: "Brand",
              columns: [
                {
                  Header: "ID",
                  accessor: "id",
                },
                {
                  Header: "Brand Name",
                  accessor: "name",
                },
              ],
            },
          ]);
          break;
        default:
          break;
      }
      setData([]);
      setProductSkuCode("");
      setProductCategory("");
      setProductBrand("");
    }
  }, [radioProductType]);

  useEffect(() => {
    if (listProductSearch.data) {
      let mappedList = listProductSearch.data.map((item) => ({
        id: item.id,
        name: item.name,
        sku_code: item.sku_code,
      }));
      setData(mappedList);
    }
  }, [listProductSearch]);

  useEffect(() => {
    if (listCategorySearch.data) {
      let mappedList = listCategorySearch.data.map((item) => ({
        id: item.id,
        name: item.name,
      }));
      setData(mappedList);
    }
  }, [listCategorySearch]);

  useEffect(() => {
    if (listBrandSearch.data) {
      let mappedList = listBrandSearch.data.map((item) => ({
        id: item.id,
        name: item.name,
      }));
      setData(mappedList);
    }
  }, [listBrandSearch]);

  useEffect(() => {
    if (selectedData.length > 0) {
      setIsDisableSubmit(false);
    }
  }, [selectedData]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy, useRowSelect, (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // column for selection
        {
          id: "selection",
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                onClick={(e) => handleOnCellClicked(e, row)}
              />
            </div>
          ),
        },
        ...columns,
      ]);
    });

  const handleOnCellClicked = (e, data) => {
    if (e.target.checked) {
      setSelectedData((selectedData) => [...selectedData, data.original]);
    } else {
      setSelectedData((selectedData) => [
        ...selectedData.filter((i) => data.original.id !== i.id),
      ]);
    }
  };

  const toggleRadioProductType = (e, value) => {
    if (e.target.checked) {
      setRadioProductType(value);
    }
  };

  const handleSearchProduct = (e) => {
    setProductSkuCode(e.target.value);
    if (e.target.value !== "") {
      setTimeout(() => {
        dispatch(searchProduct(e.target.value));
      }, 300);
    }
  };

  const handleSearchCategory = (e) => {
    setProductCategory(e.target.value);
    if (e.target.value !== "") {
      setTimeout(() => {
        dispatch(searchCategory(e.target.value));
      }, 300);
    }
  };

  const handleSearchBrand = (e) => {
    setProductBrand(e.target.value);
    if (e.target.value !== "") {
      setTimeout(() => {
        dispatch(searchBrand(e.target.value));
      }, 300);
    }
  };

  const handleOnSubmit = () => {
    if (selectedData.length > 0) {
      props.onSubmitAddProduct(selectedData);
      setSelectedData([]);
      setData([]);
      setProductSkuCode("");
      setProductCategory("");
      setProductBrand("");
      setRadioProductType("product");
      props.toggleAddProduct();
    } else {
      alert("Select the data first");
    }
  };

  return (
    <Fragment>
      <Modal
        isOpen={props.showAddProduct}
        toggle={props.toggleAddProduct}
        backdrop={true}
      >
        <ModalHeader toggle={props.toggleAddProduct}>
          Add products by
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <FormGroup tag="fieldset" row className="d-flex px-2">
              <FormGroup check className="mx-2">
                <Label check>
                  <Input
                    type="radio"
                    name="radioProduct"
                    id="radioProduct"
                    checked={radioProductType === "product" ? true : false}
                    disabled={radioProductType === "product" ? false : true}
                    onChange={(e) => toggleRadioProductType(e, "product")}
                  />{" "}
                  Product
                </Label>
              </FormGroup>
              <FormGroup check className="mx-2">
                <Label check>
                  <Input
                    type="radio"
                    name="radioProduct"
                    id="radioCategory"
                    onChange={(e) => toggleRadioProductType(e, "category")}
                    checked={radioProductType === "category" ? true : false}
                    disabled={radioProductType === "category" ? false : true}
                  />{" "}
                  Category
                </Label>
              </FormGroup>
              <FormGroup check className="mx-2">
                <Label check>
                  <Input
                    type="radio"
                    name="radioProduct"
                    id="radioBrand"
                    onChange={(e) => toggleRadioProductType(e, "brand")}
                    checked={radioProductType === "brand" ? true : false}
                    disabled={radioProductType === "brand" ? false : true}
                  />{" "}
                  Brand
                </Label>
              </FormGroup>
            </FormGroup>
            <FormGroup>
              <Label for="formParticipant">
                <b>
                  {radioProductType === "product"
                    ? "Product SKU Code"
                    : radioProductType === "category"
                    ? "Product Category"
                    : "Product Brand"}
                </b>
              </Label>
              <Input
                placeholder={
                  radioProductType === "product"
                    ? "Enter Product SKU Code"
                    : radioProductType === "category"
                    ? "Enter Product Category"
                    : "Enter Product Brand"
                }
                id="formParticipant"
                value={
                  radioProductType === "product"
                    ? productSkuCode
                    : radioProductType === "category"
                    ? productCategory
                    : productBrand
                }
                onChange={
                  radioProductType === "product"
                    ? handleSearchProduct
                    : radioProductType === "category"
                    ? handleSearchCategory
                    : handleSearchBrand
                }
                disabled={radioProductType === "" ? true : false}
              />
            </FormGroup>
          </Form>
          <div>
            <TableContainer>
              <Table {...getTableProps()} className="text-left">
                <thead>
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>

                <tbody {...getTableBodyProps()} className="text-left">
                  {rows.map((row) => {
                    prepareRow(row);
                    return (
                      <tr {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td {...cell.getCellProps()}>
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </TableContainer>
          </div>
        </ModalBody>
        <ModalFooter>
          <button className="btn-csi-white" onClick={props.toggleAddProduct}>
            Cancel
          </button>
          <Button
            color="primary"
            onClick={() => handleOnSubmit()}
            disabled={isDisableSubmit}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ModalAdd;
