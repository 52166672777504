import React, { Fragment, useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Table,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { useTable, useSortBy, useRowSelect } from "react-table";
import styled from "styled-components";

import { searchParticipant } from "../../../../../../../redux/action/campaignAction";

const TableContainer = styled.div`
  overflow-y: auto;
  max-height: 250px;
`;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const ModalAddParticipant = (props) => {
  const dispatch = useDispatch();
  const [participantNameValue, setParticipantNameValue] = useState("");
  const [columns, setColumns] = useState([
    {
      Header: "User search result",
      columns: [
        {
          Header: "ID",
          accessor: "id",
          sortAble: true,
        },
        {
          Header: "Owner Name",
          accessor: "owner_name",
          sortAble: true,
        },
        {
          Header: "Shop Name",
          accessor: "shop_name",
          sortAble: true,
        },
        {
          Header: "City",
          accessor: "city",
          sortAble: true,
        },
        {
          Header: "Phone Number",
          accessor: "phone_number",
          sortAble: true,
        },
      ],
    },
  ]);
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  const { listParticipantSearch } = useSelector(
    (state) => state.CampaignReducer
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy, useRowSelect, (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // column for selection
        {
          id: "selection",
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                onClick={(e) => handleOnCellClicked(e, row)}
              />
            </div>
          ),
        },
        ...columns,
      ]);
    });

  const handleOnCellClicked = (e, data) => {
    if (e.target.checked) {
      setSelectedData((selectedData) => [...selectedData, data.original]);
    } else {
      setSelectedData((selectedData) => [
        ...selectedData.filter((i) => data.original.id !== i.id),
      ]);
    }
  };

  const handleOnSearch = (e) => {
    e.preventDefault();
    setParticipantNameValue(e.target.value);
    if (e.target.value !== "") {
      setTimeout(() => {
        dispatch(searchParticipant(e.target.value));
      }, 300);
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (selectedData.length > 0) {
      props.handleSubmitParticipant(selectedData);
      setSelectedData([]);
      setData([]);
      setParticipantNameValue("");
      props.toggleAddParticipant();
    } else {
      alert("Select the data first");
    }
  };

  useEffect(() => {
    if (listParticipantSearch.data) {
      const newData = listParticipantSearch.data.map((item) => ({
        id: item.id,
        owner_name: item.owner_name,
        shop_name: item.shop_name,
        city: item.city,
        phone_number: item.phone_number,
      }));
      setData(newData);
    }
  }, [listParticipantSearch]);

  return (
    <Fragment>
      <Modal
        isOpen={props.showAddParticipant}
        toggle={props.toggleAddParticipant}
      >
        <ModalHeader toggle={props.toggleAddParticipant}>
          Add Participant
        </ModalHeader>
        <ModalBody>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <FormGroup>
              <Label for="formParticipant">
                <b>Participant Name</b>
              </Label>
              <Input
                placeholder="Enter Participant Name"
                id="formParticipant"
                value={participantNameValue}
                onChange={(e) => handleOnSearch(e)}
              />
            </FormGroup>
          </Form>

          <TableContainer>
            <Table {...getTableProps()} className="text-left">
              <thead>
                {headerGroups.map((headerGroup, index) =>
                  index > 0 ? (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          {/* Add a sort direction indicator */}
                          <span>
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : " 🔼"
                              : ""}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ) : null
                )}
              </thead>

              <tbody {...getTableBodyProps()} className="text-left">
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter>
          <button
            className="btn-csi-white"
            onClick={props.toggleAddParticipant}
          >
            Cancel
          </button>
          <Button
            color="primary"
            onClick={(e) => handleOnSubmit(e)}
            disabled={participantNameValue === "" ? true : false}
          >
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ModalAddParticipant;
