import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import TableCSI from "../../../../Component/table";
import CustomPagination from "../../../../Component/customPagination";
import { getDetailMutasi } from "../../../../redux/action/mutasiAction";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../../layout/breadcrumb";
import moment from "moment";

const MutasiDetailLayout = (props) => {
  const [data, setData] = useState([]);
  const [dataHeader, setDataHeader] = useState({});
  const [tableColumn, setTableColumn] = useState([]);
  const [isFetchedData, setIsFetchedData] = useState(true);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const dispatch = useDispatch();
  const { detailMutasi } = useSelector((state) => state.MutasiReducer);
  const urlParams = useParams();

  useEffect(() => {
    dispatch(getDetailMutasi(urlParams?.id));
    setTableColumn(
      [
        {
          Header: () => null,
          id: "MutasiBcaDetail",
          columns: [
            {
              Header: "Nomor Tagihan",
              accessor: "invoice_no",
              sortAble: true,
            },
            {
              Header: "Nominal",
              accessor: "total_price",
              sortAble: true,
              Cell: ({ row }) =>
                `${row.original.total_price.toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 0,
                })}`,
            },
            {
              Header: "Tgl Tagihan",
              accessor: "created_at",
              sortAble: true,
            },
            {
              Header: "Tgl Pembayaran",
              accessor: "updated_at",
              sortAble: true,
            },
          ],
        },
      ],
      []
    );
  }, []);

  useEffect(() => {
    if (detailMutasi.data && !detailMutasi.loading) {
      let dataMutasi = detailMutasi.data.data;
      let mappedListData = [
        {
          id: dataMutasi?.transaction ? dataMutasi?.transaction?.id : "-",
          invoice_no: dataMutasi?.transaction
            ? dataMutasi?.transaction?.invoice_no
            : "-",
          total_price: dataMutasi?.transaction
            ? dataMutasi?.transaction?.total_price
            : "-",
          created_at: dataMutasi?.transaction
            ? dataMutasi?.transaction?.created_at
              ? moment(dataMutasi?.transaction?.created_at).format("DD-MM-YYYY")
              : "-"
            : "-",
          updated_at: dataMutasi?.bcamutation
            ? dataMutasi?.bcamutation?.transaction_date
              ? moment(dataMutasi?.bcamutation?.transaction_date).format(
                  "DD-MM-YYYY"
                )
              : "-"
            : "-",
        },
      ];
      setData(mappedListData);
      setDataHeader(dataMutasi);
      setIsFetchedData(true);
    }
  }, [detailMutasi]);

  return (
    <Fragment>
      <Breadcrumb
        parent="Dashboard"
        title="Detail Mutasi BCA"
        customTitle={`Detail Mutasi ${dataHeader?.duser?.shop_name || ""}`}
        urlParent={"/dashboard/analytics"}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            {isFetchedData ? (
              <Card>
                <CardBody className="p-1">
                  <div className="d-flex my-4">
                    <div className="my-2 col-md-3">
                      <p className="m-1" style={{ color: "#6c757d" }}>
                        Customer
                      </p>
                      <span className="m-1">{`${
                        dataHeader?.duser?.shop_name || ""
                      }`}</span>
                    </div>
                    <div className="my-2 col-md-3">
                      <p className="m-1" style={{ color: "#6c757d" }}>
                        VA Number
                      </p>
                      <span className="m-1">{`${
                        dataHeader?.virtual_account || ""
                      }`}</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ) : null}
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className="p-0">
              <CardBody className="p-3">
                {isFetchedData ? (
                  <TableCSI
                    data={data}
                    columns={tableColumn}
                    key={data}
                    initialSortBy={initialSortBy}
                  />
                ) : null}

                <CustomPagination
                  // handlePageClick={handlePageClick}
                  pageCount={maxPageIndex}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={2}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MutasiDetailLayout;
