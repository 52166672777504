import { Card, CardBody, Form, FormGroup, Button } from "reactstrap";
import PropTypes from "prop-types";
import SpinnerLoading from "../spinnerLoading";
import { Fragment } from "react";

const FormWrapper = ({
  title,
  children,
  onSubmit,
  disableSubmit,
  onCancel,
}) => {
  return (
    <Fragment>
      <Card>
        <CardBody>
          <Form onSubmit={(e) => e.preventDefault()}>
            {title && <legend>{title}:</legend>}
            {children}
          </Form>
        </CardBody>
      </Card>
      <div className="d-flex justify-content-between my-3">
        <button
          className="mx-1 border rounded-lg shadow-sm p-2"
          type="button"
          onClick={onCancel}
		  style={{backgroundColor: '#ffffff', width: '120px'}}
        >
          Cancel
        </button>
        <Button
          className="shadow-sm mx-1"
          type="button"
          color="primary"
          disabled={disableSubmit}
		  onClick={onSubmit}
		  style={{width: '120px'}}
        >
          {disableSubmit ? <SpinnerLoading /> : "Submit"}
        </Button>
      </div>
    </Fragment>
  );
};

FormWrapper.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
  ]).isRequired,
  onSubmit: PropTypes.func,
  disableSubmit: PropTypes.bool.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default FormWrapper;
