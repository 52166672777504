import React, { useState } from "react";

import imgPlaceholder from '../../assets/images/img-placeholder.png'

/**
 * CarouselImage component
 * @param {Object[]} images - An array of image objects. Each object should have an `id` and `src` property.
 */

const CarouselImage = ({ images }) => {
  const [currImg, setCurrImg] = useState(0);

  return (
    <div className="d-flex flex-column carousel">
      <div className="border p-3 my-1">
        <img
          className="d-block mx-auto"
          src={images ? images[currImg]?.image_url : imgPlaceholder}
          alt="Product Banner"
          width={300}
          style={{ objectFit: "cover" }}
        />
      </div>
      <div
        className="carousel-inner position-relative my-1"
        style={{ overflowX: "auto", whiteSpace: "nowrap" }}
      >
        {images &&
          images.map((item, index) => (
            <img
              className="mx-1"
              key={item?.id}
              src={item?.thumbnail_image_url}
              alt="Product Images"
              width="150"
              height="150"
              onClick={() => setCurrImg(index)}
              style={{ cursor: "pointer", objectFit: "cover" }}
            />
          ))}
        <button
          className="position-absolute border-0 shadow-sm rounded-circle"
          onClick={() => {
            if (currImg === images.length - 1) {
              setCurrImg(0);
            } else {
              setCurrImg(currImg + 1);
            }
          }}
          style={{
            width: "40px",
            height: "40px",
            top: "35%",
            right: "3%",
            opacity: "0.8",
          }}
        >
          <b>{`>`}</b>
        </button>
      </div>
    </div>
  );
};

export default CarouselImage;
