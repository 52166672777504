import styled from "styled-components";

export const wrapperInputThumbnail = {
  position: "relative",
  overflow: "hidden",
  display: "inline-block",
};
export const styleInputThumbnail = {
  position: "absolute",
  left: "0",
  top: "0",
  opacity: "0",
  width: "240px",
  height: "180px",
  cursor: "pointer",
};
export const styleInputbanner = {
  position: "absolute",
  left: "0",
  top: "0",
  opacity: "0",
  width: "240px",
  height: "180px",
  cursor: "pointer",
};
export const btnUploadBanner = {
  border: "2px solid gray",
  color: "gray",
  backgroundColor: "white",
  padding: "8px",
  borderRadius: "5px",
  width: "240px",
  height: "180px",
  cursor: "pointer",
};
export const containerMutipleBanner = {
  overflowX: "scroll",
  overflowY: "hidden",
  whiteSpace: "nowrap",
};
export const btnDeleteBanner = {
  position: "absolute",
  right: "0",
  top: "0",
  width: "32px",
  height: "32px",
  cursor: 'pointer'
};
export const ImgButton = styled.img`
  display: block;
  position: relative;

  ::after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 95%;
    height: 95%;
    padding: 70px 0;
    background-color: #fff;
    text-align: center;
    content: "Tambah gambar";
  }
`;
