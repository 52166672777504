import { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
} from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getListUserAdmin,
  deleteUserAdmin,
} from "@action/userAdminAction";

import CustomPagination from "@components/customPagination";
import Breadcrumb from "@layout/breadcrumb";
import iconSearch from "@assets/images/input-search.png";
import TableCSI from "@components/table";
import PaginationRow from "@components/paginationDataPerPage";

const BtnWhite = styled.button`
  padding: 6px;
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  background-color: white;
`;

const BtnDelete = styled.button`
  padding: 6px;
  border: 2px solid #e0e0e0;
  border-radius: 4px;
  background-color: white;
  color: red;
`;

const ConfigAdminLayout = () => {
  const dispatch = useDispatch();
  const { listUserAdmin } = useSelector((state) => state.UserAdminReducer);
  const [isFetchedData, setIsFetchedData] = useState(false);
  const [mappedListData, setMappedListData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [isAfterSearch, setIsAfterSearch] = useState(false);
  const [totalResult, setTotalResult] = useState(0);
  const [totalPerPage, setTotalPerPage] = useState(0);

  useEffect(() => {
    fetchTable();
  }, []);

  useEffect(() => {
    if (listUserAdmin.data && !listUserAdmin.loading) {
      let respData = listUserAdmin.data?.data;
      if (respData === undefined || respData === null) return false;

      let mappedData = respData.data.map((item) => ({
        id: item.id,
        username: item.username,
        role: item.role,
      }));

      setMaxPageIndex(respData.last_page);
      setTotalResult(respData.total);
      setTotalPerPage(respData.data.to);
      setMappedListData(mappedData);
      setIsFetchedData(true);
    }
  }, [listUserAdmin]);

  useEffect(() => {
    if (inputSearchValue !== "") return false;

    fetchTable();
  }, [inputSearchValue]);

  const fetchTable = () => {
    setTableColumn([
      {
        Header: () => null,
        id: "configAdmin1",
        columns: [
          {
            Header: "ID",
            accessor: "id",
            width: 30,
            sortAble: true,
          },
          {
            Header: "Username",
            accessor: "username",
            minWidth: 200,
            sortAble: true,
          },
          {
            Header: "Role",
            accessor: "role",
            minWidth: 250,
            sortAble: true,
          },
          {
            Header: "Action",
            accessor: "action",
            Cell: ({ row }) => (
              <div className="d-flex">
                <BtnWhite className="mx-1">
                  <Link to={`/dashboard/admin/edit/${row.original.id}`}>
                    Edit
                  </Link>
                </BtnWhite>
                <BtnDelete
                  className="mx-1"
                  onClick={() => handleDeleteRow(row.original.id)}
                >
                  Delete
                </BtnDelete>
              </div>
            ),
            disableSortBy: true,
            width: 40,
          },
        ],
      },
    ]);

    dispatch(getListUserAdmin(1, "", 10));
  };

  const handleSubmitSearch = () => {
    if (inputSearchValue !== "") {
      dispatch(getListUserAdmin(1, inputSearchValue, 10));
      setIsAfterSearch(true);
    }
  };

  const handleOnEnter = (e) => {
    if (inputSearchValue !== "") {
      dispatch(getListUserAdmin(1, inputSearchValue, 10));
      setIsAfterSearch(true);
    }
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListUserAdmin(page, "", 10));
  };

  const handleDeleteRow = (id) => {
    dispatch(deleteUserAdmin(id));
    setTimeout(() => {
      fetchTable();
    }, 150);
  };

  const onChangePaginationRow = (perPage) => {
    dispatch(getListUserAdmin(1, "", perPage));
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Dashboard"
        title="Admin"
        urlParent={"/dashboard/analytics"}
      />
      <Container fluid>
        <Row>
          <Col>
            <Card className="p-0">
              <CardBody className="p-3">
                <div className="d-flex justify-content-between my-2">
                  <InputGroup style={{ maxWidth: "280px" }}>
                    <Input
                      placeholder="Search"
                      value={inputSearchValue}
                      onChange={(e) => setInputSearchValue(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleOnEnter(e);
                      }}
                    />
                    <InputGroupAddon addonType="prepend">
                      <Button
                        className="p-2"
                        color="primary"
                        onClick={() => handleSubmitSearch()}
                      >
                        <img src={iconSearch} width={15} height={15} />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>

                  <Button color="primary" outline>
                    <Link to={`/dashboard/admin/add`}>Add New Admin</Link>
                  </Button>
                </div>

                <TableCSI
                  data={mappedListData}
                  columns={tableColumn}
                  key={mappedListData}
                />

                <div className="d-flex justify-content-between">
                  <PaginationRow
                    onChangePaginationRow={onChangePaginationRow}
                  />
                  <CustomPagination
                    handlePageClick={handlePageClick}
                    pageCount={maxPageIndex}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ConfigAdminLayout;
