import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
} from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getDetailProduct,
  getProductPriceList,
} from "../../../../redux/action/productAction";
import Breadcrumb from "../../../../layout/breadcrumb";
import TableCSI from "../../../../Component/table";
import { ModalDialog } from "../../../../Component/modal/ModalDialog";
import ModalUpload from "../../../../Component/modal/ModalUpload";
import ButtonAction from "../../../../Component/styledComponents/btnTableAction";
import iconSearch from "../../../../assets/images/input-search.png";
import PaginationRow from "../../../../Component/paginationDataPerPage";
import CustomPagination from "../../../../Component/customPagination";

const ProductPrice = () => {
  const urlParam = useParams();
  const dispatch = useDispatch();
  const { detailProduct, listProductPrice } = useSelector((state) => state.ProductReducer);

  const [data, setData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [isFetchedData, setIsFetchedData] = useState(true);
  const [modalState, setModalState] = useState(null);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [totalResult, setTotalResult] = useState(4);
  const [perPage, setPerPage] = useState(10);
  const [totalPerPage, setTotalPerPage] = useState(1);
  const [detailData, setDetailData] = useState({
    name: "",
    sku_code: "",
    price: "",
    imageUrl: "",
  });

  useEffect(() => {
    dispatch(getDetailProduct(urlParam.id));
    dispatch(getProductPriceList(urlParam.id, 1, 10));
    setTableColumn([
      {
        Header: () => null,
        id: "ProductPrice",
        columns: [
          {
            Header: "Start Date",
            accessor: "start_date",
            sortAble: true,
            width: 100,
          },
          {
            Header: "End Date",
            accessor: "end_date",
            sortAble: true,
            width: 100,
          },
          {
            Header: "Min QTY",
            accessor: "min_qty",
            sortAble: true,
            width: 100,
          },
          {
            Header: "Price",
            accessor: "price",
            sortAble: true,
            width: 100,
          },
          {
            Header: "Created At",
            accessor: "created_at",
            sortAble: true,
            width: 100,
          },
          {
            Header: "Deleted At",
            accessor: "deleted_at",
            sortAble: true,
            width: 100,
          },
        ],
      },
    ]);
  }, []);

  useEffect(() => {
    if (detailProduct?.data && !detailProduct?.loading) {
      const data = detailProduct.data;
      setDetailData({
        ...detailData,
        name: data.name,
        sku_code: data.sku_code,
        price: data.price,
        imageUrl: data?.images[0]?.image_url,
      });
    }
  }, [detailProduct]);

  useEffect(() => {
    if (listProductPrice?.data && !listProductPrice?.loading) {
	  let dataPrice = listProductPrice?.data?.data;
      let mappedListData = dataPrice.map((item) => ({
        start_date: item?.start_date,
        end_date: item?.end_date,
        min_qty: item?.min_qty,
        price: item?.price,
        created_at: item?.created_at,
        deleted_at: item?.deleted_at,
      }));
      setData(mappedListData);
      setMaxPageIndex(listProductPrice.data?.last_page);
      setTotalResult(listProductPrice.data?.total);
      setTotalPerPage(listProductPrice.data?.to);
      setIsFetchedData(true);
    }
  }, [listProductPrice]);

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getProductPriceList(urlParam.id, page, 10));
  };

  const onChangePaginationRow = (perPage) => {
    setIsFetchedData(false);
    setPerPage(perPage);
    dispatch(getProductPriceList(urlParam.id, 1, perPage));
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Product"
        title="Product Price"
        urlParent="/dashboard/product"
      />
      <Container fluid>
        <Row>
          <Col xl={4} sm={12}>
            <Card className="p-0">
              <CardBody className="p-3">
                <h4>Price Details</h4>
                <hr />
                <Row className="my-1">
                  <Col sm={5}>
                    <img src={detailData.imageUrl} alt="" width={250} />
                  </Col>
                  <Col sm={7}>
                    <div className="my-2">
                      <b>Name: {detailData.name}</b>
                    </div>
                    <div className="my-2">
                      <b>SKU: {detailData.sku_code}</b>
                    </div>
                    <div className="my-2">
                      <b>Price: {detailData.price}</b>
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col xl={8} sm={12}>
            <Card className="p-0">
              <CardBody className="p-3">
                <div>
                  <h4>Wholesale Price</h4>
                </div>
                <div className="d-flex justify-content-between my-2">
                  <InputGroup style={{ maxWidth: "280px" }}>
                    <Input
                      placeholder="Search"
                      value={inputSearchValue}
                      onChange={(e) => {}}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") console.log(e);
                      }}
                    />
                    <InputGroupAddon addonType="prepend">
                      <Button
                        className="p-2"
                        color="primary"
                        onClick={() => {}}
                      >
                        <img src={iconSearch} width={15} height={15} alt={""} />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </div>
                {isFetchedData ? (
                  <TableCSI data={data} columns={tableColumn} key={data} />
                ) : null}
                <div className="d-flex justify-content-between my-1">
                  <PaginationRow
                    onChangePaginationRow={onChangePaginationRow}
                  />
                  <CustomPagination
                    handlePageClick={handlePageClick}
                    pageCount={maxPageIndex}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ProductPrice;
