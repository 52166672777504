import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

import iconSearch from "../../../../assets/images/input-search.png";
import { useDispatch, useSelector } from "react-redux";
import ButtonAction from "../../../../Component/styledComponents/btnTableAction";
import WrapperTableAction from "../../../../Component/styledComponents/wrapperBtnAction";
import BtnDropdownContent from "../../../../Component/styledComponents/btnDropdownContent";
import {
  changeStatusCategory,
  deleteDataCategory,
  getParentCategory,
} from "../../../../redux/action/categoryAction";
import TableCSI from "../../../../Component/table";
import PaginationRow from "../../../../Component/paginationDataPerPage";
import CustomPagination from "../../../../Component/customPagination";
import { Link } from "react-router-dom";
import BadgeStatus from "../../../../Component/badgeStatus";
import BtnDropdownAction from "../../../../Component/styledComponents/btnDropdownAction";
import ModalContent from "../../../../Component/modal/ModalContent";
import { ModalDialog } from "../../../../Component/modal/ModalDialog";

const CategoryLayout = (props) => {
  const [tableColumn, setTableColumn] = useState([]);
  const [data, setData] = useState([]);
  const [isFetchedData, setIsFetchedData] = useState(true);
  const [isAfterSearch, setIsAfterSearch] = useState(false);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [totalResult, setTotalResult] = useState(4);
  const [totalPerPage, setTotalPerPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const dispatch = useDispatch();
  const { parentCategory } = useSelector((state) => state.CategoryReducer);
  const [modalState, setModalState] = useState(null);

  useEffect(() => {
    dispatch(getParentCategory(10, 1, ""));
    setTableColumn(
      [
        {
          Header: () => null,
          id: "Category",
          columns: [
            {
              Header: "ID",
              accessor: "id",
            },
            {
              Header: "Image",
              accessor: "file_url",
              Cell: ({ row }) => (
                <div>
                  <img src={row.original.file_url} alt={""} width={50} />
                </div>
              ),
            },
            {
              Header: "Name",
              accessor: "name",
            },
            {
              Header: "Ordering",
              accessor: "ordering",
            },
            {
              Header: "Description",
              accessor: "description",
            },
            {
              Header: "Status",
              accessor: "active",
              Cell: ({ row }) => <BadgeStatus status={row.original.active} />,
            },
            {
              Header: "Action",
              accessor: "action",
              Cell: ({ row }) => (
                <div className="d-flex">
                  <div className="mx-2">
                    <Link
                      to={{
                        pathname: `/dashboard/category/edit/${row.original?.id}`,
                        data: row?.original,
                      }}
                    >
                      <Button color="primary" outline>
                        Edit
                      </Button>
                    </Link>
                  </div>
                  <WrapperTableAction>
                    <ButtonAction className="mx-2">...</ButtonAction>
                    <BtnDropdownContent>
                      <BtnDropdownAction className="py-0">
                        <Link
                          to={{
                            pathname: `/dashboard/category/child/${row.original.id}`,
                            data: row?.original,
                          }}
                        >
                          Childs
                        </Link>
                      </BtnDropdownAction>
                      <br />
                      <BtnDropdownAction
                        className="py-0"
                        onClick={() => showModal(row?.original, "delete")}
                      >
                        <Link to="#">Delete</Link>
                      </BtnDropdownAction>
                      <br />
                      <BtnDropdownAction
                        className="py-0"
                        onClick={() => showModal(row?.original, "deactive")}
                      >
                        <Link to="#">Deactivate</Link>
                      </BtnDropdownAction>
                    </BtnDropdownContent>
                  </WrapperTableAction>
                </div>
              ),
            },
          ],
        },
      ],
      []
    );
  }, []);

  useEffect(() => {
    if (parentCategory.data && !parentCategory.loading) {
      let dataCategory = parentCategory?.data?.data;
      if (dataCategory === undefined || dataCategory === null) return false;

      let mappedListData = dataCategory?.map((item) => ({
        id: item?.id,
        file_url: item?.file_url,
        name: item?.name,
        ordering: item?.ordering,
        description: item?.description,
        active: item?.active,
      }));
      setData(mappedListData);
      setMaxPageIndex(parentCategory?.data?.last_page);
      setTotalResult(parentCategory?.data?.total);
      setTotalPerPage(parentCategory?.data?.to);
      setIsFetchedData(true);
    }
  }, [parentCategory]);

  const handleSubmitSearch = () => {
    if (inputSearchValue !== "") {
      dispatch(getParentCategory(perPage, 1, inputSearchValue));
      setIsAfterSearch(true);
    }
  };

  const handleInputSearch = (e) => {
    setInputSearchValue(e.target.value);
  };

  const handleOnEnter = (e) => {
    // if (inputSearchValue !== "") {
    dispatch(getParentCategory(perPage, 1, inputSearchValue));
    setIsAfterSearch(true);
    // }
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getParentCategory(perPage, page, inputSearchValue));
    setIsAfterSearch(true);
  };

  const onChangePaginationRow = (perPage) => {
    setIsFetchedData(false);
    setPerPage(perPage);
    dispatch(getParentCategory(perPage, 1, inputSearchValue));
  };

  const showModal = (row, type) => {
    setModalState((prevState) => ({
      ...prevState,
      openModal: true,
      modalType: type,
      modalTitle: "Informasi",
      modalData: row,
      closeIcon: true,
    }));
  };

  const handleDelete = (data) => {
    setIsFetchedData(true);
    dispatch(deleteDataCategory(data?.id));
    setIsFetchedData(false);
    setModalState(null);
    dispatch(getParentCategory(perPage, 1, inputSearchValue));
  };

  const handleDeactive = (data) => {
    setIsFetchedData(true);
    dispatch(changeStatusCategory(data?.id));
    setIsFetchedData(false);
    setModalState(null);
    dispatch(getParentCategory(perPage, 1, inputSearchValue));
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Dashboard"
        title="Category"
        urlParent={"/dashboard/analytics"}
      />
      <Container fluid={true}>
        <Row>
          <Col>
            <Card className="p-0">
              <CardBody className="p-3">
                <div className="d-flex justify-content-between my-2">
                  <InputGroup style={{ maxWidth: "280px" }}>
                    <Input
                      placeholder="Search"
                      value={inputSearchValue}
                      onChange={(e) => handleInputSearch(e)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleOnEnter(e);
                      }}
                    />
                    <InputGroupAddon addonType="prepend">
                      <Button
                        className="p-2"
                        color="primary"
                        onClick={() => handleSubmitSearch()}
                      >
                        <img src={iconSearch} width={15} height={15} alt={""} />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  <Link to={`/dashboard/category/add`}>
                    <Button outline color="primary">
                      Add new Category
                    </Button>
                  </Link>
                </div>
                {isFetchedData ? (
                  <TableCSI
                    data={data}
                    columns={tableColumn}
                    key={data}
                    initialSortBy={initialSortBy}
                  />
                ) : null}
                <div className="d-flex justify-content-between">
                  <PaginationRow
                    onChangePaginationRow={onChangePaginationRow}
                  />
                  <CustomPagination
                    handlePageClick={handlePageClick}
                    pageCount={maxPageIndex}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                  />
                </div>
              </CardBody>
              <ModalDialog
                open={modalState?.openModal ?? false}
                type={modalState?.modalType ?? ""}
                footer={false}
                title={modalState?.modalTitle ?? ""}
                titleFontSize={18}
                backdropClose={true}
                onClose={() => {
                  setModalState(null);
                }}
                maxWidth={384}
              >
                <ModalContent
                  onClose={() => {
                    setModalState(null);
                  }}
                  onSubmit={() =>
                    modalState?.modalType === "delete"
                      ? handleDelete(modalState?.modalData)
                      : handleDeactive(modalState?.modalData)
                  }
                  data={modalState?.modalData ?? null}
                  descClose={"Batal"}
                  descSubmit={
                    modalState?.modalType === "delete" ? "Hapus" : "Deactive"
                  }
                  description={`${
                    modalState?.modalType === "delete" ? "Hapus" : "Deactive"
                  } data category ${modalState?.modalData?.name}?`}
                />
              </ModalDialog>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CategoryLayout;
