import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";

import {
  Container,
  Col,
  Row,
  Card,
  CardBody,
  InputGroup,
  Input,
  InputGroupAddon,
  Button,
  Label,
  FormGroup,
} from "reactstrap";
import Breadcrumb from "../../../../layout/breadcrumb";
import iconSearch from "../../../../assets/images/input-search.png";
import TableCSI from "../../../../Component/table";
import { useDispatch, useSelector } from "react-redux";
import { getListNavLog } from "../../../../redux/action/navlogAction";
import PaginationRow from "../../../../Component/paginationDataPerPage";
import CustomPagination from "../../../../Component/customPagination";

const ConfigNavlogLayout = () => {
  const [isDataReady, setIsDataReady] = useState(false);
  const [tableColumn, setTableColumn] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [data, setData] = useState([]);
  const [isAfterSearch, setIsAfterSearch] = useState(false);
  const [totalResult, setTotalResult] = useState(4);
  const [perPage, setPerPage] = useState(10);
  const [totalPerPage, setTotalPerPage] = useState(1);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [searchFilter, setSearchFilter] = useState("");

  const dispatch = useDispatch();
  const { listNavlog } = useSelector((state) => state.NavlogReducer);

  useEffect(() => {
    fetchTable();
  }, []);

  useEffect(() => {
    if (listNavlog.data && !listNavlog.loading) {
      let dataNavlog = listNavlog?.data?.data;
      if (dataNavlog === undefined || dataNavlog === null) return false;

      let mappedListData = dataNavlog.map((item) => ({
        id: item?.id,
        time: item?.updated_at,
        url: item?.url,
        method: item?.method,
        message: item?.message,
      }));
      setData(mappedListData);
      setMaxPageIndex(listNavlog?.data?.last_page);
      setTotalResult(listNavlog?.data?.total);
      setTotalPerPage(listNavlog?.data?.to);
      setIsDataReady(true);
    }
  }, [listNavlog]);

  useEffect(() => {
    if (inputSearchValue !== "") return false;

    fetchTable();
  }, [inputSearchValue]);

  const fetchTable = () => {
    setTableColumn([
      {
        Header: () => null,
        id: "configAdmin1",
        columns: [
          {
            Header: "ID",
            accessor: "id",
            width: 100,
          },
          {
            Header: "Time",
            accessor: "time",
            width: 150,
          },
          {
            Header: "URL",
            accessor: "url",
            width: 150,
          },
          {
            Header: "Method",
            accessor: "method",
            width: 150,
          },
          {
            Header: "Message",
            accessor: "message",
            width: 150,
          },
        ],
      },
    ]);

    dispatch(getListNavLog(10, 1, inputSearchValue));
  };

  const handleSubmitSearch = () => {
    if (inputSearchValue === "" || searchFilter === "") return false;
    dispatch(getListNavLog(perPage, 1, inputSearchValue, searchFilter));
    setIsAfterSearch(true);
  };

  const handleInputSearch = (e) => {
    setInputSearchValue(e.target.value);
  };

  const handleOnEnter = (e) => {
    // if (inputSearchValue !== "") {
    dispatch(getListNavLog(perPage, 1, inputSearchValue));
    setIsAfterSearch(true);
    // }
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListNavLog(perPage, page, inputSearchValue));
    setIsAfterSearch(true);
  };

  const onChangePaginationRow = (perPage) => {
    setIsDataReady(false);
    setPerPage(perPage);
    dispatch(getListNavLog(perPage, 1, inputSearchValue));
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Dashboard"
        title="Navlog"
        urlParent={"/dashboard/analytics"}
      />
      <Container fluid>
        <Row>
          <Col>
            <Card className="p-0">
              <CardBody className="p-3">
                <div className="d-flex justify-content-between my-2">
                  <InputGroup className="p-2" style={{ maxWidth: "280px" }}>
                    <Input
                      placeholder="Search"
                      value={inputSearchValue}
                      onChange={(e) => handleInputSearch(e)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleOnEnter(e);
                      }}
                    />
                    <InputGroupAddon addonType="prepend">
                      <FormGroup>
                        <Input
                          type="select"
                          name="select"
                          id="filterSelect"
                          onChange={(e) => setSearchFilter(e.target.value)}
                        >
                          <option value={""}>Select filter</option>
                          <option value={"id"}>ID</option>
                          <option value={"method"}>Method</option>
                          <option value={"message"}>Message</option>
                          <option value={"url"}>Url</option>
                          <option value={"created_at"}>Created At</option>
                        </Input>
                      </FormGroup>
                      <Button
                        color="primary"
                        className="mx-1 p-2"
                        onClick={() => handleSubmitSearch()}
                        style={{ maxHeight: "40px", borderRadius: "5px" }}
                      >
                        <img src={iconSearch} width={15} height={15} />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </div>

                {isDataReady ? (
                  <TableCSI data={data} columns={tableColumn} key={data} />
                ) : null}

                <div className="d-flex justify-content-between">
                  <PaginationRow
                    onChangePaginationRow={onChangePaginationRow}
                  />
                  <CustomPagination
                    handlePageClick={handlePageClick}
                    pageCount={maxPageIndex}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ConfigNavlogLayout;
