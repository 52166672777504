import {
	GET_LIST_ADMIN,
	GET_DETAIL_ADMIN
} from "../../actionTypes";

  const initialState = {
	listUserAdmin: {},
	detailAdmin: {}
  };

  const UserAdminReducer = (state = initialState, action) => {
	switch (action.type) {
	  case GET_LIST_ADMIN:
		state.listUserAdmin = action.payload;
		return { ...state, listUserAdmin: state.listUserAdmin };
	  case GET_DETAIL_ADMIN:
		state.detailAdmin = action.payload;
		return { ...state, detailAdmin: state.detailAdmin };

	  default:
		return { ...state };
	}
  };

  export default UserAdminReducer;
