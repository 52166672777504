import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Table,
} from "reactstrap";
import { useTable, useSortBy } from "react-table";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CustomPagination from "../../../../../../Component/customPagination";
import ModalAdd from "../modalAddProduct";
import EntryCounter from "../../../../../../Component/entryCounter";
import {
  addProductCampaign,
  deleteItems,
  fetchProductCampaign,
} from "../../../../../../redux/action/campaignAction";

import iconSearch from "../../../../../../assets/images/input-search.png";
import sortIcon from "../../../../../../assets/images/sort-icon.svg";

const BtnAction = styled.button`
  color: red;
  font-size: 1em;
  border: 1px solid #d2d2d4;
  border-radius: 5px;
  cursor: pointer;
  background: white;
`;

const Btn = styled.button`
  color: black;
  font-size: 1em;
  border: 2px solid #ebebeb;
  border-radius: 5px;
  cursor: pointer;
`;

const CampaignProduct = (props) => {
  const urlParam = useParams();
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = useState("");
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [typeProduct, setTypeProduct] = useState("");
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const [totalPerPage, setTotalPerPage] = useState(0);

  const { alertResponse } = useSelector((state) => state.AlertReducer);
  const { listCampaignProduct } = useSelector((state) => state.CampaignReducer);

  const tableInstance = useTable({ columns, data }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  useEffect(() => {
    if (props.typeProduct !== "") {
      setTypeProduct(props.typeProduct);
    }
  }, [props]);

  useEffect(() => {
    dispatch(fetchProductCampaign(props.urlParam, 1));
  }, []);

  //hook for fetching list products
  useEffect(() => {
    if (typeProduct === "product") {
      setColumns([
        {
          Header: () => null,
          id: "tableCampaignProduct",
          columns: [
            {
              Header: "SKU Code",
              accessor: "sku_code",
              sortAble: true,
            },
            {
              Header: "Product Name",
              accessor: "name",
              sortAble: true,
            },
            {
              Header: "Category",
              accessor: "category",
              sortAble: true,
            },
            {
              Header: "Brand",
              accessor: "brand",
              sortAble: true,
            },
            {
              Header: "Action",
              accessor: "action",
              Cell: ({ row }) => (
                <BtnAction
                  onClick={() => onDeleteItems(row.original.id)}
                  disabled={isLoadingDelete}
                >
                  Delete
                </BtnAction>
              ),
            },
          ],
        },
      ]);
      if (listCampaignProduct.data && !listCampaignProduct.loading) {
        setMaxPageIndex(listCampaignProduct.data.last_page);
        let arrData = listCampaignProduct.data.data;
        let newData = arrData.map((item) => ({
          id: item.id,
          sku_code: item.product.sku_code,
          name: item.product.name,
          category: item.product.category.name,
          brand: item.product.manufacturer_code,
        }));
        setData(newData);
        setTotalPerPage(listCampaignProduct.data.to);
        setTotalResult(listCampaignProduct.data.total);
      }
    } else if (typeProduct === "category") {
      setColumns([
        {
          Header: () => null,
          id: "tableCampaignCategory",
          columns: [
            {
              Header: "Category",
              accessor: "name",
              sortAble: true,
            },
            {
              Header: "Description",
              accessor: "description",
              sortAble: true,
            },
            {
              Header: "Action",
              accessor: "action",
              Cell: ({ row }) => (
                <BtnAction
                  onClick={() => onDeleteItems(row.original.id)}
                  disabled={isLoadingDelete}
                >
                  Delete
                </BtnAction>
              ),
            },
          ],
        },
      ]);
      if (!listCampaignProduct.loading) {
        setMaxPageIndex(listCampaignProduct.data.last_page);
        let arrData = listCampaignProduct.data.data;
        let newData = arrData.map((item) => ({
          id: item.id,
          name: item.category.name,
          description: item.category.description,
        }));
        setData(newData);
        setTotalPerPage(listCampaignProduct.data.to);
        setTotalResult(listCampaignProduct.data.total);
      }
    } else if (typeProduct === "brand") {
      setColumns([
        {
          Header: () => null,
          id: "tableCampaignBrand",
          columns: [
            {
              Header: "Brand Name",
              accessor: "name",
              sortAble: true,
            },
            {
              Header: "Action",
              accessor: "action",
              Cell: ({ row }) => (
                <BtnAction
                  onClick={() => onDeleteItems(row.original.id)}
                  disabled={isLoadingDelete}
                >
                  Delete
                </BtnAction>
              ),
            },
          ],
        },
      ]);
      if (!listCampaignProduct.loading) {
        setMaxPageIndex(listCampaignProduct.data.last_page);
        let arrData = listCampaignProduct.data.data;
        let newData = arrData.map((item) => ({
          id: item.id,
          name: item.brand.name,
        }));
        setData(newData);
        setTotalPerPage(listCampaignProduct.data.to);
        setTotalResult(listCampaignProduct.data.total);
      }
    }
  }, [typeProduct, listCampaignProduct]);

  useEffect(() => {
    if (alertResponse && alertResponse.status === 200) {
      setIsLoadingDelete(false);
      //props.onRenderDataAfterDelete();
      dispatch(fetchProductCampaign(props.urlParam, 1));
    }
  }, [alertResponse]);

  useEffect(() => {
    return () => {
      setColumns([]);
    };
  }, []);

  const handleSearchInput = (e) => {
    setSearchInput(e.target.value);
  };

  const toggleAddProduct = () => {
    setShowAddProduct(!showAddProduct);
  };

  const onSubmitAddProduct = (data) => {
    let formData = new FormData();
    for (let i of data) {
      formData.append("item_id[]", i.id);
	  formData.append("campaign_id[]", urlParam.id);
    }
    dispatch(addProductCampaign(formData));
  };

  const onDeleteItems = (id) => {
    setIsLoadingDelete(true);
    dispatch(deleteItems(id));
  };

  const handlePageClick = (data) => {
	let page = data.selected + 1;
	dispatch(fetchProductCampaign(props.urlParam, page));
  };

  return (
    <Fragment>
      <Card className="p-0">
        <CardBody className="p-3">
          <div className="d-flex justify-content-between my-2">
            <div className="d-flex">
              <InputGroup style={{ maxWidth: "280px" }}>
                <Input
                  placeholder="Search"
                  value={searchInput}
                  onChange={(e) => handleSearchInput(e)}
                />
                <InputGroupAddon addonType="prepend">
                  <Button className="p-2" color="primary">
                    <img src={iconSearch} width={15} height={15} />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
              <EntryCounter
                totalPerPage={totalPerPage}
                totalResult={totalResult}
              />
            </div>
            <Button color="primary" onClick={toggleAddProduct}>
              Add Products
            </Button>
          </div>

          <ModalAdd
            showAddProduct={showAddProduct}
            toggleAddProduct={toggleAddProduct}
            typeProduct={typeProduct}
            onSubmitAddProduct={onSubmitAddProduct}
          />

          <Table {...getTableProps()} className="text-left">
            <thead>
              {headerGroups.map((headerGroup, index) =>
                index > 0 ? (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map((column) => (
                      <th
                        {...column.getHeaderProps(
                          column.getSortByToggleProps()
                        )}
                      >
                        {column.render("Header")}
                        {column.sortAble ? <img src={sortIcon} /> : ""}
                      </th>
                    ))}
                  </tr>
                ) : null
              )}
            </thead>

            <tbody {...getTableBodyProps()} className="text-left">
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </Table>

          <CustomPagination
            handlePageClick={handlePageClick}
            pageCount={maxPageIndex}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
          />
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default CampaignProduct;
