import React, { useEffect, useState, Fragment } from "react";
import { Button, UncontrolledAlert, CardBody } from "reactstrap";

import TableAddParticipant from "./tableParticipant";
import ModalAddParticipant from "./modalAddParticipant";

const AddParticipant = (props) => {
  const [showAddParticipant, setShowAddParticipant] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([
    {
      Header: "User search result",
      columns: [
        {
          Header: "ID",
          accessor: "id",
        },
        {
          Header: "Owner Name",
          accessor: "owner_name",
        },
        {
          Header: "Shop Name",
          accessor: "shop_name",
        },
        {
          Header: "City",
          accessor: "city",
        },
        {
          Header: "Phone Number",
          accessor: "phone_number",
        },
      ],
    },
  ]);

  const toggleAddParticipant = () => {
    setShowAddParticipant(!showAddParticipant);
  };

  const handleSubmitParticipant = (data) => {
    const merged = [...new Set([...data, ...tableData])];
    const uniqData = Array.from(
      merged.reduce((map, obj) => map.set(obj.id, obj), new Map()).values()
    );
	setTableData(uniqData)
	props.handleOnSubmitParticipant(uniqData);
  };

  return (
    <Fragment>
      <CardBody className="p-3">
        <Button onClick={toggleAddParticipant} color="primary" outline>
          + Add participant
        </Button>

        <ModalAddParticipant
          showAddParticipant={showAddParticipant}
          toggleAddParticipant={toggleAddParticipant}
          handleSubmitParticipant={handleSubmitParticipant}
        />

        {tableData.length > 0 ? (
          <TableAddParticipant data={tableData} columns={tableColumns} />
        ) : (
          ""
        )}
      </CardBody>
    </Fragment>
  );
};

export default AddParticipant;
