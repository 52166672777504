import { useState, Fragment, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

//component
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import Breadcrumb from "@layout/breadcrumb";
import ModalContent from "@components/modal/ModalContent";
import { ModalDialog } from "@components/modal/ModalDialog";

import { createPromo } from "@action/promoAction";

//css
import {
  wrapperInputThumbnail,
  styleInputThumbnail,
  styleInputbanner,
  btnUploadBanner,
  containerMutipleBanner,
  btnDeleteBanner,
  ImgButton,
} from "../../style/cssStyle";
import iconClose from "@assets/images/Close.svg";

const PromoAdd = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [formDetail, setFormDetail] = useState({
    title: "",
    start_date: "",
    end_date: "",
    order: "",
    banner: "",
    content: "",
    images: [],
  });
  const [multipleImageSrc, setMultipleImageSrc] = useState([]);
  const imageBannerID = useRef(0);
  const [modalState, setModalState] = useState(null);
  const [imgThumbnailSrc, setImgThumbnailSrc] = useState("");

  const handleSubmit = () => {
    if (!validateForm(formDetail)) {
      let formData = new FormData();
      formData.append("title", formDetail.title);
      formData.append("start_date", formDetail.start_date);
      formData.append("end_date", formDetail.end_date);
      formData.append("order", formDetail.order);
      formData.append("content", formDetail.content);
      if (formDetail.banner) formData.append("banner", formDetail.banner);
      if (multipleImageSrc.length > 0) {
        for (let x of multipleImageSrc) {
          formData.append("image[]", x.file);
        }
      }
	  
      dispatch(createPromo(formData)).then((resp) => {
        if (resp.status !== 200) return false;

		history.push("/dashboard/promo/basic");
      });
    }
  };

  const handleCancel = () => {
    history.push("/dashboard/promo/basic");
  };

  const validateForm = (data) => {
    if (
      data.title === "" ||
      data.start_date === "" ||
      data.end_date === "" ||
      data.order === "" ||
      data.content === ""
    ) {
      toast.error(`Please fill all fields.`);
      return true;
    }
    return false;
  };

  const isImage = (imgType) => {
    switch (imgType) {
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
        return true;
    }
    return false;
  };

  const showModal = (row) => {
    setModalState((prevState) => ({
      ...prevState,
      openModal: true,
      modalType: "remind",
      modalTitle: "Delete Banner",
      modalData: row,
      closeIcon: true,
    }));
  };

  const handleRemoveBanner = (item) => {
    const newDeleted = multipleImageSrc.filter((arr) => arr.id !== item.id);
    setMultipleImageSrc(newDeleted);
    setModalState(null);
  };

  const handleUploadMultipleImg = (e) => {
    if (e.target.files.length > 0) {
      const filesImg = e.target.files[0];
      if (filesImg.size > 2000000) {
        toast.error(`Max file size is 2MB`);
        document.getElementById("selectBanner").value = "";
      } else {
        if (!isImage(filesImg.type)) {
          toast.error(`Select appropiate image file.`);
          document.getElementById("selectBanner").value = "";
        } else {
          const imgSrc = URL.createObjectURL(filesImg);
          const objImage = {
            id: imageBannerID.current,
            src: imgSrc,
            file: filesImg,
          };
          setMultipleImageSrc((multipleImageSrc) => [
            ...multipleImageSrc,
            objImage,
          ]);
          imageBannerID.current = imageBannerID.current + 1;
        }
      }
    }
  };

  const handleOnChangeInputThumbnail = (e) => {
    if (e.target.files.length > 0) {
      const filesImg = e.target.files[0];
      if (filesImg.size > 2000000) {
        toast.error(`Max file size is 2MB`);
        document.getElementById("selectThumbnail").value = "";
      } else {
        if (!isImage(filesImg.type)) {
          toast.error(`Select appropiate image file.`);
          document.getElementById("selectThumbnail").value = "";
        } else {
          const imgSrc = URL.createObjectURL(filesImg);
          setImgThumbnailSrc(imgSrc);
          setFormDetail({ ...formDetail, banner: filesImg });
        }
      }
    }
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Basic"
        title="Add New Promo"
        urlParent={"/dashboard/promo/basic"}
      />
      <Container fluid>
        <Row>
          <Col sm={12} md={12} lg={12} xl={12}>
            <Card>
              <CardBody className="p-3">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FormGroup row>
                    <Label for="formTitle" sm={12}>
                      <b>
                        Title <sup style={{ color: "red" }}>*</sup>
                      </b>
                    </Label>
                    <Col sm={8}>
                      <Input
                        placeholder="Enter Title"
                        id="formTitle"
                        value={formDetail.title}
                        onChange={(e) =>
                          setFormDetail({
                            ...formDetail,
                            title: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="selectStartDate" sm={12}>
                      <b>
                        Start Date <sup style={{ color: "red" }}>*</sup>
                      </b>
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="date"
                        name="selectStartDate"
                        id="selectStartDate"
                        placeholder="Start Date"
                        max="9999-12-31"
                        min="1700-12-31"
                        value={formDetail.start_date}
                        onChange={(e) =>
                          setFormDetail({
                            ...formDetail,
                            start_date: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="selectEndDate" sm={12}>
                      <b>
                        End Date <sup style={{ color: "red" }}>*</sup>
                      </b>
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="date"
                        name="selectEndDate"
                        id="selectEndDate"
                        placeholder="End Date"
                        max="9999-12-31"
                        min="1700-12-31"
                        value={formDetail.end_date}
                        onChange={(e) =>
                          setFormDetail({
                            ...formDetail,
                            end_date: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="formOrder" sm={12}>
                      <b>
                        Order <sup style={{ color: "red" }}>*</sup>
                      </b>
                    </Label>
                    <Col sm={8}>
                      <Input
                        placeholder="Enter Order"
                        id="formOrder"
                        type="number"
                        value={formDetail.order}
                        onChange={(e) =>
                          setFormDetail({
                            ...formDetail,
                            order: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup row style={{ maxWidth: "25%" }}>
                    <Label for="selectThumbnail" sm={12}>
                      <b>Foto Banner</b>
                    </Label>
                    <Col sm={12}>
                      <div style={wrapperInputThumbnail}>
                        <button className="p-0" style={btnUploadBanner}>
                          <ImgButton
                            className="mx-auto"
                            src={imgThumbnailSrc}
                            width={240}
                            height={180}
                          />
                        </button>
                      </div>

                      <Input
                        type="file"
                        name="selectThumbnail"
                        id="selectThumbnail"
                        accept=".png, .jpg, .jpeg"
                        onChange={handleOnChangeInputThumbnail}
                        style={styleInputThumbnail}
                      />
                      <p className="my-1" style={{ fontSize: "12px" }}>
                        Format file jpeg, jpg, png
                      </p>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="selectBanner" sm={12}>
                      <b>Foto Content</b>
                    </Label>
                    <Col sm={12}>
                      <Row>
                        <Col sm={4} style={wrapperInputThumbnail}>
                          <button style={btnUploadBanner}>Tambah Gambar</button>
                          <Input
                            type="file"
                            name="selectBanner"
                            id="selectBanner"
                            accept=".png, .jpg, .jpeg"
                            onChange={handleUploadMultipleImg}
                            style={styleInputbanner}
                          />
                        </Col>
                        <Col
                          className="py-2"
                          sm={8}
                          style={containerMutipleBanner}
                        >
                          {multipleImageSrc.length > 0
                            ? multipleImageSrc.map((data, i) => (
                                <div
                                  key={i}
                                  className="mx-2"
                                  style={{
                                    position: "relative",
                                    maxWidth: "240px",
                                    maxHeight: "180px",
                                    display: "inline-block",
                                  }}
                                >
                                  <img
                                    src={iconClose}
                                    onClick={() => showModal(data)}
                                    style={btnDeleteBanner}
                                  />
                                  <img
                                    src={data.src}
                                    style={{ height: "100%", width: "100%" }}
                                  ></img>
                                </div>
                              ))
                            : ""}
                        </Col>
                      </Row>
                      <span className="my-1" style={{ fontSize: "12px" }}>
                        Format file jpeg, jpg, png
                      </span>
                    </Col>
                  </FormGroup>

                  <FormGroup row>
                    <Label for="exampleText" sm={12}>
                      <b>
                        Content <sup style={{ color: "red" }}>*</sup>
                      </b>
                    </Label>
                    <Col sm={8}>
                      <Input
                        type="textarea"
                        name="text"
                        id="exampleText"
                        placeholder="Write content here"
                        value={formDetail.content}
                        onChange={(e) =>
                          setFormDetail({
                            ...formDetail,
                            content: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup check row className="my-3">
                    <div className="d-flex justify-content-end">
                      <Button
                        onClick={handleCancel}
                        className="mx-1"
                        color="primary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        className="mx-1"
                        color="primary"
                        onClick={handleSubmit}
                      >
                        Submit
                      </Button>
                    </div>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <Fragment>
          <ModalDialog
            open={modalState?.openModal ?? false}
            type={modalState?.modalType ?? ""}
            footer={false}
            title={modalState?.modalTitle ?? ""}
            titleFontSize={18}
            backdropClose={true}
            onClose={() => {
              setModalState(null);
            }}
            maxWidth={384}
          >
            <ModalContent
              onClose={() => {
                setModalState(null);
              }}
              onSubmit={() => {
                handleRemoveBanner(modalState?.modalData);
                // handleDelete(modalState?.modalData);
              }}
              data={modalState?.modalData ?? null}
              description={`Are you sure you want to delete this banner?`}
              descClose={"Cancel"}
              descSubmit={"Delete"}
            />
          </ModalDialog>
        </Fragment>
      </Container>
    </Fragment>
  );
};

export default PromoAdd;
