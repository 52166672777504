import React, { Fragment, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Table,
  Progress,
} from "reactstrap";
import { useTable, useSortBy } from "react-table";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ModalAddParticipant from "../modalAddParticipant";
import EntryCounter from "../../../../../../Component/entryCounter";
import {
  addParticipantCampaign,
  pushNotificationCampaign,
  fetchParticipantCampaign,
} from "../../../../../../redux/action/campaignAction";

import iconSearch from "../../../../../../assets/images/input-search.png";
import sortIcon from "../../../../../../assets/images/sort-icon.svg";

const BtnAction = styled.button`
  color: black;
  font-size: 14px;
  border: 1px solid #d2d2d4;
  border-radius: 5px;
  cursor: pointer;
  background: white;
  padding: 4px 8px;
  font-weight: 500;
`;

const CampaignParticipant = (props) => {
  const urlParam = useParams();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(1);
  const [columns, setColumns] = useState([
    {
      Header: () => null,
      id: "tableCampaignParticipant",
      columns: [
        {
          Header: "Shop Name",
          accessor: "shop_name",
          minWidth: 100,
          sortAble: true,
        },
        {
          Header: "Progress",
          accessor: "progress",
          Cell: ({ row }) => (
            <div className="d-flex justify-content-between">
              <Progress
                className="mx-2"
                value={row.original.progress}
                max={100}
                style={{ width: "100%" }}
              />{" "}
              <span className="mx-2">{row.original.progress}%</span>
            </div>
          ),
          minWidth: 180,
          sortAble: true,
        },
        {
          Header: "Action",
          accessor: "action",
          minWidth: 40,
          Cell: ({ row }) => (
            <BtnAction
              onClick={() => handlePushNotif("participant", row.original.id)}
            >
              Push Notification
            </BtnAction>
          ),
        },
      ],
    },
  ]);
  const [data, setData] = useState([]);
  const [showAddParticipant, setShowAddParticipant] = useState(false);
  const [totalResult, setTotalResult] = useState(0);
  const [totalPerPage, setTotalPerPage] = useState(0);

  const { listCampaignParticipant } = useSelector(
    (state) => state.CampaignReducer
  );

  const tableInstance = useTable({ columns, data }, useSortBy);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  useEffect(() => {
    if (props.urlParam !== "") {
      dispatch(fetchParticipantCampaign(props.urlParam, 1));
    }
  }, []);

  //hook for when list participant updated
  useEffect(() => {
    if (listCampaignParticipant.data && !listCampaignParticipant.loading) {
      let newData = listCampaignParticipant.data.map((item) => ({
        id: item.user_id,
        shop_name: item.user.shop_name,
        owner_name: item.user.owner_name,
        progress: item.participant_progress,
      }));
      setData(newData);
      setTotalPerPage(listCampaignParticipant.data.length);
      setTotalResult(listCampaignParticipant.total);
    }
  }, [listCampaignParticipant]);

  const toggleAddParticipant = () => {
    setShowAddParticipant(!showAddParticipant);
  };

  const onSubmitAddParticipant = (data) => {
    let formData = new FormData();
    for (let i of data) {
      formData.append("user_id[]", i.id);
    }
    formData.append("campaign_id[]", urlParam.id);
    dispatch(addParticipantCampaign(formData));
  };

  const handlePushNotif = (type, userId) => {
    if (type === "all") {
      const dataPush = {
        campaign_id: urlParam.id,
        type: type,
      };
      dispatch(pushNotificationCampaign(dataPush));
    } else {
      const dataPush = {
        campaign_id: urlParam.id,
        type: type,
        user_id: userId,
      };
      dispatch(pushNotificationCampaign(dataPush));
    }
  };

  return (
    <Fragment>
      <div>
        <div className="d-flex justify-content-between my-2">
          <div className="d-flex">
            <InputGroup style={{ maxWidth: "280px" }}>
              <Input placeholder="Search" />
              <InputGroupAddon addonType="prepend">
                <Button className="p-2" color="primary">
                  <img src={iconSearch} width={15} height={15} />
                </Button>
              </InputGroupAddon>
            </InputGroup>

            <EntryCounter
              totalPerPage={totalPerPage}
              totalResult={totalResult}
            />
          </div>
          <div>
            <Button
              onClick={() => handlePushNotif("all")}
              color="primary"
              outline
            >
              Push Notifications
            </Button>
            <Button
              onClick={toggleAddParticipant}
              className="mx-2"
              color="primary"
            >
              Add Participant
            </Button>
          </div>
        </div>

        <ModalAddParticipant
          showAddParticipant={showAddParticipant}
          toggleAddParticipant={toggleAddParticipant}
          onSubmitAddParticipant={onSubmitAddParticipant}
        />

        <Table {...getTableProps()} className="text-left">
          <thead>
            {headerGroups.map((headerGroup, index) =>
              index > 0 ? (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      {column.render("Header")}
                      {column.sortAble ? <img src={sortIcon} /> : ""}
                    </th>
                  ))}
                </tr>
              ) : null
            )}
          </thead>

          <tbody {...getTableBodyProps()} className="text-left">
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          style: { width: cell.column.minWidth },
                        })}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
    </Fragment>
  );
};

export default CampaignParticipant;
