import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

import TableCSI from "@components/table";
import Breadcrumbs from "@layout/breadcrumb";
import { Nav, NavItem, NavLink, TabPane, TabContent, Button } from "reactstrap";
import TableLayout from "@layout/tableLayout";

import {
  getListPromo,
  deleteRowPromo,
  updatePromoStatus,
} from "@action/promoAction";

import BadgeStatus from "@components/badgeStatus";
import TableDropdown from "@components/customDropdown";

const BasicPromo = () => {
  const [dataPromoActive, setDataPromoActive] = useState([]);
  const [dataPromoNonActive, setDataPromoNonActive] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [isFetchedData, setIsFetchedData] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [totalEntry, setTotalEntry] = useState(0);
  const [totalEntryPerPage, setTotalEntryPerPage] = useState(0);

  const { listPromo } = useSelector((state) => state.PromoReducer);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchTable();
  }, []);

  useEffect(() => {
    if (listPromo.data && !listPromo.loading) {
      setInitialSortBy([
        {
          id: "title",
          desc: false,
        },
      ]);

      const resp = listPromo.data.data;
      const resDat = resp.data;
      if (resp === undefined || resp === null) return false;

      let mappedList = resDat.map((items) => ({
        id: items.id,
        ordering: items.order,
        title: items.title,
        product: items.product ? items.product : "-",
        start_date: items.start_date !== null ? items.start_date : "-",
        end_date: items.end_date !== null ? items.end_date : "-",
        status: items.status,
      }));
      const activeObjects = mappedList.filter((item) => item.status === 1);
      const nonactiveObjects = mappedList.filter((item) => item.status === 0);

      setDataPromoActive(activeObjects);
      setDataPromoNonActive(nonactiveObjects);
      setTotalEntry(parseFloat(resp.total));
      setTotalEntryPerPage(parseFloat(resp.per_page));
      setMaxPageIndex(resp.last_page);
      setIsFetchedData(true);
    }
  }, [listPromo]);

  const fetchTable = () => {
    dispatch(getListPromo(1, ""));
    setTableColumn([
      {
        Header: () => null,
        id: "promo",
        columns: [
          {
            Header: "ID",
            accessor: "id",
            sortAble: true,
          },
          {
            Header: "Order",
            accessor: "ordering",
            sortAble: true,
          },
          {
            Header: "Title",
            accessor: "title",
            sortAble: true,
          },
          {
            Header: "Products",
            accessor: "product",
            sortAble: true,
          },
          {
            Header: "Start Date",
            accessor: "start_date",
            sortAble: true,
            Cell: ({ row }) => (
              <span>
                {row.original.start_date !== "-"
                  ? moment.utc(row.original.start_date).format("DD/MM/YYYY")
                  : "-"}
              </span>
            ),
            sortType: (rowA, rowB, columnId, desc) => {
              let startDate = Date.parse(rowA.values[columnId]);
              let endDate = Date.parse(rowB.values[columnId]);
              if (startDate > endDate) return 1;
              if (startDate < endDate) return -1;
              return 0;
            },
          },
          {
            Header: "End Date",
            accessor: "end_date",
            sortAble: true,
            Cell: ({ row }) => (
              <span>
                {row.original.end_date !== "-"
                  ? moment.utc(row.original.end_date).format("DD/MM/YYYY")
                  : "-"}
              </span>
            ),
            sortType: (rowA, rowB, columnId, desc) => {
              let startDate = Date.parse(rowA.values[columnId]);
              let endDate = Date.parse(rowB.values[columnId]);
              if (startDate > endDate) return 1;
              if (startDate < endDate) return -1;
              return 0;
            },
          },
          {
            Header: "Status",
            accessor: "active",
            sortAble: true,
            Cell: ({ row }) => <BadgeStatus status={row.original.status} />,
            sortType: (rowA, rowB) => {
              let val1 = parseInt(rowA.original.status);
              let val2 = parseInt(rowB.original.status);
              if (val1 > val2) return 1;
              if (val1 < val2) return -1;
              return 0;
            },
          },
          {
            Header: "Action",
            accessor: "action",
            Cell: ({ row }) => (
              <TableDropdown
                dropdownContent={[
                  {
                    label: "Edit",
                    type: "link",
                    link: `/dashboard/promo/basic/edit/${row.original.id}`,
                  },
                  {
                    label: "Details",
                    type: "link",
                    link: `/dashboard/promo/basic/details/${row.original.id}`,
                  },
                  {
                    label: "Products",
                    type: "link",
                    link: `/dashboard/promo/basic/products/${row.original.id}`,
                  },
                  {
                    label: "Delete",
                    type: "delete",
                  },
                ]}
                onToggleStatus={onToggleStatus}
                row={row.original}
                onDeleteAction={onDeleteCampaign}
              />
            ),
            disableSortBy: true,
          },
        ],
      },
    ]);
  };

  const handleSubmitSearch = (input) => {
    dispatch(getListPromo(1, input));
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListPromo(page, ""));
  };

  const onDeleteCampaign = (id) => {
    dispatch(deleteRowPromo(id)).then((resp) => {
      if (resp.status !== 200) return false;

      fetchTable();
    });
  };

  const onToggleStatus = ({ id }) => {
    dispatch(updatePromoStatus(id)).then((resp) => {
      if (resp.status !== 200) return false;

      fetchTable();

      toast.success("Update Success");
    });
  };

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const headerAction = (
    <Link to={`/dashboard/promo/basic/add`}>
      <Button className="shadow-sm border-0" color="primary">
        Add new Promo
      </Button>
    </Link>
  );

  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <Breadcrumbs
          parent="Promotion"
          title="Basic Promo"
          urlParent={"/dashboard/promo"}
        />
      </div>

      <TableLayout
        headerAction={headerAction}
        handleSearch={handleSubmitSearch}
        totalEntry={totalEntry}
        totalEntryPerPage={totalEntryPerPage}
        pageCount={maxPageIndex}
        handleChangePage={handlePageClick}
        handleChangeRow={(val) => dispatch(getListPromo(1, "", val))}
      >
        <Nav tabs>
          <NavItem>
            <NavLink
              className={`${activeTab === 0 ? "active" : ""}`}
              href="#"
              onClick={() => toggleTab(0)}
              style={{ cursor: "Pointer" }}
            >
              Active
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={`${activeTab === 1 ? "active" : ""}`}
              href="#"
              onClick={() => toggleTab(1)}
              style={{ cursor: "Pointer" }}
            >
              Non Active
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={`${activeTab}`}>
          <TabPane tabId="0">
            {isFetchedData && (
              <TableCSI
                data={dataPromoActive}
                columns={tableColumn}
                key={dataPromoActive}
                initialSortBy={initialSortBy}
              />
            )}
          </TabPane>
          <TabPane tabId="1">
            {isFetchedData && (
              <TableCSI
                data={dataPromoNonActive}
                columns={tableColumn}
                key={dataPromoNonActive}
                initialSortBy={initialSortBy}
              />
            )}
          </TabPane>
        </TabContent>
      </TableLayout>
    </Fragment>
  );
};

export default BasicPromo;
