import Campaign from "../../pages/dashboard/master/campaign";
import CampaignAdd from "../../pages/dashboard/master/campaign/add";
import CampaignEdit from "../../pages/dashboard/master/campaign/edit";
import CampaignDetail from "../../pages/dashboard/master/campaign/detail";

export const RouteCampaign = [
  {
    path: `/dashboard/campaign`,
    Component: Campaign,
  },
  {
    path: `/dashboard/campaign/add`,
    Component: CampaignAdd,
  },
  {
    path: `/dashboard/campaign/edit/:id`,
    Component: CampaignEdit,
  },
  {
    path: `/dashboard/campaign/detail/:id`,
    Component: CampaignDetail,
  },
];
