import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import {useHistory, useLocation, useParams} from "react-router-dom";
import iconSearch from "../../../../../assets/images/input-search.png";
import {useDispatch, useSelector} from "react-redux";
import {
  addDataSectionItem,
  deleteDataSectionItem,
  getListSectionItem, orderingDataSectionItem
} from "../../../../../redux/action/sectionAction";
import TableCSI from "../../../../../Component/table";
import PaginationRow from "../../../../../Component/paginationDataPerPage";
import CustomPagination from "../../../../../Component/customPagination";
import ModalContent from "../../../../../Component/modal/ModalContent";
import {ModalDialog} from "../../../../../Component/modal/ModalDialog";
import {toast} from "react-toastify";

const SectionItems = (props) => {
  const [tableColumn, setTableColumn] = useState([]);
  const [isFetchedData, setIsFetchedData] = useState(true);
  const [isAfterSearch, setIsAfterSearch] = useState(false);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [totalResult, setTotalResult] = useState(4);
  const [perPage, setPerPage] = useState(10);
  const [totalPerPage, setTotalPerPage] = useState(1);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [modalState, setModalState] = useState(null);
  const [modalItems, setModalItems] = useState(false);
  const [data, setData] = useState([]);
  const [payload, setPayload] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const urlParams = useParams();
  const dispatch = useDispatch();
  const {listSectionItem} = useSelector(
      (state) => state.SectionReducer
  );

  useEffect(() => {
    dispatch(getListSectionItem(10, 1, inputSearchValue, urlParams?.id))
  }, [])

  useEffect(() => {
    if (listSectionItem.data && !listSectionItem.loading) {
      let dataSectionItem = listSectionItem?.data?.data;
      let mappedListData = dataSectionItem.map((item) => ({
        id: item?.id,
        name: item?.name,
        ordering: item?.ordering,
        sku_code: item?.sku_code,
        category_id: item?.category_id,
        brand_id: item?.brand_id,
      }));
      setData(mappedListData);
      setMaxPageIndex(listSectionItem?.data?.last_page);
      setTotalResult(listSectionItem?.data?.total);
      setTotalPerPage(listSectionItem?.data?.to);
      setIsFetchedData(true);
      setTimeout(() => {
        setTableColumn([
              {
                Header: () => null,
                id: "Section Items",
                columns: [
                  {
                    Header: "ID",
                    accessor: "id",
                  },
                  {
                    Header: `${location?.data?.type === "product" ? "SKU Code" : location?.data?.type === "category" ? "Category ID" : "Brand ID"}`,
                    accessor: `${location?.data?.type === "product" ? "sku_code" : location?.data?.type === "category" ? "category_id" : "brand_id"}`,
                  },
                  {
                    Header: "Product Name",
                    accessor: "name",
                  },
                  {
                    Header: "Ordering",
                    accessor: "ordering",
                    Cell: ({row}) => {
                      let rawData = listSectionItem?.data?.data;
                      let datas = data?.length > 0 ? data : rawData;
                      return (
                          <div className="d-flex">
                            <Input placeholder={rawData[row?.index]?.ordering} id="formOrdering"
                                   type={"number"}
                                   onChange={(event) => {
                                     datas[row?.index] = {...datas[row?.index], ordering: event.target.value === "" ? rawData[row?.index]?.ordering : Number(event.target.value)};
                                     setData(datas);
                                   }} />
                          </div>
                      )
                    },
                  },
                  {
                    Header: "Action",
                    accessor: "action",
                    Cell: ({row}) => (
                        <div className="d-flex">
                          <Button color="secondary" type={"button"} outline onClick={() => showModal(row?.original, "selected")}>
                            Delete
                          </Button>
                        </div>
                    ),
                  },
                ],
              },
            ],
            []
        );
      }, 500);
    } else setData([]);
  }, [listSectionItem]);

  const handleOrder = () => {
    const uniqueValues = new Set(data.map(v => v.ordering));
    const checkValue = data?.filter(v => v?.ordering > data.length || v?.ordering === 0);

    if (checkValue?.length > 0) return toast.error(`Order tidak boleh kurang dari 0 atau lebih dari jumlah data`);

    if (uniqueValues.size < data.length) {
      toast.error(`Order tidak boleh sama!`);
    } else {
      const dataFormOrder = new FormData();
      data?.map(item => dataFormOrder.append("id_items[]", item?.id))
      data?.map(item => dataFormOrder.append("ordering[]", item?.ordering))
      dispatch(orderingDataSectionItem(dataFormOrder))
      history.push(`/section/${urlParams?.id}/items`);
    }
  }

  const handleSubmitSearch = () => {
    if (inputSearchValue !== "") {
      dispatch(getListSectionItem(perPage, 1, inputSearchValue, urlParams?.id));
      setIsAfterSearch(true);
    }
  };

  const handleInputSearch = (e) => {
    setInputSearchValue(e.target.value);
  };

  const handleOnEnter = (e) => {
    // if (inputSearchValue !== "") {
    dispatch(getListSectionItem(perPage, 1, inputSearchValue, urlParams?.id));
    setIsAfterSearch(true);
    // }
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListSectionItem(perPage, page, inputSearchValue, urlParams?.id));
    setIsAfterSearch(true);
  };

  const onChangePaginationRow =(perPage) => {
    setIsFetchedData(false);
    setPerPage(perPage);
    dispatch(getListSectionItem(perPage, 1, inputSearchValue, urlParams?.id));
  }

  const showModal = (row, type) => {
    setModalState((prevState) => ({
      ...prevState,
      openModal: true,
      modalType: type,
      modalTitle: "Informasi",
      modalData: row,
      closeIcon: true,
    }));
  };

  const handleDelete = (data, type) => {
    setIsFetchedData(true);
    dispatch(deleteDataSectionItem(data?.id, type));
    setIsFetchedData(false);
    setModalState(null);
    history.push(`/section/${data?.id}/items`);
  }

  const toggleItemsAdd = () => {
    const dataForm = new FormData();
    dataForm.append("id", urlParams?.id);
    dataForm.append("items[]", payload);
    dispatch(addDataSectionItem(dataForm))
    setTimeout(() => {
      setDisableSubmit(false);
      setModalItems(!modalItems);
      history.push(`/section/${urlParams?.id}/items`);
    }, 500);
  }

  return (
    <Fragment>
      <Breadcrumb parent="Section" title="Section Items" urlParent={'/dashboard/section'} />
      <Container fluid={true}>
        <Row>
          <Col>
            <Card className="p-0">
              <CardBody className="p-3">
                <div className="d-flex justify-content-between my-2">
                  <InputGroup style={{ maxWidth: "280px" }}>
                    <Input
                        placeholder="Search"
                        value={inputSearchValue}
                        onChange={(e) => handleInputSearch(e)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") handleOnEnter(e);
                        }}
                    />
                    <InputGroupAddon addonType="prepend">
                      <Button className="p-2" color="primary" onClick={() => handleSubmitSearch()}>
                        <img src={iconSearch} width={15} height={15} alt={""} />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  <div>
                    <Button outline color="primary" onClick={() => setModalItems(!modalItems)}>
                      Add Items
                    </Button>
                    <Modal isOpen={modalItems} toggle={() => setModalItems(!modalItems)}>
                      <ModalHeader toggle={() => setModalItems(!modalItems)}>
                        Add Item
                      </ModalHeader>
                      <ModalBody>
                        <Form>
                          <FormGroup>
                            <Label for="formBrandName">Product SKU Code</Label>
                            <Input
                              placeholder="Enter Product SKU Code"
                              id="formBrandName"
                              onChange={(event) => setPayload(event.target.value)}
                            />
                          </FormGroup>
                        </Form>
                      </ModalBody>
                      <ModalFooter>
                        <Button color="primary" type={"button"} disabled={disableSubmit} onClick={() => toggleItemsAdd()}>
                          Do Something
                        </Button>{" "}
                        <Button color="secondary" onClick={() => setModalItems(!modalItems)}>
                          Cancel
                        </Button>
                      </ModalFooter>
                    </Modal>
                    <Button className="mx-1" color="primary" onClick={() => handleOrder()}>
                      Save Ordering
                    </Button>
                    <Button className="mx-1" color="secondary" onClick={() => showModal(urlParams, "all")} type={"button"}>
                      Delete All
                    </Button>
                  </div>
                </div>
                {isFetchedData ?
                    <TableCSI
                        data={data}
                        columns={tableColumn}
                        key={data}
                        initialSortBy={initialSortBy}
                    /> : null}

                <div className="d-flex justify-content-between">
                  <PaginationRow onChangePaginationRow={onChangePaginationRow} />
                  <CustomPagination
                      handlePageClick={handlePageClick}
                      pageCount={maxPageIndex}
                      pageRangeDisplayed={2}
                      marginPagesDisplayed={2}
                  />
                </div>
              </CardBody>
              <ModalDialog
                  open={modalState?.openModal ?? false}
                  type={modalState?.modalType ?? ""}
                  footer={false}
                  title={modalState?.modalTitle ?? ""}
                  titleFontSize={18}
                  backdropClose={true}
                  onClose={() => {
                    setModalState(null);
                  }}
                  maxWidth={384}
              >
                <ModalContent
                    onClose={() => {
                      setModalState(null);
                    }}
                    onSubmit={() => handleDelete(modalState?.modalData, modalState?.modalType)}
                    data={modalState?.modalData ?? null}
                    descClose={"Batal"}
                    descSubmit={`Hapus${modalState?.modalType === "all" ? " Semua" : ""}`}
                    description={`Hapus ${modalState?.modalType === "all" ? "semua data" : modalState?.modalData?.name}?`}
                />
              </ModalDialog>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SectionItems;
