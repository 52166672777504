import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

import iconSearch from "../../../../assets/images/input-search.png";
import sortIcon from "../../../../assets/images/sort-icon.svg";
import TableCSI from "../../../../Component/table";
import CustomPagination from "../../../../Component/customPagination";
import EntryCounter from "../../../../Component/entryCounter";
import {
  exportCSVMutasi,
  getListMutasi,
  syncMutasi,
} from "../../../../redux/action/mutasiAction";
import ButtonAction from "../../../../Component/styledComponents/btnTableAction";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PaginationRow from "../../../../Component/paginationDataPerPage";

const MutasiLayout = (props) => {
  const [data, setData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [isFetchedData, setIsFetchedData] = useState(true);
  const [isAfterSearch, setIsAfterSearch] = useState(false);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [totalResult, setTotalResult] = useState(4);
  const [perPage, setPerPage] = useState(10);
  const [totalPerPage, setTotalPerPage] = useState(1);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [inputDateValue, setInputDateValue] = useState("");
  const [csvData, setCSVData] = useState([]);
  const dispatch = useDispatch();
  const { listMutasi, syncListMutasi } = useSelector(
    (state) => state.MutasiReducer
  );

  useEffect(() => {
    dispatch(getListMutasi(1, inputSearchValue, perPage, inputDateValue));
    setTableColumn(
      [
        {
          Header: () => null,
          id: "MutasiBca",
          columns: [
            {
              Header: "Customer",
              accessor: "customer_name",
              sortAble: true,
            },
            {
              Header: "Nomor VA",
              accessor: "virtual_account",
              sortAble: true,
            },
            {
              Header: "Action",
              accessor: "action",
              Cell: ({ row }) => (
                <div className="d-flex justify-content-between">
                  <ButtonAction className="mx-1">
                    <Link
                      to={`/dashboard/mutasi-bca/detail/${row.original.virtual_account_id}`}
                    >
                      Detail
                    </Link>
                  </ButtonAction>
                </div>
              ),
            },
          ],
        },
      ],
      []
    );
  }, []);

  useEffect(() => {
    if (listMutasi.data && !listMutasi.loading) {
      let dataMutasi = listMutasi.data.data;
	  if (dataMutasi === undefined || dataMutasi === null) return false;

      let mappedListData = dataMutasi?.map((item) => ({
        id: item?.id,
        customer_name: item?.users ? item?.users?.shop_name : "-",
        virtual_account: item?.virtual_account
          ? item?.virtual_account?.virtual_account
          : "-",
        virtual_account_id: item?.virtual_account_id
          ? item?.virtual_account_id
          : "-",
      }));
      setData(mappedListData);
      setMaxPageIndex(listMutasi.data.last_page);
      setTotalResult(listMutasi.data.total);
      setTotalPerPage(listMutasi.data.to);
      setCSVData(mappedListData);
      setIsFetchedData(true);
    }
  }, [listMutasi]);

  const handleSync = () => {
    dispatch(syncMutasi());
    setInputSearchValue("");
  };

  const handleExportCSV = () => {
    dispatch(exportCSVMutasi());
    setInputSearchValue("");
  };

  useEffect(() => {
    if (syncListMutasi.data === undefined && !syncListMutasi.loading) {
      dispatch(getListMutasi(1, inputSearchValue, perPage, inputDateValue));
    }
  }, [syncListMutasi]);

  const handleSubmitSearch = () => {
    if (inputSearchValue !== "") {
      dispatch(getListMutasi(1, inputSearchValue, perPage, inputDateValue));
      setIsAfterSearch(true);
    }
  };

  const handleInputSearch = (e) => {
    setInputSearchValue(e.target.value);
  };

  const handleOnEnter = (e) => {
    // if (inputSearchValue !== "") {
    dispatch(getListMutasi(1, inputSearchValue, perPage, inputDateValue));
    setIsAfterSearch(true);
    // }
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListMutasi(page, inputSearchValue, perPage, inputDateValue));
    setIsAfterSearch(true);
  };

  const onChangePaginationRow = (perPage) => {
    setIsFetchedData(false);
    setPerPage(perPage);
    dispatch(getListMutasi(1, inputSearchValue, perPage, inputDateValue));
  };

  const handleInputDate = (event) => {
    setInputDateValue(event.target.value);
    dispatch(getListMutasi(1, inputSearchValue, perPage, event.target.value));
  };

  return (
    <Fragment>
      <Container fluid={true}>
        <Row>
          <Col>
            <Card className="p-0">
              <CardBody className="p-3">
                <div className="d-flex justify-content-between my-2">
                  <div className="d-flex">
                    <InputGroup style={{ maxWidth: "280px" }}>
                      <Input
                        type="date"
                        name="tanggal"
                        id="selectTanggal"
                        onChange={(e) => handleInputDate(e)}
                      />
                      {/*<InputGroupAddon addonType="prepend">*/}
                      {/*    <Button className="p-2" color="primary">*/}
                      {/*        <img src={iconSearch} width={15} height={15} />*/}
                      {/*    </Button>*/}
                      {/*</InputGroupAddon>*/}
                    </InputGroup>
                    <InputGroup
                      style={{ maxWidth: "280px" }}
                      className={"mx-1"}
                    >
                      <Input
                        placeholder="Search"
                        value={inputSearchValue}
                        onChange={(e) => handleInputSearch(e)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") handleOnEnter(e);
                        }}
                      />
                      <InputGroupAddon addonType="prepend">
                        <Button
                          className="p-2"
                          color="primary"
                          onClick={() => handleSubmitSearch()}
                        >
                          <img src={iconSearch} width={15} height={15} />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                    <InputGroup
                      style={{ maxWidth: "180px" }}
                      className={"mx-1"}
                    >
                      <EntryCounter
                        totalPerPage={totalPerPage}
                        totalResult={totalResult}
                      />
                    </InputGroup>
                  </div>
                  <div className="d-flex">
                    <Button
                      color="primary"
                      outline
                      onClick={() => handleSync()}
                    >
                      Sync
                    </Button>
                    <Button
                      color="primary"
                      outline
                      className={"mx-1"}
                      onClick={() => handleExportCSV()}
                    >
                      Export as CSV
                    </Button>
                    {/*<div className="mx-1 dropdown-basic">*/}
                    {/*    <Dropdown className="dropdown">*/}
                    {/*        <Button color="white" className="dropbtn">*/}
                    {/*            ...*/}
                    {/*        </Button>*/}
                    {/*        <DropdownMenu className="dropdown-content">*/}
                    {/*            <DropdownItem>Bulk Action</DropdownItem>*/}
                    {/*            <DropdownItem>Mass Upload Max Qty</DropdownItem>*/}
                    {/*            <DropdownItem>Download Product</DropdownItem>*/}
                    {/*            <DropdownItem>Mass Upload Product</DropdownItem>*/}
                    {/*            <DropdownItem>Download Product Blocked</DropdownItem>*/}
                    {/*            <DropdownItem>Sync Product</DropdownItem>*/}
                    {/*        </DropdownMenu>*/}
                    {/*    </Dropdown>*/}
                    {/*</div>*/}
                  </div>
                </div>
                {isFetchedData ? (
                  <TableCSI
                    data={data}
                    columns={tableColumn}
                    key={data}
                    initialSortBy={initialSortBy}
                  />
                ) : null}

                <div className="d-flex justify-content-between">
                  <PaginationRow
                    onChangePaginationRow={onChangePaginationRow}
                  />
                  <CustomPagination
                    handlePageClick={handlePageClick}
                    pageCount={maxPageIndex}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default MutasiLayout;
