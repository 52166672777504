import apiConfig from "../../api";
import {
  DOWNLOAD_USER,
  GET_LIST_USER,
  GET_OTP_LOG,
  UPDATE_USER,
  GET_SYNC_USER,
  CHANGE_STATUS_USER,
  RESET_PASSWORD_USER,
  WATCH_RESPONSE_ALERT,
  GET_UPDATE_INFO,
  PROCESS_UPDATE_INFO,
} from "../actionTypes";

export const getListUser = (page, param, field, perPage) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_USER,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(
        `/api/admin/users?page=${page}&search=${param}&field=${field}&perPage=${
          perPage ? perPage : 10
        }`
      )
      .then((resp) => {
        dispatch({
          type: GET_LIST_USER,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LIST_USER,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const getOtpLog = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_OTP_LOG,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/users/otp-log/${id}`)
      .then((resp) => {
        dispatch({
          type: GET_OTP_LOG,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_OTP_LOG,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const updateDataUser = (id, payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.put(`/api/admin/users/${id}`, payload);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: true,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });

      return err;
    }
  };
};

export const downloadUser = () => {
  return (dispatch) => {
    dispatch({
      type: DOWNLOAD_USER,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/users/download`, { responseType: "blob" })
      .then((resp) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(resp.data);
        link.setAttribute("download", `users.xlsx`);
        // Append to html link element page
        document.body.appendChild(link);
        // Start download
        link.click();
        document.body.removeChild(link);

        dispatch({
          type: DOWNLOAD_USER,
          payload: {
            loading: false,
            data: URL.createObjectURL(resp.data),
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: DOWNLOAD_USER,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const getSyncUser = (type, id) => {
  return async (dispatch) => {
    let url = type === "all" ? "-all" : `/${id}`;

    try {
      const resp = await apiConfig.get(`/api/admin/users/sync${url}`);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: true,
          err: false,
        },
      });
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });
    }
  };
};

export const changeStatusUser = (id) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(`/api/admin/users/state/${id}`);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: true,
          err: false,
        },
      });
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });
    }
  };
};

export const resetPasswordUser = (id) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(`/api/admin/users/reset-password/${id}`);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          err: false,
        },
      });
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });
    }
  };
};

export const getUpdateInfo = (page, id) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(
        `/api/admin/users/update-info/${id}?page=${page}`
      );
      dispatch({
        type: GET_UPDATE_INFO,
        payload: {
          loading: false,
          data: resp.data.data,
          err: false,
        },
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: GET_UPDATE_INFO,
        payload: {
          loading: false,
          data: false,
          err: err.message,
        },
      });
    }
  };
};

export const processUpdateInfo = (id_user, id) => {
  return (dispatch) => {
    dispatch({
      type: PROCESS_UPDATE_INFO,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/user/${id_user}/update-info/${id}/process`)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};
