import { Fragment } from "react";
import PropTypes from "prop-types";

const badgeWaitingApproval = {
  position: "relative",
  border: "2px solid #F6C962",
  borderRadius: "10px",
  color: "#A84D20",
  fontWeight: "500",
  backgroundColor: "#FEF8E7",
  maxWidth: "120px",
  justifyContent: "center",
};
const badgeApproved = {
  position: "relative",
  border: "2px solid #AAE3BF",
  borderRadius: "10px",
  color: "#1D8242",
  fontWeight: "500",
  backgroundColor: "#EAF8EF",
  maxWidth: "120px",
  justifyContent: "center",
};
const badgeRejected = {
  position: "relative",
  border: "2px solid #FFB8B8",
  borderRadius: "10px",
  color: "#B33636",
  fontWeight: "500",
  backgroundColor: "#FFEDED",
  maxWidth: "120px",
  justifyContent: "center",
};
const badgeRevisi = {
  position: "relative",
  border: "2px solid #BEC8F8",
  borderRadius: "10px",
  color: "#1B32A1",
  fontWeight: "500",
  backgroundColor: "#E9EDFD",
  maxWidth: "120px",
  justifyContent: "center",
};

const BadgeApproval = ({ status }) => {
  return (
    <Fragment>
      {status === 1 ? (
        <div className="d-flex" style={badgeWaitingApproval}>
		<span className="mx-2 px-1" style={{fontSize: '10px'}}>Waiting Approval</span>
	  </div>
      ) : status === 2 ? (
        <div className="d-flex" style={badgeApproved}>
          <span className="mx-2 px-1">Approved</span>
        </div>
      ) : status === 3 ? (
        <div className="d-flex" style={badgeRejected}>
          <span className="mx-2 px-1">Rejected</span>
        </div>
      ) : (
        <div className="d-flex" style={badgeRevisi}>
          <span className="mx-2 px-1">Revisi</span>
        </div>
      )}
    </Fragment>
  );
};

BadgeApproval.propTypes = {
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string,
};

export default BadgeApproval;
