import moment from "moment";
import { toast } from "react-toastify";

export const guid = () => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return s4();
};

export const listenerUID = () => {
  let s4 = () => {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  };
  return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4();
};

const RoleAdmin = {
  user: 1,
  admin: 2,
  superadmin: 3,
  it_staff: 4,
  cs: 5,
};

const getCommonRoles = () => [
  RoleAdmin.admin,
  RoleAdmin.superadmin,
  RoleAdmin.cs,
];
const getAdminRoles = () => [RoleAdmin.admin, RoleAdmin.superadmin];

export { getCommonRoles, getAdminRoles };

export function validateDateRange(startDate, endDate) {
  // Parse the input dates using Moment.js
  const inputStartDate = moment(startDate, "YYYY-MM-DD");
  const inputEndDate = moment(endDate, "YYYY-MM-DD");

  // Check if the input dates are valid
  if (!inputStartDate.isValid() || !inputEndDate.isValid()) {
    toast.error("Input date is not valid");
    return false;
  }

  const today = moment().startOf("day");

  // Check if start_date is not beyond end_date
  if (inputStartDate.isAfter(inputEndDate)) {
    toast.error("Start date cannot beyond end date.");
    return false;
  }
  // Check if start_date or end_date is earlier than today
  if (inputStartDate.isBefore(today) || inputEndDate.isBefore(today)) {
    toast.error("Start date or End date cannot earlier than today");
    return false;
  }

  return true;
}

export function validateNumberValue(val) {
  if (val === "" || isNaN(val)) {
    val = 0;
  }
}
