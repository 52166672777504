import apiConfig from "../../api";
import {
  WATCH_RESPONSE_ALERT,
  GET_LIST_MASTER_APPROVAL,
  GET_LIST_ROLE_APPROVAL,
} from "../actionTypes";

export const getListMasterAproval = (page, param, perPage) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(
        `/api/admin/master/approval?page=${page}&search=${
          param ? param : ""
        }&perPage=${perPage ? perPage : 10}`
      );
      dispatch({
        type: GET_LIST_MASTER_APPROVAL,
        payload: {
          loading: false,
          data: resp.data,
          err: false,
        },
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });
    }
  };
};

export const getListRoleApproval = () => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(`/api/admin/master/level`);
      dispatch({
        type: GET_LIST_ROLE_APPROVAL,
        payload: {
          loading: false,
          data: resp.data,
          err: false,
        },
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });
    }
  };
};

export const createMasterApproval = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(`/api/admin/master/approval`, payload);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          err: false,
        },
      });

      return resp;
    } catch (error) {
      console.log(error);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: error,
          err: true,
        },
      });

      return error;
    }
  };
};

export const updateMasterApproval = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(
        `/api/admin/master/approval/update`,
        payload
      );
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          err: false,
        },
      });

      return resp;
    } catch (error) {
      console.log(error);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: error,
          err: true,
        },
      });

      return error;
    }
  };
};

export const deleteMasterApproval = (id) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.delete(`/api/admin/master/approval/${id}`);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          err: false,
        },
      });

      return resp;
    } catch (error) {
      console.log(error);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: error,
          err: true,
        },
      });

      return error;
    }
  };
};
