import { Fragment, useState, useEffect } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Button } from "reactstrap";
import TableCSI from "@components/table";
import TableLayout from "@layout/tableLayout";
import Breadcrumbs from "@layout/breadcrumb";
import BadgeStatus from "@components/badgeStatus";
import TableDropdown from "@components/customDropdown";

import { getListFreeBarang, updateStatusFreeBarang } from "@action/freeBarang";

const FreeBarang = () => {
  const dispatch = useDispatch();
  const { listFreeBarang } = useSelector((state) => state.FreeBarangReducer);

  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [data, setData] = useState([]);
  const [column, setColumn] = useState([]);
  const [totalEntry, setTotalEntry] = useState(0);
  const [totalEntryPerPage, setTotalEntryPerPage] = useState(0);

  function fetchtable() {
    setColumn([
      {
        Header: () => null,
        id: "promo",
        columns: [
          {
            Header: "ID",
            accessor: "id",
            sortAble: true,
          },
          {
            Header: "Product Utama",
            accessor: "main_product",
            sortAble: true,
          },
          {
            Header: "Start Date",
            accessor: "start_date",
            sortAble: true,
            Cell: ({ row }) => (
              <span>
                {row.original.start_date !== "-"
                  ? moment.utc(row.original.start_date).format("DD/MM/YYYY")
                  : "-"}
              </span>
            ),
            sortType: (rowA, rowB, columnId, desc) => {
              let startDate = Date.parse(rowA.values[columnId]);
              let endDate = Date.parse(rowB.values[columnId]);
              if (startDate > endDate) return 1;
              if (startDate < endDate) return -1;
              return 0;
            },
          },
          {
            Header: "End Date",
            accessor: "end_date",
            sortAble: true,
            Cell: ({ row }) => (
              <span>
                {row.original.end_date !== "-"
                  ? moment.utc(row.original.end_date).format("DD/MM/YYYY")
                  : "-"}
              </span>
            ),
            sortType: (rowA, rowB, columnId, desc) => {
              let startDate = Date.parse(rowA.values[columnId]);
              let endDate = Date.parse(rowB.values[columnId]);
              if (startDate > endDate) return 1;
              if (startDate < endDate) return -1;
              return 0;
            },
          },
          {
            Header: "Produk Gratis",
            accessor: "free_product",
            sortAble: true,
          },
          {
            Header: "Status",
            accessor: "status",
            sortAble: true,
            Cell: ({ row }) => <BadgeStatus status={row.original.status} />,
            sortType: (rowA, rowB) => {
              let val1 = parseInt(rowA.original.status);
              let val2 = parseInt(rowB.original.status);
              if (val1 > val2) return 1;
              if (val1 < val2) return -1;
              return 0;
            },
          },
          {
            Header: "Action",
            accessor: "action",
            Cell: ({ row }) => (
              <TableDropdown
                dropdownContent={[
                  {
                    label: "Edit",
                    type: "link",
                    link: `/dashboard/promo/promo-free-barang/edit/${row.original.id}`,
                  },
                ]}
                onToggleStatus={onToggleStatus}
                row={row.original}
              />
            ),
            disableSortBy: true,
          },
        ],
      },
    ]);

    dispatch(getListFreeBarang(1, ""));
  }

  function onToggleStatus(arg) {
    dispatch(updateStatusFreeBarang(arg.id.id, arg.toggle)).then((resp) => {
      if (!resp.status === 200) return;

      fetchtable()
    });
  }

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListFreeBarang(page, ""));
  };

  useEffect(() => {
    fetchtable();
  }, []);

  useEffect(() => {
    if (listFreeBarang.data) {
      const resp = listFreeBarang.data.data;
      const list = resp.data.map((item) => ({
        id: item.id,
        main_product: item.product.name,
        start_date: item.start_date,
        end_date: item.end_date,
        free_product: item.product_free
          .map((data) => data.product.name)
          .join(", "),
        status: item.active,
      }));

      setData(list);

      setTotalEntry(resp.total);
      setTotalEntryPerPage(resp.per_page);
      setMaxPageIndex(resp.last_page);
    }
  }, [listFreeBarang.data]);

  const headerAction = (
    <Link to={`/dashboard/promo/promo-free-barang/add`}>
      <Button className="shadow-sm border-0" color="primary">
        Add New Promo
      </Button>
    </Link>
  );

  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <Breadcrumbs
          parent="Promotion"
          title="Promo Free Barang"
          urlParent={"/dashboard/promo"}
        />
      </div>
      <TableLayout
        headerAction={headerAction}
        handleSearch={() => {}}
        totalEntry={totalEntry}
        totalEntryPerPage={totalEntryPerPage}
        pageCount={maxPageIndex}
        handleChangePage={handlePageClick}
        handleChangeRow={(val) => dispatch(getListFreeBarang(1, "", val))}
      >
        <TableCSI columns={column} data={data} key={data} />
      </TableLayout>
    </Fragment>
  );
};

export default FreeBarang;
