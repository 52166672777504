import apiConfig from "../../api";
import {
    GET_LIST_MUTASI,
    GET_DETAIL_MUTASI,
    SYNC_MUTASI,
    EXPORT_CSV_MUTASI
} from "../actionTypes";

export const getListMutasi = (page, param, perPage, date) => {
    return (dispatch) => {
        dispatch({
            type: GET_LIST_MUTASI,
            payload: {
                loading: true,
                data: false,
                err: false,
            },
        });

        apiConfig
            .get(`/api/admin/bca-mutation?page=${page}&search=${param}&perPage=${perPage ? perPage : 10}&date=${date}`)
            .then((resp) => {
                dispatch({
                    type: GET_LIST_MUTASI,
                    payload: {
                        loading: false,
                        data: resp.data.data,
                        err: false,
                    },
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: GET_LIST_MUTASI,
                    payload: {
                        loading: false,
                        data: false,
                        err: err.message,
                    },
                });
            });
    };
};

export const getDetailMutasi = (param) => {
    return (dispatch) => {
        dispatch({
            type: GET_DETAIL_MUTASI,
            payload: {
                loading: true,
                data: false,
                err: false,
            },
        });

        apiConfig
            .get(`/api/admin/bca-mutation/${param}/detail`)
            .then((resp) => {
                dispatch({
                    type: GET_DETAIL_MUTASI,
                    payload: {
                        loading: false,
                        data: resp.data,
                        err: false,
                    },
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: GET_DETAIL_MUTASI,
                    payload: {
                        loading: false,
                        data: false,
                        err: err.message,
                    },
                });
            });
    };
};

export const syncMutasi = () => {
    return (dispatch) => {
        dispatch({
            type: SYNC_MUTASI,
            payload: {
                loading: true,
                data: false,
                err: false,
            },
        });

        apiConfig
            .get("/api/admin/bca-mutation/sync")
            .then((resp) => {
                dispatch({
                    type: SYNC_MUTASI,
                    payload: {
                        loading: false,
                        data: resp.data.data,
                        err: false,
                    },
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: SYNC_MUTASI,
                    payload: {
                        loading: false,
                        data: false,
                        err: err.message,
                    },
                });
            });
    };
};

export const exportCSVMutasi = () => {
    return (dispatch) => {
        dispatch({
            type: EXPORT_CSV_MUTASI,
            payload: {
                loading: true,
                data: false,
                err: false,
            },
        });

        apiConfig
            .get("/api/admin/bca-mutation/exportCsv", {responseType: 'blob'})
            .then((resp) => {
                const link = document.createElement('a');
                link.href = URL.createObjectURL(resp.data);
                link.setAttribute(
                    'download',
                    `Export-CSV.csv`,
                );
                // Append to html link element page
                document.body.appendChild(link);
                // Start download
                link.click();
                document.body.removeChild(link);

                dispatch({
                    type: EXPORT_CSV_MUTASI,
                    payload: {
                        loading: false,
                        data: URL.createObjectURL(resp.data),
                        err: false,
                    },
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: EXPORT_CSV_MUTASI,
                    payload: {
                        loading: false,
                        data: false,
                        err: err.message,
                    },
                });
            });
    };
};
