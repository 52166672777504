import apiConfig from "../../api";
import {
  GET_LIST_ADMIN,
  WATCH_RESPONSE_ALERT,
  GET_DETAIL_ADMIN,
} from "../actionTypes";

export const getListUserAdmin = (page, param, perPage) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_ADMIN,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(
        `/api/admin/user-admin?page=${page}${
          param !== "" ? "&search=" + param : ""
        }&perPage=${perPage ? perPage : 10}`
      )
      .then((resp) => {
        dispatch({
          type: GET_LIST_ADMIN,
          payload: {
            loading: false,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LIST_ADMIN,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const deleteUserAdmin = (id) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .delete(`/api/admin/user-admin/${id}`)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const createUserAdmin = (payload) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post(`/api/admin/user-admin/create`, payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const updateUserAdmin = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.put(`/api/admin/user-admin`, payload);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: false,
          err: false,
        },
      });

	  return resp
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });

	  return err
    }
  };
};

export const getDetailAdmin = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_DETAIL_ADMIN,
      payload: {
        loading: true,
        data: {},
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/user-admin/${id}`)
      .then((resp) => {
        dispatch({
          type: GET_DETAIL_ADMIN,
          payload: {
            loading: false,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_DETAIL_ADMIN,
          payload: {
            loading: false,
            data: {},
            err: err.message,
          },
        });
      });
  };
};
