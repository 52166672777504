import {
  GET_USER_CHAT,
  SET_USER_CHAT,
  GET_UUID,
  SET_USER_DATA,
  SET_USER_ROLE,
  SET_USER_TOKEN
} from "../../actionTypes";

const initialState = {
  uuidData: "",
  userChatData: {},
  userLoginData: {},
  user_role: null,
  user_token: null
};

const LoginReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_UUID:
      return { ...state, uuidData: action.payload };
    case SET_USER_CHAT:
      return { ...state, userChatData: action.payload };
    case SET_USER_ROLE:
      return { ...state, user_role: action.payload };
    case SET_USER_TOKEN:
      return { ...state, user_token: action.payload };
    case GET_USER_CHAT:
      return { ...state, userChatData: state.userChatData };
    case SET_USER_DATA:
      return { ...state, userLoginData: action.payload };
    default:
      return { ...state };
  }
};

export default LoginReducer;
