import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { useParams, Link } from "react-router-dom";
import iconSearch from "../../../../assets/images/input-search.png";
import sortIcon from "../../../../assets/images/sort-icon.svg";
import TableCSI from "../../../../Component/table";
import CustomPagination from "../../../../Component/customPagination";
import EntryCounter from "../../../../Component/entryCounter";
import ButtonAction from "../../../../Component/styledComponents/btnTableAction";
import WrapperTableAction from "../../../../Component/styledComponents/wrapperBtnAction";
import BtnDropdownContent from "../../../../Component/styledComponents/btnDropdownContent";
import PaginationRow from "../../../../Component/paginationDataPerPage";
import { useDispatch, useSelector } from "react-redux";
import { getListNews } from "../../../../redux/action/newsAction";
import moment from "moment";

const NewsLayout = (props) => {
  const [tableColumn, setTableColumn] = useState([]);
  const [data, setData] = useState([]);
  const [isFetchedData, setIsFetchedData] = useState(true);
  const [isAfterSearch, setIsAfterSearch] = useState(false);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [totalResult, setTotalResult] = useState(4);
  const [totalPerPage, setTotalPerPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const dispatch = useDispatch();
  const { listNews } = useSelector((state) => state.NewsReducer);

  useEffect(() => {
    dispatch(getListNews(10, 1, inputSearchValue));
    setTableColumn(
      [
        {
          Header: () => null,
          id: "News",
          columns: [
            {
              Header: "ID",
              accessor: "id",
              // sortAble: true,
            },
            {
              Header: "Title",
              accessor: "title",
              // sortAble: true,
            },
            {
              Header: "Start Date",
              accessor: "start_date",
              // sortAble: true,
              Cell: ({ row }) => (
                <div>
                  {moment(row.original.start_date).format("DD/MM/YYYY")}
                </div>
              ),
            },
            {
              Header: "End Date",
              accessor: "end_date",
              // sortAble: true,
              Cell: ({ row }) => (
                <div>{moment(row.original.end_date).format("DD/MM/YYYY")}</div>
              ),
            },
            {
              Header: "Action",
              accessor: "action",
              Cell: ({ row }) => (
                <div className="d-flex">
                  <Link
                    to={{
                      pathname: `/dashboard/news/edit/${row.original?.id}`,
                      data: row?.original,
                    }}
                  >
                    <ButtonAction className="mx-1">Edit</ButtonAction>
                  </Link>
                  <WrapperTableAction>
                    <ButtonAction className="mx-1">...</ButtonAction>
                    <BtnDropdownContent>
                      <ul>
                        <li className="my-2">
                          <Link
                            to={{
                              pathname: `/dashboard/news/detail/${row.original?.id}`,
                              data: row?.original,
                            }}
                          >
                            Details
                          </Link>
                        </li>
                      </ul>
                    </BtnDropdownContent>
                  </WrapperTableAction>
                </div>
              ),
            },
          ],
        },
      ],
      []
    );
  }, []);

  useEffect(() => {
    if (listNews.data && !listNews.loading) {
      let dataNews = listNews?.data?.data;
	  if (dataNews === undefined || dataNews === null) return false;

      let mappedListData = dataNews.map((item) => ({
        id: item?.id,
        title: item?.title,
        start_date: item?.start_date,
        end_date: item?.end_date,
        content: item?.content,
        banner: item?.banner,
      }));
      setData(mappedListData);
      setMaxPageIndex(listNews?.data?.last_page);
      setTotalResult(listNews?.data?.total);
      setTotalPerPage(listNews?.data?.to);
      setIsFetchedData(true);
    }
  }, [listNews]);

  const handleSubmitSearch = () => {
    if (inputSearchValue !== "") {
      dispatch(getListNews(perPage, 1, inputSearchValue));
      setIsAfterSearch(true);
    }
  };

  const handleInputSearch = (e) => {
    setInputSearchValue(e.target.value);
  };

  const handleOnEnter = (e) => {
    // if (inputSearchValue !== "") {
    dispatch(getListNews(perPage, 1, inputSearchValue));
    setIsAfterSearch(true);
    // }
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListNews(perPage, page, inputSearchValue));
    setIsAfterSearch(true);
  };

  const onChangePaginationRow = (perPage) => {
    setIsFetchedData(false);
    setPerPage(perPage);
    dispatch(getListNews(perPage, 1, inputSearchValue));
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Dashboard"
        title="News"
        urlParent={"/dashboard/analytics"}
      />
      <Container fluid={true}>
        <Row>
          <Col>
            <Card className="p-0">
              <CardBody className="p-3">
                <div className="d-flex justify-content-between my-2">
                  <div className="d-flex">
                    <InputGroup
                      style={{ maxWidth: "280px" }}
                      className={"mx-1"}
                    >
                      <Input
                        placeholder="Search"
                        value={inputSearchValue}
                        onChange={(e) => handleInputSearch(e)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") handleOnEnter(e);
                        }}
                      />
                      <InputGroupAddon addonType="prepend">
                        <Button
                          className="p-2"
                          color="primary"
                          onClick={() => handleSubmitSearch()}
                        >
                          <img
                            src={iconSearch}
                            width={15}
                            height={15}
                            alt={""}
                          />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                    <InputGroup
                      style={{ maxWidth: "180px" }}
                      className={"mx-1"}
                    >
                      <EntryCounter
                        totalPerPage={totalPerPage}
                        totalResult={totalResult}
                      />
                    </InputGroup>
                  </div>
                  <div className="d-flex">
                    <Link to={`/dashboard/news/add`}>
                      <Button color="primary" outline>
                        Add New News
                      </Button>
                    </Link>
                  </div>
                </div>
                {isFetchedData ? (
                  <TableCSI
                    data={data}
                    columns={tableColumn}
                    key={data}
                    initialSortBy={initialSortBy}
                  />
                ) : null}
                <div className="d-flex justify-content-between">
                  <PaginationRow
                    onChangePaginationRow={onChangePaginationRow}
                  />
                  <CustomPagination
                    handlePageClick={handlePageClick}
                    pageCount={maxPageIndex}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default NewsLayout;
