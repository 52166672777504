import {
	GET_LIST_TRANSACTION_CONFIRM,
	GET_TRANSACTION_DETAIL,
	GET_DETAIL_TRANSACTION_DOCUMENT,
	SYNCED_TRANSACTION,
	DO_NUMBER
} from "../../actionTypes";

  const initialState = {
	listTransactionConfirm: {},
	listTransactionShipping: {},
	listTransactionDelivered: {},
	listTransactionComplete: {},
	transactionDetail: {},
	transactionDetailDocument: {},
	syncedTransaction: {},
	doTransactionNumber: {},
  };

  const TransactionReducer = (state = initialState, action) => {
	switch (action.type) {
	  case GET_LIST_TRANSACTION_CONFIRM:
		state.listTransactionConfirm = action.payload;
		return { ...state, listTransactionConfirm: state.listTransactionConfirm };
	  case GET_TRANSACTION_DETAIL:
		state.transactionDetail = action.payload;
		return { ...state, transactionDetail: state.transactionDetail };
	  case GET_DETAIL_TRANSACTION_DOCUMENT:
		state.transactionDetailDocument = action.payload;
		return { ...state, transactionDetailDocument: state.transactionDetailDocument };
	  case SYNCED_TRANSACTION:
		state.syncedTransaction = action.payload;
		return { ...state, syncedTransaction: state.syncedTransaction };
	  case DO_NUMBER:
		state.doTransactionNumber = action.payload;
		return { ...state, doTransactionNumber: state.doTransactionNumber };

	  default:
		return { ...state };
	}
  };

  export default TransactionReducer;
