import styled from "styled-components";

const BtnDropdownContent = styled.div`
  display: none;
  position: absolute;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(65, 78, 98, 0.12),
    0px 0px 1px rgba(65, 78, 98, 0.05);
  border-radius: 6px;
  padding: 12px 16px;
  width: 180px;
  text-align: left;
  z-index:999;
`;

export default BtnDropdownContent