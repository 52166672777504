import styled from "styled-components";
import BtnDropdownContent from "../btnDropdownContent";

const WrapperTableAction = styled.div`
  position: relative;
  display: inline-block;

  &:hover ${BtnDropdownContent} {
    display: block;
  }
`;

export default WrapperTableAction