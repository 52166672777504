import React, { Fragment, useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const TransactionCount = ({ data }) => {
  const [chartOptions, setChartOptions] = useState({
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
    },
  });
  const [dataLine, setDataLine] = useState({
    labels: [],
    datasets: [
      {
        label: "",
        data: [],
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  });

  useEffect(() => {
    if (
      data !== null &&
      data?.transaction_count &&
      data?.transaction_count.length > 0
    ) {
      const transData = data.transaction_count || [];
      const transDataX = transData.map((obj) => obj[0]);
      const slicedDataX = transDataX.slice(1);
      const transDataY = transData.map((obj) => obj[1]);
      const slicedDataY = transDataY.slice(1);
      const dataTitle = `Chart ${data.start_date} - ${data.end_date}`;

      setDataLine({
        ...dataLine,
        labels: slicedDataX,
        datasets: [
          {
            label: dataTitle,
            data: slicedDataY,
            borderColor: "rgb(255, 99, 132)",
            backgroundColor: "rgba(255, 99, 132, 0.5)",
          },
        ],
      });
    }
  }, [data]);

  return (
    <Fragment>
      <h5 className="my-1">Transaction Daily Count</h5>
      <Line options={chartOptions} data={dataLine} style={{maxHeight: '400px'}} />
    </Fragment>
  );
};

export default TransactionCount;
