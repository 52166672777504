import React, { Fragment } from "react";
import { Container, Row, Col, Breadcrumb, BreadcrumbItem } from "reactstrap";
import { Home } from "react-feather";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const Breadcrumbs = ({ customTitle, title, subTitle, parent, urlParent }) => {
  return (
    <Fragment>
      <Container fluid={true}>
        <div className="page-title">
          <Row>
            <Col xs="6">
              <div>
                <h3>{customTitle ? customTitle : title}</h3>
                {subTitle && <h3>{subTitle}</h3>}
              </div>
            </Col>
            <Col xs="6">
              <Breadcrumb>
                <BreadcrumbItem>
                  <Link to={`/dashboard/analytics`}>
                    <Home />
                  </Link>
                </BreadcrumbItem>
                {parent ? (
                  <BreadcrumbItem>
                    {urlParent ? (
                      <Link
                        to={urlParent}
                        style={{ color: "#092540", fontWeight: "400" }}
                      >
                        {parent}
                      </Link>
                    ) : (
                      parent
                    )}
                  </BreadcrumbItem>
                ) : null}
                {title && <BreadcrumbItem active>{title}</BreadcrumbItem>}
              </Breadcrumb>
            </Col>
          </Row>
        </div>
      </Container>
    </Fragment>
  );
};

Breadcrumbs.propTypes = {
  customTitle: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  parent: PropTypes.string.isRequired,
  urlParent: PropTypes.string.isRequired,
};

export default Breadcrumbs;
