import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Breadcrumb from "@layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";

import { paidConfirmTransaction } from "@action/transactionAction";

const TransactionPaidConfirm = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  const [form, setForm] = useState({
    point: "",
  });

  const validateForm = (data) => {
    if (data.point === "" || data.date === "") {
      toast.error("Please fill all fields");
      return true;
    }
    return false;
  };

  const handleSubmitForm = () => {
    if (!validateForm(form)) {
      let formPayload = new FormData();
      formPayload.append("id", id);
      formPayload.append("xp_point", form.point);
      dispatch(paidConfirmTransaction(formPayload)).then((res) => {
		if(res.status !== 200) return;

		history.push('/dashboard/transaction')
	  })
    }
  };

  const handleCancelButton = () => {
    history.push('/dashboard/transaction');
  };

  return (
    <Fragment>
      <Breadcrumb parent="Transaction" title="Paid Confirm" urlParent={'/dashboard/transaction'} />
      <Container fluid>
        <Row>
          <Col xl={8}>
            <Card>
              <CardBody className="p-3">
                <Form
                  className="m-2"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FormGroup row>
                    <Label for="formStartPoint" sm={3}>
                      <b>
                        XP Point <span style={{ color: "red" }}>*</span>
                      </b>
                    </Label>
                    <Col sm={9}>
                      <Input
                        placeholder="0"
                        type="number"
                        id="formStartPoint"
                        value={form.point}
                        onChange={(e) =>
                          setForm({
                            ...form,
                            point: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>

                  <FormGroup check row className="my-3">
                    <div className="d-flex justify-content-end">
                      <Button
                        className="mx-1"
                        color="primary"
                        outline
                        onClick={handleCancelButton}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="mx-1"
                        color="primary"
                        onClick={handleSubmitForm}
                      >
                        Submit
                      </Button>
                    </div>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TransactionPaidConfirm;
