import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import { sendBroadcastAllPromo } from "../../../../redux/action/broadcastAction";

const BroadcastModal = (props) => {
  const dispatch = useDispatch();
  const [formMessage, setFormMessage] = useState("");

  const sendBroadcast = () => {
    if (formMessage !== "") {
      const payload = {
        message: formMessage,
      };

	  const rawData = new URLSearchParams(
        Object.entries(payload)
      ).toString();

	  dispatch(sendBroadcastAllPromo(rawData))
	  props.doCloseBroadcast(false);
    }
    
  };

  const doCloseBroadcast = () => {
	props.doCloseBroadcast(false)
  }

  return (
    <Fragment>
      <Modal isOpen={props.isShow} toggle={doCloseBroadcast}>
        <ModalHeader toggle={doCloseBroadcast}>
          Send Broadcast Promo
        </ModalHeader>
        <ModalBody>
          <span>Message:</span>
          <Input
            type="textarea"
            name="broadcastMessage"
            id="broadcastMessage"
            className="my-1"
            value={formMessage}
            onChange={(e) => setFormMessage(e.target.value)}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={doCloseBroadcast}>
            Cancel
          </Button>
          <Button color="primary" onClick={sendBroadcast}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default BroadcastModal;
