import { Fragment } from "react";
import PropTypes from "prop-types";

import { Container, Row, Col, Card, CardBody } from "reactstrap";
import SearchBar from "@components/searchBar";
import CustomPagination from "@components/customPagination";
import PaginationRow from "@components/paginationDataPerPage";

const TableLayout = ({
  children,
  headerAction,
  handleSearch,
  headerTitle,
  totalEntry = 0,
  totalEntryPerPage = 0,
  pageCount = 1,
  handleChangePage,
  handleChangeRow,
  isCustomHeader,
  customBarHeader,
  noentry,
  nosearch,
}) => {
  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col>
            <Card className="shadow-sm border-0 p-0" style={{ zIndex: 0 }}>
              <CardBody className="p-3">
                {isCustomHeader ? (
                  <div className="d-flex flex-column my-2">
                    {headerTitle && <h3>{headerTitle}</h3>}
                    <div className="d-flex flex-lg-row flex-column justify-content-between">
                      {customBarHeader}
                      {nosearch ? (
                        ""
                      ) : (
                        <SearchBar
                          onSearch={handleSearch}
                          totalEntry={totalEntry}
                          totalEntryPerPage={totalEntryPerPage}
                          noentry={noentry}
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="d-flex flex-lg-row flex-column justify-content-between my-2">
                    {headerTitle && <h3>{headerTitle}</h3>}
                    {nosearch ? (
                      ""
                    ) : (
                      <SearchBar
                        onSearch={handleSearch}
                        totalEntry={totalEntry}
                        totalEntryPerPage={totalEntryPerPage}
                        noentry={noentry}
                      />
                    )}
                    {headerAction}
                  </div>
                )}
                {children}
                <div className="d-flex justify-content-between">
                  <PaginationRow onChangePaginationRow={handleChangeRow} />
                  <CustomPagination
                    handlePageClick={handleChangePage}
                    pageCount={pageCount}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

TableLayout.propTypes = {
  children: PropTypes.node.isRequired,
  headerAction: PropTypes.element,
  headerTitle: PropTypes.string,
  handleSearch: PropTypes.func,
  totalEntry: PropTypes.number,
  totalEntryPerPage: PropTypes.number,
  pageCount: PropTypes.number.isRequired,
  handleChangePage: PropTypes.func.isRequired,
  handleChangeRow: PropTypes.func.isRequired,
  isCustomHeader: PropTypes.bool,
  customBarHeader: PropTypes.node,
  noentry: PropTypes.bool,
  nosearch: PropTypes.bool,
};

export default TableLayout;
