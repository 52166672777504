import { Fragment, useRef, useState, useEffect } from "react";
import { FormGroup, Label, Col, Input } from "reactstrap";
import PropTypes from "prop-types";
import { toast } from "react-toastify";

import { ModalDialog } from "../modal/ModalDialog";
import ModalContent from "../modal/ModalContent";

import iconClose from "../../assets/images/Close.svg";

const inputImage = {
  position: "relative",
  overflow: "hidden",
  display: "inline-block",
  styleInput: {
    position: "absolute",
    left: "0",
    top: "0",
    opacity: "0",
    width: "140px",
    height: "120px",
    cursor: "pointer",
  },
  btnUpload: {
    border: "2px solid gray",
    color: "gray",
    backgroundColor: "white",
    padding: "8px",
    borderRadius: "5px",
    width: "140px",
    height: "120px",
    cursor: "pointer",
  },
};
const containerImages = {
  overflowX: "scroll",
  overflowY: "hidden",
  whiteSpace: "nowrap",
  btnDeleteBanner: {
    position: "absolute",
    right: "0",
    top: "0",
    width: "32px",
    height: "32px",
    cursor: "pointer",
  },
};

const InputImage = ({
  label,
  value,
  onChange,
  isRequired,
  onDispatchRemove, 
  labelWidth=2, 
  inputWidth= 10,
}) => {
  const imageBannerID = useRef(0);

  const [image, setImage] = useState([]);
  const [modalState, setModalState] = useState(null);

  useEffect(() => {
    if (value.length < 1) return;
    setImage(value);
  }, [value]);

  const isImage = (imgType) => {
    switch (imgType) {
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
        return true;
    }
    return false;
  };

  const showModal = (row) => {
    setModalState((prevState) => ({
      ...prevState,
      openModal: true,
      modalType: "remind",
      modalTitle: "Delete Thumbnail",
      modalData: row,
      closeIcon: true,
    }));
  };

  const handleUploadMultipleImg = (e) => {
    if (e.target.files.length > 0) {
      const filesImg = e.target.files[0];
      if (filesImg.size > 2000000) {
        toast.error(`Max file size is 2MB`);
        document.getElementById("selectBanner").value = "";
      } else {
        if (!isImage(filesImg.type)) {
          toast.error(`Select appropiate image file.`);
          document.getElementById("selectBanner").value = "";
        } else {
          const imgSrc = URL.createObjectURL(filesImg);
          const objImage = {
            id: imageBannerID.current,
            src: imgSrc,
            file: filesImg,
          };

          imageBannerID.current = imageBannerID.current + 1;

          const newArrImage = [...image, objImage];

          setImage(newArrImage);
          onChange(newArrImage);
        }
      }
    }
  };

  const handleRemoveBanner = (e, item) => {
    document.getElementById("selectBanner").value = "";
	e.preventDefault()
    const newArr = image.filter((arr) => arr.id !== item.id);
    setImage(newArr);
    onChange(newArr);
    if (typeof onDispatchRemove === "function" && !item.file) {
      onDispatchRemove(item.id);
    }
    setModalState(null);
  };

  return (
    <Fragment>
      <FormGroup row>
        <Label className="font-weight-bold" for={`form${label}`} sm={labelWidth}>
          {label}
          {isRequired ? (
            <span className="mx-1" style={{ color: "red" }}>
              *
            </span>
          ) : null}
        </Label>
        <Col className="d-flex flex-row" sm={inputWidth}>
          <div style={inputImage}>
            <button style={inputImage.btnUpload}>Tambah Gambar</button>
            <Input
              type="file"
              name="selectBanner"
              id="selectBanner"
              accept="image/*"
              onChange={handleUploadMultipleImg}
              style={inputImage.styleInput}
            />
          </div>
          <div className="mx-2" style={containerImages}>
            {image.length > 0
              ? image.map((data, i) => (
                  <div
                    key={i}
                    className="mx-1"
                    style={{
                      position: "relative",
                      width: "140px",
                      height: "120px",
                      display: "inline-block",
                    }}
                  >
                    <img
                      src={iconClose}
                      onClick={() => showModal(data)}
                      style={containerImages.btnDeleteBanner}
                    />
                    <img
                      src={data.src}
                      style={{ width: "100%", objectFit: "cover" }}
                    ></img>
                  </div>
                ))
              : ""}
          </div>
          <Fragment>
            <ModalDialog
              open={modalState?.openModal ?? false}
              type={modalState?.modalType ?? ""}
              footer={false}
              title={modalState?.modalTitle ?? ""}
              titleFontSize={18}
              backdropClose={true}
              onClose={() => {
                setModalState(null);
              }}
              maxWidth={384}
            >
              <ModalContent
                onClose={() => {
                  setModalState(null);
                }}
                onSubmit={(e) => {
                  handleRemoveBanner(e, modalState?.modalData);
                }}
                data={modalState?.modalData ?? null}
                description={`Are you sure you want to delete this image?`}
                descClose={"Cancel"}
                descSubmit={"Delete"}
              />
            </ModalDialog>
          </Fragment>
        </Col>
      </FormGroup>
    </Fragment>
  );
};

InputImage.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
};

export default InputImage;
