import {
    GET_LIST_PRODUCT,
    GET_DETAIL_PRODUCT,
    GET_SYNC_PRODUCT,
    DOWNLOAD_PRODUCT,
	GET_LIST_LOG,
	GET_LIST_IMAGES,
	GET_LIST_PRICE,
    GET_LIST_PRODUCT_DISCOUNT
} from "../../actionTypes";

const initialState = {
    listProduct: {},
    listProductLogs: {},
    detailProduct: {},
    syncProduct: {},
    fileProduct: {},
	listProductImages: {},
	listProductPrice: {},
	listProductDiscount: {},
};

const ProductReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_PRODUCT:
            state.listProduct = action.payload;
            return { ...state, listProduct: state.listProduct };
        case GET_LIST_LOG:
            state.listProductLogs = action.payload;
            return { ...state, listProductLogs: state.listProductLogs };
        case GET_DETAIL_PRODUCT:
            state.detailProduct = action.payload;
            return { ...state, detailProduct: state.detailProduct };
        case GET_SYNC_PRODUCT:
            state.syncProduct = action.payload;
            return { ...state, syncProduct: state.syncProduct };
        case DOWNLOAD_PRODUCT:
            state.fileProduct = action.payload;
            return { ...state, fileProduct: state.fileProduct };
        case GET_LIST_IMAGES:
            state.listProductImages = action.payload;
            return { ...state, listProductImages: state.listProductImages };
        case GET_LIST_PRICE:
            state.listProductPrice = action.payload;
            return { ...state, listProductPrice: state.listProductPrice };
        case GET_LIST_PRODUCT_DISCOUNT:
            state.listProductDiscount = action.payload;
            return { ...state, listProductDiscount: state.listProductDiscount };
        default:
            return { ...state };
    }
};

export default ProductReducer;
