import { Fragment } from "react";
import PropTypes from "prop-types";

const InputRadio = ({ label, name, onChange, checked }) => {
  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-center mr-2">
        <input
          type="radio"
          name={name}
          className="mr-2"
          checked={checked}
          onChange={onChange}
          style={{
            width: "18px",
            height: "18px",
            accentColor: "blue",
          }}
        />
        <label htmlFor={name} className="p-0">
          {label}
        </label>
      </div>
    </Fragment>
  );
};

InputRadio.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

export default InputRadio;
