import React, { Fragment, useEffect, useState } from "react";
import {
  Badge,
  Card,
  CardBody,
  FormGroup,
  Button,
  InputGroup,
  Input,
  Label,
  InputGroupAddon,
} from "reactstrap";
import styled from "styled-components";

const BtnClose = styled.div`
  cursor: pointer;
  font-weight: bold;
  :hover {
    background: #bbb;
  }
`;

const AttachSalesDetail = (props) => {
  const [salesOrderDetail, setSalesOrderDetail] = useState({});

  const toggleCloseSales = (param) => {
    setSalesOrderDetail({});
    props.onClose(param);
  };

  useEffect(() => {
    setSalesOrderDetail(props.item);
    return;
  }, []);

  return (
    <Fragment>
      <div>
        <div className="d-flex justify-content-between my-1">
          <span>
            <b>Detail Transaksi</b>
          </span>
          <BtnClose onClick={() => toggleCloseSales("")}>X</BtnClose>
        </div>
        <div className="d-flex justify-content-between my-2">
          <p>No. Invoice</p>
          <span>{salesOrderDetail.invoice_no}</span>
        </div>
        <div className="d-flex justify-content-between my-1">
          <p>Status</p>
          <div className="badge_csi badge_blue">{salesOrderDetail.state}</div>
        </div>
      </div>

      {salesOrderDetail.items ? (
        <div className="att_sales_detail_order">
          {salesOrderDetail.items.map((item) => (
            <div className="d-flex justify-content-start my-1" key={item.id}>
              <img
                src={
                  item.product.images && item.product.images.length > 0
                    ? item.product.images[0].thumbnail_image_url
                    : null
                }
                alt=""
                className="produk_detail_image"
              />
              <div
                className="produk_list_detail mx-2"
                style={{ cursor: "pointer" }}
              >
                <div>
                  <p className="produk_name m-0">{item.product.name}</p>
                  <p className="produk_price m-0">
                    <b>Rp. {item.product.price}</b>
                  </p>
                  <span className="produk_stock">
                    Stock: {item.product.stock}
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : null}

      <div className="sales_ringkasan p-1 my-1">
        <h5 className="my-3">Ringkasan</h5>
        <div className="d-flex justify-content-between my-1">
          <span>Sub Total</span>
          <span>
            <b>
              Rp.{" "}
              {salesOrderDetail.total_price -
                salesOrderDetail.additional_discount}
            </b>
          </span>
        </div>
        <div className="d-flex justify-content-between my-1">
          <span>Total Diskon</span>
          <span>
            <b>Rp. {salesOrderDetail.additional_discount}</b>
          </span>
        </div>
        <div className="d-flex justify-content-between sales_total_harga my-1 py-1">
          <span>Total Harga</span>
          <span>
            <b>Rp. {salesOrderDetail.total_price}</b>
          </span>
        </div>
      </div>
    </Fragment>
  );
};

export default AttachSalesDetail;
