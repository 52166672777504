import Mutasi from "../../pages/dashboard/mutasi";
import MutasiDetail from "../../pages/dashboard/mutasi/detail";

export const RouteMutasi = [
    // Mutasi BCA
    {
        path: `/dashboard/mutasi-bca`,
        Component: Mutasi,
    },
    {
        path: `/dashboard/mutasi-bca/detail/:id`,
        Component: MutasiDetail,
    },
];
