import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useHistory, useParams } from "react-router-dom";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import Breadcrumb from "../../../../layout/breadcrumb";
import TableCSI from "../../../../Component/table";
import CurrencyFormatter from "../../../../Component/currencyFormatter";

import { fetchDetailFlashsale } from "../../../../redux/action/flashsaleAction";

const navTab = {
  cursor: "pointer",
  border: "none",
  color: "black",
};

const FlashsaleDetail = () => {
  const dispatch = useDispatch();
  const param = useParams();

  const [isFetchedData, setIsFetchedData] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [columnHistory, setColumnHistory] = useState([]);
  const [columnProduct, setColumnProduct] = useState([]);
  const [detailData, setDetailData] = useState({});
  const [tableProduct, setTableProduct] = useState([]);
  const [tableHistory, setTableHistory] = useState([]);

  const { detailFlashsale } = useSelector((state) => state.FlashsaleReducer);

  useEffect(() => {
    dispatch(fetchDetailFlashsale(param.id));
  }, []);

  useEffect(() => {
    if (activeTab === "1") {
      setColumnProduct([
        {
          Header: () => null,
          id: "produkFlashsale",
          columns: [
            {
              Header: "Name",
              accessor: "name",
              sortAble: true,
            },
            {
              Header: "Harga",
              accessor: "harga",
              sortAble: true,
              Cell: ({ row }) =>
                `${Number(row.original.harga).toLocaleString("id-ID", {
                  style: "currency",
                  currency: "IDR",
                  minimumFractionDigits: 0,
                })}`,
            },
            {
              Header: "Diskon",
              accessor: "diskon",
              sortAble: true,
            },
            {
              Header: "Stock",
              accessor: "stock",
              sortAble: true,
            },
            {
              Header: "Batas Pembelian",
              accessor: "min_buy",
              sortAble: true,
            },
            {
              Header: "Terjual",
              accessor: "sold",
              sortAble: true,
            },
          ],
        },
      ]);

      if (detailFlashsale.data && detailFlashsale.data.item_detail) {
        const data = detailFlashsale.data;
        const itemDetail = data.item_detail;
        if (itemDetail.length > 0) {
          const mappedData = itemDetail.map((item) => ({
            id: item.product.id,
            name: item.product.name,
            harga: item.product.price,
            diskon:
              item.discount_type === "persentage"
                ? `${item.discount}%`
                : `${Number(item.discount).toLocaleString("id-ID", {
                    style: "currency",
                    currency: "IDR",
                    minimumFractionDigits: 0,
                  })}`,
            stock: item.stock,
            min_buy: item.limit_stock,
            sold: item.total_produk_terjual,
          }));

          setTableProduct(mappedData);
        }
      }
    } else {
      setColumnHistory([
        {
          Header: () => null,
          id: "historyFlashsale",
          columns: [
            {
              Header: "Waktu",
              accessor: "time",
              sortAble: true,
            },
            {
              Header: "Nama Barang",
              accessor: "product_name",
              sortAble: true,
            },
            {
              Header: "Customer",
              accessor: "customer",
              sortAble: true,
            },
            {
              Header: "Harga",
              accessor: "price",
              sortAble: true,
            },
            {
              Header: "Jumlah",
              accessor: "quantity",
              sortAble: true,
            },
            {
              Header: "Total",
              accessor: "total_price",
              sortAble: true,
            },
          ],
        },
      ]);

      if (detailFlashsale.data && detailFlashsale.data.item_detail) {
        const data = detailFlashsale.data;
        const itemDetail = data.item_detail;
        if (itemDetail.length > 0) {
          const tableData = itemDetail.flatMap((item) => {
            const itemName = item.product.name;
            const cartItems = item.cart_item;
            return cartItems.map((cartItem) => {
              return {
                product_name: itemName,
                time: cartItem.transaction.created_at,
                customer: cartItem.transaction.user.shop_name,
                price: cartItem.price,
                quantity: cartItem.quantity,
                total_price: cartItem.transaction.total_price,
              };
            });
          });
          setTableHistory(tableData);
        }
      }
    }
  }, [activeTab, detailFlashsale.data]);

  useEffect(() => {
    if (detailFlashsale.data && !detailFlashsale.loading) {
      const data = detailFlashsale.data;
      setDetailData({ ...detailData, data });

      setIsFetchedData(true);
    }
  }, [detailFlashsale]);

  return (
    <Fragment>
      <Breadcrumb
        urlParent="/dashboard/flashsale"
        parent="Flash Sale"
        title="Detail Flash Sale"
      />
      <Container fluid>
        <Row>
          <Col xl={12}>
            <Row>
              <Col xl={6} md={12} sm={12}>
                <Card>
                  <CardBody>
                    <h5>Waktu Event</h5>
                    <span>
                      <span>
                        <b>
                          {isFetchedData ? detailData.data.start_date : "-"}
                        </b>{" "}
                        ,{" "}
                        <b>
                          {isFetchedData ? detailData.data.start_time : "-"}
                        </b>
                      </span>
                      <span className="mx-2" style={{ fontSize: "13px" }}>
                        sampai
                      </span>
                      <span>
                        <b>{isFetchedData ? detailData.data.end_date : "-"}</b>{" "}
                        ,{" "}
                        <b>{isFetchedData ? detailData.data.end_time : "-"}</b>
                      </span>
                    </span>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={6} md={12} sm={12}>
                <Card>
                  <CardBody>
                    <h5>Total Pendapatan</h5>
                    <span>
                      <b>
                        {isFetchedData
                          ? Number(
                              detailData.data.total_pendapatan
                            ).toLocaleString("id-ID", {
                              style: "currency",
                              currency: "IDR",
                              minimumFractionDigits: 0,
                            })
                          : null}
                      </b>
                    </span>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>

          <Col xl={12}>
            <Card>
              <CardBody className="p-3">
                <Nav tabs className="m-2">
                  <NavItem>
                    <NavLink
                      className={activeTab === "1" ? "active" : null}
                      onClick={() => {
                        setActiveTab("1");
                      }}
                      style={{
                        ...navTab,
                        borderBottom:
                          activeTab === "1" ? "2px solid blue" : null,
                        color: activeTab === "1" ? "blue" : "black",
                      }}
                    >
                      Produk Flash Sale
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activeTab === "2" ? "active" : null}
                      onClick={() => {
                        setActiveTab("2");
                      }}
                      style={{
                        ...navTab,
                        borderBottom:
                          activeTab === "2" ? "2px solid blue" : null,
                        color: activeTab === "2" ? "blue" : "black",
                      }}
                    >
                      History Pembelian
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent className="m-2 my-3" activeTab={activeTab}>
                  <TabPane tabId="1">
                    {isFetchedData ? (
                      <TableCSI
                        data={tableProduct}
                        columns={columnProduct}
                        key={tableProduct}
                      />
                    ) : null}
                  </TabPane>
                  <TabPane tabId="2">
                    {isFetchedData ? (
                      <TableCSI
                        data={tableHistory}
                        columns={columnHistory}
                        key={tableHistory}
                      />
                    ) : null}
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default FlashsaleDetail;
