import React, { Fragment, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumb from "@layout/breadcrumb";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import styled from "styled-components";

import KeywordsCountTable from "./keywordsCountTable";
import UserUsageTable from "./userUsageTable";
import ProductCountTable from "./productCountTable";
import TransactionCount from "./transactionCountChart";
import TransactionSum from "./transactionSumChart";

import {
  getIndexAnalytics,
  getKeywordsAnalytics,
  getProductsAnalytics,
  getUsageAnalytics,
} from "@action/analyticAction";

const InputDate = styled.input`
  padding: 10px 12px;
  gap: 8px;
  border: 1px solid #d1d5dc;
  border-radius: 6px;
  background: #ffffff;
`;

const SelectSort = styled.select`
  padding: 10px 12px;
  gap: 8px;
  border: 1px solid #d1d5dc;
  border-radius: 6px;
  background: #ffffff;
`;

const AnalyticLayout = (props) => {
  const dispatch = useDispatch();
  const { indexAnalytics, listKeywords, listProducts, listUsage } = useSelector(
    (state) => state.AnalyticReducer
  );
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [dataIndex, setDataIndex] = useState({});
  const [dataKeywords, setDataKeywords] = useState({});
  const [dataProducts, setDataProducts] = useState({});
  const [dataUsage, setDataUsage] = useState({});

  useEffect(() => {
    if (!indexAnalytics.loading && indexAnalytics.status === 200) {
      setDataIndex(indexAnalytics.data.data);
    }
  }, [indexAnalytics]);

  useEffect(() => {
    if (!listKeywords.loading && listKeywords.status === 200) {
      setDataKeywords(listKeywords.data.data);
    }
  }, [listKeywords]);

  useEffect(() => {
    if (!listProducts.loading && listProducts.status === 200) {
      setDataProducts(listProducts.data.data);
    }
  }, [listProducts]);

  useEffect(() => {
    if (!listUsage.loading && listUsage.status === 200) {
      setDataUsage(listUsage.data.data);
    }
  }, [listUsage]);

  function onHandleSearch() {
    if (startDate === "" || endDate === "") return false;
    dispatch(getIndexAnalytics({ start_date: startDate, end_date: endDate }));
    dispatch(
      getKeywordsAnalytics({ start_date: startDate, end_date: endDate })
    );
    dispatch(
      getProductsAnalytics({ start_date: startDate, end_date: endDate })
    );
    dispatch(getUsageAnalytics({ start_date: startDate, end_date: endDate }));
  }

  return (
    <Fragment>
      <Breadcrumb
        parent="Analytics"
		title="Analytics"
        urlParent="/dashboard/analytics"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="d-flex">
                {/* <SelectSort className="mx-1" type="select" onChange={() => {}}>
                  <option value={1}>By Date</option>
                  <option value={2}>By Newest</option>
                </SelectSort> */}
                <InputDate
                  className="mx-1"
                  type="date"
                  max="9999-12-31"
                  min="1700-12-31"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
                <InputDate
                  className="mx-1"
                  type="date"
                  max="9999-12-31"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                />
                <Button
                  className="mx-1"
                  color="primary"
                  onClick={onHandleSearch}
                >
                  Search Analytics
                </Button>
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <TransactionCount data={dataIndex} />
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <TransactionSum data={dataIndex} />
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <KeywordsCountTable data={dataKeywords} />
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <ProductCountTable data={dataProducts} />
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <UserUsageTable data={dataUsage} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AnalyticLayout;
