import Product from "../../pages/dashboard/product";
import ProductAdd from "../../pages/dashboard/product/add";
import ProductEdit from "../../pages/dashboard/product/edit";
import ProductDetail from "../../pages/dashboard/product/detail";
import ProductLogs from "../../pages/dashboard/product/productLog";
import ProductImage from "../../pages/dashboard/product/productImage";
import ProductPrice from "../../pages/dashboard/product/productPrice";
import ProductDiscount from "../../pages/dashboard/product/productDiscount";
import ProductDiscountAdd from "../../pages/dashboard/product/productDiscount/add";
import ProductDiscountEdit from "../../pages/dashboard/product/productDiscount/edit";

export const RouteProduct = [
  {
    path: `/dashboard/product`,
    Component: Product,
  },
  {
    path: `/dashboard/product/add`,
    Component: ProductAdd,
  },
  {
    path: `/dashboard/product/edit/:id`,
    Component: ProductEdit,
  },
  {
    path: `/dashboard/product/detail/:id`,
    Component: ProductDetail,
  },
  {
    path: `/dashboard/product/logs/:id`,
    Component: ProductLogs,
  },
  {
    path: `/dashboard/product/images/:id`,
    Component: ProductImage,
  },
  {
    path: `/dashboard/product/price/:id`,
    Component: ProductPrice,
  },
  {
    path: `/dashboard/product/discount/:id`,
    Component: ProductDiscount,
  },
  {
    path: `/dashboard/product/discount/:id/add`,
    Component: ProductDiscountAdd,
  },
  {
    path: `/dashboard/product/discount/:id/edit`,
    Component: ProductDiscountEdit,
  },
];
