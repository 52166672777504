import React, { useState } from "react";
import { Box } from "@mui/material";
import { Button, Col, FormGroup, Input, Label } from "reactstrap";
import { toast } from "react-toastify";

const ModalUpload = ({
  onSubmit,
  onClose,
  description,
  descClose,
  descSubmit,
  label,
  type,
  action,
}) => {
  const [file, setFile] = useState(null);
  const [data, setData] = useState("active");

  const handleFile = (e) => {
    const fileType = type === "csv" ? "text/csv" : "application/zip";
    if (e.target.files.length > 0) {
      const fileText = e.target.files[0];
      if (fileText.type !== fileType) {
        toast.error(`File must contain ${type} format`);
        document.getElementById("formFile").value = "";
      } else {
        setFile(fileText);
      }
    }
  };

  const isImage = (imgType) => {
    switch (imgType) {
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
        return true;
    }
    return false;
  };

  const handleFileImage = (e) => {
    if (e.target.files.length > 0) {
      const filesImg = e.target.files[0];
      if (filesImg.size > 2000000) {
        toast.error(`Max file size is 2MB`);
        document.getElementById("formImage").value = "";
      } else {
        if (!isImage(filesImg.type)) {
          toast.error(`Select appropiate image file.`);
          document.getElementById("formImage").value = "";
        }
        setFile(filesImg);
      }
    }
  };

  return (
    <Box className="flex flex-col">
      {description ? (
        <div
          style={{ backgroundColor: "#E9EDFD", padding: 10, borderRadius: 5 }}
        >
          <p style={{ marginBottom: 0 }}>
            Resource,{" "}
            <span style={{ color: "#2647E6" }}>
              Download Blank CSV Template
            </span>
          </p>
        </div>
      ) : null}

      <div>
        <Label for="formFile" style={{ marginTop: 10, fontWeight: "bold" }}>
          {label} <span style={{ color: "red" }}>*</span>
        </Label>
        <FormGroup row>
          <Col sm={12}>
            {type === "image" ? (
              <Input
                type="file"
                name="selectBanner"
                id="formImage"
                accept="image/*"
                onChange={handleFileImage}
              />
            ) : (
              <Input
                className="p-2"
                type="file"
                name="file"
                id="formFile"
                style={{
                  border: "1px solid #d2d2d4",
                  borderRadius: "5px",
                }}
                onChange={handleFile}
              />
            )}
          </Col>
        </FormGroup>
      </div>

      {action ? (
        <div className={"mb-4"}>
          <Label for="selectStatus" style={{ fontWeight: "bold" }}>
            Action <span style={{ color: "red" }}>*</span>
          </Label>
          <Input
            type="select"
            name="select"
            id="selectStatus"
            onChange={(event) => setData(event.target.value)}
          >
            <option key={1} value={"active"}>
              Active
            </option>
            <option key={0} value={"deactive"}>
              Deactive
            </option>
          </Input>
        </div>
      ) : null}

      <Box
        className="flex flex-end ml-auto mt-12"
        style={{ textAlign: "right" }}
      >
        <Button color="primary" outline onClick={onClose}>
          {descClose !== "" ? descClose : "Batal"}
        </Button>
        <Button
          className="ml-2"
          color="primary"
          onClick={() => onSubmit(file, data)}
        >
          {descSubmit !== "" ? descSubmit : "Kirim"}
        </Button>
      </Box>
    </Box>
  );
};

export default ModalUpload;
