import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import {useParams, Link} from "react-router-dom";
import iconSearch from "../../../../../assets/images/input-search.png";
import {useDispatch, useSelector} from "react-redux";
import {changeStatusCategory, deleteDataCategory, getChildCategory} from "../../../../../redux/action/categoryAction";
import BadgeStatus from "../../../../../Component/badgeStatus";
import WrapperTableAction from "../../../../../Component/styledComponents/wrapperBtnAction";
import ButtonAction from "../../../../../Component/styledComponents/btnTableAction";
import BtnDropdownContent from "../../../../../Component/styledComponents/btnDropdownContent";
import BtnDropdownAction from "../../../../../Component/styledComponents/btnDropdownAction";
import TableCSI from "../../../../../Component/table";
import PaginationRow from "../../../../../Component/paginationDataPerPage";
import CustomPagination from "../../../../../Component/customPagination";
import ModalContent from "../../../../../Component/modal/ModalContent";
import {ModalDialog} from "../../../../../Component/modal/ModalDialog";

const CategoryChild = (props) => {
  const [tableColumn, setTableColumn] = useState([]);
  const [rawData, setRawData] = useState([]);
  const [data, setData] = useState([]);
  const [isFetchedData, setIsFetchedData] = useState(true);
  const [isAfterSearch, setIsAfterSearch] = useState(false);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [totalResult, setTotalResult] = useState(4);
  const [totalPerPage, setTotalPerPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const urlParams = useParams();
  const dispatch = useDispatch();
  const {childCategory} = useSelector(
      (state) => state.CategoryReducer
  );
  const [modalState, setModalState] = useState(null);

  useEffect(() => {
    dispatch(getChildCategory(urlParams?.id))
    setTableColumn([
          {
            Header: () => null,
            id: "Category",
            columns: [
              {
                Header: "ID",
                accessor: "id",
              },
              {
                Header: "Image",
                accessor: "image_url",
                Cell: ({row}) => (
                    <div>
                      <img src={row.original.image_url} alt={""} width={50} />
                    </div>
                )
              },
              {
                Header: "Name",
                accessor: "name",
              },
              {
                Header: "Ordering",
                accessor: "ordering",
              },
              {
                Header: "Description",
                accessor: "description",
              },
              {
                Header: "Status",
                accessor: "active",
                Cell: ({row}) => <BadgeStatus status={row.original.active}/>,
              },
              {
                Header: "Action",
                accessor: "action",
                Cell: ({row}) => (
                    <div className="d-flex">
                      <div className="mx-2">
                        <Link
                            to={{pathname: `/dashboard/category/child/${row.original?.id}/edit`, data: row?.original}}
                        >
                          <Button color="primary" outline>
                            Edit
                          </Button>
                        </Link>
                      </div>
                      <WrapperTableAction>
                        <ButtonAction className="mx-2">...</ButtonAction>
                        <BtnDropdownContent>
                          <BtnDropdownAction className="py-0" onClick={() => showModal(row?.original, "delete")}>
                            <Link to="#">Delete</Link>
                          </BtnDropdownAction>
                          <br />
                          <BtnDropdownAction className="py-0" onClick={() => showModal(row?.original, "deactive")}>
                            <Link to="#">Deactivate</Link>
                          </BtnDropdownAction>
                        </BtnDropdownContent>
                      </WrapperTableAction>
                    </div>
                ),
              },
            ],
          },
        ],
        []
    );
  }, [])

  useEffect(() => {
    if (childCategory.data && !childCategory.loading) {
      let dataCategory = childCategory?.data?.data;
      let mappedListData = dataCategory.map((item) => ({
        id: item?.id,
        image_url: item?.image_url,
        name: item?.name,
        ordering: item?.ordering,
        description: item?.description,
        active: 1,
        parent_category: urlParams?.id
      }));
      setRawData(mappedListData);
      setData(mappedListData?.slice(0, perPage));
      setMaxPageIndex(dataCategory?.length/perPage);
      setTotalResult(dataCategory?.length);
      setTotalPerPage(10);
      setIsFetchedData(true);
    }
  }, [childCategory]);

  const handleSubmitSearch = () => {
    if (inputSearchValue !== "") {
      let dataSearch = childCategory?.data?.data?.filter((v) => v.name?.toLowerCase()?.includes(inputSearchValue?.toLowerCase()))
      setRawData(dataSearch)
      setData(dataSearch.slice(0, perPage))
      setMaxPageIndex(dataSearch?.length/perPage);
      setIsAfterSearch(true);
    } else {
      dispatch(getChildCategory(urlParams?.id));
    }
  };

  const handleInputSearch = (e) => {
    setInputSearchValue(e.target.value);
  };

  const handleOnEnter = (e) => {
    if (inputSearchValue !== "") {
      let dataSearch = childCategory?.data?.data?.filter((v) => v.name?.toLowerCase()?.includes(inputSearchValue?.toLowerCase()))
      setRawData(dataSearch)
      setData(dataSearch.slice(0, perPage))
      setMaxPageIndex(dataSearch?.length/perPage);
      setIsAfterSearch(true);
    } else {
      dispatch(getChildCategory(urlParams?.id));
    }
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    setData(rawData.slice(data.selected*perPage, perPage*page))
    setIsAfterSearch(true);
  };

  const onChangePaginationRow =(perPage) => {
    setIsFetchedData(false);
    setPerPage(perPage);
    dispatch(getChildCategory(urlParams?.id))
  }

  const showModal = (row, type) => {
    setModalState((prevState) => ({
      ...prevState,
      openModal: true,
      modalType: type,
      modalTitle: "Informasi",
      modalData: row,
      closeIcon: true,
    }));
  };

  const handleDelete = (data) => {
    dispatch(deleteDataCategory(data?.id));
    setModalState(null);
    dispatch(getChildCategory(urlParams?.id));
  }

  const handleDeactive = (data) => {
    setIsFetchedData(true);
    dispatch(changeStatusCategory(data?.id));
    setIsFetchedData(false);
    setModalState(null);
    dispatch(getChildCategory(urlParams?.id));
  }

  return (
    <Fragment>
      <Breadcrumb parent="Category" title="Category Child" urlParent={'/dashboard/category'} />
      <Container fluid={true}>
        <Row>
          <Col>
            <Card className="p-0">
              <CardBody className="p-3">
                <div className="d-flex justify-content-between my-2">
                  <InputGroup style={{ maxWidth: "280px" }}>
                    <Input
                        placeholder="Search"
                        value={inputSearchValue}
                        onChange={(e) => handleInputSearch(e)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") handleOnEnter(e);
                        }}
                    />
                    <InputGroupAddon addonType="prepend">
                      <Button className="p-2" color="primary" onClick={() => handleSubmitSearch()}>
                        <img src={iconSearch} width={15} height={15} alt={""} />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  <Link to={{pathname: `/dashboard/category/child/${urlParams?.id}/add`}}>
                    <Button outline color="primary">
                      Add new Category
                    </Button>
                  </Link>
                </div>
                {isFetchedData ?
                    <TableCSI
                        data={data}
                        columns={tableColumn}
                        key={data}
                        initialSortBy={initialSortBy}
                    /> : null}
                <div className="d-flex justify-content-between">
                  <PaginationRow
                      onChangePaginationRow={onChangePaginationRow}
                  />
                  <CustomPagination
                      handlePageClick={handlePageClick}
                      pageCount={maxPageIndex}
                      pageRangeDisplayed={2}
                      marginPagesDisplayed={2}
                  />
                </div>
              </CardBody>
              <ModalDialog
                  open={modalState?.openModal ?? false}
                  type={modalState?.modalType ?? ""}
                  footer={false}
                  title={modalState?.modalTitle ?? ""}
                  titleFontSize={18}
                  backdropClose={true}
                  onClose={() => {
                    setModalState(null);
                  }}
                  maxWidth={384}
              >
                <ModalContent
                    onClose={() => {
                      setModalState(null);
                    }}
                    onSubmit={() => modalState?.modalType === "delete" ? handleDelete(modalState?.modalData) : handleDeactive(modalState?.modalData)}
                    data={modalState?.modalData ?? null}
                    descClose={"Batal"}
                    descSubmit={modalState?.modalType === "delete" ? "Hapus" : "Deactive"}
                    description={`${modalState?.modalType === "delete" ? "Hapus" : "Deactive"} data category ${
                        modalState?.modalData?.name
                    }?`}
                />
              </ModalDialog>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CategoryChild;
