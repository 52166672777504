import Transaction from "../../pages/dashboard/transaction";
import TransactionDetail from "../../pages/dashboard/transaction/detail";
import TransactionEdit from "../../pages/dashboard/transaction/edit";
import AddDocumentTransaction from "../../pages/dashboard/transaction/add";
import TransactionPaidConfirm from "../../pages/dashboard/transaction/paidConfirmPage";
import TransactionAcceptForm from "../../pages/dashboard/transaction/confirmFormPage/accept";
import TransactionDeliverForm from "../../pages/dashboard/transaction/confirmFormPage/deliver";
import TransactionCompleteForm from "../../pages/dashboard/transaction/confirmFormPage/complete";
import TransactionSROForm from "../../pages/dashboard/transaction/confirmFormPage/sro";

export const RouteTransaction = [
  {
    path: `/dashboard/transaction`,
    Component: Transaction,
  },
  {
    path: `/dashboard/transaction/detail/:id`,
    Component: TransactionDetail,
  },
  {
    path: `/dashboard/transaction/edit/:id`,
    Component: TransactionEdit,
  },
  {
    path: `/dashboard/transaction/detail/:id/add`,
    Component: AddDocumentTransaction,
  },
  {
    path: `/dashboard/transaction/detail/:id/edit/:id_item`,
    Component: AddDocumentTransaction,
  },
  {
    path: `/dashboard/transaction/detail/:id/detail/:id_item`,
    Component: AddDocumentTransaction,
  },
  {
    path: `/dashboard/transaction/paidconfirm/:id`,
    Component: TransactionPaidConfirm,
  },
  {
    path: `/dashboard/transaction/paidconfirm/:id/accept`,
    Component: TransactionAcceptForm,
  },
  {
    path: `/dashboard/transaction/paidconfirm/:id/deliver`,
    Component: TransactionDeliverForm,
  },
  {
    path: `/dashboard/transaction/paidconfirm/:id/complete`,
    Component: TransactionCompleteForm,
  },
  {
    path: `/dashboard/transaction/paidconfirm/:id/sro`,
    Component: TransactionSROForm,
  },
];
