import {
  GET_CHAT_CONFIG,
  GET_USER_UID,
  GET_LIST_CHAT_USER,
  GET_CHAT_HISTORY,
  GET_ADMIN_PRODUCT,
  GET_ADMIN_PROMO,
  GET_ADMIN_INVOICE,
  CLEAR_DATA_LIST,
  SET_USER_UID,
  SET_LATEST_MESSAGE,
  GET_LATEST_MESSAGE,
  SET_NOTIF_DATA,
  GET_ADMIN_PROMO_SEARCH,
  GET_ADMIN_PRODUCT_SEARCH,
  SET_ENABLE_FETCH_CHAT,
  SET_USER_INFO,
} from "../../actionTypes";

const initialState = {
  chatConfig: {},
  listConversationChat: {},
  chatHistory: [],
  listAdminProduct: [],
  listAdminPromo: [],
  listAdminPromoSearch: {},
  listAdminProductSearch: {},
  listAdminInvoice: [],
  cometchatUID: {},
  chatLatestMessage: {},
  notifData: {
    loading: false,
    fromNotif: false,
    data: {},
    err: false,
  },
  stateIsFetchChat: {},
  userInfoData: {},
};

const ChatReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CHAT_CONFIG:
      state.chatConfig = action.payload.data;
      return { ...state, chatConfig: state.chatConfig };
    case GET_USER_UID:
      return { ...state, cometchatUID: state.cometchatUID };
    case SET_USER_UID:
      state.cometchatUID = action.payload;
      return { ...state, cometchatUID: state.cometchatUID };
    case GET_LIST_CHAT_USER:
      state.listConversationChat = action.payload;
      return { ...state, listConversationChat: state.listConversationChat };
    case GET_CHAT_HISTORY:
      state.chatHistory = action.payload.data;
      return { ...state, chatHistory: state.chatHistory };
    case GET_ADMIN_PRODUCT:
      state.listAdminProduct = [
        ...state.listAdminProduct,
        ...action.payload.data.data,
      ];
      return { ...state, listAdminProduct: state.listAdminProduct };
    case GET_ADMIN_PROMO:
      state.listAdminPromo = [...state.listAdminPromo, ...action.payload.data];
      return { ...state, listAdminPromo: state.listAdminPromo };
    case GET_ADMIN_INVOICE:
      state.listAdminInvoice = [
        ...state.listAdminInvoice,
        ...action.payload.data,
      ];
      return { ...state, listAdminInvoice: state.listAdminInvoice };
    case SET_LATEST_MESSAGE:
      state.chatLatestMessage = action.payload;
      return { ...state, chatLatestMessage: state.chatLatestMessage };
    case GET_LATEST_MESSAGE:
      return { ...state, chatLatestMessage: state.chatLatestMessage };
    case SET_NOTIF_DATA:
      state.notifData = action.payload;
      return { ...state, notifData: state.notifData };
    case GET_ADMIN_PROMO_SEARCH:
      state.listAdminPromoSearch = action.payload;
      return { ...state, listAdminPromoSearch: state.listAdminPromoSearch };
    case GET_ADMIN_PRODUCT_SEARCH:
      state.listAdminProductSearch = action.payload;
      return { ...state, listAdminProductSearch: state.listAdminProductSearch };
    case SET_ENABLE_FETCH_CHAT:
      state.stateIsFetchChat = action.payload;
      return { ...state, stateIsFetchChat: state.stateIsFetchChat };
    case SET_USER_INFO:
      state.userInfoData = action.payload;
      return { ...state, userInfoData: state.userInfoData };

    case CLEAR_DATA_LIST:
      state.listAdminPromo = [];
      state.listAdminProduct = [];
      state.listAdminInvoice = [];
      return {
        ...state,
        listAdminProduct: state.listAdminProduct,
        listAdminPromo: state.listAdminPromo,
        listAdminInvoice: state.listAdminInvoice,
      };
    default:
      return { ...state };
  }
};

export default ChatReducer;
