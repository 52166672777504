import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { Button } from "reactstrap";
import Breadcrumb from "@layout/breadcrumb";
import TableCSI from "@components/table";
import TableLayout from "@layout/tableLayout";
import ButtonAction from "@components/styledComponents/btnTableAction";
import TableDropdown from "@components/customDropdown";

import { getListLevel, updateRowLevel } from "@action/levelAction";

const LevelLayout = () => {
  const [isFetchedData, setIsFetchedData] = useState(false);
  const [mappedListData, setMappedListData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const [totalPerPage, setTotalPerPage] = useState(0);

  const dispatch = useDispatch();
  const { listLevel } = useSelector((state) => state.LevelReducer);

  useEffect(() => {
    fetchTable();
  }, []);

  useEffect(() => {
    if (listLevel.data && !listLevel.loading) {
      const resp = listLevel.data.data;
      if (resp === undefined || resp === null) return false;

      const newData = resp.map((item) => ({
        id: item.id,
        level: item.title,
        omset: `${item.start_xp} - ${item.end_xp}`,
        customer: item.customer,
        status: item.active === "Y" ? 1 : 0,
      }));

      setMaxPageIndex(listLevel.data.last_page);
      setTotalResult(listLevel.data.total);
      setTotalPerPage(listLevel.data.to);

      setMappedListData(newData);
      setIsFetchedData(true);
    }
  }, [listLevel]);

  function fetchTable() {
    dispatch(getListLevel(1, "", 10));

    setTableColumn([
      {
        Header: () => null,
        id: "levelColomn",
        columns: [
          {
            Header: "ID",
            accessor: "id",
            sortAble: true,
          },
          {
            Header: "Level",
            accessor: "level",
            sortAble: true,
          },
          {
            Header: "Omset per tahun",
            accessor: "omset",
            sortAble: true,
          },
          {
            Header: "Customer",
            accessor: "customer",
            sortAble: true,
            Cell: ({ row }) => <p>{row.original.customer} Customer</p>,
          },
          {
            Header: "Action",
            accessor: "action",
            Cell: ({ row }) => (
              <div className="d-flex">
                <Link
                  className="mr-1"
                  to={`/dashboard/level/edit/${row.original.id}`}
                >
                  <ButtonAction>Edit</ButtonAction>
                </Link>
                <TableDropdown
                  onToggleStatus={onHandleDeactivate}
                  row={row.original}
                />
              </div>
            ),
            disableSortBy: true,
            sortAble: false,
          },
        ],
      },
    ]);
  }

  const onHandleDeactivate = ({ id, toggle }) => {
    setIsFetchedData(false);
    const formData = new FormData();

    switch (toggle) {
      case "deactive":
        formData.append("active", "N");
        break;
      case "active":
        formData.append("active", "Y");
        break;
      default:
        break;
    }

    dispatch(updateRowLevel(id.id, formData)).then((resp) => {
      if (resp.status !== 200) return;

      fetchTable();
    });
  };

  const handleSearch = (val) => {
    dispatch(getListLevel(1, val, 10));
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListLevel(page, "", 10));
  };

  const headerAction = (
    <Link to={`/dashboard/level/add`}>
      <Button className="shadow-sm border-0" color="primary">
        Add New Level
      </Button>
    </Link>
  );

  return (
    <Fragment>
      <Breadcrumb
        parent="Dashboard"
        title="Level Customer"
        urlParent={"/dashboard/analytics"}
      />
      <TableLayout
        headerAction={headerAction}
        handleSearch={handleSearch}
        totalEntry={totalResult}
        totalEntryPerPage={totalPerPage}
        pageCount={maxPageIndex}
        handleChangePage={handlePageClick}
        handleChangeRow={(val) => dispatch(getListLevel(1, "", val))}
      >
        <TableCSI
          columns={tableColumn}
          data={mappedListData}
          key={mappedListData}
        />
      </TableLayout>
    </Fragment>
  );
};

export default LevelLayout;
