import { Fragment, useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import BadgeStatus from "@components/badgeStatus";
import Breadcrumbs from "@layout/breadcrumb";
import TableCSI from "@components/table";
import SearchBar from "@components/searchBar";
import PaginationRow from "@components/paginationDataPerPage";
import CustomPagination from "@components/customPagination";
import TableDropdown from "@components/customDropdown";
import { Card, CardBody } from "reactstrap";

const VoucherDetail = () => {
  const { id } = useParams();
  const [column, setColumn] = useState([]);

  useEffect(() => {
    fetchTable();
  }, []);

  function fetchTable() {
    setColumn([
      {
        Header: () => null,
        id: "VoucherDetail",
        columns: [
          {
            Header: "OID",
            accessor: "id",
            sortAble: true,
          },
          {
            Header: "Customer",
            accessor: "customer",
            sortAble: true,
          },
          {
            Header: "Status",
            accessor: "status",
            sortAble: true,
            Cell: ({ row }) => <BadgeStatus status={row.original.status} />,
          },
          {
            Header: "Action",
            accessor: "action",
            Cell: ({ row }) => (
              <div className="d-flex flex-row">
                <TableDropdown
                  dropdownContent={[
                    {
                      label: "Delete",
                      type: "delete",
                    },
                  ]}
                  onToggleStatus={() => {}}
                  row={row.original}
                  onDeleteAction={() => {}}
                />
              </div>
            ),
            disableSortBy: true,
            sortAble: false,
          },
        ],
      },
    ]);
  }

  return (
    <Fragment>
      <Breadcrumbs
        parent="Voucher"
        title="Detail Voucher"
        urlParent={"/dashboard/promo/voucher"}
      />

      <Card className="shadow-sm border-0">
        <CardBody>
          <div>
            <div className="d-flex flex-row justify-content-between">
              <div className="d-flex flex-row align-items-center">
                <h3 className="text-uppercase my-auto mr-2">Promo 10.10</h3>
                <BadgeStatus status={1} />
              </div>
              <div>
                <Link to={`/dashboard/promo/voucher/edit/${id}`}>
                  <button className="border-1 rounded-lg bg-white px-3 py-2">
                    Edit
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <hr />
          <div className="d-flex flex-lg-row flex-md-column justify-content-between">
            <div className="d-flex flex-column">
              <span style={{ color: "gray" }}>Expired</span>
              <p className="mx-0 my-1">29/01/2024</p>
            </div>
            <div className="d-flex flex-column">
              <span style={{ color: "gray" }}>Jenis Voucher</span>
              <p className="mx-0 my-1">Loyalty</p>
            </div>
            <div className="d-flex flex-column">
              <span style={{ color: "gray" }}>Target Voucher</span>
              <p className="mx-0 my-1">Semua</p>
            </div>
            <div className="d-flex flex-column">
              <span style={{ color: "gray" }}>Kode Voucher</span>
              <p className="mx-0 my-1">101HanyaTextBukanFormatData</p>
            </div>
            <div className="d-flex flex-column">
              <span style={{ color: "gray" }}>Minimum Qty</span>
              <p className="mx-0 my-1">5</p>
            </div>
            <div className="d-flex flex-column">
              <span style={{ color: "gray" }}>Minimum Pembelian</span>
              <p className="mx-0 my-1">2</p>
            </div>
            <div className="d-flex flex-column">
              <span style={{ color: "gray" }}>Jenis Penawaran</span>
              <p className="mx-0 my-1">Free Gift</p>
            </div>
          </div>
        </CardBody>
      </Card>

      <Card className="shadow-sm border-0">
        <CardBody>
          <div className="d-flex flex-row justify-content-between">
            <h3>Penggunaan Voucher</h3>
            <SearchBar
              onSearch={() => {}}
              totalEntry={0}
              totalEntryPerPage={0}
            />
          </div>
          <TableCSI columns={column} data={[]} />
          <div className="d-flex justify-content-between">
            <PaginationRow onChangePaginationRow={() => {}} />
            <CustomPagination
              handlePageClick={() => {}}
              pageCount={1}
              pageRangeDisplayed={2}
              marginPagesDisplayed={2}
            />
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default VoucherDetail;
