import apiConfig from "../../api";
import {
  ADD_BRAND,
  GET_LIST_BRAND,
  UPDATE_BRAND,
  WATCH_RESPONSE_ALERT,
} from "../actionTypes";

export const getListBrand = (perPage = 10, page = 1, param = "") => {
  return (dispatch) => {
    return apiConfig
      .get(`/api/admin/brand?page=${page}&search=${param}&perPage=${perPage}`)
      .then((resp) => {
        dispatch({
          type: GET_LIST_BRAND,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LIST_BRAND,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const addDataBrand = (payload) => {
  return (dispatch) => {
    dispatch({
      type: ADD_BRAND,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post(`/api/admin/brand`, payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: true,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const updateDataBrand = (payload, type) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_BRAND,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    let url = type === "status" ? "ActiveBrand" : "";

    apiConfig
      .post(`/api/admin/brand/update${url}`, payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: true,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};
