import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Row,
  Badge,
  Col,
  Card,
  CardTitle,
  CardHeader,
  CardBody,
  Media,
  FormGroup,
  Button,
  InputGroup,
  Input,
  Label,
} from "reactstrap";

import produkExample from "../../../../../assets/images/product/image 4.png";

const AttachOffer = (props) => {
  const toggleCloseSales = (param) => {
    props.onClose(param);
  };

  const dataOffer = [
    {
      produk_name: "Gourmia - 8-Quart Pressure Cooker",
      price: 1190000,
      stock: 10,
      image: produkExample,
      id: 1,
    },
  ];

  return (
    <Fragment>
      <div>
        <div className="d-flex justify-content-start my-1">
          <Button onClick={() => toggleCloseSales("")} close />
          <p className="my-auto mx-2"><b>Sales Order</b></p>
        </div>
        <div className="my-3">
          {dataOffer.map((item) => (
            <div className="my-3 d-flex" key={item.id}>
              <div className="mr-2">
                <Media src={item.image} className="rounded-circle" alt="" />
              </div>
              <div>
                <div>
                  <p className="m-0">{item.produk_name}</p>
                  <p className="m-0">
                    Rp. <span>{item.price}</span>
                  </p>
                  <span>Stock: {item.stock}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="my-3">
          <h5>Ringkasan</h5>
          <div className="d-flex justify-content-between my-2">
            <span>Sub Total</span>
            <span>
              <b>Rp. 2.600.000</b>
            </span>
          </div>
          <div className="d-flex justify-content-between my-2">
            <span>Total Diskon</span>
            <span>
              <b>Rp. 460.000</b>
            </span>
          </div>
          <div className="d-flex justify-content-between my-2">
            <span>Total Harga</span>
            <span>
              <b>Rp. 2.180.000</b>
            </span>
          </div>
        </div>
        <div className="att_produk_btn d-flex my-2">
          <Button color="primary" block>
            Create Sales Order
          </Button>
        </div>
      </div>
    </Fragment>
  );
};

export default AttachOffer;
