import styled from "styled-components";

const BtnDeactivate = styled.div`
  color: red;
  border: none;
  cursor: pointer;
  background: transparent;
  font-size: 14px;
  margin: 8px;
`;

export default BtnDeactivate