import User from "../../pages/dashboard/user";
import UserEdit from "../../pages/dashboard/user/edit";
import UserOTPLog from "../../pages/dashboard/user/otplogs";
import UserUpdateInfo from "../../pages/dashboard/user/updateInfo";

export const RouteUser = [
  {
    path: `/dashboard/user`,
    Component: User,
  },
  {
    path: `/dashboard/user/edit/:id`,
    Component: UserEdit,
  },
  {
    path: `/dashboard/user/otplog/:id`,
    Component: UserOTPLog,
  },
  {
    path: `/dashboard/user/update-info/:id`,
    Component: UserUpdateInfo,
  },
];
