import React, { Fragment, useState, useEffect } from "react";

const EntryCounter = ({totalPerPage, totalResult}) => {
  return (
    <Fragment>
      <span className="my-lg-auto mx-lg-2">
        Show {totalPerPage} of {totalResult} entries
      </span>
    </Fragment>
  );
};

export default EntryCounter;
