import { Fragment, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import Cookies from "universal-cookie";

//cometchat
import { CometChat } from "@cometchat-pro/chat";

import {
  getListChatUsers,
  storeLatestMessage,
  isEnableFetchChat,
} from "../redux/action/chatAction";
import { setUserChat, getLoginConfigs } from "../redux/action/loginAction";
import { listenerUID } from "../constant/param";

import Loader from "../layout/loader";
import Header from "../layout/header";
import Sidebar from "../layout/sidebar";
import Footer from "../layout/footer";
import notification_outchat from "../assets/audio/notification_outchat.mp3";
import "react-toastify/dist/ReactToastify.css";
import ErrorBoundary from "../features/errorBoundaries";

const App = ({ children }) => {
  const dispatch = useDispatch();
  const currLocation = useLocation();
  const history = useHistory();
  const { alertResponse } = useSelector((state) => state.AlertReducer);
  const cookies = new Cookies();
  const toastId = useRef(null);

  useEffect(() => {
    initListenerMessage();

    if (
      pageAccessedByReload() === "reload" ||
      pageAccessedByReload() === "navigate"
    ) {
      //Init cometchat and login cometchat
      initCometchat();
    }
  }, []);

  //TODO: to-be changed this navigation flow
  useEffect(() => {
    if (alertResponse.status === 200) {
      toast.success(alertResponse.data.message);
    }
  }, [alertResponse]);

  const initListenerMessage = () => {
    const listenerID = cookies.get("listenerID");
    CometChat.addMessageListener(
      listenerID,
      new CometChat.MessageListener({
        onTextMessageReceived: (textMessage) => {
          let src = notification_outchat;
          let audio = new Audio(src);
          audio.play();
          dispatch(getListChatUsers());
          dispatch(storeLatestMessage(textMessage));
        },
        onMediaMessageReceived: (mediaMessage) => {
          let src = notification_outchat;
          let audio = new Audio(src);
          audio.play();
          dispatch(getListChatUsers());
          dispatch(storeLatestMessage(mediaMessage));
        },
        onCustomMessageReceived: (customMessage) => {
          let src = notification_outchat;
          let audio = new Audio(src);
          audio.play();
          dispatch(getListChatUsers());
          dispatch(storeLatestMessage(customMessage));
        },
      })
    );
  };

  const initListenerStatusChat = () => {
    cookies.set("listenerID", listenerUID(), { path: "/" });
    let listenerID = cookies.get("listenerID");
    CometChat.addConnectionListener(
      listenerID,
      new CometChat.ConnectionListener({
        onConnected: () => {
          dispatch(isEnableFetchChat());
          toast.update(toastId.current, {
            render: "Online chat is connected",
            type: toast.TYPE.SUCCESS,
            autoClose: 2500,
            closeOnClick: true,
            pauseOnHover: true,
          });
        },
        inConnecting: () => {
          toastId.current = toast(
            "Online chat is diconnected, reconnecting...",
            {
              position: "bottom-right",
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              autoClose: 2500,
              type: toast.TYPE.ERROR,
            }
          );
        },
        onDisconnected: () => {
          toastId.current = toast("Online chat is not-connected", {
            position: "bottom-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            type: toast.TYPE.ERROR,
          });
        },
      })
    );
    CometChat.addLoginListener(
      listenerID,
      new CometChat.LoginListener({
        loginSuccess: (e) => {
          cookies.set("isConnectedChat", true, { path: "/" });
        },
        loginFailure: (e) => {
          cookies.set("isConnectedChat", false, { path: "/" });
        },
      })
    );
  };

  const pageAccessedByReload = () => {
    let result, p;
    if (window.performance.getEntriesByType("navigation")) {
      p = window.performance.getEntriesByType("navigation")[0].type;

      switch (p) {
        case "navigate":
          result = "navigate";
          break;
        case "reload":
          result = "reload";
          break;
        case "back_forward":
          result = "back_forward";
          break;
        default:
          break;
      }
    }
    return result;
  };

  const initCometchat = () => {
    dispatch(getLoginConfigs())
      .then((resp) => {
        let dataResp = resp.data.data;
        const configData = dataResp.reduce(
          (data, key) => ({ ...data, [key.key]: key.value }),
          {}
        );
        const adminUID = cookies.get("uid");

        //Cometchat init
        const appID = configData.come_chat_app_id;
        const region = configData.come_chat_region;
        const appSetting = new CometChat.AppSettingsBuilder()
          .subscribePresenceForAllUsers()
          .setRegion(region)
          .build();

        CometChat.init(appID, appSetting).then(
          () => {
            console.log("Cometchat Initialization completed");
            //Cometchat login
            CometChat.login(adminUID, configData.come_chat_auth_key).then(
              (user) => {
                dispatch(setUserChat(user));
                initListenerStatusChat();
              },
              (error) => {
                console.log("Login failed with exception:", error);
              }
            );
          },
          (error) => {
            console.log("Initialization failed with error:", error);
            // Check the reason for error and take appropriate action.
          }
        );
      })
      .catch((err) => {
        console.log("Login failed with error:", err.response);
      });
  };

  return (
    <Fragment>
      <ErrorBoundary>
        <Loader />
        <div className="page-wrapper compact-wrapper" id="pageWrapper">
          <Header />
          <div className="page-body-wrapper sidebar-icon">
            <Sidebar />
            <div className="page-body">{children}</div>
            <Footer />
          </div>
        </div>
      </ErrorBoundary>
    </Fragment>
  );
};

export default App;
