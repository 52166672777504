import React, { useEffect, useState, Fragment } from "react";
import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import { Stepper } from "react-form-stepper";
import draftToHtml from "draftjs-to-html";
import {
  convertToRaw,
  EditorState,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { useDispatch } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import { createCampaign } from "../../../../../redux/action/campaignAction";
import AddParticipant from "./addParticipant";
import AddProduct from "./addProduct";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

const AddCampaign = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currStepper, setCurrentStepper] = useState(1);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [imgPreviewSrc, setImgPreviewSrc] = useState("");
  const [formDetail, setFormDetail] = useState({
    title: "",
    start_date: "",
    end_date: "",
    type: "",
    ordering: "",
    banner: "",
    items: [],
    content: "",
    type_target: "",
    target: "",
    participants: [],
  });
  const [typeTarget, setTypeTarget] = useState("");
  const [editorInput, setEditorInput] = useState(EditorState.createEmpty());
  const [typeItemSelection, setTypeItemSelection] = useState("");
  const [tempProductData, setTempProductData] = useState([]);
  const [tempProductColumn, setTempProductColumn] = useState([]);

  const toggleCurrentStepper = () => {
    if (currStepper < 3) {
      if (currStepper === 1) {
        validateFirstStepForm();
      } else if (currStepper === 2) {
        validateSecondStepForm();
      }
    } else {
      validateThirdStepForm();
    }
  };

  const toggleBackStepper = () => {
    if (currStepper > 1) {
      setCurrentStepper(currStepper - 1);
    } else {
      history.push("/dashboard/campaign");
    }
  };

  const handleSubmitData = () => {
    setLoadingSubmit(!loadingSubmit);
    let dataForm = new FormData();
    let formattedAmmount = formDetail.target.replaceAll(",", "");
    dataForm.append("banner", formDetail.banner);
    dataForm.append("content", formDetail.content);
    dataForm.append("start_date", formDetail.start_date);
    dataForm.append("end_date", formDetail.end_date);
    dataForm.append("ordering", formDetail.ordering);
    dataForm.append("target", formattedAmmount);
    dataForm.append("title", formDetail.title);
    dataForm.append("type_target", formDetail.type_target);
    dataForm.append("type", formDetail.type);

    if (typeItemSelection !== "all") {
      for (let i of formDetail.items) {
        dataForm.append("items[]", i);
      }
    } else {
      dataForm.append("items[]", formDetail.items);
    }

    for (let x of formDetail.participants) {
      dataForm.append("participants[]", x);
    }
    dispatch(createCampaign(dataForm));
  };

  const validateFirstStepForm = () => {
    if (validateForm(formDetail)) {
      toast.error(`Please fill all fields.`);
    } else if (validateDate() === 1) {
      toast.error("End Date cannot be earlier than Start Date or Today Date");
      setFormDetail({
        ...formDetail,
        end_date: "",
      });
    } else setCurrentStepper(currStepper + 1);
  };

  const validateSecondStepForm = () => {
    if (validateFormProduct(formDetail)) {
      toast.error(`Please add product first.`);
    } else setCurrentStepper(currStepper + 1);
  };

  const validateThirdStepForm = () => {
    if (validateFormParticipant(formDetail)) {
      toast.error(`Please add participant.`);
    } else if (
      !validateForm(formDetail) &&
      !validateFormProduct(formDetail) &&
      !validateFormParticipant(formDetail)
    ) {
      handleSubmitData();
    } else toast.error(`Something Wrong`);
  };

  const isImage = (imgType) => {
    switch (imgType) {
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
        return true;
    }
    return false;
  };

  const validateForm = (data) => {
    if (
      data.title === "" ||
      data.start_date === "" ||
      data.end_date === "" ||
      data.type_target === "" ||
      data.target === "" ||
      data.ordering === "" ||
      data.banner === "" ||
      data.content === "" ||
      data.content.length === 8
    ) {
      return true;
    }
    return false;
  };

  const validateFormProduct = (data) => {
    if (data.type === "" || data.items.length < 1) {
      return true;
    }
    return false;
  };

  const validateFormParticipant = (data) => {
    if (data.participants.length < 1) {
      return true;
    }
    return false;
  };

  const validateDate = () => {
    if (formDetail.start_date !== "" && formDetail.end_date) {
      const today = new Date();
      const todayDate = moment.utc(today).format("YYYY-MM-DD");
      if (formDetail.start_date > formDetail.end_date) return 1;
      if (todayDate > formDetail.end_date) return 1;
      if (formDetail.start_date < formDetail.end_date) return -1;
    }
    return 0;
  };

  const validateOrder = (e) => {
    if (e.key === "-") {
      e.preventDefault();
    }
  };

  const handleFileInput = (e) => {
    if (e.target.files.length > 0) {
      const filesImg = e.target.files[0];
      if (filesImg.size > 2000000) {
        toast.error(`Max file size is 2MB`);
        document.getElementById("formFile").value = "";
      } else {
        if (!isImage(filesImg.type)) {
          toast.error(`Select appropiate image file.`);
          document.getElementById("formFile").value = "";
        } else {
          const imgSrc = URL.createObjectURL(filesImg);
          setImgPreviewSrc(imgSrc);
          setFormDetail({ ...formDetail, banner: filesImg });
        }
      }
    }
  };

  const handleTypeTarget = (type) => {
    setTypeTarget(type);
    setFormDetail({
      ...formDetail,
      type_target: type,
    });
  };

  const handleConvertContentToHtml = (e) => {
    let convertedHtml = draftToHtml(convertToRaw(e.getCurrentContent()));
    setFormDetail({
      ...formDetail,
      content: convertedHtml,
    });
    setEditorInput(e);
  };

  const handleOnSubmitProduct = (column, data, typeProduct, typeSelection) => {
    if (typeSelection === "all") {
      setTypeItemSelection(typeSelection);
      setFormDetail({ ...formDetail, items: typeSelection, type: typeProduct });
      setTempProductData(data);
      setTempProductColumn(column);
    } else {
      setTypeItemSelection("");
      let newItem = data.map((item) => item.id);
      setFormDetail({ ...formDetail, items: newItem, type: typeProduct });
      setTempProductData(data);
      setTempProductColumn(column);
    }
  };

  const handleOnSubmitParticipant = (data) => {
    let newItem = data.map((item) => item.id);
    setFormDetail({ ...formDetail, participants: newItem });
  };

  function getNumber(_str) {
    var arr = _str.split("");
    var out = new Array();
    for (var cnt = 0; cnt < arr.length; cnt++) {
      if (isNaN(arr[cnt]) == false) {
        out.push(arr[cnt]);
      }
    }
    return Number(out.join(""));
  }

  const onTypedAmmount = (e) => {
    var num = getNumber(e.target.value);

    if (num == 0) {
      e.target.value = "";
      setFormDetail({
        ...formDetail,
        target: "",
      });
    } else {
      e.target.value = num.toLocaleString();
      setFormDetail({
        ...formDetail,
        target: e.target.value,
      });
    }
  };

  useEffect(() => {
    if (currStepper === 1) {
      if (!validateForm(formDetail)) {
        let typeTarget = formDetail.type_target;
        let radioAmmount = document.getElementById("radioTargetAmmount");
        let radioQty = document.getElementById("radioTargetQty");

        setTypeTarget(typeTarget);
        if (typeTarget === "amount") {
          radioAmmount.checked = true;
        } else {
          radioQty.checked = true;
        }

        const htmlMarkup = formDetail.content || "";
        const blocksFromHTML = convertFromHTML(htmlMarkup);
        const state = ContentState.createFromBlockArray(
          blocksFromHTML.contentBlocks,
          blocksFromHTML.entityMap
        );
        setEditorInput(EditorState.createWithContent(state));
      }
    }
  }, [currStepper]);

  useEffect(() => {
    let abortController = new AbortController();

    return () => {
      abortController.abort();
    };
  }, []);

  return (
    <Fragment>
      <Breadcrumb
        parent="Campaign"
        title="Add New Campaign"
        urlParent="/dashboard/campaign"
      />
      <Container fluid>
        <Row>
          <Col sm={8}>
            <Card>
              <Stepper
                steps={[
                  { label: "Campaign Detail" },
                  { label: "Products" },
                  { label: "Participants" },
                ]}
                activeStep={currStepper}
                styleConfig={{
                  activeBgColor: "grey",
                  completedBgColor: "blue",
                }}
              />
              {currStepper === 1 ? (
                <CardBody className="p-3">
                  <Form
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <FormGroup row>
                      <Label for="formTitle" sm={2}>
                        <b>Title</b>
                      </Label>
                      <Col sm={10}>
                        <Input
                          placeholder="Enter Title"
                          id="formTitle"
                          value={formDetail.title}
                          onChange={(e) =>
                            setFormDetail({
                              ...formDetail,
                              title: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="selectStartDate" sm={2}>
                        <b>Start Date</b>
                      </Label>
                      <Col sm={10}>
                        <Input
                          type="date"
                          name="date"
                          id="selectStartDate"
                          placeholder="Start Date"
                          max="9999-12-31"
                          min="1700-12-31"
                          value={formDetail.start_date}
                          onChange={(e) =>
                            setFormDetail({
                              ...formDetail,
                              start_date: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="selectEndDate" sm={2}>
                        <b>End Date</b>
                      </Label>
                      <Col sm={10}>
                        <Input
                          type="date"
                          name="date"
                          id="selectEndDate"
                          placeholder="End Date"
                          max="9999-12-31"
                          value={formDetail.end_date}
                          onChange={(e) =>
                            setFormDetail({
                              ...formDetail,
                              end_date: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup tag="fieldset" row className="d-flex mb-1">
                      <Col>
                        <b>Target</b>
                      </Col>
                      <Col sm={10} className="d-flex mx-3">
                        <FormGroup check className="mx-2">
                          <Label check>
                            <Input
                              type="radio"
                              name="radioTarget"
                              id="radioTargetAmmount"
                              onChange={(e) =>
                                e.target.checked
                                  ? handleTypeTarget("amount")
                                  : ""
                              }
                            />{" "}
                            Amount
                          </Label>
                        </FormGroup>
                        <FormGroup check className="mx-2">
                          <Label check>
                            <Input
                              type="radio"
                              name="radioTarget"
                              id="radioTargetQty"
                              onChange={(e) =>
                                e.target.checked ? handleTypeTarget("qty") : ""
                              }
                            />{" "}
                            Quantity
                          </Label>
                        </FormGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row className="mt-1">
                      <Col></Col>
                      <Col sm={10}>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            {typeTarget === "amount" ? (
                              <span
                                className="p-2"
                                style={{ border: "1px solid grey" }}
                              >
                                Rp
                              </span>
                            ) : typeTarget === "qty" ? (
                              <span
                                className="p-2"
                                style={{ border: "1px solid grey" }}
                              >
                                Pcs
                              </span>
                            ) : null}
                          </InputGroupAddon>
                          {typeTarget !== "" ? (
                            <Input
                              type="text"
                              placeholder="0"
                              id="formAmount"
                              value={formDetail.target}
                              onChange={(e) => onTypedAmmount(e)}
                            />
                          ) : (
                            ""
                          )}
                        </InputGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="formOrder" sm={2}>
                        <b>Order</b>
                      </Label>
                      <Col sm={10}>
                        <Input
                          type="number"
                          name="formOrder"
                          placeholder="0"
                          id="formOrder"
                          min={0}
                          value={formDetail.ordering}
                          onKeyDown={validateOrder}
                          onChange={(e) =>
                            setFormDetail({
                              ...formDetail,
                              ordering: e.target.value,
                            })
                          }
                        />
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Label for="formFile" sm={2}>
                        <b>Banner (720 x 480)</b>
                      </Label>
                      <Col sm={10}>
                        <Input
                          className="p-2"
                          type="file"
                          name="file"
                          id="formFile"
                          accept="image/*"
                          onChange={handleFileInput}
                          style={{
                            border: "1px solid #d2d2d4",
                            borderRadius: "5px",
                          }}
                        />
                      </Col>
                    </FormGroup>
                    <div className="d-flex my-2">
                      <span>
                        <b>Banner Preview</b>
                      </span>
                      <img
                        className="mx-auto"
                        src={imgPreviewSrc}
                        alt=""
                        width={620}
                        height={360}
                        style={{ objectFit: "contain" }}
                      />
                    </div>
                    <FormGroup row>
                      <Label for="exampleText" sm={2}>
                        <b>Content</b>
                      </Label>
                      <Col sm={10}>
                        <Editor
                          className="richtext-editor"
                          editorState={editorInput}
                          onEditorStateChange={handleConvertContentToHtml}
                          editorStyle={{
                            border: "1px #c6c9cf solid",
                            height: "250px",
                          }}
                        />
                      </Col>
                    </FormGroup>
                  </Form>
                </CardBody>
              ) : currStepper === 2 ? (
                <AddProduct
                  handleOnSubmitProduct={handleOnSubmitProduct}
                  tempProductColumn={tempProductColumn}
                  tempProductData={tempProductData}
                />
              ) : (
                <AddParticipant
                  handleOnSubmitParticipant={handleOnSubmitParticipant}
                />
              )}
            </Card>
            <div className="d-flex justify-content-between mt-2 mb-3">
              <button
                className="btn-csi-white"
                onClick={() => toggleBackStepper()}
              >
                {currStepper === 1 ? "Cancel" : "Back"}
              </button>
              <Button
                color="primary"
                onClick={() => toggleCurrentStepper()}
                disable={loadingSubmit ? "true" : "false"}
              >
                {currStepper === 3 ? "Submit" : "Next"}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default AddCampaign;
