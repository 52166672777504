import apiConfig from "../../api";
import {
  GET_LIST_TABLE_FLASHSALE,
  WATCH_RESPONSE_ALERT,
  GET_LIST_PRODUK_FLASHSALE,
  GET_DETAIL_FLASHSALE,
} from "../actionTypes";

export const getListTableFlashsale = (page, param, perPage) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(
        `/api/admin/flashale?page=${page}${
          param !== "" ? "&" + param : ""
        }&perPage=${perPage ? perPage : 10}`
      );
      dispatch({
        type: GET_LIST_TABLE_FLASHSALE,
        payload: {
          loading: false,
          data: resp.data,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: GET_LIST_TABLE_FLASHSALE,
        payload: {
          loading: false,
          data: false,
          err: err.message,
        },
      });
    }
  };
};

export const deleteRowFlashsale = (id) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.delete(`/api/admin/flashale/detail/${id}`);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: false,
          err: false,
        },
      });

	  return resp
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });
    }
  };
};

export const searchProduct = (page, param, type) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_PRODUK_FLASHSALE,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(
        `/api/admin/products?search=${param}&page=${page}${
          type === "flashsale" ? `&type=flashsale` : ""
        }`
      )
      .then((resp) => {
        dispatch({
          type: GET_LIST_PRODUK_FLASHSALE,
          payload: {
            loading: false,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LIST_PRODUK_FLASHSALE,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const createFlashsale = (payload, locationKey) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post("/api/admin/flashale", payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
            to: locationKey,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const fetchDetailFlashsale = (param) => {
  return (dispatch) => {
    dispatch({
      type: GET_DETAIL_FLASHSALE,
      payload: {
        loading: true,
        data: {},
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/flashale/detail/${param}`)
      .then((resp) => {
        dispatch({
          type: GET_DETAIL_FLASHSALE,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_DETAIL_FLASHSALE,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const updateFlashsale = (id, payload, locationKey) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(
        `/api/admin/flashale/update/${id}`,
        payload
      );
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: false,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      if (err.response) {
        console.log(err.response);
      } else if (err.request) {
        console.log(err.request);
      } else if (err.message) {
        console.log(err.message);
      }
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });
    }
  };
};

export const deleteFlashsaleBanner = (id) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .delete(`/api/admin/flashale/banner/${id}`)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const getCSVListFlashsale = () => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/flashale/export`, { responseType: "blob" })
      .then((resp) => {
        var blob = new Blob([resp.data], {
          type: resp.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Flashsale_report_${new Date().getTime()}.xlsx`;
        link.click();
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const deleteFlashsaleProduct = (id) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .delete(`/api/admin/flashale/product/${id}`)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        if (err.response) {
          console.log(err.response);
        } else if (err.request) {
          console.log(err.request);
        } else if (err.message) {
          console.log(err.message);
        }
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};
