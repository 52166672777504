import ConfigLayout from "../../pages/dashboard/konfigurasi/config";
import ConfigAdminLayout from "../../pages/dashboard/konfigurasi/admin";
import ConfigNavlogLayout from "../../pages/dashboard/konfigurasi/navlog";

export const RouteConfig = [
  {
    path: `/dashboard/configs`,
    Component: ConfigLayout,
  },
  {
    path: `/dashboard/admin`,
    Component: ConfigAdminLayout,
  },
  {
    path: `/dashboard/navlog`,
    Component: ConfigNavlogLayout,
  },
];
