import React, { Fragment, useEffect, useState } from "react";
import { Input, InputGroup, InputGroupAddon, Button } from "reactstrap";

import TableCSI from "../../../../Component/table";
import EntryCounter from "../../../../Component/entryCounter";
import iconSearch from "../../../../assets/images/input-search.png";

const ProductCountTable = ({ data }) => {
  const [isDataReady, setIsDataReady] = useState(false);
  const [tableColumn, setTableColumn] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [totalResult, setTotalResult] = useState(0);
  const [totalPerPage, setTotalPerPage] = useState(0);

  useEffect(() => {
    setIsDataReady(true);
    setTableColumn([
      {
        Header: () => null,
        id: "keywordsCountTable1",
        columns: [
          {
            Header: "SKU Code",
            accessor: "sku_code",
            width: 150,
            sortAble: true,
          },
          {
            Header: "Name",
            accessor: "name",
            width: 150,
            sortAble: true,
          },
          {
            Header: "Total",
            accessor: "total",
            width: 150,
            sortAble: true,
          },
        ],
      },
    ]);
  }, []);

  useEffect(() => {
    if (data.data && data.data.length > 0) {
      const mappedData = data.data.map((arr) => ({
        sku_code: arr.sku_code,
        name: arr.name,
        total: arr.total,
      }));
      setTableData(mappedData);
    }
  }, [data]);

  return (
    <Fragment>
      <h5>Product Seens Count</h5>
      {/* <div className="d-flex">
        <InputGroup style={{ maxWidth: "280px" }}>
          <Input
            placeholder="Search"
            onChange={(e) => console.log(e)}
            onKeyDown={(e) => {
              console.log(e);
            }}
          />
          <InputGroupAddon addonType="prepend">
            <Button className="p-2" color="primary" onClick={() => {}}>
              <img src={iconSearch} width={15} height={15} />
            </Button>
          </InputGroupAddon>
        </InputGroup>

        <EntryCounter totalResult={totalResult} totalPerPage={totalPerPage} />
      </div> */}

      <TableCSI data={tableData} columns={tableColumn} />
    </Fragment>
  );
};

export default ProductCountTable;
