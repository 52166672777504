import { GET_INDEX, GET_KEYWORDS_ANALYTICS, GET_PRODUCTS_ANALYTICS, GET_USAGE_ANALYTICS } from "../../actionTypes";

const initialState = {
  indexAnalytics: {},
  listKeywords: {},
  listProducts: {},
  listUsage: {},
};

const AnalyticReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_INDEX:
      state.indexAnalytics = action.payload;
      return { ...state, indexAnalytics: state.indexAnalytics };
    case GET_KEYWORDS_ANALYTICS:
      state.listKeywords = action.payload;
      return { ...state, listKeywords: state.listKeywords };
    case GET_PRODUCTS_ANALYTICS:
      state.listProducts = action.payload;
      return { ...state, listProducts: state.listProducts };
    case GET_USAGE_ANALYTICS:
      state.listUsage = action.payload;
      return { ...state, listUsage: state.listUsage };
    default:
      return { ...state };
  }
};

export default AnalyticReducer;
