import {
    GET_LIST_USER, GET_OTP_LOG, GET_SYNC_USER, GET_UPDATE_INFO
} from "../../actionTypes";

const initialState = {
    listUser: {},
    otpLog: {},
    syncUser: {},
    updateInfo: {}
};

const UserReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_USER:
            state.listUser = action.payload;
            return { ...state, listUser: state.listUser };
        case GET_OTP_LOG:
            state.otpLog = action.payload;
            return { ...state, otpLog: state.otpLog };
        case GET_SYNC_USER:
            state.syncUser = action.payload;
            return { ...state, syncUser: state.syncUser };
        case GET_UPDATE_INFO:
            state.updateInfo = action.payload;
            return { ...state, updateInfo: state.updateInfo };
        default:
            return { ...state };
    }
};

export default UserReducer;
