import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import Breadcrumb from "@layout/breadcrumb";
import { FormWrapper } from "@components/formComponent";
import { Col, Input, Label, FormGroup } from "reactstrap";

import { createLevel } from "@action/levelAction";

const LevelAdd = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [formPayload, setFormPayload] = useState({
    title: "",
    start_point: "",
    end_point: "",
  });
  const [disableSubmit, setDisableSubmit] = useState(false);

  const validateForm = (data) => {
    if (data.title === "" || data.start_point === "" || data.end_point === "") {
      toast.error("Please fill all fields");
      return true;
    }
    return false;
  };

  const compareMinMaxNumber = (data) => {
    const num1 = parseInt(data.start_point);
    const num2 = parseInt(data.end_point);
    const result = num1 < num2;
    if (result) return true;
	
    toast.error("Omset akhir tidak bisa lebih kecil dari Omset awal.");
    return false;
  };

  const handleSubmitForm = () => {
    if (!validateForm(formPayload)) {
      if (compareMinMaxNumber(formPayload)) {
        setDisableSubmit(true);
        let formData = new FormData();
        formData.append("title", formPayload.title);
        formData.append("start_xp", formPayload.start_point);
        formData.append("end_xp", formPayload.end_point);

        dispatch(createLevel(formData)).then((resp) => {
          if (resp.status !== 200) return;

          setDisableSubmit(false);
          history.push("/dashboard/level");
        });
      }
    }
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Level"
        title="Add New Level"
        urlParent={"/dashboard/level"}
      />
      <FormWrapper
        disableSubmit={disableSubmit}
        onSubmit={handleSubmitForm}
        onCancel={() => history.push("/dashboard/level")}
      >
        <FormGroup row>
          <Label for="formNamaBroadcast" sm={3}>
            <b>Title</b>
          </Label>
          <Col sm={9}>
            <Input
              placeholder="Enter Title Name"
              id="formNamaBroadcast"
              value={formPayload.title}
              onChange={(e) =>
                setFormPayload({
                  ...formPayload,
                  title: e.target.value,
                })
              }
            />
          </Col>
        </FormGroup>
        <FormGroup row>
          <Label for="formStartPoint" sm={3}>
            <b>Omset per tahun</b>
          </Label>
          <Col className="d-flex flex-row justify-content-between" sm={9}>
            <Input
              placeholder="0"
              type="number"
              id="formStartPoint"
              min={0}
              value={formPayload.start_point}
              onChange={(e) =>
                setFormPayload({
                  ...formPayload,
                  start_point: e.target.value,
                })
              }
            />

            <span className="d-flex flex-row my-auto mx-2">
              <i className="fa fa-minus" aria-hidden="true"></i>
              <i className="fa fa-minus" aria-hidden="true"></i>
            </span>

            <Input
              placeholder="0"
              type="number"
              id="formStartPoint"
              className="ml-2"
              min={0}
              value={formPayload.end_point}
              onChange={(e) =>
                setFormPayload({
                  ...formPayload,
                  end_point: e.target.value,
                })
              }
            />
          </Col>
        </FormGroup>
      </FormWrapper>
    </Fragment>
  );
};

export default LevelAdd;
