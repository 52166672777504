import apiConfig from "../../api";
import {
    ADD_SECTION,
    GET_LIST_SECTION,
    UPDATE_SECTION,
    DELETE_SECTION,
    DEACTIVE_SECTION,
    ADD_SECTION_ITEM,
    GET_LIST_SECTION_ITEM,
    ORDER_SECTION_ITEM,
    DELETE_SECTION_ITEM,
    WATCH_RESPONSE_ALERT
} from "../actionTypes";

export const getListSection = (perPage = 10, page= 1, param = "") => {
    return (dispatch) => {
        dispatch({
            type: GET_LIST_SECTION,
            payload: {
                loading: true,
                data: false,
                err: false,
            },
        });

        apiConfig
            .get(`/api/admin/sections?page=${page}&search=${param}&perPage=${perPage}`)
            .then((resp) => {
                dispatch({
                    type: GET_LIST_SECTION,
                    payload: {
                        loading: false,
                        data: resp.data.data,
                        err: false,
                    },
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: GET_LIST_SECTION,
                    payload: {
                        loading: false,
                        data: false,
                        err: err.message,
                    },
                });
            });
    };
};

export const addDataSection = (payload) => {
    return (dispatch) => {
        dispatch({
            type: ADD_SECTION,
            payload: {
                loading: true,
                status: null,
                data: {},
                err: false,
            },
        });

        apiConfig
            .post(`/api/admin/sections`, payload)
            .then((resp) => {
                dispatch({
                    type: WATCH_RESPONSE_ALERT,
                    payload: {
                        loading: false,
                        status: resp.status,
                        data: resp.data,
                        goBack: true,
                        err: false,
                    },
                });
            })
            .catch((err) => {
                if (err.response) {
                    console.log(err.response);
                } else if (err.request) {
                    console.log(err.request);
                } else if (err.message) {
                    console.log(err.message);
                }
                dispatch({
                    type: WATCH_RESPONSE_ALERT,
                    payload: {
                        loading: false,
                        data: err,
                        goBack: false,
                        err: true,
                    },
                });
            });
    };
};

export const updateDataSection = (payload) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_SECTION,
            payload: {
                loading: true,
                status: null,
                data: {},
                err: false,
            },
        });

        apiConfig
            .put(`/api/admin/sections`, payload)
            .then((resp) => {
                dispatch({
                    type: WATCH_RESPONSE_ALERT,
                    payload: {
                        loading: false,
                        status: resp.status,
                        data: resp.data,
                        goBack: true,
                        err: false,
                    },
                });
            })
            .catch((err) => {
                if (err.response) {
                    console.log(err.response);
                } else if (err.request) {
                    console.log(err.request);
                } else if (err.message) {
                    console.log(err.message);
                }
                dispatch({
                    type: WATCH_RESPONSE_ALERT,
                    payload: {
                        loading: false,
                        data: err,
                        goBack: false,
                        err: true,
                    },
                });
            });
    };
};

export const deleteDataSection = (id) => {
    return (dispatch) => {
        dispatch({
            type: DELETE_SECTION,
            payload: {
                loading: true,
                status: null,
                data: {},
                err: false,
            },
        });

        apiConfig
            .delete(`/api/admin/sections/${id}`)
            .then((resp) => {
                dispatch({
                    type: WATCH_RESPONSE_ALERT,
                    payload: {
                        loading: false,
                        status: resp.status,
                        data: resp.data,
                        goBack: true,
                        err: false,
                    },
                });
            })
            .catch((err) => {
                if (err.response) {
                    console.log(err.response);
                } else if (err.request) {
                    console.log(err.request);
                } else if (err.message) {
                    console.log(err.message);
                }
                dispatch({
                    type: WATCH_RESPONSE_ALERT,
                    payload: {
                        loading: false,
                        data: err,
                        goBack: false,
                        err: true,
                    },
                });
            });
    };
};

export const deactiveDataSection = (id) => {
    return (dispatch) => {
        dispatch({
            type: DEACTIVE_SECTION,
            payload: {
                loading: true,
                status: null,
                data: {},
                err: false,
            },
        });

        apiConfig
            .get(`/api/admin/sections/status/${id}`)
            .then((resp) => {
                dispatch({
                    type: WATCH_RESPONSE_ALERT,
                    payload: {
                        loading: false,
                        status: resp.status,
                        data: resp.data,
                        goBack: true,
                        err: false,
                    },
                });
            })
            .catch((err) => {
                if (err.response) {
                    console.log(err.response);
                } else if (err.request) {
                    console.log(err.request);
                } else if (err.message) {
                    console.log(err.message);
                }
                dispatch({
                    type: WATCH_RESPONSE_ALERT,
                    payload: {
                        loading: false,
                        data: err,
                        goBack: false,
                        err: true,
                    },
                });
            });
    };
};

export const getListSectionItem = (perPage = 10, page= 1, param = "", id) => {
    return (dispatch) => {
        dispatch({
            type: GET_LIST_SECTION_ITEM,
            payload: {
                loading: true,
                data: false,
                err: false,
            },
        });

        apiConfig
            .get(`/api/admin/sections/items/${id}?page=${page}&search=${param}&perPage=${perPage}`)
            .then((resp) => {
                dispatch({
                    type: GET_LIST_SECTION_ITEM,
                    payload: {
                        loading: false,
                        data: resp.data.data,
                        err: false,
                    },
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: GET_LIST_SECTION_ITEM,
                    payload: {
                        loading: false,
                        data: false,
                        err: err.message,
                    },
                });
            });
    };
};

export const addDataSectionItem = (payload) => {
    return (dispatch) => {
        dispatch({
            type: ADD_SECTION_ITEM,
            payload: {
                loading: true,
                status: null,
                data: {},
                err: false,
            },
        });

        apiConfig
            .post(`/api/admin/sections/items`, payload)
            .then((resp) => {
                dispatch({
                    type: WATCH_RESPONSE_ALERT,
                    payload: {
                        loading: false,
                        status: resp.status,
                        data: resp.data,
                        goBack: true,
                        err: false,
                    },
                });
            })
            .catch((err) => {
                if (err.response) {
                    console.log(err.response);
                } else if (err.request) {
                    console.log(err.request);
                } else if (err.message) {
                    console.log(err.message);
                }
                dispatch({
                    type: WATCH_RESPONSE_ALERT,
                    payload: {
                        loading: false,
                        data: err,
                        goBack: false,
                        err: true,
                    },
                });
            });
    };
};

export const orderingDataSectionItem = (payload) => {
    return (dispatch) => {
        dispatch({
            type: ORDER_SECTION_ITEM,
            payload: {
                loading: true,
                status: null,
                data: {},
                err: false,
            },
        });

        apiConfig
            .post(`/api/admin/sections/items/ordering`, payload)
            .then((resp) => {
                dispatch({
                    type: WATCH_RESPONSE_ALERT,
                    payload: {
                        loading: false,
                        status: resp.status,
                        data: resp.data,
                        goBack: true,
                        err: false,
                    },
                });
            })
            .catch((err) => {
                if (err.response) {
                    console.log(err.response);
                } else if (err.request) {
                    console.log(err.request);
                } else if (err.message) {
                    console.log(err.message);
                }
                dispatch({
                    type: WATCH_RESPONSE_ALERT,
                    payload: {
                        loading: false,
                        data: err,
                        goBack: false,
                        err: true,
                    },
                });
            });
    };
};

export const deleteDataSectionItem = (id, type) => {
    return (dispatch) => {
        dispatch({
            type: DELETE_SECTION_ITEM,
            payload: {
                loading: true,
                status: null,
                data: {},
                err: false,
            },
        });

        apiConfig
            .delete(`/api/admin/sections/items/${type === "all" ? `all/${id}` : id}`)
            .then((resp) => {
                dispatch({
                    type: WATCH_RESPONSE_ALERT,
                    payload: {
                        loading: false,
                        status: resp.status,
                        data: resp.data,
                        goBack: true,
                        err: false,
                    },
                });
            })
            .catch((err) => {
                if (err.response) {
                    console.log(err.response);
                } else if (err.request) {
                    console.log(err.request);
                } else if (err.message) {
                    console.log(err.message);
                }
                dispatch({
                    type: WATCH_RESPONSE_ALERT,
                    payload: {
                        loading: false,
                        data: err,
                        goBack: false,
                        err: true,
                    },
                });
            });
    };
};
