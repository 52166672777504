import { Fragment } from "react";
import PropTypes from "prop-types";

import { Input, Modal, ModalBody } from "reactstrap";

const AlertApproval = ({
  isOpenAlert,
  isNote,
  modalTitle,
  modalSubTitle,
  handleToggle,
}) => {
  return (
    <Fragment>
      <Modal isOpen={isOpenAlert} toggle={handleToggle} size="sm" centered>
        <ModalBody>
          <div className="d-flex flex-column text-center my-2 py-1">
            <h3>{modalTitle}</h3>
            <span>{modalSubTitle}</span>
          </div>
          {isNote ? (
            <Input
              className="mx-auto"
              type="textarea"
              placeholder="Note"
              name="textNote"
              id="textNote"
            />
          ) : null}
          <div className="d-flex flex-row justify-content-center">
            <button
              className="border rounded-lg bg-white p-2 mx-2 my-3"
              onClick={handleToggle}
            >
              Cancel
            </button>
            <button
              className="border rounded-lg bg-primary p-2 mx-2 my-3"
              onClick={handleToggle}
            >
              Confirm
            </button>
          </div>
        </ModalBody>
      </Modal>
    </Fragment>
  );
};

AlertApproval.propTypes = {
  isOpenAlert: PropTypes.bool.isRequired,
  isNote: PropTypes.bool.isRequired,
  modalTitle: PropTypes.string.isRequired,
  modalSubTitle: PropTypes.string.isRequired,
};

export default AlertApproval;
