import { Fragment } from "react";

const CurrencyFormatter = ({ digit, typeDigit }) => {
  return (
    <Fragment>
      {typeDigit === "amount" ? (
        <span>
          {(digit ? digit : "").toLocaleString("id-ID", {
            style: "currency",
            currency: "IDR",
            minimumFractionDigits: 2,
          })}
        </span>
      ) : (
        <span>
          {(digit ? digit : "").toLocaleString("id-ID", {
            minimumFractionDigits: 2,
          })}
          {" Pcs"}
        </span>
      )}
    </Fragment>
  );
};

export default CurrencyFormatter;
