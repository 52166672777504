import {
	WATCH_RESPONSE_ALERT,
  } from "../../actionTypes";
  
  const initialState = {
	alertResponse: {},
  };
  
  const AlertReducer = (state = initialState, action) => {
	switch (action.type) {
	  case WATCH_RESPONSE_ALERT:
		state.alertResponse = action.payload;
		return { ...state, alertResponse: state.alertResponse };
	  default:
		return { ...state };
	}
  };
  
  export default AlertReducer;
  