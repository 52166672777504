import { Fragment, useState } from "react";

import { InputSwitch } from "@components/formComponent";
import SwitchAndOr from "../component/SwitchAndOr";
import PopUpKondisi from "../component/PopUpKondisi";
import FilterByKondisiProduct from "../component/filterByKondisiProduct";
import FilterByManufacture from "../component/filterByManufacture";
import FilterByKategori from "../component/filterByKategori";
import FilterBySKU from "../component/filterBySKU";

const AddKondisiProduct = ({
  productOn,
  setProductOn,
  productCondition,
  setProductCondition,
  isAddKondisi,
  setAddKondisi,
  isManufaktur,
  setManufaktur,
  manufakturData,
  setManufakturData,
  selectedManufactur,
  setSelectedManufactur,
  isKategori,
  setKategori,
  kategoriData,
  setKategoriData,
  selectedKategori,
  setSelectedKategori,
  isSKU,
  setSKU,
  skuData,
  setSkuData,
  selectedSKU,
  setSelectedSKU,
  supertier,
  setSupertier,
  setBenefitData,
  setBenefitSuperTier,
  setBenefitKelipatan,
  productConditionKC,
  setProductConditionKC,
	isBenefit,
}) => {
  const [openModal, setModal] = useState(false);

  function handleAddFilter(val) {
    switch (val) {
      case "manufactur":
        setManufaktur(true);
        break;
      case "kategori":
        setKategori(true);
        break;
      case "sku":
        setSKU(true);
        break;
      default:
        break;
    }
  }

  function handleAddKondisi() {
    setModal(false);
    setAddKondisi([
      ...isAddKondisi,
      {
        name: `${
          isAddKondisi?.length
            ? parseInt(isAddKondisi[isAddKondisi.length - 1].name) + 1
            : 1
        }`,
      },
    ]);
  }

  const handleRemoveCondition = (itemName) => {
    setAddKondisi((prevData) =>
      prevData.filter((item) => item.name !== itemName)
    );
  };

  return (
    <Fragment>
      <div className="my-3 p-3 border rounded-lg">
        <div className="d-flex flex-row justify-content-between">
          <div className="d-flex my-1">
            <div className="my-auto">
              <InputSwitch
                value={productOn}
                onChange={(val) => setProductOn(val.target.checked)}
              />
            </div>
            <span className="mx-2 font-weight-bold h5">Product</span>
          </div>
          <div className="d-flex">
            <SwitchAndOr
              value={productCondition}
              onChange={(val) => setProductCondition(val)}
            />
            <button
              className="btn btn-primary"
              onClick={() => setModal(!openModal)}
            >
              {"+"} Tambah Kondisi
            </button>
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center my-2 p-3">
          {isManufaktur && (
            <FilterByManufacture
              onToggle={() => {
                setManufakturData({ value: "", type: "manufactur" });
                setSelectedManufactur([]);
                setManufaktur(!isManufaktur);
              }}
              selectedData={selectedManufactur}
              setSelectedData={setSelectedManufactur}
              filterData={manufakturData}
              setFilterData={setManufakturData}
            />
          )}
          {isKategori && (
            <FilterByKategori
              onToggle={() => {
                setKategoriData({ value: "", type: "kategori" });
                setSelectedKategori([]);
                setKategori(!isKategori);
              }}
              selectedData={selectedKategori}
              setSelectedData={setSelectedKategori}
              filterData={kategoriData}
              setFilterData={setKategoriData}
            />
          )}
          {isSKU && (
            <FilterBySKU
              typeRadio="SKU"
              onToggle={() => {
                setSkuData({ value: "", type: "sku", group: "kelipatan" });
                setSelectedSKU([]);
                setSKU(!isSKU);
								setBenefitSuperTier(null);
								setBenefitKelipatan(null);
								if (isBenefit) {
									setBenefitData([]);
								}
              }}
              selectedData={selectedSKU}
              setSelectedData={setSelectedSKU}
              filterData={skuData}
              setFilterData={setSkuData}
              supertier={supertier}
              setSupertier={setSupertier}
              setBenefitData={setBenefitData}
              setBenefitSuperTier={setBenefitSuperTier}
              setBenefitKelipatan={setBenefitKelipatan}
            />
          )}

          {!isAddKondisi?.length
            ? null
            : isAddKondisi.map((item) => (
                <FilterByKondisiProduct
                  kondisi="product"
                  item={item}
                  onDeleteFilter={() => handleRemoveCondition(item.name)}
                  productCondition={productConditionKC}
                  setProductCondition={setProductConditionKC}
                  isAddKondisi={isAddKondisi}
                  setAddKondisi={setAddKondisi}
                />
              ))}

          {!isManufaktur && !isKategori && !isSKU && !isAddKondisi?.length ? (
            <div className="bg-light p-3 rounded-lg text-center text-dark">
              <span>Silahkan untuk menambahkan filter</span>
            </div>
          ) : (
            ""
          )}
        </div>

        <PopUpKondisi
          kondisi="product"
          isOpen={openModal}
          onToggle={() => setModal(!openModal)}
          onAddFilter={handleAddFilter}
          onAddKondisi={handleAddKondisi}
        />
      </div>
    </Fragment>
  );
};

export default AddKondisiProduct;
