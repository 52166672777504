import React, { useState, Fragment } from "react";
import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import {useLocation} from "react-router-dom";
import {useDispatch} from "react-redux";
import {updateDataSection} from "../../../../../redux/action/sectionAction";

const defaultPayload = {
  id: "",
  title: ""
}

const SectionEdit = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [data, setData] = useState(location ? location?.data : defaultPayload);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleSubmit = () => {
    setDisableSubmit(true);
    const dataForm = new URLSearchParams(
        Object.entries(data)
    ).toString();
    dispatch(updateDataSection(dataForm));

    setTimeout(() => {
      setDisableSubmit(false);
    }, 500);
  }

  return (
    <Fragment>
      <Breadcrumb parent="Section" title="Edit Section" urlParent={'/dashboard/section'} />
      <Container fluid>
        <Row>
          <Col sm={8}>
            <Card>
              <CardBody className="p-3">
                <Form>
                  <FormGroup row>
                    <Label for="formTitle" sm={2}>
                      Title
                    </Label>
                    <Col sm={10}>
                      <Input placeholder="Enter Title" id="formTitle" value={data?.title} onChange={(event) => setData({...data, title: event.target.value})} />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="selectStartDate" sm={2}>
                      Type
                    </Label>
                    <Col sm={10}>
                      <Input type="select" name="select" id="selectStartDate" value={data?.type} disabled={true}>
                        <option key={1} value={"product"}>Product</option>
                        <option key={2} value={"category"}>Category</option>
                        <option key={3} value={"brand"}>Brand</option>
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup check row>
                    <Col>
                      <Button type="button" onClick={() => handleSubmit()} disabled={disableSubmit}>Submit</Button>
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SectionEdit;
