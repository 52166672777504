import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";

import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import TableLayout from "../../../../../layout/tableLayout";
import TableCSI from "../../../../../Component/table";
import { ModalDialog } from "../../../../../Component/modal/ModalDialog";
import ModalContent from "../../../../../Component/modal/ModalContent";
import SpinnerLoading from "../../../../../Component/spinnerLoading";

import {
  getPromoProducts,
  delPromoProduct,
  createPromoProduct,
} from "../../../../../redux/action/promoAction";

const PromoProducts = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [data, setData] = useState([]);
  const [totalEntry, setTotalEntry] = useState(0);
  const [totalEntryPerPage, setTotalEntryPerPage] = useState(0);
  const [titlePromo, setTitlePromo] = useState("");
  const [modal, setModal] = useState(false);
  const [inputSku, setInputSKU] = useState("");
  const [modalState, setModalState] = useState(null);
  const [isSubmitProduct, setSubmitProduct] = useState(false);

  const { listPromoProduct } = useSelector((state) => state.PromoReducer);

  const columns = [
    {
      Header: () => null,
      id: "promoProduct",
      columns: [
        {
          Header: "SKU Code",
          accessor: "sku_code",
        },
        {
          Header: "Products Name",
          accessor: "name",
        },
        {
          Header: "Action",
          accessor: "action",
          Cell: ({ row }) => (
            <Button
              outline
              onClick={() => showModal(row.original)}
              style={{ color: "Red" }}
            >
              Delete
            </Button>
          ),
        },
      ],
    },
  ];

  const headerAction = (
    <Button color="primary" outline onClick={() => setModal(true)}>
      Add Products
    </Button>
  );

  useEffect(() => {
    dispatch(getPromoProducts(id, 1));
  }, []);

  useEffect(() => {
    if (listPromoProduct.data === undefined || listPromoProduct.data === null)
      return;
    const res = listPromoProduct.data.data;
    const dataProduct = res.promo_products;
    const mappedData = dataProduct.data.map((item) => ({
      id: item.id,
      sku_code: item.product.sku_code,
      name: item.product.name,
    }));

    setTitlePromo(res.promo.title);
    setTotalEntry(dataProduct.total);
    setTotalEntryPerPage(mappedData.length);
    setData(mappedData);
    setMaxPageIndex(dataProduct.last_page);
  }, [listPromoProduct.data]);

  const handleSearch = (input) => {
    dispatch(getPromoProducts(id, 1, input, 10));
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getPromoProducts(id, page, "", 10));
  };

  const showModal = (row) => {
    setModalState((prevState) => ({
      ...prevState,
      openModal: true,
      modalType: "remind",
      modalTitle: "Delete Product",
      modalData: row,
      closeIcon: true,
    }));
  };

  function handleDeleteRow(e, item) {
    e.preventDefault();
    setData((prevData) => {
      const newData = prevData.filter((dataItem) => dataItem.id !== item.id);
      return newData;
    });

    dispatch(delPromoProduct(item.id));

    setModalState(null);
  }

  function handleSubmitProduct() {
    setSubmitProduct(true);

    let payload = new FormData();
    payload.append("products[]", inputSku);

    dispatch(createPromoProduct(id, payload)).then((res) => {
      if (res.status !== 200) return;
	  
      setTimeout(() => {
        setInputSKU("");
        setSubmitProduct(false);
        setModal(false);
        dispatch(getPromoProducts(id, 1));
      }, 650);
    });
  }

  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <Breadcrumb
          parent="Basic"
          title={`${titlePromo} Products`}
          urlParent={"/dashboard/promo/basic"}
        />
      </div>

      <TableLayout
        headerAction={headerAction}
        handleSearch={handleSearch}
        totalEntry={totalEntry}
        totalEntryPerPage={totalEntryPerPage}
        pageCount={maxPageIndex}
        handleChangePage={handlePageClick}
        handleChangeRow={(val) => dispatch(getPromoProducts(id, 1, "", val))}
      >
        <TableCSI columns={columns} data={data} key={data} />
      </TableLayout>

      <Button
        className="float-right mx-3"
        color="primary"
        onClick={() => history.goBack()}
      >
        Back
      </Button>

      <Modal isOpen={modal} toggle={() => setModal(false)}>
        <div className="d-flex flex-row justify-content-between p-3 border-bottom">
          <h5 className="my-2 font-weight-bolder">Add Products</h5>
          <button
            type="button"
            onClick={() => setModal(false)}
            className="border-0 bg-white p-auto"
          >
            <i className="fa-solid fa-x"></i>
          </button>
        </div>
        <ModalBody>
          <Form onSubmit={(e) => e.preventDefault()}>
            <FormGroup>
              <Label
                className="my-2 p-0 font-weight-bold"
                for="exampleEmail"
                sm={12}
              >
                Product SKU Code <span style={{ color: "red" }}>*</span>
              </Label>
              <Input
                type="text"
                name="product"
                id="product"
                placeholder="Enter Product SKU Code"
                value={inputSku}
                onChange={(e) => setInputSKU(e.target.value)}
                sm={12}
              />
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <button
            className="bg-white p-2 border rounded"
            onClick={() => setModal(false)}
            style={{ width: "80px" }}
          >
            Cancel
          </button>
          <Button
            color="primary"
            onClick={handleSubmitProduct}
            disabled={inputSku === "" || isSubmitProduct ? true : false}
          >
            {isSubmitProduct ? <SpinnerLoading /> : "Submit"}
          </Button>
        </ModalFooter>
      </Modal>
      <Fragment>
        <ModalDialog
          open={modalState?.openModal ?? false}
          type={modalState?.modalType ?? ""}
          footer={false}
          title={modalState?.modalTitle ?? ""}
          titleFontSize={18}
          backdropClose={true}
          onClose={() => {
            setModalState(null);
          }}
          maxWidth={384}
        >
          <ModalContent
            onClose={() => {
              setModalState(null);
            }}
            onSubmit={(e) => {
              handleDeleteRow(e, modalState?.modalData);
            }}
            data={modalState?.modalData ?? null}
            description={`Are you sure you want to delete this product?`}
            descClose={"Cancel"}
            descSubmit={"Delete"}
          />
        </ModalDialog>
      </Fragment>
    </Fragment>
  );
};

export default PromoProducts;
