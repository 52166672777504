import React from "react";
import { Box } from "@mui/material";
import { Button } from "reactstrap";

const ModalContent = ({
  onSubmit,
  onClose,
  description,
  descClose,
  descSubmit,
}) => {
  return (
    <Box className="flex flex-col">
      <div>
        <p>{description}</p>
      </div>

      <Box
        className="flex flex-end ml-auto mt-12"
        style={{ textAlign: "right" }}
      >
        <Button color="primary" outline onClick={onClose}>
          {descClose !== "" ? descClose : "Batal"}
        </Button>
        <Button className="ml-2" color="primary" onClick={onSubmit}>
          {descSubmit !== "" ? descSubmit : "Kirim"}
        </Button>
      </Box>
    </Box>
  );
};

export default ModalContent;
