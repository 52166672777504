import React, { Fragment, useEffect, useState } from "react";
import { useTable, useSortBy } from "react-table";
import { Table } from "reactstrap";
import styled from "styled-components";

import sortIcon from "../../../../../../../assets/images/sort-icon.svg";

const BtnAction = styled.button`
  color: black;
  font-size: 14px;
  border: 1px solid #d2d2d4;
  border-radius: 5px;
  cursor: pointer;
  background: white;
  padding: 4px 8px;
  font-weight: 500;
`;

const EditableCell = ({
  value: initialValue,
  row: { index },
  column: { id },
  updateMyData, // This is a custom function that we supplied to our table instance
  editableRowIndex, // index of the row we requested for editing
  editState,
  participantID,
}) => {
  // We need to keep and update the state of the cell normally
  const [value, setValue] = React.useState(initialValue);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  // We'll only update the external data when the input is blurred
  const onBlur = () => {
    updateMyData(index, participantID, value);
  };

  // If the initialValue is changed externall, sync it up with our state
  React.useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  return id === editState && index === editableRowIndex ? (
    <input value={value || ""} onChange={onChange} onBlur={onBlur} />
  ) : (
    <p>{value}</p>
  );
};

const defaultColumn = {
  Cell: EditableCell,
};

const TableReward = ({ columns, data, updateMyData, skipPageReset }) => {
  const [editableRowIndex, setEditableRowIndex] = React.useState(null);
  const [editState, setEditState] = React.useState("");
  const [participantID, setParticipantID] = React.useState("");

  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows } =
    useTable(
      {
        columns,
        data,
        defaultColumn,
        autoResetPage: !skipPageReset,
        updateMyData,
        editableRowIndex,
        setEditableRowIndex,
        editState,
        setEditState,
        participantID,
        setParticipantID,
        initialState: { hiddenColumns: ["id"] },
      },
      useSortBy,
      (hooks) => {
        hooks.allColumns.push((columns) => [
          ...columns,
          // pass edit hook
          {
            accessor: "action",
            id: "action",
            Header: "Action",
            width: 60,
            Cell: ({
              row,
              setEditableRowIndex,
              editableRowIndex,
              setEditState,
              setParticipantID,
            }) => (
              <BtnAction
                onClick={() => {
                  const currentIndex = row.index;
                  if (editableRowIndex !== currentIndex) {
                    setEditableRowIndex(currentIndex);
                    setEditState("reward");
                    setParticipantID(row.values.id);
                  } else {
                    // call your updateRow API
                    setEditableRowIndex(null);
                    setEditState("");
                  }
                }}
              >
                {/* single action button supporting 2 modes */}
                {editableRowIndex !== row.index ? "Edit" : "Save"}
              </BtnAction>
            ),
          },
        ]);
      }
    );

  return (
    <Fragment>
      <Table {...getTableProps()} className="text-left">
        <thead>
          {headerGroups.map((headerGroup, index) =>
            index > 0 ? (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {column.sortAble ? <img src={sortIcon} /> : ""}
                  </th>
                ))}
              </tr>
            ) : null
          )}
        </thead>

        <tbody {...getTableBodyProps()} className="text-left">
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, index) => {
                  return (
                    <td
                      {...cell.getCellProps({
                        style: { width: cell.column.width },
                      })}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  );
};

export default TableReward;
