import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Badge,
  Col,
  Card,
  CardBody,
  Button,
  InputGroup,
  InputGroupAddon,
  Input,
} from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import moment from "moment";
import styled from "styled-components";

import { useDispatch, useSelector } from "react-redux";
import {
  clearDataList,
  fetchAdminInvoiceList,
} from "../../../../../redux/action/chatAction";

const BtnClose = styled.div`
  cursor: pointer;
  font-weight: bold;
  :hover {
    background: #bbb;
  }
`;

const AttachInvoice = (props) => {
  const [selectedCard, setSelectedCard] = useState({});
  const [selected, setSelected] = useState(false);
  const [isEnableClearSearch, setIsEnableClearSearch] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [currPage, setCurrPage] = useState(1);
  const [listInvoice, setInvoiceList] = useState([]);
  const [listInvoiceTemp, setInvoiceListTemp] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const { listAdminInvoice } = useSelector((state) => state.ChatReducer);
  const dispatch = useDispatch();

  const toggleCloseProduct = (param) => {
    props.onClose(param);
  };

  const handleTypedSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const handleOnEnterPress = () => {
    let newArr = listInvoice.filter((item) =>
      item.description.toLowerCase().includes(searchInput.toLowerCase())
    );
    setInvoiceListTemp(listInvoice);
    setInvoiceList(newArr);
    setIsEnableClearSearch(true);
  };

  const handleClearSearchInput = () => {
    if (isEnableClearSearch) {
      setSearchInput("");
      setInvoiceList(listInvoiceTemp);
      setInvoiceListTemp([]);
      setIsEnableClearSearch(false);
    } else {
      setSearchInput("");
    }
  };

  const handleSelectedCard = (data) => {
    setSelectedCard(data);
    setSelected(true);
  };

  const handleSendButton = () => {
    if (selectedCard) {
      let dataArray = [];
      dataArray.push(selectedCard);
      props.handleUpdateAttachment(dataArray);
      props.setTypeAttachment("invoice");
      setSelectedCard({});
      setSelected(false);
    }
  };

  const loadMore = () => {
    setCurrPage(currPage + 1);
    // setTimeout(() => {
    //   dispatch(fetchAdminInvoiceList(currPage + 1));
    // }, 300);
  };

  useEffect(() => {
    if (listAdminInvoice && listAdminInvoice.length > 0) {
      let newId = 0;
      let newList = listAdminInvoice.map((data) => ({ ...data, id: newId++ }));
      setInvoiceList(newList);
    } else {
      setInvoiceList([]);
    }
  }, [listAdminInvoice]);

  useEffect(() => {
    dispatch(fetchAdminInvoiceList(props.receiverID));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearDataList());
    };
  }, []);

  return (
    <Fragment>
      <div className="">
        <div className="d-flex justify-content-between py-1">
          <span>
            <b>Pilih Produk</b>
          </span>
          <BtnClose onClick={() => toggleCloseProduct("")}>X</BtnClose>
        </div>
        <InputGroup>
          <Input
            placeholder="Cari Invoice"
            value={searchInput}
            onChange={handleTypedSearch}
            onKeyDown={(e) => {
              if (e.key === "Enter") handleOnEnterPress();
            }}
          />
          {searchInput !== "" ? (
            <InputGroupAddon addonType="prepend">
              <Button
                className="m-1"
                onClick={() => handleClearSearchInput()}
                close
              />
            </InputGroupAddon>
          ) : null}
        </InputGroup>
      </div>
      <div className="att_invoice_list my-3" id="list_invoice_id">
        <InfiniteScroll
          dataLength={listInvoice.length}
          next={() => loadMore()}
          hasMore={hasMore}
          scrollThreshold={0.9}
          loader={null}
          scrollableTarget="list_invoice_id"
        >
          {listInvoice.length > 0
            ? listInvoice.map((item, id) => (
                <Card
                  className={`invoice_card p-0 my-2 ${
                    selected && selectedCard.id == id ? `card_selected` : ``
                  }`}
                  key={id}
                  onClick={() => handleSelectedCard(item)}
                  style={{ cursor: "pointer" }}
                >
                  <CardBody className="p-2">
                    <div className="d-flex justify-content-between my-1">
                      <div className="badge_csi badge_blue">
                        {item.document_type}
                      </div>
                      <span style={{ color: "grey", fontSize: "12px" }}>
                        {moment.utc(item.due_date).format("DD MMM YYYY")}
                      </span>
                    </div>
                    <div className="my-1">
                      <div className="d-flex justify-content-start">
                        <div>
                          <p className="my-1">
                            <b>{item.description}</b>
                          </p>
                          <span style={{ color: "grey", fontSize: "12px" }}>
                            {item.document_no}
                          </span>
                        </div>
                      </div>
                      <div className="my-1">
                        <p style={{ color: "#2647e6" }}>
                          <b>Rp. {item.remaining_amount}</b>
                        </p>
                      </div>
                    </div>
                  </CardBody>
                </Card>
              ))
            : null}
        </InfiniteScroll>
      </div>
      <div className="btn-send-produk d-flex justify-content-between p-0">
        <Button
          className="my-2"
          color="primary"
          block
          disabled={selected ? false : true}
          onClick={() => handleSendButton()}
        >
          Lampirkan
        </Button>
      </div>
    </Fragment>
  );
};

export default AttachInvoice;
