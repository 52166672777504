import React, { useEffect, useState, Fragment } from "react";
import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import { Stepper } from "react-form-stepper";
import draftToHtml from "draftjs-to-html";
import {
  convertToRaw,
  EditorState,
  convertFromHTML,
  ContentState,
} from "draft-js";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";
import moment from "moment";
import { toast } from "react-toastify";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import {
  fetchDetailCampaign,
  editCampaign,
} from "../../../../../redux/action/campaignAction";

const EditCampaign = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const urlParam = useParams();
  const currLocation = useLocation();
  const [currStepper, setCurrentStepper] = useState(1);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [imgPreviewSrc, setImgPreviewSrc] = useState("");
  const [formDetail, setFormDetail] = useState({
    title: "",
    start_date: "",
    end_date: "",
    type: "",
    ordering: "",
    banner: "",
    content: "",
    type_target: "",
    target: "",
  });
  const [typeTarget, setTypeTarget] = useState("");
  const [campaignDetail, setCampaignDetail] = useState({});
  const [editorInput, setEditorInput] = useState(EditorState.createEmpty());
  const { detailCampaign } = useSelector((state) => state.CampaignReducer);

  useEffect(() => {
    dispatch(fetchDetailCampaign(urlParam.id));

    let abortController = new AbortController();

    return () => {
      window.onbeforeunload = function () {
        return "Data will be lost if you leave the page, are you sure?";
      };

      abortController.abort();
    };
  }, []);

  useEffect(() => {
    if (detailCampaign.data && !detailCampaign.loading) {
      setCampaignDetail(detailCampaign.data);
      let radioAmmount = document.getElementById("radioTargetAmmount");
      let radioQty = document.getElementById("radioTargetQty");

      if (detailCampaign.data.type_target === "amount") {
        setTypeTarget("amount");
        radioAmmount.checked = true;
      } else {
        setTypeTarget("qty");
        radioQty.checked = true;
      }

      const startDate = moment
        .utc(detailCampaign.data.start_date)
        .format("YYYY-MM-DD");
      const endDate = moment
        .utc(detailCampaign.data.end_date)
        .format("YYYY-MM-DD");

      let amountToString = detailCampaign.data.target
        ? detailCampaign.data.target.toString()
        : 0;
      let targetAmount = amountToString > 0 ? getNumber(amountToString) : "";

      setFormDetail({
        title: detailCampaign.data.title,
        start_date: startDate,
        end_date: endDate,
        ordering: detailCampaign.data.ordering,
        banner: "",
        content: detailCampaign.data.content,
        type_target: detailCampaign.data.type_target,
        target: targetAmount.toLocaleString(),
      });
      setImgPreviewSrc(detailCampaign.data.banner_url);

      const htmlMarkup = detailCampaign.data.content || "";
      const blocksFromHTML = convertFromHTML(htmlMarkup);
      const state = ContentState.createFromBlockArray(
        blocksFromHTML.contentBlocks,
        blocksFromHTML.entityMap
      );
      setEditorInput(EditorState.createWithContent(state));
    }
  }, [detailCampaign]);

  const toggleBack = () => {
    if (currLocation.key) {
      history.goBack();
    } else history.push("/dashboard/campaign");
  };

  const isImage = (imgType) => {
    switch (imgType) {
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
        return true;
    }
    return false;
  };

  const validateForm = (data) => {
    if (
      data.title === "" ||
      data.start_date === "" ||
      data.end_date === "" ||
      data.type_target === "" ||
      data.target === "" ||
      data.ordering === "" ||
      data.content === "" ||
      data.content.length === 8
    ) {
      return true;
    }
    return false;
  };

  const validateDate = () => {
    if (formDetail.start_date !== "" && formDetail.end_date) {
      const today = new Date();
      const todayDate = moment.utc(today).format("YYYY-MM-DD");
      if (formDetail.start_date > formDetail.end_date) return 1;
      if (todayDate > formDetail.end_date) return 1;
      if (formDetail.start_date < formDetail.end_date) return -1;
    }
    return 0;
  };

  const validateOrder = (e) => {
    if (e.key === "-") {
      e.preventDefault();
    }
  };

  const handleFileInput = (e) => {
    if (e.target.files.length > 0) {
      const filesImg = e.target.files[0];
      if (filesImg.size > 2000000) {
        alert("max size 2MB");
        document.getElementById("formFile").value = "";
      } else {
        if (!isImage(filesImg.type)) {
          alert("Please select image file");
          document.getElementById("formFile").value = "";
        } else {
          const imgSrc = URL.createObjectURL(filesImg);
          setImgPreviewSrc(imgSrc);
          setFormDetail({ ...formDetail, banner: filesImg });
        }
      }
    }
  };

  const handleTypeTarget = (type) => {
    setTypeTarget(type);
    setFormDetail({
      ...formDetail,
      type_target: type,
    });
  };

  const handleConvertContentToHtml = (e) => {
    let convertedHtml = draftToHtml(convertToRaw(e.getCurrentContent()));
    setFormDetail({
      ...formDetail,
      content: convertedHtml,
    });

    setEditorInput(e);
  };

  const validateFirstStepForm = () => {
    if (validateForm(formDetail)) {
      toast.error(`Please fill all fields.`);
    } else if (validateDate() === 1) {
      toast.error("End Date cannot be earlier than Start Date or Today Date");
      setFormDetail({
        ...formDetail,
        end_date: "",
      });
    } else {
      onSubmitEdit();
    }
  };

  const onSubmitEdit = () => {
    if (!validateForm(formDetail)) {
      let dataForm = new FormData();
      let formattedAmmount = formDetail.target.replaceAll(",", "");
      if (formDetail.banner !== "") {
        dataForm.append("banner", formDetail.banner);
      }
      dataForm.append("content", formDetail.content);
      dataForm.append("start_date", formDetail.start_date);
      dataForm.append("end_date", formDetail.end_date);
      dataForm.append("ordering", formDetail.ordering);
      dataForm.append("target", formattedAmmount);
      dataForm.append("title", formDetail.title);
      dataForm.append("type_target", formDetail.type_target);
      dataForm.append("id", urlParam.id);

      dispatch(editCampaign(dataForm));
    }
  };

  const onTypedAmmount = (e) => {
    var num = getNumber(e.target.value);
    if (num == 0) {
      e.target.value = "";
      setFormDetail({
        ...formDetail,
        target: "",
      });
    } else {
      e.target.value = num.toLocaleString();
      setFormDetail({
        ...formDetail,
        target: e.target.value,
      });
    }
  };

  function getNumber(_str) {
    var arr = _str.split("");
    var out = new Array();
    for (var cnt = 0; cnt < arr.length; cnt++) {
      if (isNaN(arr[cnt]) == false) {
        out.push(arr[cnt]);
      }
    }
    return Number(out.join(""));
  }

  return (
    <Fragment>
      <Breadcrumb
        parent="Campaign"
        title="Edit Campaign"
        urlParent="/dashboard/campaign"
      />
      <Container fluid>
        <Row>
          <Col sm={8}>
            <Card>
              <Stepper
                steps={[
                  { label: "Campaign Detail" },
                  { label: "Products" },
                  { label: "Participants" },
                ]}
                activeStep={0}
                styleConfig={{
                  activeBgColor: "blue",
                }}
              />
              <CardBody className="p-3">
                <Form>
                  <FormGroup row>
                    <Label for="formTitle" sm={2}>
                      <b>Title</b>
                    </Label>
                    <Col sm={10}>
                      <Input
                        placeholder="Enter Title"
                        id="formTitle"
                        value={formDetail.title || ""}
                        onChange={(e) =>
                          setFormDetail({
                            ...formDetail,
                            title: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="selectStartDate" sm={2}>
                      <b>Start Date</b>
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="date"
                        name="date"
                        id="selectStartDate"
                        placeholder="Start Date"
                        max="9999-12-31"
                        min="1700-12-31"
                        value={formDetail.start_date || ""}
                        onChange={(e) =>
                          setFormDetail({
                            ...formDetail,
                            start_date: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="selectEndDate" sm={2}>
                      <b>End Date</b>
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="date"
                        name="date"
                        id="selectStartDate"
                        placeholder="End Date"
                        max="9999-12-31"
                        value={formDetail.end_date || ""}
                        onChange={(e) =>
                          setFormDetail({
                            ...formDetail,
                            end_date: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup tag="fieldset" row className="d-flex mb-1">
                    <Col>
                      <b>Target</b>
                    </Col>
                    <Col sm={10} className="d-flex mx-3">
                      <FormGroup check className="mx-2">
                        <Label check>
                          <Input
                            type="radio"
                            name="radioTarget"
                            id="radioTargetAmmount"
                            disabled
                            onChange={(e) =>
                              e.target.checked ? handleTypeTarget("amount") : ""
                            }
                          />{" "}
                          Amount
                        </Label>
                      </FormGroup>
                      <FormGroup check className="mx-2">
                        <Label check>
                          <Input
                            type="radio"
                            name="radioTarget"
                            id="radioTargetQty"
                            disabled
                            onChange={(e) =>
                              e.target.checked ? handleTypeTarget("qty") : ""
                            }
                          />{" "}
                          Quantity
                        </Label>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="mt-1">
                    <Col></Col>
                    <Col sm={10}>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          {typeTarget === "amount" ? (
                            <span
                              className="p-2"
                              style={{ border: "1px solid grey" }}
                            >
                              Rp
                            </span>
                          ) : typeTarget === "qty" ? (
                            <span
                              className="p-2"
                              style={{ border: "1px solid grey" }}
                            >
                              Pcs
                            </span>
                          ) : null}
                        </InputGroupAddon>
                        {typeTarget !== "" ? (
                          <Input
                            type="text"
                            placeholder="0"
                            id="formAmount"
                            value={formDetail.target || ""}
                            onChange={(e) => onTypedAmmount(e)}
                          />
                        ) : (
                          ""
                        )}
                      </InputGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="formOrder" sm={2}>
                      <b>Order</b>
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="number"
                        name="formOrder"
                        placeholder="0"
                        id="formOrder"
                        min={0}
                        value={formDetail.ordering || ""}
                        onKeyDown={validateOrder}
                        onChange={(e) =>
                          setFormDetail({
                            ...formDetail,
                            ordering: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="formFile" sm={2}>
                      <b>Banner (720 x 480)</b>
                    </Label>
                    <Col sm={10}>
                      <Input
                        className="p-2"
                        type="file"
                        name="file"
                        id="formFile"
                        accept="image/*"
                        onChange={handleFileInput}
                        style={{
                          border: "1px solid #d2d2d4",
                          borderRadius: "5px",
                        }}
                      />
                    </Col>
                  </FormGroup>
                  <div className="d-flex my-2">
                    <span>
                      <b>Banner Preview</b>
                    </span>
                    <img
                      className="mx-auto"
                      src={imgPreviewSrc}
                      alt=""
                      width={620}
                      height={360}
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                  <FormGroup row>
                    <Label for="exampleText" sm={2}>
                      <b>Content</b>
                    </Label>
                    <Col sm={10}>
                      <Editor
                        className="richtext-editor"
                        editorState={editorInput}
                        onEditorStateChange={handleConvertContentToHtml}
                        editorStyle={{
                          border: "1px #c6c9cf solid",
                          height: "250px",
                        }}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
            <div className="d-flex justify-content-between mt-2 mb-3">
              <button className="btn-csi-white" onClick={() => toggleBack()}>
                Cancel
              </button>
              <Button
                color="primary"
                onClick={() => validateFirstStepForm()}
                disable={loadingSubmit ? "true" : "false"}
              >
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default EditCampaign;
