import { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  FormGroup,
  Form,
} from "reactstrap";
import { useDispatch } from "react-redux";
import { updateDataProductDiscount } from "../../../../../redux/action/productAction";
import { Link, useLocation, useParams, useHistory } from "react-router-dom";
import moment from "moment";
const defaultPayload = {
  start_date: "",
  end_date: "",
  discount: "",
  product_id: "",
  id: "",
};

const ProductDiscountEdit = (props) => {
  const urlParams = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  let timeoutId;

  const [data, setData] = useState(location ? location?.data : defaultPayload);
  const [disableSubmit, setDisableSubmit] = useState(false);

  useEffect(() => {
    if (location?.data) {
      const startDate = moment
        .utc(location?.data?.start_date_time, "DD/MM/YYYY")
        .format("YYYY-MM-DD");
      const endDate = moment
        .utc(location?.data?.end_date_time, "DD/MM/YYYY")
        .format("YYYY-MM-DD");
      setData({
        ...data,
        start_date: startDate,
        end_date: endDate,
        discount: location?.data?.discount_price,
      });
    }
  }, []);

  const handleSubmit = () => {
    setDisableSubmit(true);
    let finalData = {
      product_id: data?.product_id ? data?.product_id : "",
      id: urlParams?.id,
      start_date: data?.start_date
        ? moment(data?.start_date).format("MM/DD/YYYY")
        : "",
      end_date: data?.end_date
        ? moment(data?.end_date).format("MM/DD/YYYY")
        : "",
      discount: data?.discount,
    };
    const dataForm = new URLSearchParams(Object.entries(finalData)).toString();
    dispatch(updateDataProductDiscount(dataForm))
      .then(() => {
        clearTimeout(timeoutId);
        history.push("/dashboard/product/discount/" + urlParams.id);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        timeoutId = setTimeout(() => {
          setDisableSubmit(false);
        }, 600);
      });
  };

  useEffect(() => {
    return () => {
      timeoutId = setTimeout(() => {
        setDisableSubmit(false);
      }, 600);
    };
  }, []);

  return (
    <Fragment>
      <Breadcrumb
        parent="Product"
        title="Edit Discount"
        urlParent={"/dashboard/product"}
      />
      <Container fluid>
        <Row>
          <Col xl={8}>
            <Card>
              <CardBody className="p-3">
                <Form>
                  <FormGroup row>
                    <Label for="selectStartDate" sm={2}>
                      <b>Start Date</b>
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="date"
                        name="date"
                        id="selectStartDate"
                        placeholder="Start Date"
                        max="9999-12-31"
                        min="1700-12-31"
                        value={data?.start_date}
                        onChange={(e) =>
                          setData({
                            ...data,
                            start_date: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="selectEndDate" sm={2}>
                      <b>End Date</b>
                    </Label>
                    <Col sm={10}>
                      <Input
                        type="date"
                        name="date"
                        id="selectEndDate"
                        placeholder="End Date"
                        max="9999-12-31"
                        value={data?.end_date}
                        onChange={(e) =>
                          setData({
                            ...data,
                            end_date: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row>
                    <Label for="formSKU" sm={2}>
                      Discount Price
                    </Label>
                    <Col sm={10}>
                      <Input
                        type={"number"}
                        placeholder="Enter Discount Price"
                        id="formPrice"
                        value={data?.discount}
                        onChange={(event) =>
                          setData({ ...data, discount: event.target.value })
                        }
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup check row>
                    <div className="d-flex justify-content-end">
                      <Link
                        to={{
                          pathname: `/dashboard/product/discount/${urlParams?.id}`,
                        }}
                      >
                        <Button className="mx-1" color="primary" outline>
                          Cancel
                        </Button>
                      </Link>
                      <Button
                        className="mx-1"
                        type="button"
                        color="primary"
                        onClick={() => handleSubmit()}
                        disabled={disableSubmit}
                      >
                        Submit
                      </Button>
                    </div>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ProductDiscountEdit;
