import styled from "styled-components";

const ButtonAction = styled.button`
  flex-direction: row;
  align-items: flex-start;
  padding: 6px 8px;
  background: #ffffff;
  border: 1px solid #d1d5dc;
  border-radius: 6px;
  text-align: center;

  a {
    text-decoration: none;
	color: black;
  }
`;

export default ButtonAction;
