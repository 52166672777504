import { Fragment, useEffect, useState } from "react";

import { FormGroup, Label, Col, Button } from "reactstrap";
import TableCSI from "@components/table";
import AddProductModal from "./AddProductModal";
import CurrencyFormatter from "@components/currencyFormatter";
import { ModalDialog } from "@components/modal/ModalDialog";
import ModalContent from "@components/modal/ModalContent";
import ModalPotongan from "./ModalPotongan";

import trash_icon from "@assets/images/trash_icon.svg";

const AddProductBundle = ({
  label,
  dataProduct,
  onUpdatedData,
  isRequired,
  onDispatchDeleteRow,
}) => {
  const [isVisibleProduct, setVisibleProduct] = useState(false);
  const [column, setColumn] = useState([]);
  const [data, setData] = useState([]);
  const [openProduct, setOpenProduct] = useState(false);
  const [total, setTotal] = useState(0);
  const [modalState, setModalState] = useState(null);
  const [isVisiblePotongan, setVisiblePotongan] = useState(false);

  useEffect(() => {
    if (dataProduct && dataProduct.length > 0) {
      setVisibleProduct(true);
      setTableColumn();
      setData(dataProduct);
    }
  }, [dataProduct]);

  useEffect(() => {
    if (data.length > 0) handleSumTotal();
    else if (data.length === 0) {
      setVisibleProduct(false);
      setTotal(0);
    }
  }, [data]);

  function setTableColumn() {
    setColumn([
      {
        Header: () => null,
        id: "promo",
        columns: [
          {
            Header: "SKU Code",
            accessor: "sku_code",
            sortAble: false,
            width: 40,
          },
          {
            Header: "Product Name",
            accessor: "product_name",
            sortAble: false,
          },
          {
            Header: "Harga",
            accessor: "harga",
            sortAble: false,
            width: 50,
            Cell: ({ row }) => (
              <CurrencyFormatter
                digit={row.original.harga}
                typeDigit="amount"
              />
            ),
          },
          {
            Header: "Qty",
            accessor: "qty",
            Cell: ({ row }) => (
              <div className="d-flex flex-row my-auto mx-2">
                <Button
                  className="border py-1 px-3"
                  color="white"
                  onClick={() => handleIncrementQty(row.original.id, -1)}
                  disabled={row.original.qty === 1 ? true : false}
                >
                  {"-"}
                </Button>
                <input
                  className="mx-1"
                  type="number"
                  min={1}
                  value={row.original.qty}
                  onChange={(e) =>
                    handleQtyChange(row.original.id, e.target.value)
                  }
                  style={{ width: "50px" }}
                />
                <Button
                  className="py-1 px-3"
                  color="primary"
                  onClick={() => handleIncrementQty(row.original.id, 1)}
                >
                  {"+"}
                </Button>
              </div>
            ),
            disableSortBy: true,
          },
          {
            Header: "Potongan",
            accessor: "potongan",
            sortAble: false,
            Cell: ({ row }) => (
              <div className="d-flex flex-row my-auto mx-2">
                <span className="my-auto mr-3">{row.original.potongan}</span>
                <Button
                  className="border rounded ml-2 px-3"
                  color="white"
                  onClick={() => setVisiblePotongan(true)}
                >
                  Edit
                </Button>
              </div>
            ),
          },
          {
            Header: "Harga Final",
            accessor: "harga_final",
            sortAble: false,
            Cell: ({ row }) => (
              <span>{row.original.harga - row.original.potongan}</span>
            ),
          },
          {
            Header: "Total",
            accessor: "total",
            sortAble: false,
            Cell: ({ row }) => (
              <span>
                {(row.original.harga - row.original.potongan) *
                  row.original.qty}
              </span>
            ),
          },
          {
            accessor: "action",
            Cell: ({ row }) => (
              <button
                className="border-0 bg-white"
                onClick={() => showModal(row.original)}
                type="button"
              >
                <img src={trash_icon} alt="Delete row product" width={15} />
              </button>
            ),
            disableSortBy: true,
          },
        ],
      },
    ]);
  }

  function handleIncrementQty(id, change) {
    setData((prevVal) => {
      const newData = prevVal.map((selectedItem) => {
        if (selectedItem.id === id) {
          let newQty = selectedItem.qty + parseInt(change, 10);
          newQty = newQty < 0 ? 0 : newQty;
          return { ...selectedItem, qty: newQty };
        }
        return selectedItem;
      });

      onUpdatedData(newData);
      return newData;
    });
  }

  function handleQtyChange(id, change) {
    setData((prevVal) => {
      const newData = prevVal.map((selectedItem) => {
        if (selectedItem.id === id) {
          let newQty = change;
          newQty = newQty < 0 ? 0 : newQty;
          return { ...selectedItem, qty: newQty };
        }
        return selectedItem;
      });

      onUpdatedData(newData);
      return newData;
    });
  }

  function handleSumTotal() {
    const totalPrice = data.reduce((accumulator, item) => {
      return accumulator + item.harga * item.qty;
    }, 0);
    setTotal(totalPrice);
  }

  function handleDeleteRow(e, item) {
    e.preventDefault();
    setData((prevData) => {
      const newData = prevData.filter((dataItem) => dataItem.id !== item.id);
      return newData;
    });

    if (typeof onDispatchDeleteRow === "function" && item.id) {
      onDispatchDeleteRow(item.id);
    }
    setModalState(null);
  }

  const showModal = (row) => {
    setModalState((prevState) => ({
      ...prevState,
      openModal: true,
      modalType: "remind",
      modalTitle: "Delete Product",
      modalData: row,
      closeIcon: true,
    }));
  };

  function handleSubmitProduct(product) {
    const newArr = product.map((item) => ({
      id: item.id,
      product_id: item.id,
      sku_code: item.sku_code,
      product_name: item.name,
      harga: item.price,
      qty: item.qty,
      potongan: 0,
      harga_final: 0,
      total: 0,
    }));

    setTableColumn();
    setData((prevVal) => {
      const filteredNewArr = newArr.filter(
        (newItem) => !prevVal.some((prevItem) => prevItem.id === newItem.id)
      );
      return [...prevVal, ...filteredNewArr];
    });

    const filteredData = [...data, ...newArr].filter(
      (item, index, self) => index === self.findIndex((t) => t.id === item.id)
    );
    onUpdatedData(filteredData);
    setVisibleProduct(true);
  }

  function handleChangePotongan(value) {}

  return (
    <Fragment>
      <FormGroup row>
        <Label className="font-weight-bold" for={`form${label}`} sm={2}>
          {label}
          {isRequired ? (
            <span className="mx-1" style={{ color: "red" }}>
              *
            </span>
          ) : null}
        </Label>
        <Col sm={10}>
          <Button
            className="mb-2"
            color="primary"
            outline
            onClick={() => setOpenProduct(!openProduct)}
          >
            {"+ Add Products"}
          </Button>

          {isVisibleProduct ? (
            <>
              <div
                className="overflow-auto my-2"
                style={{ maxHeight: "55dvh" }}
              >
                <TableCSI columns={column} data={data} key={data} />
              </div>
              <p>
                Total harga normal:{" "}
                <b>
                  <CurrencyFormatter digit={total} typeDigit="amount" />
                </b>
              </p>
            </>
          ) : null}

          <Fragment>
            <ModalDialog
              open={modalState?.openModal ?? false}
              type={modalState?.modalType ?? ""}
              footer={false}
              title={modalState?.modalTitle ?? ""}
              titleFontSize={18}
              backdropClose={true}
              onClose={() => {
                setModalState(null);
              }}
              maxWidth={384}
            >
              <ModalContent
                onClose={() => {
                  setModalState(null);
                }}
                onSubmit={(e) => {
                  handleDeleteRow(e, modalState?.modalData);
                }}
                data={modalState?.modalData ?? null}
                description={`Are you sure you want to delete this product?`}
                descClose={"Cancel"}
                descSubmit={"Delete"}
              />
            </ModalDialog>
          </Fragment>
        </Col>
      </FormGroup>

      <AddProductModal
        isVisible={openProduct}
        onClose={() => setOpenProduct(!openProduct)}
        onSubmit={handleSubmitProduct}
      />
      <ModalPotongan
        isVisible={isVisiblePotongan}
        onClose={() => setVisiblePotongan(false)}
        onSubmit={handleChangePotongan}
      />
    </Fragment>
  );
};

export default AddProductBundle;
