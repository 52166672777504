import {
	GET_CONFIG,
  } from "../../actionTypes";
  
  const initialState = {
	listConfig: {},
  };
  
  const ConfigReducer = (state = initialState, action) => {
	switch (action.type) {
	  case GET_CONFIG:
		state.listConfig = action.payload;
		return { ...state, listConfig: state.listConfig };
	  
	  default:
		return { ...state };
	}
  };
  
  export default ConfigReducer;
  