import { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  getListVoucher,
  updateRowVoucher,
  deleteRowVoucher,
} from "@action/voucherAction";

import { Button } from "reactstrap";
import Breadcrumb from "@layout/breadcrumb";
import TableCSI from "@components/table";
import BadgeStatus from "@components/badgeStatus";
import TableLayout from "@layout/tableLayout";
import TableDropdown from "@components/customDropdown";

const VoucherLayout = () => {
  const [isFetchedData, setIsFetchedData] = useState(false);
  const [mappedListData, setMappedListData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [isAfterSearch, setIsAfterSearch] = useState(false);
  const [totalEntry, setTotalEntry] = useState(0);
  const [totalEntryPerPage, setTotalEntryPerPage] = useState(0);

  const dispatch = useDispatch();
  const { listVoucher } = useSelector((state) => state.VoucherReducer);

  useEffect(() => {
    fetchTable();
  }, []);

  useEffect(() => {
    if (listVoucher.data && !listVoucher.loading) {
      let respData = listVoucher.data.data;
      if (respData === undefined || respData === null) return false;

      let mappedData = respData.map((item) => ({
        id: item.id,
        name: item.name,
        level: item.level ? item.level.title : null,
        credit: item.credit_poin,
        status: item.active === "Y" ? 1 : 0,
      }));

      setMaxPageIndex(listVoucher.data.last_page);
      setTotalEntry(listVoucher.data.total);
      setTotalEntryPerPage(listVoucher.data.to);
      setMappedListData(mappedData);
      setIsFetchedData(true);
    }
  }, [listVoucher]);

  const onHandleDeactivate = (data, param) => {
    setIsFetchedData(false);
    const formData = new FormData();
    formData.append("active", param);
    dispatch(updateRowVoucher(data.id, formData));
    dispatch(getListVoucher(1));
  };

  function fetchTable() {
    setTableColumn([
      {
        Header: () => null,
        id: "levelColomn",
        columns: [
          {
            Header: "ID",
            accessor: "id",
            sortAble: true,
          },
          {
            Header: "Name",
            accessor: "name",
            sortAble: true,
          },
          {
            Header: "Kode Voucher",
            accessor: "kode",
            sortAble: true,
          },
          {
            Header: "Penawaran",
            accessor: "offer",
            sortAble: true,
          },
          {
            Header: "Besar",
            accessor: "amount",
            sortAble: true,
          },
          {
            Header: "Jenis",
            accessor: "type",
            sortAble: true,
          },
          {
            Header: "Status",
            accessor: "status",
            sortAble: true,
            Cell: ({ row }) => <BadgeStatus status={row.original.status} />,
          },
          {
            Header: "Action",
            accessor: "action",
            Cell: ({ row }) => (
              <div className="d-flex flex-row">
                <Link to={`/dashboard/promo/voucher/detail/${row.original.id}`}>
                  <button className="border rounded-lg bg-white px-3 py-2 mr-2">
                    Detail
                  </button>
                </Link>
                <TableDropdown
                  dropdownContent={[
                    {
                      label: "Edit",
                      type: "link",
                      link: `/dashboard/promo/voucher/edit/${row.original.id}`,
                    },
                    {
                      label: "Delete",
                      type: "delete",
                    },
                  ]}
                  onToggleStatus={() => {}}
                  row={row.original}
                  onDeleteAction={() => {}}
                />
              </div>
            ),
            disableSortBy: true,
            sortAble: false,
          },
        ],
      },
    ]);

    dispatch(getListVoucher(1));
  }

  const handleSubmitSearch = () => {};

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListVoucher(page));
    setIsAfterSearch(true);
  };

  const handleDeleteRow = (id) => {
    setIsFetchedData(false);
    dispatch(deleteRowVoucher(id));
    dispatch(getListVoucher(1));
  };

  const headerAction = (
    <Link to={`/dashboard/promo/voucher/add`}>
      <Button className="shadow-sm border-0" color="primary">
        Add New Voucher
      </Button>
    </Link>
  );

  return (
    <Fragment>
      <Breadcrumb
        parent="Promotion"
        title="Voucher"
        urlParent={"/dashboard/promo"}
      />
      <TableLayout
        headerAction={headerAction}
        handleSearch={handleSubmitSearch}
        totalEntry={totalEntry}
        totalEntryPerPage={totalEntryPerPage}
        pageCount={maxPageIndex}
        handleChangePage={handlePageClick}
        handleChangeRow={(val) => {}}
      >
        <TableCSI
          columns={tableColumn}
          data={mappedListData}
          key={mappedListData}
        />
      </TableLayout>
    </Fragment>
  );
};

export default VoucherLayout;
