import React, { Fragment, useState, useEffect, useCallback } from "react";
import { Form, Row } from "reactstrap";
import { X } from "react-feather";
import { MENUITEMS } from "../sidebar/menu";
import LeftHeader from "./leftbar";
import RightHeader from "./rightbar";
import { Link } from "react-router-dom";
import { Loading } from "../../constant";
import { useSelector } from "react-redux";

const Header = (props) => {
  // eslint-disable-next-line
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [searchValue, setsearchValue] = useState("");
  // eslint-disable-next-line
  const [searchResult, setSearchResult] = useState(false);
  // eslint-disable-next-line
  const [searchResultEmpty, setSearchResultEmpty] = useState(false);
  const layout_type = useSelector((content) => content.Customizer.layout);
  const layout_version = useSelector(
    (content) => content.Customizer.mix_background_layout
  );

  const escFunction = useCallback((event) => {
    if (event.keyCode === 27) {
      setsearchValue("");
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  return (
    <Fragment>
      <div className="page-header">
        <div className="d-flex flex-row header-wrapper justify-content-between m-0 px-1">
          <LeftHeader />
          <RightHeader />
        </div>
      </div>
    </Fragment>
  );
};

export default Header;
