import { Fragment } from "react";
import { FormGroup, Label, Col, Input } from "reactstrap";
import PropTypes from "prop-types";

const InputText = ({ label, value, onChange, isRequired, labelWidth=2, inputWidth= 10, placeholder }) => {
  return (
    <Fragment>
      <FormGroup row>
        <Label className="font-weight-bold" for={`form${label}`} sm={labelWidth}>
          {label}
		  {isRequired ? <span className="mx-1" style={{color: 'red'}}>*</span> : null}
        </Label>
        <Col sm={inputWidth}>
          <Input
            id={`form${label}`}
            placeholder={`${placeholder ?? `Enter ${label}`}`}
            value={value}
            onChange={onChange}
			type="text"
          />
        </Col>
      </FormGroup>
    </Fragment>
  );
};

InputText.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
};

export default InputText;
