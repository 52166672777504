import React, { Fragment, useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  getDetailProduct,
  uploadProductImage,
} from "../../../../redux/action/productAction";
import Breadcrumb from "../../../../layout/breadcrumb";
import TableCSI from "../../../../Component/table";
import { ModalDialog } from "../../../../Component/modal/ModalDialog";
import ModalUpload from "../../../../Component/modal/ModalUpload";
import ButtonAction from "../../../../Component/styledComponents/btnTableAction";

const ProductImage = () => {
  const urlParam = useParams();
  const dispatch = useDispatch();
  const { detailProduct } = useSelector((state) => state.ProductReducer);
  const [data, setData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [isFetchedData, setIsFetchedData] = useState(true);
  const [modalState, setModalState] = useState(null);

  useEffect(() => {
    dispatch(getDetailProduct(urlParam.id));
    setTableColumn([
      {
        Header: () => null,
        id: "ProductImages",
        columns: [
          {
            Header: "ID",
            accessor: "id",
            sortAble: true,
            width: 50,
          },
          {
            Header: "Preview",
            accessor: "preview",
            sortAble: false,
            width: 200,
            Cell: ({ row }) => <img src={row.original.preview} alt="" width={200} />,
          },
          {
            Header: "Ordering",
            accessor: "ordering",
            sortAble: true,
            width: 50,
          },
          {
            Header: "Action",
            accessor: "action",
            sortAble: false,
            width: 100,
          },
        ],
      },
    ]);
  }, []);

  useEffect(() => {
    if (detailProduct?.data && !detailProduct?.loading) {
      const arrImage = detailProduct.data?.images;
      if (arrImage.length > 0) {
        const mappedData = arrImage.map((item) => ({
          id: item.id,
          preview: item.thumbnail_image_url,
          ordering: item.ordering ?? 0,
        }));
		setData(mappedData)
		setIsFetchedData(true)
      }
    }
  }, [detailProduct]);

  const showModal = (type) => {
    setModalState((prevState) => ({
      ...prevState,
      openModal: true,
      modalType: type,
      modalTitle: "Upload Product Image",
      modalData: type,
      closeIcon: true,
    }));
  };

  const handleSubmitImages = (file, data) => {
    let dataForm = new FormData();
    dataForm.append("file", file);

    dispatch(uploadProductImage(urlParam.id, dataForm));
    setModalState(null);
  };

  return (
    <Fragment>
      <Breadcrumb parent="Product" title="Product Image" urlParent={'/dashboard/product'} />
      <Container fluid>
        <Row>
          <Col>
            <Card className="p-0">
              <CardBody className="p-3">
                <div className="d-flex justify-content-end my-2">
                  <ButtonAction
                    className="px-3 py-2"
                    onClick={() => showModal("image")}
                  >
                    Upload image
                  </ButtonAction>
                </div>
                {isFetchedData ? (
                  <TableCSI data={data} columns={tableColumn} key={data} />
                ) : null}
              </CardBody>
            </Card>

            <ModalDialog
              open={modalState?.openModal ?? false}
              type={modalState?.modalType ?? ""}
              footer={false}
              title={modalState?.modalTitle ?? ""}
              titleFontSize={18}
              backdropClose={true}
              onClose={() => {
                setModalState(null);
              }}
              maxWidth={500}
            >
              <ModalUpload
                onClose={() => {
                  setModalState(null);
                }}
                onSubmit={(file, data) => handleSubmitImages(file, data)}
                data={modalState?.modalData ?? null}
                descClose={"Batal"}
                descSubmit={"Submit"}
                type={modalState?.modalType}
                label={
                  modalState?.modalType === "image"
                    ? "Image"
                    : "CSV file to import"
                }
              />
            </ModalDialog>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ProductImage;
