import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";

import {
  getListPoint,
  getListPointBySearch,
  updateRowPoint,
  deleteRowPoint,
} from "../../../../redux/action/pointAction";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import Breadcrumb from "../../../../layout/breadcrumb";
import TableCSI from "../../../../Component/table";
import CustomPagination from "../../../../Component/customPagination";
import EntryCounter from "../../../../Component/entryCounter";
import iconSearch from "../../../../assets/images/input-search.png";
import BtnDropdownContent from "../../../../Component/styledComponents/btnDropdownContent";
import ButtonAction from "../../../../Component/styledComponents/btnTableAction";
import BtnDeactivate from "../../../../Component/styledComponents/btnDeactivate";
import BtnActivate from "../../../../Component/styledComponents/btnActivate";
import BtnDropdownAction from "../../../../Component/styledComponents/btnDropdownAction";
import WrapperTableAction from "../../../../Component/styledComponents/wrapperBtnAction";

const PointLayout = () => {
  const [isFetchedData, setIsFetchedData] = useState(false);
  const [mappedListData, setMappedListData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [isAfterSearch, setIsAfterSearch] = useState(false);
  const [totalResult, setTotalResult] = useState(0);
  const [totalPerPage, setTotalPerPage] = useState(0);

  const dispatch = useDispatch();
  const { listPoint } = useSelector((state) => state.PointReducer);

  useEffect(() => {
    setTableColumn([
      {
        Header: () => null,
        id: "levelColomn",
        columns: [
          {
            Header: "ID",
            accessor: "id",
            sortAble: true,
          },
          {
            Header: "Purchase",
            accessor: "purchase",
            sortAble: true,
          },
          {
            Header: "Poin",
            accessor: "point",
            sortAble: true,
          },
          {
            Header: "Action",
            accessor: "action",
            Cell: ({ row }) => (
              <div className="d-flex">
                <Link to={`/dashboard/point/edit/${row.original.id}`}>
                  <ButtonAction>Edit</ButtonAction>
                </Link>
                <WrapperTableAction>
                  <ButtonAction className="mx-2">...</ButtonAction>
                  <BtnDropdownContent>
                    <BtnDropdownAction onClick={() => handleDeleteRow(row.original.id)}>
                      Delete
                    </BtnDropdownAction>
                    <hr className="my-2" />
                    {row.original.status === 1 ? (
                      <BtnDeactivate
                        onClick={() => onHandleDeactivate(row.original, "N")}
                      >
                        Deactivate
                      </BtnDeactivate>
                    ) : (
                      <BtnActivate
                        onClick={() => onHandleDeactivate(row.original, "Y")}
                      >
                        Activate
                      </BtnActivate>
                    )}
                  </BtnDropdownContent>
                </WrapperTableAction>
              </div>
            ),
            disableSortBy: true,
            sortAble: false,
          },
        ],
      },
    ]);

    dispatch(getListPoint(1));
  }, []);

  useEffect(() => {
    if (listPoint.data && !listPoint.loading) {
      let respData = listPoint.data ? listPoint.data.data : null;
      let pageData = listPoint.data ? listPoint.data : null;
	  if (respData === undefined || respData === null) return false;

      let mappedData = respData.map((item) => ({
        id: item.id,
        purchase: `${item.minimum_purchase.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        })} - ${item.maximum_purchase.toLocaleString("id-ID", {
          style: "currency",
          currency: "IDR",
          minimumFractionDigits: 0,
        })}`,
        point: item.poin,
        status: item.active === "Y" ? 1 : 0,
      }));
      setMaxPageIndex(pageData.last_page);
      setTotalResult(pageData.total);
      setTotalPerPage(pageData.to);

      setMappedListData(mappedData);
      setIsFetchedData(true);
    }
  }, [listPoint]);

  const handleOnEnter = (e) => {
    if (inputSearchValue !== "") {
      dispatch(getListPointBySearch(1, inputSearchValue));
      setIsAfterSearch(true);
    }
  };

  const handleSubmitSearch = () => {
    if (inputSearchValue !== "") {
      dispatch(getListPointBySearch(1, inputSearchValue));
      setIsAfterSearch(true);
    }
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListPoint(page));
    setIsAfterSearch(true);
  };

  const onHandleDeactivate = (data, param) => {
    setIsFetchedData(false);
    const formData = new FormData();
    formData.append("active", param);
    dispatch(updateRowPoint(data.id, formData));
    dispatch(getListPoint(1));
  };

  const handleDeleteRow = (id) => {
    setIsFetchedData(false);
    dispatch(deleteRowPoint(id));
    dispatch(getListPoint(1));
  };

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Poin Management" urlParent={'/dashboard/analytics'} />
      <Container fluid={true}>
        <Row>
          <Col>
            <Card className="p-0">
              <CardBody className="p-3">
                <div className="d-flex justify-content-between my-2">
                  <div className="d-flex">
                    <InputGroup style={{ maxWidth: "280px" }}>
                      <Input
                        placeholder="Search"
                        value={inputSearchValue}
                        onChange={(e) => setInputSearchValue(e.target.value)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") handleOnEnter(e);
                        }}
                      />
                      <InputGroupAddon addonType="prepend">
                        <Button
                          className="p-2"
                          color="primary"
                          onClick={() => handleSubmitSearch()}
                        >
                          <img src={iconSearch} width={15} height={15} />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>

                    <EntryCounter
                      totalPerPage={totalPerPage}
                      totalResult={totalResult}
                    />
                  </div>
                  <Link to="/dashboard/point/add">
                    <Button color="primary" outline>
                      Add new Poin
                    </Button>
                  </Link>
                </div>

                {isFetchedData ? (
                  <TableCSI
                    data={mappedListData}
                    columns={tableColumn}
                    key={mappedListData}
                    initialSortBy={initialSortBy}
                  />
                ) : null}

                <CustomPagination
                  handlePageClick={handlePageClick}
                  pageCount={maxPageIndex}
                  pageRangeDisplayed={2}
                  marginPagesDisplayed={2}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PointLayout;
