import apiConfig from "../../api";
import {
  WATCH_RESPONSE_ALERT,
  GET_LIST_BROADCAST,
  GET_LIST_USER_UUID,
} from "../actionTypes";

export const sendBroadcastAllPromo = (payload) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post("/api/admin/promos/message", payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const getListBroadcast = (page) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_BROADCAST,
      payload: {
        loading: true,
        data: {},
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/broadcast?page=${page}`)
      .then((resp) => {
        dispatch({
          type: GET_LIST_BROADCAST,
          payload: {
            loading: false,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LIST_BROADCAST,
          payload: {
            loading: false,
            data: {},
            err: true,
          },
        });
      });
  };
};

export const getListBroadcastBySearch = (page, param) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_BROADCAST,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/broadcast?page=${page}&search=${param}`)
      .then((resp) => {
        dispatch({
          type: GET_LIST_BROADCAST,
          payload: {
            loading: false,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LIST_BROADCAST,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const createBroadcast = (payload) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post("/api/admin/broadcast", payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: true,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const getListUser = (page, param) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_USER_UUID,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/users/comechat`)
      .then((resp) => {
        dispatch({
          type: GET_LIST_USER_UUID,
          payload: {
            loading: false,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LIST_USER_UUID,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};
