import {
	GET_LIST_HARGACORET,
  } from "../../actionTypes";
  
  const initialState = {
	listHargaCoret: {},
  };
  
  const HargaCoretReducer = (state = initialState, action) => {
	switch (action.type) {
	  case GET_LIST_HARGACORET:
		return { ...state, listHargaCoret: action.payload };
	  default:
		return { ...state };
	}
  };
  
  export default HargaCoretReducer;
  