import { Fragment, useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Breadcrumb from "@layout/breadcrumb";
import TableCSI from "@components/table";
import PromoNav from "./promoNav";
import TableLayout from "@layout/tableLayout";
import BadgeApproval from "@components/badgeApproval";
import SelectList from "@components/selectList";
import DateRangePicker from "rsuite/DateRangePicker";

import "rsuite/DateRangePicker/styles/index.css";

import { getListApproval } from "@action/promoAction";

const PromoLayout = () => {
  const [dataPromoActive, setDataPromoActive] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [isFetchedData, setIsFetchedData] = useState(false);
  const [totalEntry, setTotalEntry] = useState(0);
  const [totalEntryPerPage, setTotalEntryPerPage] = useState(0);

  const { listApproval } = useSelector((state) => state.PromoReducer);

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    fetchTable();
    let abortController = new AbortController();
    return () => {
      abortController.abort();
    };
  }, []);

  //watcher when list promo updated
  useEffect(() => {
    if (listApproval.data && !listApproval.loading) {
      setInitialSortBy([
        {
          id: "title",
          desc: false,
        },
      ]);

      const resp = listApproval.data.data;
      console.log("🚀 ~ useEffect ~ resp:", resp);
      const resDat = resp.data;
      if (resp === undefined || resp === null) return false;

      const mappedList = resDat.map((items) => ({
        id: items.id,
        nama: items.name,
        jenis: items.jenis,
        tanggal: items.tanggal,
		pengaju: items.creator.owner_name,
        status: items.approval_state,
      }));

      setDataPromoActive(mappedList);
      setTotalEntry(resp.total);
      setTotalEntryPerPage(resp.per_page);
      setMaxPageIndex(resp.last_page);
      setIsFetchedData(true);
    }
  }, [listApproval]);

  const fetchTable = () => {
    dispatch(getListApproval(1));
    setTableColumn([
      {
        Header: () => null,
        id: "promo",
        columns: [
          {
            Header: "Tanggal",
            accessor: "tanggal",
            sortAble: true,
            Cell: ({ row }) => (
              <span>
                {row.original.tanggal !== "-"
                  ? moment.utc(row.original.tanggal).format("DD/MM/YYYY")
                  : "-"}
              </span>
            ),
            sortType: (rowA, rowB, columnId, desc) => {
              let startDate = Date.parse(rowA.values[columnId]);
              let endDate = Date.parse(rowB.values[columnId]);
              if (startDate > endDate) return 1;
              if (startDate < endDate) return -1;
              return 0;
            },
          },
          {
            Header: "Nama",
            accessor: "nama",
            sortAble: true,
          },
          {
            Header: "Jenis",
            accessor: "jenis",
            sortAble: true,
          },
          {
            Header: "Pengaju",
            accessor: "pengaju",
            sortAble: true,
          },
          {
            Header: "Status",
            accessor: "status",
            sortAble: true,
            Cell: ({ row }) => <BadgeApproval status={row.original.status} />,
            sortType: (rowA, rowB, columnId, desc) => {
              let val1 = parseInt(rowA.original.status);
              let val2 = parseInt(rowB.original.status);
              if (val1 > val2) return 1;
              if (val1 < val2) return -1;
              return 0;
            },
          },
          {
            accessor: "action",
            sortAble: false,
            Cell: ({ row }) => (
              <button
                className="border rounded-lg bg-white px-3 py-2 mr-2"
                onClick={() =>
                  handleDetail(row.original.jenis, row.original.id)
                }
              >
                Detail
              </button>
            ),
          },
        ],
      },
    ]);
  };

  const handleSubmitSearch = (input) => {
    dispatch(getListApproval(1, input));
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListApproval(page));
  };

  function handleDetail(type, id) {
    const to = type.toLowerCase();
    console.log("🚀 ~ handleDetail ~ to:", to);

    switch (to) {
      case "flashale":
        history.push(`/dashboard/approval/harga-coret/${id}`);
        break;
      default:
        break;
    }
  }

  const headerFilter = (
    <div className="d-flex flex-row">
      <SelectList
        values={[{ val: "1", name: "Jenis Promo" }]}
        onChange={() => {}}
      />
      <SelectList
        values={[{ val: "2", name: "Pengaju" }]}
        onChange={() => {}}
      />
      <DateRangePicker
        size="md"
        placeholder="dd-mm-yyyy ~ dd-mm-yyyy"
        block
        style={{ width: "100%" }}
      />
    </div>
  );

  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <h3 className="text-center">Promotion</h3>
        <Breadcrumb parent="Promotion" urlParent={"/dashboard/promo"} />
      </div>
      <PromoNav />
      <TableLayout
        headerTitle="Approval"
        handleSearch={handleSubmitSearch}
        totalEntry={totalEntry}
        totalEntryPerPage={totalEntryPerPage}
        pageCount={maxPageIndex}
        handleChangePage={handlePageClick}
        handleChangeRow={(val) => dispatch(getListApproval(1, "", val))}
        isCustomHeader
        customBarHeader={headerFilter}
        noentry
      >
        <TableCSI
          data={isFetchedData ? dataPromoActive : []}
          columns={tableColumn}
          key={tableColumn}
          initialSortBy={initialSortBy}
        />
      </TableLayout>
    </Fragment>
  );
};

export default PromoLayout;
