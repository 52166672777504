import MasterApproval from "pages/dashboard/konfigurasi/masterApproval";
import ApprovalHargaCoret from "pages/dashboard/approval/hargaCoret";

export const RouteApproval = [
  {
    path: `/dashboard/master-approval`,
    Component: MasterApproval,
  },
  {
    path: `/dashboard/approval/harga-coret/:id`,
    Component: ApprovalHargaCoret,
  },
];
