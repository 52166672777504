import apiConfig from "../../api";
import { GET_LIST_NAVLOG } from "../actionTypes";

export const getListNavLog = (perPage = 10, page = 1, param, method) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_NAVLOG,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/nav/log?field=${method}&search=${param}&page=${page}&perPage=${perPage}`)
      .then((resp) => {
        dispatch({
          type: GET_LIST_NAVLOG,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LIST_NAVLOG,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};
