import { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

import {
  fetchListCampaign,
  deactivateCampaign,
  deleteCampaign,
  setReminderCampaign,
} from "../../../../redux/action/campaignAction";

import TableCSI from "../../../../Component/table";
import TableLayout from "../../../../layout/tableLayout";
import TableDropdown from "../../../../Component/customDropdown";
import BadgeStatus from "../../../../Component/badgeStatus";

const CampaignLayout = () => {
  const dispatch = useDispatch();
  const [mappedListData, setMappedListData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [isFetchedData, setIsFetchedData] = useState(false);
  const [isAfterSearch, setIsAfterSearch] = useState(false);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const [totalPerPage, setTotalPerPage] = useState(0);

  const { listCampaign } = useSelector((state) => state.CampaignReducer);

  useEffect(() => {
    initTable();

    let abortController = new AbortController();
    return () => {
      abortController.abort();
    };
  }, []);

  //fetch data on mount
  useEffect(() => {
    if (listCampaign.data && !listCampaign.loading) {
      const listDat = listCampaign.data.data.data;
      if (listDat === undefined || listDat === null) return false;

      //setListData(listCampaign.data.data.data);
      let mappedList = listDat.map((items) => ({
        id: items.id,
        ordering: items.ordering,
        title: items.title,
        participant: items.participant,
        start_date: items.start_date,
        end_date: items.end_date,
        status: items.active,
      }));
      setMappedListData(mappedList);
      setIsFetchedData(true);
      setMaxPageIndex(listCampaign.data.data.last_page);
      setTotalResult(listCampaign.data.data.total);
      setTotalPerPage(listCampaign.data.data.to);
    }
  }, [listCampaign]);

  const initTable = () => {
    dispatch(fetchListCampaign(1, "", 10));
    setTableColumn([
      {
        Header: () => null,
        id: "campaign1",
        columns: [
          {
            Header: "ID",
            accessor: "id",
            sortAble: true,
          },
          {
            Header: "Order",
            accessor: "ordering",
            sortAble: true,
          },
          {
            Header: "Title",
            accessor: "title",
            sortAble: true,
          },
          {
            Header: "Participants",
            accessor: "participant",
            sortAble: true,
          },
          {
            Header: "Start Date",
            accessor: "start_date",
            sortAble: true,
            Cell: ({ row }) => (
              <span>
                {moment.utc(row.original.start_date).format("DD/MM/YYYY")}
              </span>
            ),
            sortType: (rowA, rowB, columnId, desc) => {
              let startDate = Date.parse(rowA.values[columnId]);
              let endDate = Date.parse(rowB.values[columnId]);
              if (startDate > endDate) return 1;
              if (startDate < endDate) return -1;
              return 0;
            },
          },
          {
            Header: "End Date",
            accessor: "end_date",
            sortAble: true,
            Cell: ({ row }) => (
              <span>
                {moment.utc(row.original.end_date).format("DD/MM/YYYY")}
              </span>
            ),
            sortType: (rowA, rowB, columnId, desc) => {
              let startDate = Date.parse(rowA.values[columnId]);
              let endDate = Date.parse(rowB.values[columnId]);
              if (startDate > endDate) return 1;
              if (startDate < endDate) return -1;
              return 0;
            },
          },
          {
            Header: "Status",
            accessor: "active",
            sortAble: true,
            Cell: ({ row }) => <BadgeStatus status={row.original.status} />,
          },
          {
            Header: "Action",
            accessor: "action",
            Cell: ({ row }) => (
              <TableDropdown
                dropdownContent={[
                  {
                    label: "Detail",
                    type: "link",
                    link: `/dashboard/campaign/detail/${row.original.id}`,
                  },
                  {
                    label: "Set Reminder",
                    type: "button",
                    onclick: () => setReminder(row.original),
                  },
                  {
                    label: "Delete",
                    type: "delete",
                  },
                ]}
                onToggleStatus={onHandleDeactivate}
                row={row.original}
                onDeleteAction={(id) => onDeleteCampaign(id)}
              />
            ),
            disableSortBy: true,
          },
        ],
      },
    ]);
  };

  const setReminder = (data) => {
    const formData = new FormData();
    formData.append("campaign_id", data.id);
    formData.append("type", "all");
    dispatch(setReminderCampaign(formData));
  };

  const onDeleteCampaign = (id) => {
    dispatch(deleteCampaign(id)).then((resp) => {
      if (resp.status !== 200) return;

      initTable();
    });
  };

  const onHandleDeactivate = ({ id, toggle }) => {
    switch (toggle) {
      case "deactive":
        dispatch(deactivateCampaign(id.id, "nonactive"));
        initTable();
        break;
      case "active":
        dispatch(deactivateCampaign(id.id, "active"));
        initTable();
        break;
      default:
        break;
    }
  };

  const handleSubmitSearch = (val) => {
    dispatch(fetchListCampaign(1, val, 10));
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(fetchListCampaign(page, "", 10));
  };

  const headerAction = (
    <Link to={`/dashboard/campaign/add`}>
      <Button color="primary">Add New Campaign</Button>
    </Link>
  );

  return (
    <Fragment>
      <Breadcrumb
        parent="Promotion"
        title="Campaign"
        urlParent="/dashboard/promo"
      />
      <TableLayout
        handleSearch={handleSubmitSearch}
        headerAction={headerAction}
        totalEntry={totalResult}
        totalEntryPerPage={totalPerPage}
        pageCount={maxPageIndex}
        handleChangePage={handlePageClick}
        handleChangeRow={(val) => dispatch(fetchListCampaign(1, "", val))}
      >
        <TableCSI
          data={mappedListData}
          columns={tableColumn}
          key={tableColumn}
          initialSortBy={initialSortBy}
        />
      </TableLayout>
    </Fragment>
  );
};

export default CampaignLayout;
