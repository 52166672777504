import React, { Fragment, useEffect, useState } from "react";

import {
  Badge,
  Card,
  CardBody,
  Media,
  Input,
  InputGroup,
  Button,
  ListGroup,
  ListGroupItem,
  InputGroupAddon,
} from "reactstrap";
import BroadcastDropdown from "../../broadcast/broadcastButton";

import errorImg from "../../../../assets/images/search-not-found.png";
import user_profile from "../../../../assets/images/user/user-profile.svg";

const ChatSidebar = (props, { isFetchUser, toggleChat, activeChat }) => {
  const [userSearchKeywords, setUserSearchKeywords] = useState("");

  const onClickChat = (data) => {
    props.onClickChat(data);
  };

  return (
    <Fragment>
      <Card>
        <CardBody className="chat-body">
          <div className="chat-box">
            <div>
              <BroadcastDropdown />
            </div>

            <div className="chat-left-aside">
              <div className="people-list">
                <div className="search my-2">
                  <InputGroup>
                    <Input
                      placeholder="search"
                      value={props.userSearchKeywords}
                      onChange={(e) => props.setUserSearchKeywords(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") props.handleOnEnterUserSearch();
                      }}
                    />
                    <i className="fa fa-search"></i>
                    {props.userSearchKeywords !== "" ? (
                      <InputGroupAddon addonType="prepend">
                        <Button
                          className="m-1"
                          onClick={() => props.handleClearSearchInput()}
                          close
                        />
                      </InputGroupAddon>
                    ) : null}
                  </InputGroup>
                </div>

                {!isFetchUser ? (
                  props.userChatList.length > 0 ? (
                    <ListGroup className="list">
                      {props.userChatList.map((item, i) => {
                        return (
                          <ListGroupItem
                            className="chat-user-list d-flex my-1 p-1"
                            tag="button"
                            onClick={() => onClickChat(item)}
                            active={
                              toggleChat &&
                              activeChat === item.conversationWith.uid
                                ? true
                                : false
                            }
                            action
                            key={i}
                          >
                            <img
                              src={user_profile}
                              alt=""
                              className="user-image my-auto"
                            />
                            <div className="about float-left m-1">
                              <div
                                className={
                                  toggleChat &&
                                  activeChat === item.conversationWith.uid
                                    ? `text-break name_active`
                                    : `text-break name`
                                }
                              >
                                {item.conversationWith.name}
                              </div>
                              <div
                                className={
                                  toggleChat &&
                                  activeChat === item.conversationWith.uid
                                    ? `status status_active`
                                    : `status status_non_active`
                                }
                              >
                                {item.conversationWith.status}
                              </div>
                            </div>
                            {item.unreadMessageCount > 0 ? (
                              <Badge
                                className="float-right m-auto p-auto"
                                color="danger"
                                pill
                              >
                                {item.unreadMessageCount}
                              </Badge>
                            ) : (
                              ""
                            )}
                          </ListGroupItem>
                        );
                      })}
                    </ListGroup>
                  ) : (
                    <Media className="img-fluid m-auto" src={errorImg} alt="" />
                  )
                ) : (
                  <div className="loader m-auto"></div>
                )}
              </div>
            </div>
          </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default ChatSidebar;
