import React, { Fragment, useEffect, useState } from "react";
import { useTable, useSortBy } from "react-table";
import { Table } from "reactstrap";

import sortIcon from '../../../../../../../assets/images/sort-icon.svg'

const TableAddProduct = (props) => {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);

  const tableInstance = useTable({ columns, data }, useSortBy);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  useEffect(() => {
    if (props) {
      if (props.columns.length > 0) {
        setColumns(props.columns);
      }
      if (props.data.length > 0) {
        setData(props.data);
      }
    }
  }, [props]);

  return (
    <Fragment>
      <Table
        {...getTableProps()}
        className="text-left"
      >
        <thead>
          {headerGroups.map((headerGroup, index) =>
            index > 0 ? (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    {column.sortAble ? <img src={sortIcon} /> : ""}
                  </th>
                ))}
              </tr>
            ) : null
          )}
        </thead>

        <tbody {...getTableBodyProps()} className="text-left">
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Fragment>
  );
};

export default TableAddProduct;
