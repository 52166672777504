import { Link } from "react-router-dom";

import { Card, CardBody, Container, Row, Col } from "reactstrap";

import basicPromo from "@assets/images/promotion-new-icon/basic-promo.svg";
import campaign from "@assets/images/promotion-new-icon/campaign.svg";
import flashSale from "@assets/images/promotion-new-icon/flashsale.svg";
import voucher from "@assets/images/promotion-new-icon/voucher.svg";
import hargaCoret from "@assets/images/promotion-new-icon/harga-coret.svg";
import promoFree from "@assets/images/promotion-new-icon/promo-free.svg";
import bundle from "@assets/images/promotion-new-icon/bundle.svg";

const PromoNav = () => {
  return (
    <Container fluid>
      <Row>
        <Col>
          <Card className="shadow-sm border-0 p-0">
            <CardBody className="p-3">
              <h3>Fitur Promosi</h3>
              <div className="d-flex flex-lg-row flex-sm-column">
                <div className="d-flex flex-row w-100 align-items-center p-2 border rounded-lg m-2">
                  <div
                    className="rounded-circle border p-2"
                    style={{ background: "#E9EDFD", minWidth: "40px" }}
                  >
                    <img src={basicPromo} alt="basic-promo" />
                  </div>
                  <Link
                    to="/dashboard/promo/basic"
                    className="d-flex flex-column mx-3"
                    style={{ color: "black" }}
                  >
                    Basic Promo
                    <span style={{ fontSize: "10px", fontWeight: "lighter" }}>
                      Promosikan tokomu untuk mendapatkan lebih banyak pembeli.
                    </span>
                  </Link>
                </div>
                <div className="d-flex flex-row w-100 align-items-center p-2 border rounded-lg m-2">
                  <div
                    className="rounded-circle border p-2"
                    style={{ background: "#FEF8E7", minWidth: "40px" }}
                  >
                    <img src={campaign} alt="campaign" />
                  </div>
                  <Link
                    to="/dashboard/campaign"
                    className="d-flex flex-column  mx-3"
                    style={{ color: "black" }}
                  >
                    Campaign
                    <span style={{ fontSize: "10px", fontWeight: "lighter" }}>
                      Buat campaign sebarkan ke audience untuk promosikan tokomu
                      sendiri.
                    </span>
                  </Link>
                </div>
                <div className="d-flex flex-row w-100 align-items-center p-2 border rounded-lg m-2">
                  <div
                    className="rounded-circle border p-2"
                    style={{ background: "#FFEDED", minWidth: "40px" }}
                  >
                    <img src={flashSale} alt="flash-sale" />
                  </div>
                  <Link
                    to="/dashboard/flashsale"
                    className="d-flex flex-column  mx-3"
                    style={{ color: "black" }}
                  >
                    Flash Sale
                    <span style={{ fontSize: "10px", fontWeight: "lighter" }}>
                      Promosikan produk pilihanmu dengan cepat melalui fitur
                      flash sale.
                    </span>
                  </Link>
                </div>
              </div>
              <div className="d-flex flex-lg-row flex-sm-column">
                <div className="d-flex flex-row w-100 align-items-center p-2 border rounded-lg m-2">
                  <div
                    className="rounded-circle border p-2"
                    style={{ background: "#EAF8EF", minWidth: "40px" }}
                  >
                    <img src={voucher} alt="voucher" />
                  </div>
                  <Link
                    to="/dashboard/promo/voucher"
                    className="d-flex flex-column  mx-3"
                    style={{ color: "black" }}
                  >
                    Voucher
                    <span style={{ fontSize: "10px", fontWeight: "lighter" }}>
                      Memberikan kesempatan kepada pelanggan untuk menggunakan
                      voucher diskon khusus saat berbelanja.
                    </span>
                  </Link>
                </div>
                <div className="d-flex flex-row w-100 align-items-center p-2 border rounded-lg m-2">
                  <div
                    className="rounded-circle border p-2"
                    style={{ background: "#F0F8FE", minWidth: "40px" }}
                  >
                    <img src={hargaCoret} alt="harga-coret" />
                  </div>
                  <Link
                    to="/dashboard/promo/harga-coret"
                    className="d-flex flex-column  mx-3"
                    style={{ color: "black" }}
                  >
                    Harga Coret
                    <span style={{ fontSize: "10px", fontWeight: "lighter" }}>
                      Berikan harga khusus pada produk tertentu untuk memberi
                      penawaran menarik.
                    </span>
                  </Link>
                </div>
                <div className="d-flex flex-row w-100 align-items-center p-2 border rounded-lg m-2">
                  <div
                    className="rounded-circle border p-2"
                    style={{ background: "#F4F3FF", minWidth: "40px" }}
                  >
                    <img src={promoFree} alt="promo-free-barang" />
                  </div>
                  <Link
                    to="/dashboard/promo/promo-free-barang"
                    className="d-flex flex-column  mx-3"
                    style={{ color: "black" }}
                  >
                    Promo Free Barang
                    <span style={{ fontSize: "10px", fontWeight: "lighter" }}>
                      Penawaran barang gratis ketika pelanggan memenuhi syarat
                      pembelian minimum tertentu.
                    </span>
                  </Link>
                </div>
              </div>
              <div className="d-flex flex-lg-row flex-sm-column">
                <div className="d-flex flex-row w-100 align-items-center p-2 border rounded-lg m-2">
                  <div
                    className="rounded-circle border p-2"
                    style={{ background: "#FDF2FA", minWidth: "40px" }}
                  >
                    <img src={bundle} alt="bundle" />
                  </div>
                  <Link
                    to="/dashboard/promo/bundle"
                    className="d-flex flex-column  mx-3"
                    style={{ color: "black" }}
                  >
                    Bundle
                    <span style={{ fontSize: "10px", fontWeight: "lighter" }}>
                      Buat paket penawaran dengan menggabungkan beberapa barang
                      dengan harga spesial.
                    </span>
                  </Link>
                </div>
                <div className="d-flex flex-row w-100 align-items-center p-2 m-2"></div>
                <div className="d-flex flex-row w-100 align-items-center p-2 m-2"></div>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

PromoNav.propTypes = {};

export default PromoNav;
