import React from "react";
import { useTable, useSortBy } from "react-table";
import { Table } from "reactstrap";
import PropTypes from "prop-types";

import sortIcon from "../../assets/images/sort-icon.svg";

const TableCSI = ({ columns, data, initialSortBy }) => {
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { sortBy: initialSortBy ? initialSortBy : [] },
    },
    useSortBy
  );
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  return (
    <div
      style={{
        overflowX: "auto",
        minHeight: `${data.length > 0 ? `50dvh` : "15dvh"}`,
      }}
    >
      <Table {...getTableProps()} className="text-left">
        <thead style={{ backgroundColor: "#F9FAFB" }}>
          {headerGroups.map((headerGroup, index) =>
            index > 0 ? (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    className="border-0"
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                  >
                    {column.render("Header")}
                    {column.sortAble ? <img src={sortIcon} /> : ""}
                  </th>
                ))}
              </tr>
            ) : null
          )}
        </thead>

        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps({
                        style: {
                          width: cell.column.width,
                          minWidth: cell.column.minWidth,
                        },
                      })}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

TableCSI.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      Header: PropTypes.oneOfType([PropTypes.string, PropTypes.func])
        .isRequired,
      id: PropTypes.string.isRequired,
      columns: PropTypes.array.isRequired,
    }).isRequired
  ).isRequired,
  data: PropTypes.array.isRequired,
};

export default React.memo(TableCSI);
