import Category from "../../pages/dashboard/master/category";
import CategoryAdd from "../../pages/dashboard/master/category/add";
import CategoryEdit from "../../pages/dashboard/master/category/edit";
import CategoryChild from "../../pages/dashboard/master/category/categoryChild";
import CategoryChildAdd from "../../pages/dashboard/master/category/categoryChild/add";
import CategoryChildEdit from "../../pages/dashboard/master/category/categoryChild/edit";

export const RouteCategory = [
  {
    path: `/dashboard/category`,
    Component: Category,
  },
  {
    path: `/dashboard/category/add`,
    Component: CategoryAdd,
  },
  {
    path: `/dashboard/category/edit/:id`,
    Component: CategoryEdit,
  },
  {
    path: `/dashboard/category/child/:id`,
    Component: CategoryChild,
  },
  {
    path: `/dashboard/category/child/:id/add`,
    Component: CategoryChildAdd,
  },
  {
    path: `/dashboard/category/child/:id/edit`,
    Component: CategoryChildEdit,
  },
];
