import { useState, Fragment, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Breadcrumb from "../../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  Form,
  FormGroup,
  Label,
} from "reactstrap";
import { FormWrapper } from "../../../../../Component/formComponent";

import BannerPreview from "../../../../../assets/images/blog/img.png";
import {
  updateDataCategory,
  getCategoryDetail,
} from "../../../../../redux/action/categoryAction";

const CategoryEdit = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  let timeoutId;

  const [data, setData] = useState({
    name: "",
    description: "",
    file_url: null,
    ordering: "",
    parent_category: "",
  });
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [imgThumbnailSrc, setImgThumbnailSrc] = useState(BannerPreview);

  useEffect(() => {
    dispatch(getCategoryDetail(id)).then((resp) => {
      const data = resp.data.data;

      setImgThumbnailSrc(data.file_url);
      setData((prevData) => ({
        ...prevData,
        name: data.name,
        description: data.description,
        ordering: data.ordering,
        parent_category: data.parent_category_id || "",
      }));
    });
  }, []);

  const isImage = (imgType) => {
    switch (imgType) {
      case "image/png":
      case "image/jpeg":
      case "image/jpg":
      case "image/jif":
      case "image/webp":
        return true;
    }
    return false;
  };

  const handleOnChangeInputBanner = (e) => {
    if (e.target.files.length > 0) {
      const filesImg = e.target.files[0];
      if (filesImg.size > 2000000) {
        toast.error(`Max file size is 2MB`);
        document.getElementById("formFile").value = "";
      } else {
        if (!isImage(filesImg.type)) {
          toast.error(`Select appropiate image file.`);
          document.getElementById("formFile").value = "";
        } else {
          const imgSrc = URL.createObjectURL(filesImg);
          setImgThumbnailSrc(imgSrc);
          setData({ ...data, file_url: filesImg });
        }
      }
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setDisableSubmit(true);

    const dataForm = new FormData();
    dataForm.append("name", data?.name);
    if (data.file_url) {
      dataForm.append("banner", data?.file_url);
    }
    dataForm.append("ordering", data?.ordering);
    dataForm.append("description", data?.description);

    dispatch(updateDataCategory(dataForm, id)).then((resp) => {
      if (resp.status !== 200) return false;

      timeoutId = setTimeout(() => {
        setDisableSubmit(false);
        history.push("/dashboard/category");
      }, 800);
    });
  };

  useEffect(() => {
    return () => {
      clearTimeout(timeoutId);
    };
  }, []);

  return (
    <Fragment>
      <Breadcrumb
        parent="Category"
        title="Edit Category"
        urlParent={"/dashboard/category"}
      />
      <Container fluid>
        <Row>
          <Col sm={8}>
            <FormWrapper
              onSubmit={handleSubmit}
              onCancel={() => history.push("/dashboard/category")}
              disableSubmit={disableSubmit}
            >
              <FormGroup row>
                <Label for="formTitle" sm={2}>
                  Title
                </Label>
                <Col sm={10}>
                  <Input
                    placeholder="Enter Title"
                    id="formTitle"
                    value={data?.name}
                    onChange={(event) =>
                      setData({ ...data, name: event.target.value })
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row style={{ display: "none" }}>
                <Label for="selectStartDate" sm={2}>
                  Start Date
                </Label>
                <Col sm={10}>
                  <Input type="select" name="select" id="selectStartDate">
                    <option>Start Date</option>
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row style={{ display: "none" }}>
                <Label for="selectEndDate" sm={2}>
                  End Date
                </Label>
                <Col sm={10}>
                  <Input type="select" name="select" id="selectEndDate">
                    <option>End Date</option>
                  </Input>
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="formOrder" sm={2}>
                  Enter Order
                </Label>
                <Col sm={10}>
                  <Input
                    placeholder="Enter Order"
                    id="formOrder"
                    value={data?.ordering}
                    onChange={(event) =>
                      setData({ ...data, ordering: event.target.value })
                    }
                  />
                </Col>
              </FormGroup>
              <FormGroup row>
                <Label for="formFile" sm={2}>
                  Banner (720 x 480)
                </Label>
                <Col sm={10}>
                  <Input
                    className="p-2"
                    type="file"
                    name="file"
                    id="formFile"
                    style={{
                      border: "1px solid #d2d2d4",
                      borderRadius: "5px",
                    }}
                    onChange={handleOnChangeInputBanner}
                  />
                </Col>
              </FormGroup>
              <div className="d-flex my-2">
                <p>Banner Preview</p>
                <img className="mx-auto" src={imgThumbnailSrc} alt="" />
              </div>
              <FormGroup row>
                <Label for="exampleText" sm={2}>
                  Content
                </Label>
                <Col sm={10}>
                  <Input
                    type="textarea"
                    name="text"
                    id="exampleText"
                    placeholder="Write content here"
                    value={data?.description}
                    onChange={(event) =>
                      setData({ ...data, description: event.target.value })
                    }
                  />
                </Col>
              </FormGroup>
            </FormWrapper>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default CategoryEdit;
