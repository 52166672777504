import { GET_LIST_VOUCHER, GET_VOUCHER_DETAIL } from "../../actionTypes";

const initialState = {
  listVoucher: {},
  VoucherDetail: {},
};

const VoucherReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_VOUCHER:
      state.listVoucher = action.payload;
      return { ...state, listVoucher: state.listVoucher };
    case GET_VOUCHER_DETAIL:
      state.VoucherDetail = action.payload;
      return { ...state, VoucherDetail: state.VoucherDetail };

    default:
      return { ...state };
  }
};

export default VoucherReducer;
