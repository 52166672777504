import { Fragment, useState, useRef, useEffect } from "react";
import {
  BtnDropdownAction,
  BtnDeactivate,
  BtnActivate,
  ButtonAction,
} from "../styledComponents";
import styled from "styled-components";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const WrapperTableAction = styled.div`
  position: relative;
  display: inline-block;
`;

const BtnDropdownContent = styled.div`
  position: absolute;
  right: 0.3dvw;
  background: #ffffff;
  box-shadow: 0px 2px 4px rgba(65, 78, 98, 0.12),
    0px 0px 1px rgba(65, 78, 98, 0.05);
  border-radius: 6px;
  padding: 8px;
  width: 180px;
  text-align: center;
  z-index: 999;
  display: flex;
  flex-direction: column;
`;

const TableDropdown = ({
  dropdownContent,
  row,
  onToggleStatus,
  onDeleteAction,
}) => {
  const [dropdownOpen, setOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Fragment>
      <WrapperTableAction ref={dropdownRef}>
        <ButtonAction onClick={() => setOpen(!dropdownOpen)}>...</ButtonAction>
        {dropdownOpen ? (
          <BtnDropdownContent>
            {dropdownContent &&
              dropdownContent.map((item, i) => (
                <div key={i}>
                  <BtnDropdownAction>
                    {item.type === "link" ? (
                      <Link
                        className="w-100 m-0"
                        to={item.link}
                        data={item.linkData ? item.linkData : null}
                        style={{
                          color: `${item.disable ? "gray" : "initial"}`,
                          cursor: `${item.disable ? "not-allowed" : "default"}`,
                        }}
                      >
                        {item.label}
                      </Link>
                    ) : item.type === "delete" ? (
                      <BtnDeactivate
                        onClick={() => onDeleteAction({ id: row.id })}
                      >
                        {item.label}
                      </BtnDeactivate>
                    ) : item.type === "button" ? (
                      <button
                        className="bg-white border-0"
                        onClick={item.onclick}
                        style={{ fontSize: "14px" }}
                        disabled={item.disable ? item.disable : false}
                      >
                        {item.label}
                      </button>
                    ) : (
                      item.label
                    )}
                  </BtnDropdownAction>
                  {dropdownContent.length > 1 ? <hr className="m-0" /> : null}
                </div>
              ))}

            {row && row.status === 1 ? (
              <BtnDeactivate
                onClick={() => onToggleStatus({ id: row, toggle: "deactive" })}
              >
                Deactivate
              </BtnDeactivate>
            ) : row && row.status === 0 ? (
              <BtnActivate
                className="my-2 mx-2"
                onClick={() => onToggleStatus({ id: row, toggle: "active" })}
              >
                Activate
              </BtnActivate>
            ) : null}
          </BtnDropdownContent>
        ) : (
          ""
        )}
      </WrapperTableAction>
    </Fragment>
  );
};

TableDropdown.propTypes = {
  dropdownContent: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      link: PropTypes.string,
      label: PropTypes.string.isRequired,
    })
  ),
  row: PropTypes.object,
  onToggleStatus: PropTypes.func,
  onDeleteAction: PropTypes.func,
};

export default TableDropdown;
