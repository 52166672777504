import apiConfig from "../../api";
import {
    ADD_NEWS, GET_DETAIL_NEWS,
    GET_LIST_NEWS, UPDATE_NEWS, WATCH_RESPONSE_ALERT,
} from "../actionTypes";

export const getListNews = (perPage = 10, page= 1, param = "") => {
    return (dispatch) => {
        dispatch({
            type: GET_LIST_NEWS,
            payload: {
                loading: true,
                data: false,
                err: false,
            },
        });

        apiConfig
            .get(`/api/admin/news?page=${page}&search=${param}&perPage=${perPage}`)
            .then((resp) => {
                dispatch({
                    type: GET_LIST_NEWS,
                    payload: {
                        loading: false,
                        data: resp.data.data,
                        err: false,
                    },
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: GET_LIST_NEWS,
                    payload: {
                        loading: false,
                        data: false,
                        err: err.message,
                    },
                });
            });
    };
};

export const addDataNews = (payload) => {
    return (dispatch) => {
        dispatch({
            type: ADD_NEWS,
            payload: {
                loading: true,
                status: null,
                data: {},
                err: false,
            },
        });

        apiConfig
            .post(`/api/admin/news`, payload)
            .then((resp) => {
                dispatch({
                    type: WATCH_RESPONSE_ALERT,
                    payload: {
                        loading: false,
                        status: resp.status,
                        data: resp.data,
                        goBack: true,
                        err: false,
                    },
                });
            })
            .catch((err) => {
                if (err.response) {
                    console.log(err.response);
                } else if (err.request) {
                    console.log(err.request);
                } else if (err.message) {
                    console.log(err.message);
                }
                dispatch({
                    type: WATCH_RESPONSE_ALERT,
                    payload: {
                        loading: false,
                        data: err,
                        goBack: false,
                        err: true,
                    },
                });
            });
    };
};

export const updateDataNews = (payload) => {
    return (dispatch) => {
        dispatch({
            type: UPDATE_NEWS,
            payload: {
                loading: true,
                status: null,
                data: {},
                err: false,
            },
        });

        apiConfig
            .post(`/api/admin/news/update`, payload)
            .then((resp) => {
                dispatch({
                    type: WATCH_RESPONSE_ALERT,
                    payload: {
                        loading: false,
                        status: resp.status,
                        data: resp.data,
                        goBack: true,
                        err: false,
                    },
                });
            })
            .catch((err) => {
                if (err.response) {
                    console.log(err.response);
                } else if (err.request) {
                    console.log(err.request);
                } else if (err.message) {
                    console.log(err.message);
                }
                dispatch({
                    type: WATCH_RESPONSE_ALERT,
                    payload: {
                        loading: false,
                        data: err,
                        goBack: false,
                        err: true,
                    },
                });
            });
    };
};

export const getDetailNews = (id) => {
    return (dispatch) => {
        dispatch({
            type: GET_DETAIL_NEWS,
            payload: {
                loading: true,
                data: false,
                err: false,
            },
        });

        apiConfig
            .get(`/api/admin/news/${id}`)
            .then((resp) => {
                dispatch({
                    type: GET_DETAIL_NEWS,
                    payload: {
                        loading: false,
                        data: resp.data.data,
                        err: false,
                    },
                });
            })
            .catch((err) => {
                console.log(err);
                dispatch({
                    type: GET_DETAIL_NEWS,
                    payload: {
                        loading: false,
                        data: false,
                        err: err.message,
                    },
                });
            });
    };
};
