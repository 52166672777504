import { Fragment, useState } from "react";

import {
  InputRadio,
  InputNumber,
  InputSelectProduct,
} from "@components/formComponent";
import { Col, Label, FormGroup } from "reactstrap";

const InputPilihanProduct = ({
  value,
  onChangeProduct,
  onSelectKategori,
  onSelectBrand,
  onSelectSKU
}) => {
  const [productType, setProductType] = useState("semua");

  return (
    <Fragment>
      <FormGroup row tag="fieldset">
        <Label for="formFile" sm={2}>
          <b>
            Pilihan Product <sup style={{ color: "red" }}>*</sup>
          </b>
        </Label>
        <Col className="d-flex flex-row py-2" sm={10}>
          <InputRadio
            label="Semua"
            name="radioProduct"
            checked={productType === 'semua' ? true : false}
            onChange={(val) => setProductType("semua")}
          />
          <InputRadio
            label="Kategori"
            name="radioProduct"
			checked={productType === 'kategori' ? true : false}
            onChange={(val) => setProductType("kategori")}
          />
          <InputRadio
            label="Brand"
            name="radioProduct"
			checked={productType === 'brand' ? true : false}
            onChange={(val) => setProductType("brand")}
          />
          <InputRadio
            label="SKU"
            name="radioProduct"
			checked={productType === 'sku' ? true : false}
            onChange={(val) => setProductType("sku")}
          />
        </Col>
      </FormGroup>

      {productType === "kategori" ? (
        <InputSelectProduct
          label="Kategori"
          options={[]}
          onInputChange={() => {}}
          onSelectChange={() => {}}
          isRequired
        />
      ) : productType === "brand" ? (
        <InputSelectProduct
          label="Brand"
          options={[]}
          onInputChange={() => {}}
          onSelectChange={() => {}}
          isRequired
        />
      ) : productType === "sku" ? (
        <InputSelectProduct
          label="SKU"
          options={[]}
          onInputChange={() => {}}
          onSelectChange={() => {}}
          isRequired
        />
      ) : (
        ""
      )}
    </Fragment>
  );
};

export default InputPilihanProduct;
