import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  Input,
  FormGroup,
  Form,
  InputGroup,
} from "reactstrap";
import BroadcastUser from "../broadcastUserModal";

import { createBroadcast } from "../../../../redux/action/broadcastAction";
import { getListVoucher } from "../../../../redux/action/voucherAction";

const BroadcastAdd = (props) => {
  const [typeSelectTimeBroadcast, setTypeSelectTimeBroadcast] = useState("");
  const [typeSelectSegment, setTypeSelectSegment] = useState("");
  const [isOpenModalSegment, setIsOpenModalSegment] = useState(false);
  const [formPayload, setFormPayload] = useState({
    nama_broadcast: "",
    sender_type: "",
    segment_type: "",
    content: "",
    voucher: "",
    uuid: [],
    date_sender: "",
  });
  const [voucherOption, setVoucherOption] = useState([]);

  const history = useHistory();
  const dispatch = useDispatch();
  const { listVoucher } = useSelector((state) => state.VoucherReducer);
  const toastId = React.useRef(null);

  useEffect(() => {
    dispatch(getListVoucher(1));
  }, []);

  useEffect(() => {
    if (listVoucher.data && !listVoucher.loading) {
      let respData = listVoucher.data.data;
      let mappedData = respData.map((item) => ({
        id: item.id,
        title: item.name,
      }));
      mappedData.unshift({ id: "", title: "Select Voucher" });
      setVoucherOption(mappedData);
    }
  }, [listVoucher]);

  const handleSelectTimeBroadcast = (val) => {
    setTypeSelectTimeBroadcast(val);
    setFormPayload({ ...formPayload, sender_type: val });
  };

  const handleTypeSegment = (val) => {
    setTypeSelectSegment(val);
    setFormPayload({ ...formPayload, segment_type: val });
  };

  const handleOpenModal = (val) => {
    setIsOpenModalSegment(val);
  };

  const validateForm = (data) => {
    if (data.sender_type === "schedule" && data.date_sender === "") {
      return true;
    }
    if (data.segment_type === "manual" && data.uuid.length < 1) {
      return true;
    }
    if (
      data.nama_broadcast === "" ||
      data.sender_type === "" ||
      data.segment_type === "" ||
      data.content === "" ||
      data.voucher === ""
    ) {
      return true;
    }
    return false;
  };

  const handleOptionVoucher = (val) => {
    setFormPayload({ ...formPayload, voucher: val });
  };

  const getSelectedData = (val) => {
    let arrUID = val.map((item) => item.uuid);
    setFormPayload({ ...formPayload, uuid: arrUID });
    handleOpenModal(false);
  };

  const handleSubmitForm = () => {
    if (validateForm(formPayload)) {
      toast.error(`Please fill all fields.`);
    } else {
      let formData = new FormData();
      if (formPayload.uuid.length > 0) {
        for (let i of formPayload.uuid) {
          formData.append("uuid[]", i);
        }
      }
      if (formPayload.date_sender !== "") {
        formData.append("date_sender", formPayload.date_sender);
      }
      formData.append("nama_broadcast", formPayload.nama_broadcast);
      formData.append("sender_type", formPayload.sender_type);
      formData.append("segment_type", formPayload.segment_type);
      formData.append("content", formPayload.content);
      formData.append("voucher", formPayload.voucher);

      dispatch(createBroadcast(formData));
      toastId.current = toast("Please wait...", {
        position: "top-center",
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        autoClose: 5000,
        type: toast.TYPE.INFO,
      });
    }
  };

  const handleClose = () => {
    history.push("/dashboard/broadcast");
  };

  return (
    <Fragment>
      <Breadcrumb parent="Broadcast" title="Tambah Broadcast" urlParent={'/dashboard/broadcast'} />
      <Container fluid>
        <Row>
          <Col xl={8}>
            <Card>
              <CardBody className="p-3">
                <Form
                  className="m-2"
                  onSubmit={(e) => {
                    e.preventDefault();
                  }}
                >
                  <FormGroup row>
                    <Label for="formNamaBroadcast" sm={3}>
                      <b>Nama Broadcast</b>
                    </Label>
                    <Col sm={9}>
                      <Input
                        placeholder="Enter Broadcast Name"
                        id="formNamaBroadcast"
                        value={formPayload.nama_broadcast}
                        onChange={(e) =>
                          setFormPayload({
                            ...formPayload,
                            nama_broadcast: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup tag="fieldset" row className="d-flex my-2 py-1">
                    <Col>
                      <b>Waktu Broadcast</b>
                    </Col>
                    <Col sm={9} className="d-flex mx-3">
                      <FormGroup check className="mx-2">
                        <Label check>
                          <Input
                            type="radio"
                            name="radioSendTime"
                            id="rbSendNow"
                            onChange={(e) =>
                              e.target.checked
                                ? handleSelectTimeBroadcast("now")
                                : ""
                            }
                          />{" "}
                          Kirim Sekarang
                        </Label>
                      </FormGroup>
                      <FormGroup check className="mx-2">
                        <Label check>
                          <Input
                            type="radio"
                            name="radioSendTime"
                            id="rbSendLater"
                            onChange={(e) =>
                              e.target.checked
                                ? handleSelectTimeBroadcast("schedule")
                                : ""
                            }
                          />{" "}
                          Jadwalkan Nanti
                        </Label>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="mt-1">
                    <Col></Col>
                    <Col sm={9}>
                      <InputGroup>
                        {typeSelectTimeBroadcast === "schedule" ? (
                          <Input
                            type="date"
                            name="dateSender"
                            id="selectSendDate"
                            max="9999-12-31"
                            value={formPayload.date_sender}
                            onChange={(e) =>
                              setFormPayload({
                                ...formPayload,
                                date_sender: e.target.value,
                              })
                            }
                            style={{ maxWidth: "50%" }}
                          />
                        ) : (
                          ""
                        )}
                      </InputGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup tag="fieldset" row className="d-flex my-3 py-1">
                    <Col>
                      <b>Pilih Segment</b>
                    </Col>
                    <Col sm={9} className="d-flex mx-3">
                      <FormGroup check className="mx-2">
                        <Label check>
                          <Input
                            type="radio"
                            name="radioSegment"
                            id="rbAll"
                            onChange={(e) =>
                              e.target.checked ? handleTypeSegment("all") : ""
                            }
                          />{" "}
                          Semua
                        </Label>
                      </FormGroup>
                      <FormGroup check className="mx-2">
                        <Label check>
                          <Input
                            type="radio"
                            name="radioSegment"
                            id="rbManual"
                            onChange={(e) =>
                              e.target.checked
                                ? handleTypeSegment("manual")
                                : ""
                            }
                          />{" "}
                          Manual
                        </Label>
                      </FormGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="mt-1">
                    <Col></Col>
                    <Col sm={9}>
                      <InputGroup>
                        {typeSelectSegment === "manual" ? (
                          <div className="d-flex">
                            <Button
                              color="primary"
                              outline
                              onClick={() => handleOpenModal(true)}
                            >
                              Pilih Manual
                            </Button>
                            <span className="mx-2 mt-2">
                              <b className="mx-2">
                                {formPayload.uuid.length > 0
                                  ? `${formPayload.uuid.length} user`
                                  : `${0} user`}
                              </b>
                              terpilih
                            </span>
                          </div>
                        ) : null}
                      </InputGroup>
                    </Col>
                  </FormGroup>
                  <FormGroup row className="my-2 py-1">
                    <Label for="formDescription" sm={3}>
                      <b>Konten Broadcast</b>
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="textarea"
                        name="text"
                        id="formKonten"
                        placeholder="Write content here"
                        value={formPayload.content}
                        onChange={(e) =>
                          setFormPayload({
                            ...formPayload,
                            content: e.target.value,
                          })
                        }
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup row className="my-2 py-1">
                    <Label for="exampleSelect" sm={3}>
                      <b>Voucher</b>
                    </Label>
                    <Col sm={9}>
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        value={formPayload.voucher}
                        onChange={(e) => handleOptionVoucher(e.target.value)}
                      >
                        {voucherOption.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.title}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </FormGroup>
                  <FormGroup check row className="my-3">
                    <div className="d-flex justify-content-end">
                      <Button
                        onClick={handleClose}
                        className="mx-1"
                        color="primary"
                        outline
                      >
                        Cancel
                      </Button>
                      <Button
                        className="mx-1"
                        color="primary"
                        onClick={handleSubmitForm}
                      >
                        Submit
                      </Button>
                    </div>
                  </FormGroup>
                </Form>
              </CardBody>
            </Card>
            <BroadcastUser
              isOpenModal={isOpenModalSegment}
              doCloseBroadcast={handleOpenModal}
              getSelectedData={getSelectedData}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BroadcastAdd;
