import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../../layout/breadcrumb";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    Button,
} from "reactstrap";
import {Link, useLocation, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getDetailNews} from "../../../../../redux/action/newsAction";
import moment from "moment";

const NewsDetail = (props) => {
    const urlParams = useParams();
    const location = useLocation();
    const [payload, setPayload] = useState([])

    const dispatch = useDispatch();
    const {detailNews} = useSelector(
        (state) => state.NewsReducer
    );

    useEffect(() => {
        dispatch(getDetailNews(urlParams?.id));
    }, [])

    useEffect(() => {
        if (detailNews?.data && !detailNews?.loading) {
            setPayload(detailNews?.data)
        }
    }, [detailNews])

    return (
        <Fragment>
            <Breadcrumb parent="News" title="Detail News" urlParent={'/dashboard/news'} />
            <Container fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardTitle tag="h5" className="p-3" style={{marginBottom: -10}}>
                                {payload?.title || "-"}
                            </CardTitle>
                            <CardBody className="p-3" style={{marginTop: -10}}>
                                <div>
                                    <Link
                                        to={{pathname: `/dashboard/news/edit/${urlParams?.id}`, data: location?.data}}
                                    >
                                        <Button color="primary" outline  style={{width: "100%"}}>Edit</Button>
                                    </Link>
                                </div>
                                <div className="my-2">
                                    <div className="d-flex">
                                        <span>Start Date: {payload?.start_date ? moment(payload?.start_date).format("DD/MM/YYYY") : "-"}</span>
                                    </div>
                                    <div className="d-flex">
                                        <span>End Date: {payload?.end_date ? moment(payload?.end_date).format("DD/MM/YYYY") : "-"}</span>
                                    </div>
                                    <div>
                                        <p style={{marginBottom: 0}}>Content: </p>
                                        <span dangerouslySetInnerHTML={{__html: payload?.content}}></span>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <CardBody className="p-3" style={{textAlign: "center"}}>
                                <img src={`https://csi-staging.oss-ap-southeast-5.aliyuncs.com/${payload?.filename}`} alt={""} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default NewsDetail;
