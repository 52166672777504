import PropTypes from "prop-types";

const containerMutipleBanner = {
  overflowX: "scroll",
  overflowY: "hidden",
  whiteSpace: "nowrap",
};

const ImgSlider = ({ images }) => {
  return (
    <div className="carousel">
      <div className="carousel-inner" style={containerMutipleBanner}>
        {images && images.length > 0
          ? images.map((data, i) => (
              <div
                key={i}
                className="mx-2"
                style={{
                  position: "relative",
                  maxWidth: "240px",
                  maxHeight: "180px",
                  display: "inline-block",
                }}
              >
                <img
                  src={data.imageUrl}
                  style={{ height: "100%", width: "100%" }}
                ></img>
              </div>
            ))
          : ""}
      </div>
    </div>
  );
};

ImgSlider.propTypes = {
  images: PropTypes.array.isRequired,
};

export default ImgSlider;
