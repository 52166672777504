import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTable, useSortBy, useRowSelect } from "react-table";
import styled from "styled-components";

import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Col,
} from "reactstrap";
import BtnWhite from "@components/styledComponents/btnWhite";
import CustomPagination from "@components/customPagination";
import iconSearch from "@assets/images/input-search.png";
import EntryCounter from "@components/entryCounter";
import sortIcon from "@assets/images/sort-icon.svg";

import { searchProduct } from "@action/flashsaleAction";

const TableContainer = styled.div`
  overflow-y: auto;
  max-height: 250px;
`;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const AddProdukFlashsale = (props) => {
  const dispatch = useDispatch();
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);
  const [isAfterSearch, setIsAfterSearch] = useState(false);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const [totalPerPage, setTotalPerPage] = useState(0);

  const { listProdukFlashsale } = useSelector(
    (state) => state.FlashsaleReducer
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy, useRowSelect, (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox
                {...getToggleAllRowsSelectedProps()}
                onClick={(e) => handleOnSelectAllProduct(e)}
              />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                onClick={(e) => handleOnCellClicked(e, row)}
              />
            </div>
          ),
        },
        ...columns,
      ]);
    });

  useEffect(() => {
    setColumns([
      {
        Header: () => null,
        id: "AddProductFlashsale",
        columns: [
          {
            Header: "Name",
            accessor: "name",
            sortAble: true,
          },
          {
            Header: "Harga",
            accessor: "harga",
            sortAble: false,
          },
          {
            Header: "Stock",
            accessor: "stock",
            sortAble: false,
          },
        ],
      },
    ]);
  }, []);

  useEffect(() => {
    if (listProdukFlashsale.data && !listProdukFlashsale.loading) {
      let respData = listProdukFlashsale.data.data;
      let metaData = listProdukFlashsale.data.meta;
      let mappedData = respData.map((item) => ({
        id: item.id,
        name: item.name,
        harga: item.price || null,
        stock: item.stock.toString() || null,
      }));

      setMaxPageIndex(metaData.last_page);
      setTotalResult(metaData.total);
      setTotalPerPage(metaData.to);
      setData(mappedData);
    }
  }, [listProdukFlashsale]);

  const resetState = () => {
    setSelectedData([]);
    setData([]);
    setInputSearchValue("");
    setMaxPageIndex(1);
    setTotalResult(0);
    setTotalPerPage(0);
  };

  const handleOnSelectAllProduct = (e) => {
    if (e.target.checked) {
      setIsSelectAll(true);
    }
  };

  const handleOnCellClicked = (e, data) => {
    if (e.target.checked) {
      setSelectedData((selectedData) => [...selectedData, data.original]);
    } else {
      setSelectedData((selectedData) => [
        ...selectedData.filter((i) => data.original.id !== i.id),
      ]);
    }
  };

  const handleSearchProduct = (e) => {
    setInputSearchValue(e.target.value);
    if (e.target.value !== "") {
      setTimeout(() => {
        dispatch(searchProduct(1, e.target.value, 'flashsale'));
      }, 300);
    }
  };

  const handleOnSubmit = () => {
    if (selectedData.length > 0) {
      props.handleSubmitProduct(selectedData);
      resetState();
      props.toggleAddProduct();
    }
  };

  const handleOnEnter = (e) => {
    if (inputSearchValue !== "") {
      dispatch(searchProduct(1, inputSearchValue, 'flashsale'));
      setIsAfterSearch(true);
    }
  };

  const handleSubmitSearch = () => {
    if (inputSearchValue !== "") {
      dispatch(searchProduct(1, inputSearchValue, 'flashsale'));
      setIsAfterSearch(true);
    }
  };

  const handleCloseModal = () => {
    resetState();
    props.toggleAddProduct();
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(searchProduct(page, inputSearchValue, 'flashsale'));
    setIsAfterSearch(true);
  };

  return (
    <Fragment>
      <Modal
        isOpen={props.showAddProduct}
        backdrop={true}
        toggle={handleCloseModal}
      >
        <ModalHeader toggle={handleCloseModal}>
          <p className="my-1">
            <b>Mass Upload Product Max Qty</b>
          </p>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col sm={12} className="d-flex">
              <InputGroup className="mx-2" style={{ maxWidth: "280px" }}>
                <Input
                  placeholder="Search"
                  value={inputSearchValue}
                  onChange={handleSearchProduct}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") handleOnEnter(e);
                  }}
                />
                <InputGroupAddon addonType="prepend">
                  <Button
                    className="p-2"
                    color="primary"
                    onClick={() => handleSubmitSearch()}
                  >
                    <img src={iconSearch} width={15} height={15} />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
              <EntryCounter
                className="mx-2"
                totalPerPage={totalPerPage}
                totalResult={totalResult}
              />
            </Col>

            <Col sm={12}>
              <TableContainer className="my-2">
                <Table {...getTableProps()} className="text-left">
                  <thead>
                    {headerGroups.map((headerGroup, index) =>
                      index > 0 ? (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          {headerGroup.headers.map((column) => (
                            <th
                              {...column.getHeaderProps(
                                column.getSortByToggleProps()
                              )}
                            >
                              {column.render("Header")}
                              {column.sortAble ? <img src={sortIcon} /> : ""}
                            </th>
                          ))}
                        </tr>
                      ) : null
                    )}
                  </thead>

                  <tbody {...getTableBodyProps()} className="text-left">
                    {rows.map((row) => {
                      prepareRow(row);
                      return (
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell) => {
                            return (
                              <td
                                {...cell.getCellProps({
                                  style: {
                                    width: cell.column.width,
                                    minWidth: cell.column.minWidth,
                                  },
                                })}
                              >
                                {cell.render("Cell")}
                              </td>
                            );
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </TableContainer>
            </Col>
          </Row>

          <CustomPagination
            handlePageClick={handlePageClick}
            pageCount={maxPageIndex}
            pageRangeDisplayed={2}
            marginPagesDisplayed={2}
          />
        </ModalBody>
        <ModalFooter>
          <BtnWhite
            style={{ width: "100px", padding: "6px" }}
            onClick={handleCloseModal}
          >
            Cancel
          </BtnWhite>
          <Button color="primary" onClick={() => handleOnSubmit()}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default AddProdukFlashsale;
