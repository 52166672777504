import styled from "styled-components";

export const wrapperInputThumbnail = {
  position: "relative",
  overflow: "hidden",
  display: "inline-block",
};
export const styleInputThumbnail = {
  position: "absolute",
  left: "0",
  top: "0",
  opacity: "0",
  width: "240px",
  height: "180px",
  cursor: "pointer",
};
export const styleInputbanner = {
  position: "absolute",
  left: "0",
  top: "0",
  opacity: "0",
  width: "240px",
  height: "180px",
  cursor: "pointer",
};
export const btnUploadBanner = {
  border: "2px solid gray",
  color: "gray",
  backgroundColor: "white",
  padding: "8px",
  borderRadius: "5px",
  width: "240px",
  height: "180px",
  cursor: "pointer",
};
export const containerMutipleBanner = {
  overflowX: "scroll",
  overflowY: "hidden",
  whiteSpace: "nowrap",
};
export const btnDeleteBanner = {
  position: "absolute",
  right: "0",
  top: "0",
  width: "32px",
  height: "32px",
  cursor: "pointer",
};
export const ImgButton = styled.img`
  display: block;
  position: relative;

  ::after {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 95%;
    height: 95%;
    padding: 70px 0;
    background-color: #fff;
    text-align: center;
    content: "Tambah gambar";
  }
`;

export const customDropdown = {
  position: "relative",
  display: "inline-block",
};
export const customDropbtn = {
  backgroundColor: "white",
  color: "black",
  padding: "5px 6px",
  cursor: "pointer",
  border: "1px solid #e0e0e0",
  borderRadius: "4px",
};
export const customDropdownContent = {
  position: "absolute",
  overflow: "auto",
  zIndex: "1",
  minWidth: "150px",
  padding: "6px",
  backgroundColor: "white",
  textAlign: "center",
  boxShadow: "1px 1px 1px 1px #e0e0e0",
  borderRadius: "4px",
};
export const BtnAction = styled.button`
  color: black;
  font-size: 14px;
  border: 1px solid #d2d2d4;
  border-radius: 5px;
  cursor: pointer;
  background: white;
  padding: 4px 8px;
  font-weight: 500;
  width: 100%;
  height: 30px;
`;
export const cssBtnReminder = {
  position: "relative",
  display: "inline-block",
};
export const cssDropdownReminder = {
  position: "absolute",
  overflow: "auto",
  zIndex: "1",
  minWidth: "150px",
  padding: "6px",
  backgroundColor: "white",
  boxShadow: "2px 2px 2px 2px #e0e0e0",
  borderRadius: "4px",
  top: "30px",
};
