import React, { useEffect, useState, Fragment } from "react";
import Breadcrumb from "../../../../../layout/breadcrumb";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Input,
    Form,
    FormGroup,
    Label,
} from "reactstrap";
import {toast} from "react-toastify";
import {useDispatch} from "react-redux";
import {addDataNews} from "../../../../../redux/action/newsAction";
import {Editor} from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import {convertToRaw, EditorState} from "draft-js";

const defaultPayload = {
    title: "",
    banner: "",
    start_date: "",
    end_date: "",
    content: ""
}

const NewsAdd = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState(defaultPayload);
    const [disableSubmit, setDisableSubmit] = useState(false);
    const [imgThumbnailSrc, setImgThumbnailSrc] = useState("");
    const [editorInput, setEditorInput] = useState(EditorState.createEmpty());

    const handleSubmit = () => {
        setDisableSubmit(true);
        const dataForm = new FormData();
        dataForm.append("title", data?.title);
        dataForm.append("banner", data?.banner);
        dataForm.append("start_date", data?.start_date);
        dataForm.append("end_date", data?.end_date);
        dataForm.append("content", data?.content);
        dispatch(addDataNews(dataForm));

        setTimeout(() => {
            setDisableSubmit(false);
        }, 500);
    }

    const isImage = (imgType) => {
        switch (imgType) {
            case "image/png":
            case "image/jpeg":
            case "image/jpg":
                return true;
        }
        return false;
    };

    const handleOnChangeInputBanner = (e) => {
        if (e.target.files.length > 0) {
            const filesImg = e.target.files[0];
            if (filesImg.size > 2000000) {
                toast.error(`Max file size is 2MB`);
                document.getElementById("formFile").value = "";
            } else {
                if (!isImage(filesImg.type)) {
                    toast.error(`Select appropiate image file.`);
                    document.getElementById("formFile").value = "";
                } else {
                    const imgSrc = URL.createObjectURL(filesImg);
                    setImgThumbnailSrc(imgSrc);
                    setData({ ...data, banner: filesImg });
                }
            }
        }
    };

    const handleConvertContentToHtml = (e) => {
        let convertedHtml = draftToHtml(convertToRaw(e.getCurrentContent()));
        setData({
            ...data,
            content: convertedHtml,
        });
        setEditorInput(e);
    };

    return (
        <Fragment>
            <Breadcrumb parent="News" title="Add New News" urlParent={'/dashboard/news'} />
            <Container fluid>
                <Row>
                    <Col sm={7}>
                        <Card>
                            <CardBody className="p-3">
                                <Form>
                                    <FormGroup row>
                                        <Label for="formNewsTitle" sm={2}>
                                            News Title
                                        </Label>
                                        <Col sm={10}>
                                            <Input placeholder="Enter News Title" id="formNewsTitle" onChange={(event) => setData({...data, title: event.target.value})} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="formFile" sm={2}>
                                            Banner
                                        </Label>
                                        <Col sm={10}>
                                            <Input
                                                className="p-2"
                                                type="file"
                                                name="file"
                                                id="formFile"
                                                style={{
                                                    border: "1px solid #d2d2d4",
                                                    borderRadius: "5px",
                                                }}
                                                onChange={handleOnChangeInputBanner}  />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="selectStartDate" sm={2}>
                                            <b>Start Date</b>
                                        </Label>
                                        <Col sm={10}>
                                            <Input
                                                type="date"
                                                name="date"
                                                id="selectStartDate"
                                                placeholder="Start Date"
                                                max="9999-12-31"
                                                min="1700-12-31"
                                                value={data?.start_date}
                                                onChange={(e) =>
                                                    setData({
                                                        ...data,
                                                        start_date: e.target.value,
                                                    })
                                                }
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="selectEndDate" sm={2}>
                                            <b>End Date</b>
                                        </Label>
                                        <Col sm={10}>
                                            <Input
                                                type="date"
                                                name="date"
                                                id="selectEndDate"
                                                placeholder="End Date"
                                                max="9999-12-31"
                                                value={data?.end_date}
                                                onChange={(e) =>
                                                    setData({
                                                        ...data,
                                                        end_date: e.target.value,
                                                    })
                                                }
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="exampleText" sm={2}>
                                            <b>Content</b>
                                        </Label>
                                        <Col sm={10}>
                                            <Editor
                                                className="richtext-editor"
                                                editorState={editorInput}
                                                onEditorStateChange={handleConvertContentToHtml}
                                                editorStyle={{
                                                    border: "1px #c6c9cf solid",
                                                    height: "250px",
                                                }}
                                            />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup check row>
                                        <Col>
                                            <Button type="button" onClick={() => handleSubmit()} disabled={disableSubmit}>Save</Button>
                                        </Col>
                                    </FormGroup>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default NewsAdd;
