import styled from "styled-components";

const BtnDropdownAction = styled.div`
  border: none;
  cursor: pointer;
  background: transparent;
  font-size: 12px;
  margin: 8px;
  font-weight: 400;

  a {
    color: black;
    text-decoration: none;
	font-weight: 400;
  }
`;

export default BtnDropdownAction;
