import { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";

//components
import { Container, Row, Col, Card, CardBody } from "reactstrap";
import BadgeApproval from "@components/badgeApproval";
import Breadcrumb from "@layout/breadcrumb";
import TableLayout from "@layout/tableLayout";
import TableCSI from "@components/table";
import AlertApproval from "@components/alertApproval";

const ApprovalHargaCoret = () => {
  const urlParam = useParams();
  const dispatch = useDispatch();

  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [isFetchedData, setIsFetchedData] = useState(false);
  const [totalEntry, setTotalEntry] = useState(0);
  const [totalEntryPerPage, setTotalEntryPerPage] = useState(0);
  const [column, setColumn] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const [columnApproval, setColumnApproval] = useState([]);
  const [dataAprroval, setDataApproval] = useState([]);
  const [modalData, setModalData] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  useEffect(() => {
    fetchtable();
  }, []);

  function fetchtable() {
    setColumn([
      {
        Header: () => null,
        id: "promo",
        columns: [
          {
            Header: "Product Name",
            accessor: "name",
            sortAble: true,
          },
          {
            Header: "User Group",
            accessor: "group",
            sortAble: true,
          },
          {
            Header: "Discount Group",
            accessor: "discount_group",
            sortAble: true,
          },
          {
            Header: "User Group",
            accessor: "user",
            sortAble: true,
          },
          {
            Header: "Kuota",
            accessor: "kuota",
            sortAble: true,
          },
          {
            Header: "Price",
            accessor: "price",
            sortAble: true,
          },
          {
            Header: "Diskon",
            accessor: "diskon",
            sortAble: true,
          },
          {
            Header: "Periode Promo",
            accessor: "periode",
            Cell: ({ row }) => (
              <span>
                {row.original.tanggal !== "-"
                  ? moment.utc(row.original.tanggal).format("DD/MM/YYYY")
                  : "-"}
              </span>
            ),
            sortType: (rowA, rowB, columnId, desc) => {
              let startDate = Date.parse(rowA.values[columnId]);
              let endDate = Date.parse(rowB.values[columnId]);
              if (startDate > endDate) return 1;
              if (startDate < endDate) return -1;
              return 0;
            },
          },
        ],
      },
    ]);

    setColumnApproval([
      {
        Header: () => null,
        id: "promo",
        columns: [
          {
            Header: "Approver",
            accessor: "approver",
            sortAble: true,
          },
          {
            Header: "Approval Time",
            accessor: "time",
            sortAble: true,
          },
          {
            Header: "Status",
            accessor: "status",
            sortAble: true,
            Cell: ({ row }) => <BadgeApproval status={row.original.status} />,
            sortType: (rowA, rowB, columnId, desc) => {
              let val1 = parseInt(rowA.original.status);
              let val2 = parseInt(rowB.original.status);
              if (val1 > val2) return 1;
              if (val1 < val2) return -1;
              return 0;
            },
          },
        ],
      },
    ]);

    setDataTable([
      {
        name: "Rice Box FIFO",
        group: ["grosir", "retail"],
        discount_group: ["gold", "silver", "diamond"],
        user: ["Dulce Philips", "Krisnna Septimus", "Miracle"],
        kuota: 45,
        price: 145000,
        diskon: 5000,
        periode: "24/10/2023 - 24/11/2024",
      },
      {
        name: "Rice Box FIFO",
        group: ["grosir", "retail"],
        discount_group: ["gold", "silver", "diamond"],
        user: ["Dulce Philips", "Krisnna Septimus", "Miracle"],
        kuota: 45,
        price: 145000,
        diskon: 5000,
        periode: "24/10/2023 - 24/11/2024",
      },
    ]);
    setDataApproval([
      {
        approver: "PIC",
        time: "06/06/2024",
        status: 1,
      },
    ]);
  }

  function handleAlert() {
    setModalData({
      isOpen: false,
	  isNote: false,
      title: "",
      subTitle: "",
    });
  }

  return (
    <Fragment>
      <Breadcrumb
        parent="Promotion"
        title="Detail Promo Harga Coret"
        urlParent="/dashboard/promo"
      />
      <Container fluid>
        <Row>
          <Col className="p-0">
            <TableLayout
              headerTitle="Product Promo"
              handleSearch={() => {}}
              totalEntry={totalEntry}
              totalEntryPerPage={totalEntryPerPage}
              pageCount={maxPageIndex}
              handleChangePage={() => {}}
              handleChangeRow={(val) => {}}
              nosearch
            >
              <TableCSI data={dataTable} columns={column} />
            </TableLayout>
          </Col>
        </Row>

        <Row>
          <Col>
            <Card
              className="my-0"
              style={{ maxHeight: "400px", overflowY: "auto" }}
            >
              <CardBody className="p-3">
                <h3>Approval Log</h3>
                <TableCSI data={dataAprroval} columns={columnApproval} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <div className="d-flex flex-row justify-content-end my-2">
          <button
            className="border rounded-lg mx-1 bg-white px-3 py-2"
            onClick={() =>
              setModalData({
                isOpen: true,
                isNote: true,
                title: "Are you sure?",
                subTitle: "Promo Sample Promo will change to Reject",
              })
            }
            style={{ fontSize: "16px" }}
          >
            Tolak
          </button>
          <button
            className="border rounded-lg mx-1 bg-white px-3 py-2"
            style={{ fontSize: "16px" }}
            onClick={() =>
              setModalData({
                isOpen: true,
                isNote: true,
                title: "Revisi",
                subTitle: "Masukan notes untuk revisi",
              })
            }
          >
            Revisi
          </button>
          <button
            className="border rounded-lg mx-1 bg-primary px-3 py-2"
            style={{ fontSize: "16px" }}
            onClick={() =>
              setModalData({
                isOpen: true,
                isNote: false,
                title: "Are you sure?",
                subTitle: "Promo Sample Promo will change to Approve",
              })
            }
          >
            Setujui
          </button>
        </div>
        <AlertApproval
          isOpenAlert={modalData.isOpen}
		  isNote={modalData.isNote || false}
          modalTitle={modalData.title}
          modalSubTitle={modalData.subTitle}
          handleToggle={handleAlert}
        />
      </Container>
    </Fragment>
  );
};

export default ApprovalHargaCoret;
