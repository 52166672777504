import { Fragment } from "react";
import PropTypes from "prop-types";

const cssBadgeContainerActive = {
  position: "relative",
  border: "2px solid #AAE3BF",
  borderRadius: "10px",
  color: "#1D8242",
  fontWeight: "500",
  backgroundColor: "#EAF8EF",
  maxWidth: "80px",
  justifyContent: "center",
};
const cssBadgeContainerDisable = {
  position: "relative",
  border: "2px solid #FFB8B8",
  borderRadius: "10px",
  color: "#B33636",
  fontWeight: "500",
  backgroundColor: "#FFEDED",
  maxWidth: "120px",
  justifyContent: "center",
};
const cssStatusDotActive = {
  content: "",
  width: "5px",
  height: "5px",
  border: "1px solid #1D8242",
  borderRadius: "50%",
  margin: "0 auto",
  padding: "0",
  background: "#1D8242",
  position: "absolute",
  left: "0",
  top: "40%",
};
const cssStatusDotDisable = {
  content: "",
  width: "5px",
  height: "5px",
  border: "1px solid #FF4D4D",
  borderRadius: "50%",
  margin: "0 auto",
  padding: "0",
  background: "#FF4D4D",
  position: "absolute",
  left: "0",
  top: "40%",
};

const BadgeStatus = ({ status, label }) => {
  return (
    <Fragment>
      {status === 1 ? (
        <div className="d-flex" style={cssBadgeContainerActive}>
          <div className="mx-1" style={cssStatusDotActive}></div>
          <span className="mx-2 px-1">{label ? label : "Active"}</span>
        </div>
      ) : status && status.toLowerCase() === "y" ? (
        <div className="d-flex" style={cssBadgeContainerActive}>
          <div className="mx-1" style={cssStatusDotActive}></div>
          <span className="mx-2 px-1">Active</span>
        </div>
      ) : (
        <div className="d-flex" style={cssBadgeContainerDisable}>
          <div className="mx-1" style={cssStatusDotDisable}></div>
          <span className="mx-2 px-1">{label ? label : "Inactive"}</span>
        </div>
      )}
    </Fragment>
  );
};

BadgeStatus.propTypes = {
  status: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  label: PropTypes.string
};

export default BadgeStatus;
