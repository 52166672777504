import React, { Fragment, useState, useEffect } from "react";
import {
  FileText,
  LogIn,
  Mail,
  User,
  MessageSquare,
  Bell,
} from "react-feather";
import {
  setTranslations,
  setDefaultLanguage,
  setLanguageCookie,
  setLanguage,
  translate,
} from "react-switch-lang";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import moment from "moment";

import { toLogout } from "@action/loginAction";
import {
  getListChatUsers,
  storeNotificationData,
} from "@action/chatAction";

import {
  ViewAll,
  MessageBox,
  Admin,
  Account,
  Inbox,
  Taskboard,
  LogOut,
} from "../../constant";

import en from "@assets/i18n/en.json";
import es from "@assets/i18n/es.json";
import pt from "@assets/i18n/pt.json";
import fr from "@assets/i18n/fr.json";
import du from "@assets/i18n/du.json";
import cn from "@assets/i18n/cn.json";
import ae from "@assets/i18n/ae.json";

setTranslations({ en, es, pt, fr, du, cn, ae });
setDefaultLanguage("en");
setLanguageCookie();

const Rightbar = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { listConversationChat, cometchatUID } = useSelector(
    (state) => state.ChatReducer
  );
  const userData = useSelector((state) => state?.LoginReducer?.userLoginData);

  const [chatDropDown, setChatDropDown] = useState(false);
  const [chatList, setChatlist] = useState([]);
  const [counterUnread, setCounterUnread] = useState(0);
  const [adminData, setAdminData] = useState({});

  useEffect(() => {
    if (listConversationChat.data && listConversationChat.data.length > 0) {
      setChatlist(listConversationChat.data);
    }
  }, [listConversationChat]);

  useEffect(() => {
    if (!userData.data) return false;

    setAdminData({
      ...adminData,
      avatar_url: userData.data.avatar_url,
      username: userData.data.username,
      role: userData.data.role_string,
    });
  }, [userData.data]);

  useEffect(() => {
    if (cometchatUID.auth) {
      dispatch(getListChatUsers());
    }
  }, [cometchatUID]);

  useEffect(() => {
    if (chatList.length > 0) {
      const counter = chatList.filter((item) => item.unreadMessageCount > 0);
      setCounterUnread(counter.length);
    }
  }, [chatList]);

  const handleChatNotifClick = (data) => {
    setCounterUnread(counterUnread - 1);
    dispatch(storeNotificationData(data));
    history.push("/dashboard/chat");
  };

  const signOut = () => {
    dispatch(toLogout());
    history.push(`/login`);
  };

  return (
    <Fragment>
      <div className="nav-right right-header p-0">
        <ul className="nav-menus">
          <li
            className="onhover-dropdown mx-2"
            onClick={() => setChatDropDown(!chatDropDown)}
          >
            <MessageSquare />
            {counterUnread > 0 ? (
              <span className="badge badge-pill badge-secondary">
                {counterUnread}
              </span>
            ) : null}
            <ul
              className={`chat-dropdown onhover-show-div ${
                chatDropDown ? "active" : ""
              }`}
            >
              <li>
                <MessageSquare />
                <h6 className="f-18 mb-0">{MessageBox}</h6>
              </li>

              {chatList.map((chat, i) => (
                <li key={i} onClick={() => handleChatNotifClick(chat)}>
                  {/* <img
                    className="img-fluid rounded-circle mr-3"
                    src={require("../../assets/images/user/1.jpg")}
                    alt=""
                  />
                  <div className="status-circle online"></div> */}
                  {chat.unreadMessageCount > 0 ? (
                    <div className="media">
                      <div className="media-body">
                        <b>
                          <span>{chat.conversationWith.name}</span>
                        </b>
                        <b>
                          <p>{chat.lastMessage.text}</p>
                        </b>
                      </div>
                      <b>
                        <p className="f-12 font-success">
                          {moment
                            .unix(chat.lastMessage.sentAt)
                            .format("DD MMM HH:mm")}
                        </p>
                      </b>
                    </div>
                  ) : (
                    <div className="media">
                      <div className="media-body">
                        <span style={{ color: "grey" }}>
                          {chat.conversationWith.name}
                        </span>
                        <p>{chat.lastMessage.text}</p>
                      </div>
                      <p className="f-12 font-success">
                        {moment
                          .unix(chat.lastMessage.sentAt)
                          .format("DD MMM HH:mm")}
                      </p>
                    </div>
                  )}
                </li>
              ))}

              <li className="text-center">
                {" "}
                <button className="btn btn-primary">
                  <Link to={"/dashboard/chat"} style={{ color: "#FFF" }}>
                    {ViewAll}
                  </Link>
                </button>
              </li>
            </ul>
          </li>
		  
          <li className="profile-nav onhover-dropdown p-0">
            {adminData ? (
              <div className="media profile-media">
                <img
                  className="b-r-10"
                  src={adminData.avatar_url}
                  alt=""
                  tyle={{ objectFit: "cover" }}
                />
                <div className="media-body">
                  <span>{adminData.username}</span>
                  <p className="mb-0 font-roboto">
                    {adminData.role}{" "}
                    <i className="middle fa fa-angle-down"></i>
                  </p>
                </div>
              </div>
            ) : null}
            <ul className="profile-dropdown onhover-show-div">
              <li>
                <Link to={`/dashboard/account`}>
                  <User />
                  <span>{Account} </span>
                </Link>
              </li>
              <li onClick={() => signOut()}>
                <LogIn />
                <span>{LogOut}</span>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </Fragment>
  );
};
export default translate(Rightbar);
