import { SET_UPDATE_ADMIN_PROFILE } from "../../actionTypes";

const initialState = {
  respUpdateAdminProfile: {},
};

const AccountReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_UPDATE_ADMIN_PROFILE:
      state.respUpdateAdminProfile = action.payload;
      return { ...state, respUpdateAdminProfile: state.respUpdateAdminProfile };
    default:
      return { ...state };
  }
};

export default AccountReducer;
