import React, { Fragment, useEffect, useState } from "react";
import {
  Container,
  Row,
  Badge,
  Col,
  Card,
  CardBody,
  Media,
  FormGroup,
  Button,
  InputGroup,
  Input,
  Label,
  InputGroupAddon,
} from "reactstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";
import CurrencyFormatter from "../../../../../Component/currencyFormatter";

//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  clearDataList,
  fetchAdminProduct,
} from "../../../../../redux/action/chatAction";

const BtnClose = styled.div`
  cursor: pointer;
  font-weight: bold;
  :hover {
    background: #bbb;
  }
`;

const AttachSales = (props) => {
  const dispatch = useDispatch();
  const { listAdminProduct } = useSelector((state) => state.ChatReducer);
  const [showProdukDetail, setShowProdukDetail] = useState(false);
  const [showSalesDetail, setShowSalesDetail] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [isEnableClearSearch, setIsEnableClearSearch] = useState(false);
  const [productPage, setProductPage] = useState(1);
  const [listProduk, setListProduk] = useState([]);
  const [listProdukTemp, setListProdukTemp] = useState([]);
  const [selectedProduk, setSelectedProduk] = useState([]);
  const [selectedProdukDetail, setSelectedProdukDetail] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [subTotalSO, setSubTotalSO] = useState(0);
  const [totalPriceSO, setTotalPriceSO] = useState(0);

  const toggleShowDetail = (param) => {
    setShowProdukDetail(!showProdukDetail);
    setSelectedProdukDetail(param);
  };

  const toggleCloseDetail = () => {
    setShowProdukDetail(!showProdukDetail);
    setSelectedProdukDetail({});
  };

  const toggleSalesDetail = () => {
    //setShowProdukDetail(!showProdukDetail);
    setShowSalesDetail(!showSalesDetail);
    if (selectedProduk.length > 0) {
      document
        .querySelectorAll("input[type=checkbox]")
        .forEach((el) => (el.checked = false));

      let subTotal = subTotalSO;
      for (let i = 0; i < selectedProduk.length; i++) {
        setSubTotalSO((subTotal += selectedProduk[i].price));
      }
      setTotalPriceSO(subTotal);
    }
  };

  const toggleCloseSalesDetail = () => {
    setShowSalesDetail(!showSalesDetail);
    if (selectedProduk.length > 0) {
      setSelectedProduk([]);
    }
  };

  const toggleCloseSales = (param) => {
    props.onClose(param);
  };

  const handleSelectedProduk = (item, e) => {
    if (e.target.checked) {
      setSelectedProduk([...selectedProduk, item]);
    } else {
      setSelectedProduk(selectedProduk.filter((arr) => arr.id !== item.id));
    }
  };

  const handleTypedSearch = (e) => {
    setSearchInput(e.target.value);
  };

  const handleOnEnterPress = () => {
    let newArr = listProduk.filter((item) =>
      item.name.toLowerCase().includes(searchInput.toLowerCase())
    );
    setListProdukTemp(listProduk);
    setListProduk(newArr);
    setIsEnableClearSearch(true);
  };

  const handleClearSearchInput = () => {
    if (isEnableClearSearch) {
      setSearchInput("");
      setListProduk(listProdukTemp);
      setListProdukTemp([]);
      setIsEnableClearSearch(false);
    } else {
      setSearchInput("");
    }
  };

  const handleSendButton = () => {
    if (selectedProduk.length > 0) {
      props.handleUpdateAttachment(selectedProduk);
      props.setTypeAttachment("salesorder");
      setSelectedProduk([]);
      setShowSalesDetail(!showSalesDetail);
    }
    return;
  };

  const loadMore = () => {
    setProductPage(productPage + 1);
    setTimeout(() => {
      dispatch(fetchAdminProduct(productPage + 1));
    }, 300);
  };

  useEffect(() => {
    if (listAdminProduct && listAdminProduct.length > 0) {
      setListProduk(listAdminProduct);
      setIsLoadingList(false);
    }
  }, [listAdminProduct]);

  useEffect(() => {
    dispatch(fetchAdminProduct(productPage));
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearDataList());
    };
  }, []);

  return (
    <Fragment>
      {!showProdukDetail && !showSalesDetail ? (
        <div>
          <div>
            <div className="d-flex justify-content-between py-1">
              <span>
                <b>Pilih Produk</b>
              </span>
              <BtnClose onClick={() => toggleCloseSales("")}>X</BtnClose>
            </div>
            <InputGroup>
              <Input
                placeholder="Cari Produk"
                value={searchInput}
                onChange={handleTypedSearch}
                onKeyDown={(e) => {
                  if (e.key === "Enter") handleOnEnterPress();
                }}
              />
              {searchInput !== "" ? (
                <InputGroupAddon addonType="prepend">
                  <Button
                    className="m-1"
                    onClick={() => handleClearSearchInput()}
                    close
                  />
                </InputGroupAddon>
              ) : null}
            </InputGroup>
          </div>
          <div className="my-3 att_prod_list" id="att_prod_list_id">
            {!isLoadingList && listProduk.length > 0 ? (
              <InfiniteScroll
                dataLength={listProduk.length}
                next={() => loadMore()}
                hasMore={hasMore}
                scrollThreshold={0.9}
                loader={listProduk.length < 1 ? <p>Loading...</p> : null}
                scrollableTarget="att_prod_list_id"
              >
                {listProduk.map((item, id) => (
                  <div className="d-flex my-3" key={id}>
                    <img
                      src={
                        item.images && item.images.length > 0
                          ? item.images[0].thumbnail_image_url
                          : null
                      }
                      alt=""
                      className="produk_detail_image"
                    />
                    <div
                      onClick={() => toggleShowDetail(item)}
                      className="mx-2 produk_list_detail"
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <p className="m-0 produk_name">{item.name}</p>
                        <p className="m-0 produk_price">
                          <b>
                            <CurrencyFormatter
                              digit={item.price}
                              typeDigit={"amount"}
                            />
                          </b>
                        </p>
                        <span className="produk_stock">
                          Stock: {item.stock}
                        </span>
                      </div>
                    </div>
                    <div className="mx-2">
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            id={`checkbox-${id}`}
                            onChange={(e) => handleSelectedProduk(item, e)}
                          />
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                ))}
              </InfiniteScroll>
            ) : isLoadingList ? (
              <div className="m-auto loader"></div>
            ) : null}
          </div>
          <div className="att_produk_btn btn-send-produk">
            <div className="d-flex justify-content-between">
              <span className="text_produk_terpilih">Produk terpilih:</span>
              <Button color="primary" onClick={() => toggleSalesDetail()} block>
                Selanjutnya
              </Button>
            </div>
            <div>
              <span className="text_qty_item">
                {selectedProduk.length} item
              </span>
            </div>
          </div>
        </div>
      ) : showProdukDetail && !showSalesDetail ? (
        <div>
          <div className="d-flex justify-content-start my-1">
            <Button onClick={() => toggleCloseDetail()} close />
            <p className="mx-2 my-1">
              <b>Detail Barang</b>
            </p>
          </div>
          <div className="att_sales_detail">
            <Card className="p-0">
              <CardBody>
                <img
                  className="mx-auto"
                  src={
                    selectedProdukDetail.images
                      ? selectedProdukDetail.images[0].thumbnail_image_url
                      : ""
                  }
                  alt=""
                  width={200}
                />
                <div>
                  <p className="my-2">{selectedProdukDetail.name}</p>
                  {selectedProdukDetail.stock !== 0 ? (
                    <Badge className="my-1" color="primary">
                      Stok Tersedia
                    </Badge>
                  ) : (
                    <Badge className="my-1" color="primary">
                      Stok Tidak Tersedia
                    </Badge>
                  )}
                  <p className="my-2">
                    <b>
                      <CurrencyFormatter
                        digit={selectedProdukDetail.price}
                        typeDigit={"amount"}
                      />
                    </b>
                  </p>
                </div>
              </CardBody>
            </Card>
            <Card className="p-0">
              <CardBody>
                <b>Informasi Produk</b>
                <div className="d-flex justify-content-between">
                  <span>Berat</span>
                  <span>{selectedProdukDetail.weight} gram</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span>Brand</span>
                  <span>{selectedProdukDetail.brand.name}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span>SKU</span>
                  <span>{selectedProdukDetail.sku_code}</span>
                </div>
                <div className="d-flex justify-content-between">
                  <span>Category</span>
                  <span>{selectedProdukDetail.category.name}</span>
                </div>
              </CardBody>
            </Card>
          </div>
          {/* <Button className="my-2 att_produk_btn" color="primary" block>
            Lampirkan
          </Button> */}
        </div>
      ) : (
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-start my-1">
            <Button onClick={() => toggleCloseSalesDetail()} close />
            <p className="mx-2 my-1">Sales Order</p>
          </div>
          <div className="p-2 att_sales_detail_order">
            {selectedProduk.map((item) => (
              <div className="d-flex justify-content-start my-2" key={item.id}>
                <img
                  src={
                    item.images && item.images.length > 0
                      ? item.images[0].thumbnail_image_url
                      : null
                  }
                  alt=""
                  className="produk_detail_image"
                />
                <div
                  onClick={() => toggleShowDetail(item)}
                  className="mx-2 produk_list_detail"
                  style={{ cursor: "pointer" }}
                >
                  <div>
                    <p className="m-0 produk_name">{item.name}</p>
                    <p className="m-0 produk_price">
                      <b>
                        <CurrencyFormatter
                          digit={item.price}
                          typeDigit={"amount"}
                        />
                      </b>
                    </p>
                    <span className="produk_stock">Stock: {item.stock}</span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className="my-2 p-2 sales_ringkasan">
            <h5 className="my-3">Ringkasan</h5>
            <div className="d-flex justify-content-between my-2">
              <span>Sub Total</span>
              <span>
                <b>Rp. {subTotalSO}</b>
              </span>
            </div>
            <div className="d-flex justify-content-between my-2">
              <span>Total Diskon</span>
              <span>
                <b>Rp. {0}</b>
              </span>
            </div>
            <div className="d-flex justify-content-between my-2 py-2 sales_total_harga">
              <span>Total Harga</span>
              <span>
                <b>Rp. {totalPriceSO}</b>
              </span>
            </div>
          </div>
          <div className="d-flex p-2 att_produk_btn">
            <Button onClick={() => handleSendButton()} color="primary" block>
              Create Sales Order
            </Button>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default AttachSales;
