import {
    GET_LIST_NEWS,
    GET_DETAIL_NEWS
} from "../../actionTypes";

const initialState = {
    listNews: {},
    detailNews: {}
};

const NewsReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_NEWS:
            state.listNews = action.payload;
            return { ...state, listNews: state.listNews };
        case GET_DETAIL_NEWS:
            state.detailNews = action.payload;
            return { ...state, detailNews: state.detailNews };
        default:
            return { ...state };
    }
};

export default NewsReducer;
