import {
  GET_LIST_CATEGORY,
  GET_PARENT_CATEGORY,
  GET_CHILD_CATEGORY,
  CHANGE_STATUS_CATEGORY,
  GET_CATEGORY_DETAIL,
} from "../../actionTypes";

const initialState = {
  listCategory: {},
  parentCategory: {},
  childCategory: {},
  statusCategory: {},
  detailCategory: {},
};

const CategoryReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_CATEGORY:
      state.listCategory = action.payload;
      return { ...state, listCategory: state.listCategory };
    case GET_CATEGORY_DETAIL:
      state.detailCategory = action.payload;
      return { ...state, detailCategory: state.detailCategory };
    case GET_PARENT_CATEGORY:
      state.parentCategory = action.payload;
      return { ...state, parentCategory: state.parentCategory };
    case GET_CHILD_CATEGORY:
      state.childCategory = action.payload;
      return { ...state, childCategory: state.childCategory };
    case CHANGE_STATUS_CATEGORY:
      state.statusCategory = action.payload;
      return { ...state, statusCategory: state.statusCategory };
    default:
      return { ...state };
  }
};

export default CategoryReducer;
