import { Fragment } from "react";
import ReactPaginate from "react-paginate";
import styled from "styled-components";

const ContainerPagination = styled.div`
  li {
    padding: 8px 12px;
    border: 1px solid #d1d5dc;
    border-radius: 6px;
    margin: 4px;
  }
  .selected {
    color: #2647e6;
    border: 1px solid #2647e6;
  }
`;

const CustomPagination = ({
  handlePageClick,
  pageRangeDisplayed,
  pageCount,
  marginPagesDisplayed,
}) => {
  return (
    <Fragment>
      <ContainerPagination className="d-flex justify-content-end my-auto">
        <ReactPaginate
          className="d-flex text-right"
          breakLabel="..."
          nextLabel={
            <i
              className="fa fa-chevron-right"
              aria-hidden="true"
              style={{ color: "black" }}
            ></i>
          }
          onPageChange={handlePageClick}
          pageRangeDisplayed={pageRangeDisplayed}
          pageCount={pageCount}
          marginPagesDisplayed={marginPagesDisplayed}
          previousLabel={
            <i
              className="fa fa-chevron-left"
              aria-hidden="true"
              style={{ color: "black" }}
            ></i>
          }
          renderOnZeroPageCount={null}
        />
      </ContainerPagination>
    </Fragment>
  );
};

export default CustomPagination;
