import Section from "../../pages/dashboard/master/section";
import SectionItems from "../../pages/dashboard/master/section/items";
import SectionAdd from "../../pages/dashboard/master/section/add";
import SectionEdit from "../../pages/dashboard/master/section/edit";

export const RouteSection = [
  {
    path: `/dashboard/section`,
    Component: Section,
  },
  {
    path: `/dashboard/section/:id/items`,
    Component: SectionItems,
  },
  {
    path: `/dashboard/section/add`,
    Component: SectionAdd,
  },
  {
    path: `/dashboard/sectionedit/:id`,
    Component: SectionEdit,
  },
];
