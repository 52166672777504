import React, { useState } from "react";
import { InputGroup, Input, InputGroupAddon, Button } from "reactstrap";
import PropTypes from "prop-types";

import iconSearch from "@assets/images/input-search.png";

const SearchBarWithSelect = ({
  onSearch,
  totalEntry,
  totalEntryPerPage,
  noentry,
  optionArray,
}) => {
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [selectedInput, setSelectedInput] = useState("");

  const handleSearch = (e) => {
    setInputSearchValue(e.target.value);

    setTimeout(() => {
      onSearch(e.target.value);
    }, 450);
  };

  const handleOnEnter = (e) => {
    if (e.key === "Enter") {
      onSearch(selectedInput, inputSearchValue);
    }
  };

  const handleSubmitSearch = () => {
    onSearch(selectedInput, inputSearchValue);
  };

  return (
    <div className="d-flex align-items-center">
      <InputGroup style={{ minWidth: "440px" }}>
        <Input
          type="select"
          name="select"
          id="selectOption"
          onChange={(event) => setSelectedInput(event.target.value)}
        >
          <option value={""}>
            All
          </option>
          {optionArray.length > 0
            ? optionArray.map((i, item) => (
                <option key={i} value={item.value}>
                  {item.name}
                </option>
              ))
            : null}
        </Input>
        <Input
          className="auto"
          placeholder="Search"
          value={inputSearchValue}
          onChange={handleSearch}
          onKeyDown={handleOnEnter}
        />
        <InputGroupAddon addonType="prepend">
          <Button
            className="rounded-right p-2"
            color="primary"
            onClick={handleSubmitSearch}
          >
            <img src={iconSearch} width={18} />
          </Button>
        </InputGroupAddon>
      </InputGroup>

      {noentry ? (
        ""
      ) : (
        <span className="my-auto text-muted mx-2 ">
          Show {totalEntryPerPage} of {totalEntry} entries
        </span>
      )}
    </div>
  );
};

SearchBarWithSelect.propTypes = {
  onSearch: PropTypes.func.isRequired,
  totalEntryPerPage: PropTypes.string,
  totalEntry: PropTypes.string,
  noentry: PropTypes.bool,
  optionArray: PropTypes.array.isRequired,
};

export default SearchBarWithSelect;
