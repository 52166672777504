import React, { Fragment, useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Button, Modal, ModalBody, ModalFooter, Input } from "reactstrap";

import { getListProduct } from "@action/productAction";

import image_small from "@assets/images/image_small.svg";
import close_icon from "@assets/images/x_icon.svg";

const AddProductModal = ({ isVisible, onClose, onSubmit }) => {
  const dispatch = useDispatch();

  const [isShowResult, setShowResult] = useState(false);
  const [input, setInput] = useState("");
  const [listResult, setListResult] = useState([]);
  const [selectedResult, setSelectedResult] = useState([]);

  useEffect(() => {
    if (listResult.length > 0) setShowResult(true);
  }, [listResult]);

  function handleSearch(e) {
    setInput(e.target.value);

    setTimeout(() => {
      dispatch(getListProduct(1, e.target.value)).then((resp) => {
        const data = resp.data.data;
        setListResult(data);
      });
    }, 800);
  }

  function resetResult() {
    setShowResult(false);
    setInput("");
    setListResult([]);
  }

  function handleClickProduct(item) {
    item.qty = 1;

    setSelectedResult((prevState) => {
      // Check if the item already exists
      const itemExists = prevState.some(
        (selectedItem) => selectedItem.id === item.id
      );

      if (!itemExists) {
        return [...prevState, item];
      }

      return prevState;
    });

    resetResult();
  }

  function handleRemoveSelected(item) {
    const newArr = selectedResult.filter((arr) => arr.id !== item.id);
    setSelectedResult(newArr);
  }

  function handleQtyChange(e, val) {
    setSelectedResult((prevVal) => {
      return prevVal.map((selectedItem) => {
        if (selectedItem.id === val.id) {
          return { ...selectedItem, qty: e.target.value };
        }
        return selectedItem;
      });
    });
  }

  function handleIncrementQty(item, change) {
    setSelectedResult((prevVal) => {
      const newData = prevVal.map((selectedItem) => {
        if (selectedItem.id === item.id) {
          let newQty = selectedItem.qty + parseInt(change, 10);
          newQty = newQty < 0 ? 0 : newQty;
          return { ...selectedItem, qty: newQty };
        }
        return selectedItem;
      });
      return newData;
    });
  }

  function handleSubmit() {
    if (selectedResult.length < 1) return;

    onSubmit(selectedResult);
    resetResult();
    setSelectedResult([]);
    onClose();
  }

  return (
    <Fragment>
      <Modal isOpen={isVisible} backdrop={true} toggle={onClose}>
        <div className="d-flex flex-row justify-content-between border-bottom p-3">
          <p className="font-weight-bolder my-2">Add Products</p>
          <button
            type="button"
            onClick={onClose}
            className="border-0 bg-white p-auto"
          >
            <i className="fa-solid fa-x"></i>
          </button>
        </div>
        <ModalBody className="position-relative">
          <span className="font-weight-bolder">
            Product SKU Code <span style={{ color: "red" }}>*</span>
          </span>
          <Input
            className="my-2"
            type="text"
            placeholder="Enter Product SKU Code or Product name"
            value={input}
            onChange={handleSearch}
          />
          {isShowResult && (
            <ul
              className="position-absolute overflow-auto bg-white rounded-lg shadow-sm p-1"
              style={{ width: "93%", maxHeight: "45dvh", zIndex: "999" }}
            >
              {listResult.map((item) => (
                <li
                  key={item.id}
                  className="d-flex flex-column m-2"
                  onClick={() => handleClickProduct(item)}
                  style={{ cursor: "pointer" }}
                >
                  {item.name}
                  <span style={{ fontSize: "10px" }}>
                    {item.sku_code && item.sku_code}
                  </span>
                </li>
              ))}
            </ul>
          )}

          {selectedResult.length > 0 ? (
            <ul className="overflow-auto" style={{ maxHeight: "30dvh" }}>
              {selectedResult.map((item) => (
                <div key={item.id}>
                  <li className="d-flex flex-row justify-content-between p-1">
                    <img
                      src={
                        item.images.length > 0
                          ? item.images[0].thumbnail_image_url
                          : image_small
                      }
                      alt="image of selected produk"
                      width={35}
                    />
                    <div
                      className="d-flex flex-column mx-1 text-wrap"
                      style={{ width: "45%" }}
                    >
                      <p className="my-0">{item.name}</p>
                      <span className="my-0" style={{ fontSize: "8px" }}>
                        {item.sku_code}
                      </span>
                    </div>
                    <div
                      className="d-flex flex-row my-auto mx-1"
                      style={{ width: "120px" }}
                    >
                      <Button
                        className="border p-1"
                        color="white"
                        onClick={() => handleIncrementQty(item, -1)}
                        disabled={item.qty === 1 ? true : false}
                        style={{ width: "80px" }}
                      >
                        <i className="fa-solid fa-minus"></i>
                      </Button>
                      <input
                        className="mx-1"
                        type="number"
                        min={1}
                        value={item.qty}
                        onChange={(e) => handleQtyChange(e, item)}
                      />
                      <Button
                        className="p-1"
                        color="primary"
                        onClick={() => handleIncrementQty(item, 1)}
                        style={{ width: "80px" }}
                      >
                        <i className="fa-solid fa-plus"></i>
                      </Button>
                    </div>
                    <img
                      onClick={() => handleRemoveSelected(item)}
                      src={close_icon}
                      alt="delete selected item"
                      width={25}
                      style={{ cursor: "pointer" }}
                    />
                  </li>
                  <hr />
                </div>
              ))}
            </ul>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button className="border" color="white" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSubmit}>
            Add Product
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default AddProductModal;
