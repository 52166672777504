import { GET_LIST_BROADCAST, GET_LIST_USER_UUID } from "../../actionTypes";

const initialState = {
  listBroadcast: {},
  listUserUUID: {},
};

const BroadcastReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_BROADCAST:
      state.listBroadcast = action.payload;
      return { ...state, listBroadcast: state.listBroadcast };
    case GET_LIST_USER_UUID:
      state.listUserUUID = action.payload;
      return { ...state, listUserUUID: state.listUserUUID };
    
    default:
      return { ...state };
  }
};

export default BroadcastReducer;
