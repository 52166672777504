import { Fragment, useEffect, useState } from "react";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Badge,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
} from "reactstrap";
import TableCSI from "@components/table";
import Breadcrumb from "@layout/breadcrumb";
import CurrencyFormatter from "@components/currencyFormatter";
import BadgeStatus from "@components/badgeStatus";
import ModalContent from "@components/modal/ModalContent";
import { ModalDialog } from "@components/modal/ModalDialog";

import {
  deleteTransactionDocument,
  getTransactionDetail,
} from "@action/transactionAction";

const BtnAction = styled.button`
  padding: 6px 20px;
  border-radius: 6px;
  border: 1px solid #d1d5dc;
  margin: 8px 6px;
`;

const TransactionDetail = (props) => {
  const [isFetchedData, setIsFetchedData] = useState(false);
  const [listDataTransItem, setListDataItem] = useState([]);
  const [listDataTransDocs, setListDataDocs] = useState([]);
  const [tableColumnItems, setTableColumnItems] = useState([]);
  const [tableColumnDocs, setTableColumnDocs] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [pageParam, setPageParam] = useState("");
  const [detailTransaction, setDetailTransaction] = useState({});
  const [modalState, setModalState] = useState(null);

  const urlParam = useParams();
  const dispatch = useDispatch();
  const { transactionDetail } = useSelector(
    (state) => state.TransactionReducer
  );

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  useEffect(() => {
    setPageParam(urlParam.id);

    setTableColumnItems([
      {
        Header: () => null,
        id: "tableTransItem",
        columns: [
          {
            Header: "Product Name",
            accessor: "productname",
            sortAble: true,
          },
          {
            Header: "Quantity",
            accessor: "quantity",
            sortAble: true,
          },
          {
            Header: "Price",
            accessor: "price",
            sortAble: true,
            Cell: ({ row }) => (
              <CurrencyFormatter
                digit={row.original.price}
                typeDigit={"amount"}
              />
            ),
          },
          {
            Header: "Discount",
            accessor: "discount",
            sortAble: true,
          },
          {
            Header: "Final Price",
            accessor: "finalPrice",
            sortAble: true,
            Cell: ({ row }) => (
              <CurrencyFormatter
                digit={row.original.finalPrice}
                typeDigit={"amount"}
              />
            ),
          },
          {
            Header: "Ready Stock",
            accessor: "stock",
            sortAble: true,
          },
          {
            Header: "PO Stock",
            accessor: "postock",
            sortAble: true,
          },
          {
            Header: "Notes",
            accessor: "notes",
            sortAble: true,
          },
        ],
      },
    ]);

    setTableColumnDocs([
      {
        Header: () => null,
        id: "tableTransDoc",
        columns: [
          {
            Header: "Pick Ticket Number",
            accessor: "ticketNumber",
            sortAble: true,
          },
          {
            Header: "Do Number",
            accessor: "doNumber",
            sortAble: true,
          },
          {
            Header: "SI",
            accessor: "si",
            sortAble: true,
          },
          {
            Header: "Action",
            accessor: "action",
            sortAble: false,
            Cell: ({ row }) => (
              <div className="d-flex">
                <Link
                  to={{
                    pathname: `/dashboard/transaction/detail/${urlParam?.id}/detail/${row.original?.id}`,
                    data: row?.original,
                    type: "Detail",
                  }}
                >
                  <Button outline color="primary">
                    Detail
                  </Button>
                </Link>
                <Link
                  to={{
                    pathname: `/dashboard/transaction/detail/${urlParam?.id}/edit/${row.original?.id}`,
                    data: row?.original,
                    type: "Edit",
                  }}
                >
                  <Button className="mx-2" outline color="primary">
                    Edit
                  </Button>
                </Link>
                <Button
                  className="mx-2"
                  outline
                  onClick={() => showModal(row?.original)}
                >
                  Hapus
                </Button>
              </div>
            ),
          },
        ],
      },
    ]);

    dispatch(getTransactionDetail(urlParam.id));
  }, []);

  useEffect(() => {
    if (transactionDetail.data && !transactionDetail.loading) {
      let detail = transactionDetail.data?.transaction;
      let listItems = transactionDetail.data?.transactionItems;
      let listDocs = transactionDetail.data?.transactionDocuments;
      let mappedItems = listItems.map((item) => ({
        productname: item.product ? item.product?.name : "null",
        quantity: item.quantity,
        price: item.price,
        discount: item.discount_price,
        finalPrice: item.final_price,
        stock: item.ready_qty,
        postock: item.pre_order_qty,
        notes: item.notes,
      }));
      let mappedDocs = listDocs.map((item) => ({
        id: item.id ? item.id : "null",
        ticketNumber: item.pick_ticket_no ? item.pick_ticket_no : "null",
        doNumber: item.do_no,
        si: item.si_no,
      }));
      setListDataItem(mappedItems);
      setListDataDocs(mappedDocs);
      setDetailTransaction(detail);
      setIsFetchedData(true);
    }
  }, [transactionDetail]);

  const showModal = (row) => {
    setModalState((prevState) => ({
      ...prevState,
      openModal: true,
      modalType: "Status",
      modalTitle: "Informasi",
      modalData: row,
      closeIcon: true,
    }));
  };

  const handleDelete = (data) => {
    setIsFetchedData(true);
    dispatch(deleteTransactionDocument(urlParam?.id, data?.id));
    setIsFetchedData(false);
    setModalState(null);
    setTimeout(() => {
      dispatch(getTransactionDetail(urlParam.id));
    }, 500);
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Transaction"
        title="Transaction Detail"
        urlParent="/dashboard/transaction"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            {isFetchedData ? (
              <Card>
                <CardHeader className="d-flex justify-content-between p-3">
                  <div>
                    <h5>{`Order #${detailTransaction.invoice_no}`}</h5>
                    <span>
                      Status:{" "}
                      <Badge color="light">{`${
                        detailTransaction.state === 0
                          ? "Order Accepted"
                          : detailTransaction.state === 1
                          ? "Waiting For Delivery"
                          : detailTransaction.state === 2
                          ? "Delivered"
                          : detailTransaction.state === 3
                          ? "Done"
                          : null
                      }`}</Badge>
                      <span>{`Created at: ${detailTransaction.created_at}`}</span>
                    </span>
                  </div>
                  <div className="d-flex justify-content-between">
                    {detailTransaction.state === 0 ? (
                      <BtnAction>
                        <Link
                          to={`/dashboard/transaction/paidconfirm/${urlParam.id}/accept`}
                        >
                          Accept
                        </Link>
                      </BtnAction>
                    ) : detailTransaction.state === 1 ? (
                      <BtnAction>
                        <Link
                          to={`/dashboard/transaction/paidconfirm/${urlParam.id}/deliver`}
                        >
                          Deliver
                        </Link>
                      </BtnAction>
                    ) : detailTransaction.state === 2 ? (
                      <BtnAction>
                        <Link
                          to={`/dashboard/transaction/paidconfirm/${urlParam.id}/complete`}
                        >
                          Complete
                        </Link>
                      </BtnAction>
                    ) : detailTransaction.state === 3 ? (
                      <BtnAction>
                        <Link
                          to={`/dashboard/transaction/paidconfirm/${urlParam.id}/sro`}
                        >
                          SRO
                        </Link>
                      </BtnAction>
                    ) : null}

                    <BtnAction>
                      <Link to={`/dashboard/transaction/edit/` + urlParam.id}>
                        Edit
                      </Link>
                    </BtnAction>
                    <BtnAction>Sync</BtnAction>
                    <BtnAction
                      style={{
                        cursor:
                          detailTransaction.paid === 1 ? "auto" : "pointer",
                      }}
                    >
                      {detailTransaction.paid === 1 ? (
                        <div style={{ color: "#808080" }}>Paid</div>
                      ) : (
                        <Link
                          to={`/dashboard/transaction/paidconfirm/${urlParam.id}`}
                        >
                          Paid
                        </Link>
                      )}
                    </BtnAction>
                  </div>
                </CardHeader>

                <CardBody className="p-3">
                  <div className="d-flex justify-content-between">
                    <div className={"col-md-2"}>
                      <p className="m-0">Invoice No:</p>
                      <span>{`${detailTransaction.invoice_no}`}</span>
                    </div>
                    <div className={"col-md-2"}>
                      {" "}
                      <p className="m-0">SO Number:</p>
                      <span>{`${detailTransaction.nav_id}`}</span>
                    </div>
                    <div className={"col-md-2"}>
                      {" "}
                      <p className="m-0">Reciever Address:</p>
                      <span>{`${detailTransaction.receiver_address}`}</span>
                    </div>
                    <div className={"col-md-2"}>
                      <p className="m-0">Shop Name:</p>
                      <span>{`${detailTransaction.user.shop_name}`}</span>
                    </div>
                    <div className={"col-md-2"}>
                      <p className="m-0">Total Price:</p>
                      <span>
                        <CurrencyFormatter
                          digit={detailTransaction.total_price}
                          typeDigit={"amount"}
                        />
                      </span>
                    </div>
                    <div className={"col-md-2"}>
                      <p className="m-0">Paid Status:</p>
                      <BadgeStatus
                        status={detailTransaction.paid}
                        label={detailTransaction.paid === 0 ? "Unpaid" : "Paid"}
                      />
                    </div>
                  </div>

                  <div className="d-flex my-4">
                    <div className="my-2 col-md-2">
                      <p className="m-0">Catatan:</p>
                      <span>{`${detailTransaction.note}`}</span>
                    </div>
                    <div className="my-2 col-md-2">
                      <p className="m-0">Pembayaran:</p>
                      <span>{`${detailTransaction?.payment_type}`}</span>
                    </div>
                    <div className="my-2 col-md-2">
                      <p className="m-0">Nomor Virtual:</p>
                      <span
                        style={{ cursor: "pointer", color: "#2647E6" }}
                        onClick={() =>
                          navigator.clipboard.writeText(
                            `${detailTransaction?.virtual_account_id}`
                          )
                        }
                      >{`${
                        detailTransaction?.payment_type === "BCA"
                          ? detailTransaction?.virtual_account_id
                          : "-"
                      }`}</span>
                    </div>
                    <div className="my-2 col-md-2">
                      <p className="m-0">XP Point:</p>
                      <span>{`${detailTransaction?.xp_point}`}</span>
                    </div>
                  </div>
                </CardBody>
              </Card>
            ) : null}
          </Col>
        </Row>

        <Row>
          <Col sm="12">
            <Card>
              <CardBody className="p-3">
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={`${activeTab === 1 ? "active" : ""}`}
                      href="#"
                      onClick={() => toggleTab(1)}
                      style={{
                        cursor: "Pointer",
                        color: activeTab === 1 ? "#2647E6" : "#495057",
                        fontWeight: "bold",
                      }}
                    >
                      Transaction Items
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`${activeTab === 2 ? "active" : ""}`}
                      href="#"
                      onClick={() => toggleTab(2)}
                      style={{
                        cursor: "Pointer",
                        color: activeTab === 2 ? "#2647E6" : "#495057",
                      }}
                    >
                      Transaction Documents
                    </NavLink>
                  </NavItem>
                </Nav>

                <TabContent className="" activeTab={`${activeTab}`}>
                  <TabPane tabId="1">
                    <Col sm="12">
                      {isFetchedData ? (
                        <TableCSI
                          data={listDataTransItem}
                          columns={tableColumnItems}
                          key={listDataTransItem}
                        />
                      ) : null}
                    </Col>
                  </TabPane>
                  <TabPane tabId="2">
                    <Col sm="12">
                      <div className="d-flex justify-content-end my-2">
                        <Link
                          to={`/dashboard/transaction/detail/${urlParam.id}/add`}
                        >
                          <Button outline color="primary">
                            Add New Document
                          </Button>
                        </Link>
                      </div>

                      {isFetchedData ? (
                        <TableCSI
                          data={listDataTransDocs}
                          columns={tableColumnDocs}
                          key={listDataTransDocs}
                        />
                      ) : null}
                    </Col>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
			
            <ModalDialog
              open={modalState?.openModal ?? false}
              type={modalState?.modalType ?? ""}
              footer={false}
              title={modalState?.modalTitle ?? ""}
              titleFontSize={18}
              backdropClose={true}
              onClose={() => {
                setModalState(null);
              }}
              maxWidth={384}
            >
              <ModalContent
                onClose={() => {
                  setModalState(null);
                }}
                onSubmit={() => handleDelete(modalState?.modalData)}
                data={modalState?.modalData ?? null}
                descClose={"Batal"}
                descSubmit={"Hapus"}
                description={`Hapus data ${modalState?.modalData?.ticketNumber}?`}
              />
            </ModalDialog>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TransactionDetail;
