import { Fragment, useState } from "react";
import {
  FormGroup,
  Label,
  Col,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
} from "reactstrap";

import { InputSwitch } from "@components/formComponent";

const InputMinPembelian = () => {
  const [isShow, setShow] = useState(false);

  return (
    <Fragment>
      <FormGroup row tag="fieldset">
        <Label
          className="d-flex flex-xl-row flex-md-column py-auto"
          sm={2}
        >
          <InputSwitch
            value={isShow}
            onChange={(e) => setShow(e.target.checked)}
          />
          <span>
            <b className="ml-xl-1">Minimum Pembelian</b>
          </span>
        </Label>
        {isShow ? (
          <Col className="d-flex flex-row py-2" sm={10}>
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText className="bg-white">Rp</InputGroupText>
              </InputGroupAddon>
              <Input placeholder="0" type="number" min={0} />
            </InputGroup>
          </Col>
        ) : null}
      </FormGroup>
    </Fragment>
  );
};

export default InputMinPembelian;
