import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import {
  bulkProduct,
  downloadProduct,
  getListProduct,
  getSyncProduct,
  uploadMassProduct,
} from "@action/productAction";

import Breadcrumb from "@layout/breadcrumb";
import {
  Button,
  ButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import TableCSI from "@components/table";
import TableLayout from "@layout/tableLayout";
import TableDropdown from "@components/customDropdown";
import BadgeStatus from "@components/badgeStatus";
import { ModalDialog } from "@components/modal/ModalDialog";
import ModalUpload from "@components/modal/ModalUpload";

const ProductLayout = () => {
  const [data, setData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [isFetchedData, setIsFetchedData] = useState(true);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [totalResult, setTotalResult] = useState(4);
  const [totalPerPage, setTotalPerPage] = useState(1);
  const [modalState, setModalState] = useState(null);
  const [dropdownOpen, setOpenDropdown] = useState(false);

  const dispatch = useDispatch();
  const { listProduct } = useSelector((state) => state.ProductReducer);

  useEffect(() => {
    dispatch(getListProduct(1, ""));
    setTableColumn([
      {
        Header: () => null,
        id: "Product",
        columns: [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: "Product Name",
            accessor: "name",
          },
          {
            Header: "SKU Code",
            accessor: "sku_code",
          },
          {
            Header: "Category",
            accessor: "category",
          },
          {
            Header: "Brand",
            accessor: "brand",
          },
          {
            Header: "Price",
            accessor: "price",
            Cell: ({ row }) =>
              `${row.original.price.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
              })}`,
          },
          {
            Header: "Status",
            accessor: "status",
            Cell: ({ row }) => <BadgeStatus status={row.original.status} />,
          },
          {
            Header: "Blocked",
            accessor: "blocked",
          },
          {
            Header: "Action",
            accessor: "action",
            Cell: ({ row }) => (
              <TableDropdown
                dropdownContent={[
                  {
                    label: "Details",
                    type: "link",
                    link: `/dashboard/product/detail/${row.original?.id}`,
                  },
                  {
                    label: "Edit",
                    type: "link",
                    link: `/dashboard/product/edit/${row.original?.id}`,
                  },
                  {
                    label: "Logs",
                    type: "link",
                    link: `/dashboard/product/logs/${row.original?.id}`,
                  },
                  {
                    label: "Price",
                    type: "link",
                    link: `/dashboard/product/price/${row.original?.id}`,
                  },
                  {
                    label: "Images",
                    type: "link",
                    link: `/dashboard/product/images/${row.original?.id}`,
                  },
                  {
                    label: "Discount",
                    type: "link",
                    link: `/dashboard/product/discount/${row.original?.id}`,
                    linkData: row.original,
                  },
                ]}
                onToggleStatus={() => {}}
                row={row.original}
              />
            ),
          },
        ],
      },
    ]);
  }, []);

  useEffect(() => {
    if (listProduct.data && !listProduct.loading) {
      let dataProduct = listProduct?.data?.data;
      let mappedListData = dataProduct.map((item) => ({
        id: item?.id,
        name: item?.name,
        sku_code: item?.sku_code,
        category: item?.category?.name,
        brand: item?.brand?.name,
        price: item?.price,
        status: item?.active,
        blocked: item?.blocked === 0 ? "Non-blocked" : "Blocked",
        stock: item?.stock,
        video_url: item?.video_url,
        description: item?.description,
        category_id: item?.category?.id,
        brand_id: item?.brand?.id,
      }));
      setData(mappedListData);
      setMaxPageIndex(listProduct.data?.meta?.last_page);
      setTotalResult(listProduct.data?.meta?.total);
      setTotalPerPage(listProduct.data?.meta?.to);
      setIsFetchedData(true);
    }
  }, [listProduct]);

  const handleSearch = (val) => {
    dispatch(getListProduct(1, val, 10));
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListProduct(page, "", 10));
  };

  const handleAction = (type, file, action) => {
    switch (type) {
      case "bulk":
        const dataForm = new FormData();
        dataForm.append("file", file);
        dataForm.append("action", action);
        dispatch(bulkProduct(dataForm));
        setModalState(null);
        break;
      case "qty":
        const dataFormQty = new FormData();
        dataFormQty.append("file", file);
        dispatch(uploadMassProduct(type, dataFormQty));
        setModalState(null);
        break;
      case "all-product":
        dispatch(downloadProduct("all"));
        break;
      case "image":
        const dataFormImg = new FormData();
        dataFormImg.append("file", file);
        dispatch(uploadMassProduct(type, dataFormImg));
        setModalState(null);
        break;
      case "blocked-product":
        dispatch(downloadProduct("blocked"));
        break;
      case "sync-product":
        dispatch(getSyncProduct());
        break;
      default:
        break;
    }
  };

  const showModal = (type) => {
    setModalState((prevState) => ({
      ...prevState,
      openModal: true,
      modalType: type,
      modalTitle:
        type === "bulk"
          ? "Bulk Action"
          : `Max Upload Product ${type === "qty" ? "Max Qty" : "Images"}`,
      modalData: type,
      closeIcon: true,
    }));
  };

  const headerAction = (
    <div className="d-flex">
      <Link className='my-auto' to={`/dashboard/product/add`}>
        <Button color="primary" outline>
          Add New Product
        </Button>
      </Link>

      <ButtonDropdown
        className="mx-2 my-auto"
        isOpen={dropdownOpen}
        toggle={() => setOpenDropdown(!dropdownOpen)}
		direction="left"
      >
        <DropdownToggle className="border rounded-lg" color="white">
          ...
        </DropdownToggle>
        <DropdownMenu>
          <DropdownItem toggle={false} onClick={() => showModal("bulk")}>
            Bulk Action
          </DropdownItem>
          <DropdownItem toggle={false} onClick={() => showModal("qty")}>
            Mass Upload Max Qty
          </DropdownItem>
          <DropdownItem
            toggle={false}
            onClick={() => handleAction("all-product")}
          >
            Download Product
          </DropdownItem>
          <DropdownItem toggle={false} onClick={() => showModal("image")}>
            Mass Upload Image
          </DropdownItem>
          <DropdownItem
            toggle={false}
            onClick={() => handleAction("blocked-product")}
          >
            Download Product Blocked
          </DropdownItem>
          <DropdownItem
            toggle={false}
            onClick={() => handleAction("sync-product")}
          >
            Sync Product
          </DropdownItem>
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );

  return (
    <Fragment>
      <Breadcrumb
        parent="Dashboard"
        title="Product"
        urlParent={"/dashboard/analytics"}
      />

      <TableLayout
        headerAction={headerAction}
        handleSearch={handleSearch}
        totalEntry={totalResult}
        totalEntryPerPage={totalPerPage}
        pageCount={maxPageIndex}
        handleChangePage={handlePageClick}
        handleChangeRow={(val) => dispatch(getListProduct(1, "", val))}
      >
        <TableCSI columns={tableColumn} data={data} key={data} />
      </TableLayout>

      <Fragment>
        <ModalDialog
          open={modalState?.openModal ?? false}
          type={modalState?.modalType ?? ""}
          footer={false}
          title={modalState?.modalTitle ?? ""}
          titleFontSize={18}
          backdropClose={true}
          onClose={() => {
            setModalState(null);
          }}
          maxWidth={400}
        >
          <ModalUpload
            onClose={() => {
              setModalState(null);
            }}
            action={modalState?.modalType === "bulk"}
            onSubmit={(file, data) =>
              handleAction(modalState?.modalType, file, data)
            }
            data={modalState?.modalData ?? null}
            descClose={"Batal"}
            descSubmit={"Kirim"}
            type={modalState?.modalType === "image" ? "zip" : "csv"}
            label={
              modalState?.modalType === "image" ? "File" : "CSV file to import"
            }
            description={modalState?.modalType !== "image"}
          />
        </ModalDialog>
      </Fragment>
    </Fragment>
  );
};

export default ProductLayout;
