import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import { useTable, useSortBy, useRowSelect } from "react-table";
import styled from "styled-components";

import { getListUser } from "../../../../redux/action/broadcastAction";

import iconSearch from "../../../../assets/images/input-search.png";
import sortIcon from "../../../../assets/images/sort-icon.svg";
import BadgeStatus from "../../../../Component/badgeStatus";

const TableContainer = styled.div`
  overflow-y: auto;
  max-height: 250px;
`;

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);

const BroadcastUser = (props) => {
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [selectedData, setSelectedData] = useState([]);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const dispatch = useDispatch();
  const { listUserUUID } = useSelector((state) => state.BroadcastReducer);

  useEffect(() => {
    setColumns([
      {
        Header: () => null,
        id: "tableUserManual",
        columns: [
          {
            Header: "NAV ID",
            accessor: "navid",
            sortAble: true,
          },
          {
            Header: "Name",
            accessor: "name",
            sortAble: true,
          },
          {
            Header: "Shop",
            accessor: "shop",
            sortAble: true,
          },
          {
            Header: "Phone",
            accessor: "phone",
            sortAble: true,
          },
          {
            Header: "Kota",
            accessor: "kota",
            sortAble: true,
          },
          {
            Header: "Status",
            accessor: "status",
            sortAble: true,
            Cell: ({ row }) => <BadgeStatus status={row.original.status} />,
          },
        ],
      },
    ]);

    dispatch(getListUser());
  }, []);

  useEffect(() => {
    if (listUserUUID.data && !listUserUUID.loading) {
      const dataResp = listUserUUID.data.data;
      const mappedData = dataResp.map((item) => ({
        navid: item.nav_id,
        uuid: item.uuid,
        name: item.username,
        shop: item.shop_name,
        phone: item.phone ? item.phone : "null",
        kota: item.kota ? item.kota : "null",
        status: item.status ? item.status : "null",
      }));

      setData(mappedData);
    }
  }, [listUserUUID]);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({ columns, data }, useSortBy, useRowSelect, (hooks) => {
      hooks.visibleColumns.push((columns) => [
        // column for selection
        {
          id: "selection",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllRowsSelectedProps }) => (
            <div>
              <IndeterminateCheckbox
                {...getToggleAllRowsSelectedProps()}
                onClick={(e) => handleOnSelectAllProduct(e)}
              />
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => (
            <div>
              <IndeterminateCheckbox
                {...row.getToggleRowSelectedProps()}
                onClick={(e) => handleOnCellClicked(e, row)}
              />
            </div>
          ),
        },
        ...columns,
      ]);
    });

  const handleOnSelectAllProduct = (e) => {
    if (e.target.checked) {
      setIsSelectAll(true);
    } else {
      setIsSelectAll(false);
    }
  };

  const handleOnCellClicked = (e, data) => {
    if (e.target.checked) {
      setSelectedData((selectedData) => [...selectedData, data.original]);
    } else {
      setSelectedData((selectedData) => [
        ...selectedData.filter((i) => data.original.id !== i.id),
      ]);
    }
  };

  const doCloseBroadcast = () => {
    props.doCloseBroadcast(false);
  };

  const handleSubmitUser = () => {
    if (isSelectAll) {
      props.getSelectedData(data);
    } else if (selectedData.length > 0) {
      props.getSelectedData(selectedData);
    }
    return;
  };

  return (
    <Fragment>
      <Modal isOpen={props.isOpenModal} toggle={doCloseBroadcast} size="lg">
        <ModalHeader toggle={doCloseBroadcast}>
          Pilih Broadcast Manual
        </ModalHeader>
        <ModalBody>
          <InputGroup className="mb-2" style={{ maxWidth: "280px" }}>
            <Input placeholder="Search" />
            <InputGroupAddon addonType="prepend">
              <Button className="p-2" color="primary">
                <img src={iconSearch} width={15} height={15} />
              </Button>
            </InputGroupAddon>
          </InputGroup>
          <TableContainer className="my-2">
            <Table {...getTableProps()} className="text-left">
              <thead>
                {headerGroups.map((headerGroup, index) =>
                  index > 0 ? (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          {column.render("Header")}
                          {/* Add a sort direction indicator */}
                          {column.sortAble ? <img src={sortIcon} /> : ""}
                        </th>
                      ))}
                    </tr>
                  ) : null
                )}
              </thead>

              <tbody {...getTableBodyProps()} className="text-left">
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </TableContainer>
        </ModalBody>
        <ModalFooter>
          <span className="mx-2 mt-2">
            <b className="mx-2">
              {isSelectAll
                ? `${data.length} user`
                : selectedData.length > 0
                ? `${selectedData.length} user`
                : `0 user`}
            </b>
            terpilih
          </span>
          <Button color="primary" onClick={handleSubmitUser}>
            Submit
          </Button>{" "}
          <Button color="primary" outline onClick={doCloseBroadcast}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default BroadcastUser;
