import { useState, Fragment } from "react";
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import styled from "styled-components";

import iconSearch from "@assets/images/input-search.png";

const StartDateStyle = styled.div`
  input {
    border-radius: 0px;
    border-right: none;
  }
`;
const EndDateStyle = styled.div`
  input {
    border-radius: 0px;
    border-left: none;
  }
`;

const DateRangePicker = ({ sendDateRange }) => {
  const [date, setDate] = useState({
    startDate: "",
    endDate: "",
  });

  const handleOnChangeDate = () => {
    if (date.setDate === "" || date.endDate === "") return;
    sendDateRange(date);
  };

  const handleClearDate = () => {
    setDate({
      startDate: "",
      endDate: "",
    });
  };

  return (
    <Fragment>
      <InputGroup>
        <StartDateStyle>
          <Input
            type="date"
            name="date"
            placeholder="DD/MM/YYYY"
            max="9999-12-31"
            min="1700-12-31"
            value={date.startDate}
            onChange={(e) =>
              setDate({
                ...date,
                startDate: e.target.value,
              })
            }
          />
        </StartDateStyle>
        <EndDateStyle>
          <Input
            type="date"
            name="date"
            placeholder="DD/MM/YYYY"
            max="9999-12-31"
            min="1700-12-31"
            value={date.endDate}
            onChange={(e) =>
              setDate({
                ...date,
                endDate: e.target.value,
              })
            }
          />
        </EndDateStyle>

        {date.startDate !== "" || date.endDate !== "" ? (
          <button
            type="button"
            class="border-dark mx-2 px-2 pb-2 border rounded close"
            aria-label="Close"
            onClick={handleClearDate}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        ) : null}

        <InputGroupAddon addonType="prepend">
          <Button
            className="mx-1 px-2 pb-2 rounded"
            color="primary"
            onClick={handleOnChangeDate}
          >
            <img src={iconSearch} width={15} height={15} />
          </Button>
        </InputGroupAddon>
      </InputGroup>
    </Fragment>
  );
};

export default DateRangePicker;
