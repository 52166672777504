import { Fragment, useEffect } from "react";
import Breadcrumb from "@layout/breadcrumb";
import {
  Container
} from "reactstrap";
import TableUser from "./table";

const UserLayout = (props) => {
  useEffect(() => {
    return () => {};
  }, []);

  return (
      <Fragment>
        <Breadcrumb parent="Dashboard" title="User" urlParent={'/dashboard/analytics'} />
        <Container fluid={true}>
          <TableUser />
        </Container>
      </Fragment>
  );
};

export default UserLayout;
