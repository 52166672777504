import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Button
} from "reactstrap";
import {useDispatch, useSelector} from "react-redux";
import {getUpdateInfo, processUpdateInfo} from "../../../../redux/action/userAction";
import {useParams} from "react-router-dom";
import M from "moment"
import TableCSI from "../../../../Component/table";
import CustomPagination from "../../../../Component/customPagination";

const UserUpdateInfo = (props) => {
    const [data, setData] = useState([]);
    const [tableColumn, setTableColumn] = useState([]);
    const [isFetchedData, setIsFetchedData] = useState(true);
    const [maxPageIndex, setMaxPageIndex] = useState(1);
    const [totalResult, setTotalResult] = useState(4);
    const [totalPerPage, setTotalPerPage] = useState(1);
    const dispatch = useDispatch();
    const {updateInfo} = useSelector(
        (state) => state.UserReducer
    );
    const urlParams = useParams()

    useEffect(() => {
        dispatch(getUpdateInfo(1, urlParams?.id));
    }, [])

    useEffect(() => {
        if (updateInfo.data && !updateInfo.loading) {
            let updateInfos = updateInfo.data.data;
            let mappedListData = updateInfos?.map((item) => ({
                id: item.id,
                store_name: item.store_name,
                store_address: item.store_address,
                owner_name: item.owner_name,
                email: item.email,
                phone_number: item.phone_number,
                status_string: item.status_string,
                created_at: item.created_at ? M(item.created_at).format("DD/MM/YYYY HH:mm:ss") : "No Data",
            }));
            setData(mappedListData);
            setMaxPageIndex(updateInfo.data.last_page);
            setTotalResult(updateInfo.data.total);
            setTotalPerPage(updateInfo.data.to);
            setIsFetchedData(true);
            setTimeout(() => {
                setTableColumn([
                        {
                            Header: () => null,
                            id: "User",
                            columns: [
                                {
                                    Header: "Store Name",
                                    accessor: "store_name",
                                },
                                {
                                    Header: "Store Address",
                                    accessor: "store_address",
                                },
                                {
                                    Header: "Owner Name",
                                    accessor: "owner_name",
                                },
                                {
                                    Header: "Email",
                                    accessor: "email",
                                },
                                {
                                    Header: "Phone Number",
                                    accessor: "phone_number",
                                },
                                {
                                    Header: "Status",
                                    accessor: "status_string",
                                },
                                {
                                    Header: "Created At",
                                    accessor: "created_at",
                                },
                                {
                                    Header: "Action",
                                    accessor: "action",
                                    Cell: ({row}) => (
                                        <div className="d-flex">
                                            <Button color="primary" onClick={() => handleAction(row?.original)}>
                                                Process
                                            </Button>
                                        </div>
                                    ),
                                }
                            ],
                        },
                    ],
                    []
                );
            }, 500);
        } else setData([])
    }, [updateInfo]);

    const handleAction = (data) => {
        dispatch(processUpdateInfo(urlParams?.id, data?.id));
        setIsFetchedData(true);
    }
    const handlePageClick = (data) => {
        let page = data.selected + 1;
        dispatch(getUpdateInfo(page, urlParams?.id));
        setIsFetchedData(true);
    };

    return (
        <Fragment>
            <Breadcrumb parent="User" title="Update Info" urlParent={'/dashboard/user'} />
            <Container fluid>
                <Row>
                    <Col>
                        <Card className="p-0">
                            <CardBody className="p-3">
                                {isFetchedData ?
                                    <TableCSI
                                        data={data}
                                        columns={tableColumn}
                                        key={data}
                                    /> : null}

                                <CustomPagination
                                    handlePageClick={handlePageClick}
                                    pageCount={maxPageIndex}
                                    pageRangeDisplayed={2}
                                    marginPagesDisplayed={2}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};

export default UserUpdateInfo;
