import FlashsaleLayout from "../../pages/dashboard/flashsale";
import FlashsaleDetail from "../../pages/dashboard/flashsale/detail";
import FlashsaleCreate from "../../pages/dashboard/flashsale/add";
import FlashsaleEdit from "../../pages/dashboard/flashsale/edit";

export const RouteFlashsale = [
  {
    path: `/dashboard/flashsale`,
    Component: FlashsaleLayout,
  },
  {
    path: `/dashboard/flashsale/add`,
    Component: FlashsaleCreate,
  },
  {
    path: `/dashboard/flashsale/edit/:id`,
    Component: FlashsaleEdit,
  },
  {
    path: `/dashboard/flashsale/detail/:id`,
    Component: FlashsaleDetail,
  },
];
