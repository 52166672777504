import { Fragment } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { GuardProvider, GuardedRoute } from "react-router-guards";
import Cookies from "universal-cookie";
import { ToastContainer, toast } from "react-toastify";

//
import App from "./pages/app";
import * as serviceWorker from "./serviceWorker";
import Signin from "./features/authentication/signin";
import store from "./store";
import { routes } from "./route";
import NotFoundPage from "features/notFoundPage";

//css
import "react-toastify/dist/ReactToastify.css";
import "./index.scss";

//Sentry
import * as Sentry from "@sentry/browser";

//sentry init
Sentry.init({
  dsn: "https://9cc8c4848b174ebcb2fe4fee40d20188@sentry-relay.echoteam.tech/81",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  tracesSampleRate: 1.0,
});

// Middleware
const requireLogin = (to, from, next) => {
  const currPath = window.location.pathname;
  const cookie = new Cookies();
  const isAuthenticated = cookie.get("api_token") !== undefined;
  const userRoles = cookie.get("role") || null;
  const requiresAuth = to.meta.auth;
  const requiresRole = requiresAuth && to.meta.roles;
  const isAuthorized =
    requiresRole.length > 0 ? checkRoleAuthorization(to, userRoles) : false;

  // If URL requires auth
  if (requiresAuth) {
    if (isAuthenticated) {
      if (!isAuthorized) {
        next.redirect("/");
        toast.error("Role is not Authorized.");
      }
      next();
    }
    next.redirect("/");
  } else {
    if (isAuthenticated && currPath === "/login") {
      next.redirect("/");
    }
    next();
  }
};

const checkRoleAuthorization = (to, userRoles) => {
  if (to.meta.roles) {
    return to.meta.roles.some((role) => userRoles.includes(role));
  }
  return true;
};

const Root = () => {
  return (
    <Fragment>
      <Provider store={store}>
        <BrowserRouter basename={"/"}>
          <GuardProvider guards={[requireLogin]}>
            <Switch>
              <GuardedRoute
                exact
                path={`/login`}
                meta={{ auth: false, roles: null }}
                component={() => <Signin />}
              />
              <App>
                <Route
                  exact
                  path={`/`}
                  meta={{ auth: true, roles: null }}
                  render={() => {
                    return <Redirect to={`/dashboard/analytics`} />;
                  }}
                />
                {routes.map(({ path, Component, roles }) => (
                  <GuardedRoute
                    key={path}
                    exact
                    path={`${path}`}
                    meta={{ auth: true, roles: roles }}
                  >
                    <Component />
                  </GuardedRoute>
                ))}
              </App>
              <Route path="*" component={() => <NotFoundPage />} />
            </Switch>
          </GuardProvider>
        </BrowserRouter>
      </Provider>
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Fragment>
  );
};
ReactDOM.render(<Root />, document.getElementById("root"));

serviceWorker.unregister();
