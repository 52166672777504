import styled from "styled-components";

const Wrapper = styled.div`
  background: #ffffff;
  border: 1px solid #d1d5dc;
  min-width: 100px;

  input {
    border: none;
    max-width: 40px;
	padding: 4px;
  }
`;

const TimeInput = ({ hour, minute, setHour, setMinute }) => {
  const handleHourChange = (e) => {
    let inputHour = e.target.value;
    if (inputHour >= 0 && inputHour <= 23) {
      setHour(inputHour);
    }
  };

  const handleMinuteChange = (e) => {
    const inputMinute = e.target.value;
    if (inputMinute >= 0 && inputMinute <= 59) {
      setMinute(inputMinute);
    }
  };

  const validateTimeInput = (input) => {
    const value = input.target.value;

    // Remove any non-digit characters
    const digitsOnly = value.replace(/\D/g, "");

    // Limit the value to 2 digits
    const truncatedValue = digitsOnly.slice(0, 2);

    // Limit the value to a maximum of 23
    const sanitizedValue = Math.min(parseInt(truncatedValue, 10), 23);

    // Update the input value
    input.target.value = sanitizedValue.toString();
  };

  const validateMinuteInput = (input) => {
    const value = input.target.value;

    // Remove any non-digit characters
    const digitsOnly = value.replace(/\D/g, "");

    // Limit the value to 2 digits
    const truncatedValue = digitsOnly.slice(0, 2);

    // Limit the value to a maximum of 59
    const sanitizedValue = Math.min(parseInt(truncatedValue, 10), 59);

    const sanitizedString = sanitizedValue.toString().replace(/^0+/, "");

    // Update the input value
    input.target.value = sanitizedString.toString();
  };

  return (
    <Wrapper className="d-flex">
      <input
        type="number"
        value={hour}
        onChange={handleHourChange}
        placeholder="00"
        min={0}
        max={23}
        maxLength={2}
        onInput={validateTimeInput}
      />
      <span className="mx-1 my-2">:</span>
      <input
        type="number"
        value={minute}
        onChange={handleMinuteChange}
        placeholder="00"
        min={0}
        max={59}
        maxLength={2}
        onInput={validateMinuteInput}
      />
    </Wrapper>
  );
};

export default TimeInput;
