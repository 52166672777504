import { Fragment } from "react";

const SpinnerLoading = () => {
  return (
    <Fragment>
      <div className="spinner-border spinner-border-sm text-light" role="status"></div>
    </Fragment>
  );
};

export default SpinnerLoading;
