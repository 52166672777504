import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";

import SelectList from "@components/selectList";
import SearchBar from "@components/searchBar";
import TableCSI from "@components/table";
import DateRangePicker from "rsuite/DateRangePicker";
import BadgeApproval from "@components/badgeApproval";

import "rsuite/DateRangePicker/styles/index.css";
import { useDispatch, useSelector } from "react-redux";
import { getListPromoApproval } from "@action/promoAction";
import moment from "moment";
import { getListUserAdmin } from "@action/userAdminAction";

const TableApproval = () => {
  const [column, setColumn] = useState([]);

  const [dataPromo, setDataPromo] = useState([]);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [isFetchedData, setIsFetchedData] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [typePromo, setTypePromo] = useState("");
  const [pengaju, setPengaju] = useState("");
  const [listPengaju, setListPengaju] = useState([]);
  const [search, setSearch] = useState("");

  const { listPromoApproval } = useSelector((state) => state.PromoReducer);
  const { listUserAdmin } = useSelector((state) => state.UserAdminReducer);

  const dispatch = useDispatch();

  const fetchTable = () => {
    const params = {
      page: 1,
      perPage: 10,
      search,
      start_date: startDate,
      end_date: endDate,
      jenis: typePromo,
      creator: pengaju,
    };

    const queryString = generateQueryParams(params);
    dispatch(getListPromoApproval(queryString));
  };
  const generateQueryParams = (params) => {
    const queryString = Object.keys(params)
      .filter((key) => params[key] !== undefined && params[key] !== "")
      .map(
        (key) => `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
      )
      .join("&");
    return queryString ? `?${queryString}` : "";
  };

  useEffect(() => {
    fetchTable();
  }, [search, startDate, endDate, typePromo, pengaju]);

  useEffect(() => {
    setColumn([
      {
        Header: () => null,
        id: "promoApproval",
        columns: [
          {
            Header: "Nama",
            accessor: "nama",
            sortAble: true,
            width: 300,
          },
          {
            Header: "Tipe Promo",
            accessor: "tipe_promo",
            sortAble: true,
            width: 200,
          },
          {
            Header: "Periode",
            accessor: "periode",
            sortAble: true,
            width: 300,
          },
          {
            Header: "Pengaju",
            accessor: "pengaju",
            sortAble: true,
            width: 300,
          },
          {
            Header: "Status",
            accessor: "status",
            sortAble: true,
            Cell: ({ row }) => <BadgeApproval status={row.original.status} />,
          },
          {
            accessor: "action",
            Cell: ({ row }) => (
              <Link
                to={`/dashboard/promotion/detail-promotion/${row.original.relation_id}`}
              >
                <button className="bg-white px-3 py-1 border rounded-lg font-weight-bold text-dark">
                  Detail
                </button>
              </Link>
            ),
            disableSortBy: true,
            sortAble: false,
          },
        ],
      },
    ]);
  }, []);

  useEffect(() => {
    if (listPromoApproval.data && !listPromoApproval.loading) {
      setInitialSortBy([
        {
          id: "title",
          desc: false,
        },
      ]);

      const resp = listPromoApproval.data.data;
      const resDat = resp.data;
      if (resp === undefined || resp === null) return false;

      const mappedList = resDat.map((items) => ({
        id: items.id,
        relation_id: items.relation_id,
        nama: items.name,
        pengaju: items.creator.username,
        tipe_promo: items.jenis,
        periode: `${moment(items.tanggal).format("DD/MM/YYYY")}`,
        // periode: `${moment(items.start_date).format("DD/MM/YYYY")} - ${moment(
        //   items.end_date
        // ).format("DD/MM/YYYY")}`,
        status: items.approval,
      }));

      setDataPromo(mappedList);
      setIsFetchedData(true);
    }
  }, [listPromoApproval]);

  useEffect(() => {
    dispatch(getListUserAdmin(1, "", 500));
  }, []);

  useEffect(() => {
    transformListPengaju(listUserAdmin?.data?.data?.data);
  }, [listUserAdmin]);

  const transformListPengaju = (array) => {
    if (!array?.length) return [];
    const data = array.map((item) => {
      return { name: item.username, val: item.username };
    });
    setListPengaju(data);
  };

  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-between">
        <h3>Approval</h3>
        <Link to="/dashboard/promotion/add">
          <button className="px-3 py-2 rounded-lg btn-primary">
            New Promotion
          </button>
        </Link>
      </div>
      <div className="d-flex flex-row justify-content-between my-2">
        <div className="d-flex flex-row w-100">
          <div className="d-flex flex-row mr-2 w-25">
            <SelectList
              placeholder="Tipe Promo"
              onChange={(e) => {
                setTypePromo(e.target.value);
              }}
              values={[
                { name: "Other", val: "other" },
                { name: "Flash Sale", val: "FLASHALE" },
                { name: "New Promo", val: "NEW PROMO" },
                { name: "Bundle", val: "BUNDLE" },
                { name: "Campaign", val: "CAMPAIGN" },
                { name: "Product Free", val: "PRODUCT FREE" },
              ]}
            />
            <SelectList
              placeholder="Pengaju"
              onChange={(e) => {
                setPengaju(e.target.value);
              }}
              values={listPengaju}
            />
          </div>
          <DateRangePicker
            size="md"
            placeholder="dd-mm-yyyy ~ dd-mm-yyyy"
            block
            onChange={(val) => {
              setStartDate(
                val && val[0] ? moment(val[0]).format("YYYY-MM-DD") : ""
              );
              setEndDate(
                val && val[1] ? moment(val[1]).format("YYYY-MM-DD") : ""
              );
            }}
          />
        </div>
        <div>
          <SearchBar
            noentry
            onSearch={(value) => {
              setSearch(value);
            }}
          />
        </div>
      </div>
      <div>
        {isFetchedData ? (
          <TableCSI
            columns={column}
            data={dataPromo}
            key={dataPromo}
            initialSortBy={initialSortBy}
          />
        ) : null}
      </div>
    </Fragment>
  );
};

export default TableApproval;
