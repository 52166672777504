import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";
import {Link, useHistory} from "react-router-dom";
import iconSearch from "../../../../assets/images/input-search.png";
import {useDispatch, useSelector} from "react-redux";
import BadgeStatus from "../../../../Component/badgeStatus";
import {deactiveDataSection, deleteDataSection, getListSection} from "../../../../redux/action/sectionAction";
import TableCSI from "../../../../Component/table";
import PaginationRow from "../../../../Component/paginationDataPerPage";
import CustomPagination from "../../../../Component/customPagination";
import WrapperTableAction from "../../../../Component/styledComponents/wrapperBtnAction";
import ButtonAction from "../../../../Component/styledComponents/btnTableAction";
import BtnDropdownContent from "../../../../Component/styledComponents/btnDropdownContent";
import BtnDropdownAction from "../../../../Component/styledComponents/btnDropdownAction";
import {ModalDialog} from "../../../../Component/modal/ModalDialog";
import ModalContent from "../../../../Component/modal/ModalContent";

const SectionLayout = (props) => {
  const [data, setData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [isFetchedData, setIsFetchedData] = useState(true);
  const [isAfterSearch, setIsAfterSearch] = useState(false);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [totalResult, setTotalResult] = useState(4);
  const [perPage, setPerPage] = useState(10);
  const [totalPerPage, setTotalPerPage] = useState(1);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [modalState, setModalState] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const {listSection} = useSelector(
      (state) => state.SectionReducer
  );

  useEffect(() => {
    dispatch(getListSection(10, 1, inputSearchValue));
    setTableColumn([
          {
            Header: () => null,
            id: "Section",
            columns: [
              {
                Header: "ID",
                accessor: "id",
              },
              {
                Header: "Title",
                accessor: "title",
              },
              {
                Header: "Type",
                accessor: "type",
              },
              {
                Header: "Status",
                accessor: "status",
                Cell: ({row}) => <BadgeStatus status={row.original.status}/>,
              },
              {
                Header: "Action",
                accessor: "action",
                Cell: ({row}) => (
                    <div className="d-flex">
                      <div className="mx-2">
                        <Link
                            to={{pathname: `/dashboard/sectionedit/${row.original.id}`, data: row?.original}}
                        >
                          <Button color="primary" outline>
                            Edit
                          </Button>
                        </Link>
                      </div>
                      <WrapperTableAction>
                        <ButtonAction className="mx-2">...</ButtonAction>
                        <BtnDropdownContent>
                          <BtnDropdownAction className="py-0">
                            <Link
                                to={{pathname: `/dashboard/section/${row.original.id}/items`, data: row?.original}}
                            >
                              Items
                            </Link>
                          </BtnDropdownAction>
                          <br />
                          <BtnDropdownAction className="py-0" onClick={() => showModal(row?.original, "delete")}>
                            <Link to="#">Delete</Link>
                          </BtnDropdownAction>
                          <br />
                          <BtnDropdownAction className="py-0" onClick={() => showModal(row?.original, "deactive")}>
                            <Link to="#" style={{color: "red"}}>Deactivate</Link>
                          </BtnDropdownAction>
                        </BtnDropdownContent>
                      </WrapperTableAction>
                    </div>
                ),
              },
            ],
          },
        ],
        []
    );
  }, [])

  useEffect(() => {
    if (listSection.data && !listSection.loading) {
      let dataSection = listSection?.data?.data;
	  if (dataSection === undefined || dataSection === null) return false;

      let mappedListData = dataSection.map((item) => ({
        id: item?.id,
        title: item?.title,
        type: item?.type,
        status: item?.active,
      }));
      setData(mappedListData);
      setMaxPageIndex(listSection?.data?.last_page);
      setTotalResult(listSection?.data?.total);
      setTotalPerPage(listSection?.data?.to);
      setIsFetchedData(true);
    }
  }, [listSection]);

  const handleSubmitSearch = () => {
    if (inputSearchValue !== "") {
      dispatch(getListSection(perPage, 1, inputSearchValue));
      setIsAfterSearch(true);
    }
  };

  const handleInputSearch = (e) => {
    setInputSearchValue(e.target.value);
  };

  const handleOnEnter = (e) => {
    // if (inputSearchValue !== "") {
    dispatch(getListSection(perPage, 1, inputSearchValue));
    setIsAfterSearch(true);
    // }
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListSection(perPage, page, inputSearchValue));
    setIsAfterSearch(true);
  };

  const onChangePaginationRow =(perPage) => {
    setIsFetchedData(false);
    setPerPage(perPage);
    dispatch(getListSection(perPage, 1, inputSearchValue));
  }

  const showModal = (row, type) => {
    setModalState((prevState) => ({
      ...prevState,
      openModal: true,
      modalType: type,
      modalTitle: "Informasi",
      modalData: row,
      closeIcon: true,
    }));
  };

  const handleDelete = (data) => {
    setIsFetchedData(true);
    dispatch(deleteDataSection(data?.id));
    setIsFetchedData(false);
    setModalState(null);
    history.push("/section");
  }

  const handleDeactive = (data) => {
    setIsFetchedData(true);
    dispatch(deactiveDataSection(data?.id));
    setIsFetchedData(false);
    setModalState(null);
    history.push("/section");
  }

  return (
    <Fragment>
      <Breadcrumb parent="Dashboard" title="Section" urlParent={'/dashboard/analytics'} />
      <Container fluid={true}>
        <Row>
          <Col>
            <Card className="p-0">
              <CardBody className="p-3">
                <div className="d-flex justify-content-between my-2">
                  <InputGroup style={{ maxWidth: "280px" }}>
                    <Input
                        placeholder="Search"
                        value={inputSearchValue}
                        onChange={(e) => handleInputSearch(e)}
                        onKeyDown={(e) => {
                          if (e.key === "Enter") handleOnEnter(e);
                        }}
                    />
                    <InputGroupAddon addonType="prepend">
                      <Button className="p-2" color="primary" onClick={() => handleSubmitSearch()}>
                        <img src={iconSearch} width={15} height={15} alt={""} />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  <Link to={`/dashboard/section/add`}>
                    <Button outline color="primary">
                      Add new Section
                    </Button>
                  </Link>
                </div>
                {isFetchedData ?
                    <TableCSI
                        data={data}
                        columns={tableColumn}
                        key={data}
                        initialSortBy={initialSortBy}
                    /> : null}

                <div className="d-flex justify-content-between">
                  <PaginationRow onChangePaginationRow={onChangePaginationRow} />
                  <CustomPagination
                      handlePageClick={handlePageClick}
                      pageCount={maxPageIndex}
                      pageRangeDisplayed={2}
                      marginPagesDisplayed={2}
                  />
                </div>
              </CardBody>
              <ModalDialog
                  open={modalState?.openModal ?? false}
                  type={modalState?.modalType ?? ""}
                  footer={false}
                  title={modalState?.modalTitle ?? ""}
                  titleFontSize={18}
                  backdropClose={true}
                  onClose={() => {
                    setModalState(null);
                  }}
                  maxWidth={384}
              >
                <ModalContent
                    onClose={() => {
                      setModalState(null);
                    }}
                    onSubmit={() => modalState?.modalType === "delete" ? handleDelete(modalState?.modalData) : handleDeactive(modalState?.modalData)}
                    data={modalState?.modalData ?? null}
                    descClose={"Batal"}
                    descSubmit={modalState?.modalType === "delete" ? "Hapus" : "Deactive"}
                    description={`${modalState?.modalType === "delete" ? "Hapus" : "Deactive"} data section ${
                        modalState?.modalData?.title
                    }?`}
                />
              </ModalDialog>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default SectionLayout;
