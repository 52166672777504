import styled from "styled-components";

const BtnWhite = styled.button`
  border-radius: 6px;
  border: 1px solid #d1d5dc;
  background: #FFFFFF;
  padding: 10px, 24px, 10px, 24px;
  gap: 8px;
  width: 160px;
`;

export default BtnWhite;
