import apiConfig from "@api";
import { GET_LIST_FREE_BARANG, WATCH_RESPONSE_ALERT } from "../actionTypes";

export const getListFreeBarang = (page, param, perPage) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(
        `/api/admin/products/free?page=${page}&search=${
          param ? param : ""
        }&perPage=${perPage ? perPage : 10}`
      );
      dispatch({
        type: GET_LIST_FREE_BARANG,
        payload: {
          loading: false,
          data: resp.data,
          err: false,
        },
      });
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });
    }
  };
};

export const updateStatusFreeBarang = (id, status) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(
        `/api/admin/products/free/${status}/${id}`
      );
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: false,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });
    }
  };
};

export const createFreeBarang = (payload) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.post(`/api/admin/products/free`, payload);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          status: resp.status,
          data: resp.data,
          goBack: true,
          err: false,
        },
      });

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          goBack: false,
          err: true,
        },
      });

      return err;
    }
  };
};

export const getDetailFreeBarang = (id) => {
  return async (dispatch) => {
    try {
      const resp = await apiConfig.get(`/api/admin/products/free/${id}`);

      return resp;
    } catch (err) {
      console.log(err);
      dispatch({
        type: WATCH_RESPONSE_ALERT,
        payload: {
          loading: false,
          data: err,
          err: true,
        },
      });
    }
  };
};

export const updateFreeBarang = (payload) => {
	return async (dispatch) => {
	  try {
		const resp = await apiConfig.post(`/api/admin/products/free/update`, payload);
		dispatch({
		  type: WATCH_RESPONSE_ALERT,
		  payload: {
			loading: false,
			status: resp.status,
			data: resp.data,
			goBack: true,
			err: false,
		  },
		});
  
		return resp;
	  } catch (err) {
		console.log(err);
		dispatch({
		  type: WATCH_RESPONSE_ALERT,
		  payload: {
			loading: false,
			data: err,
			goBack: false,
			err: true,
		  },
		});
  
		return err;
	  }
	};
  };