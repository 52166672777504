import React, { Fragment, useState, useEffect } from "react";
import { Card, CardBody, CardHeader } from "reactstrap";
import styled from "styled-components";

import user_profile from "../../../../assets/images/user/user-profile.svg";

const BtnClose = styled.div`
  cursor: pointer;
  font-weight: bold;
  :hover {
    background: #bbb;
  }
`;

const ImgUser = styled.img`
  width: 100px;
  border-radius: 50%;
`;

const UserCustomerInfo = (props) => {
  const [customerInfo, setCustomerInfo] = useState({});

  useEffect(() => {
    if (props.data) {
      setCustomerInfo(props.data);
    }
  }, [props]);

  const handleCloseCustomerInfo = () => {
	props.toggleShowUserInfo('close', {})
  }

  return (
    <Fragment>
      <div className="d-flex justify-content-between my-2">
        <span>Customer Info</span>
        <BtnClose onClick={handleCloseCustomerInfo}>X</BtnClose>
      </div>
      <Card>
        <CardHeader className="text-center my-1 p-1">
          <figure>
            <ImgUser src={user_profile} alt="Customer Avatar" />
          </figure>
          <h6 className="my-1">{customerInfo.shop_name}</h6>
          <span>{customerInfo.owner_name}</span>
        </CardHeader>
        <CardBody className="p-2">
          <div className="d-flex m-2">
			<span>Nav ID:</span>
			<p className="mx-1">{customerInfo.nav_id}</p>
		  </div>
          <div className="d-flex m-2">
			<span>Email:</span>
			<p className="mx-1">{customerInfo.email}</p>
		  </div>
          <div className="d-flex m-2">
			<span>Address:</span>
			<p className="mx-1">{customerInfo.address}</p>
		  </div>
		  <div className="d-flex m-2">
			<span>Phone Number:</span>
			<p className="mx-1">{customerInfo.phone_number}</p>
		  </div>
        </CardBody>
      </Card>
    </Fragment>
  );
};

export default UserCustomerInfo;
