import { Fragment, useState } from "react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import Breadcrumb from "@layout/breadcrumb";
import { FormWrapper, InputText } from "@components/formComponent";

import { updateDataUser } from "@action/userAction";

const UserEdit = () => {
  const urlParams = useParams();
  const history = useHistory();
  const location = useLocation();

  const [payload, setPayload] = useState({
    owner_name: location ? location?.name : "",
  });
  const [disableSubmit, setDisableSubmit] = useState(false);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    setDisableSubmit(true);
    const dataForm = new URLSearchParams(Object.entries(payload)).toString();
    dispatch(updateDataUser(urlParams?.id, dataForm)).then((res) => {
      if (res.status !== 200) return;

      setDisableSubmit(false);
    });
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="User"
        title="Edit User"
        urlParent={"/dashboard/user"}
      />
      <FormWrapper
        disableSubmit={disableSubmit}
        onSubmit={handleSubmit}
        onCancel={() => history.push("/dashboard/user")}
      >
        <InputText
          label="Owner Name"
          value={payload?.owner_name}
          onChange={(e) => setPayload({ owner_name: e.target.value })}
          isRequired
        />
      </FormWrapper>
    </Fragment>
  );
};

export default UserEdit;
