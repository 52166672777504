import { Fragment, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Badge,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Button,
  Input,
} from "reactstrap";
import Breadcrumb from "@layout/breadcrumb";
import BadgeStatus from "@components/badgeStatus";
import TableCSI from "@components/table";

import {
  getTransactionDetail,
  updateTransItems,
} from "@action/transactionAction";

const TransactionEdit = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { transactionDetail } = useSelector(
    (state) => state.TransactionReducer
  );

  const [activeTab, setActiveTab] = useState(1);
  const [data, setData] = useState({});
  const [column, setColumn] = useState([]);
  const [columnDoc, setColumnDoc] = useState([]);
  const [dataItems, setDataItems] = useState([]);
  const [dataDoc, setDataDoc] = useState([]);
  const [onEdit, setOnEdit] = useState(false);
  const [dataItemsTemp, setDataItemsTemp] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (transactionDetail.data && !transactionDetail.loading) {
      const res = transactionDetail.data;
      const dataItems = res.transactionItems?.map((item) => ({
        id: item.id,
        product_name: item.product.name,
        qty: item.quantity,
        price: item.price,
        discount: item.discount_price,
        final_price: item.final_price,
        ready_stock: item.ready_qty,
        po_stock: item.pre_order_qty,
        notes: item.notes,
      }));

      setData(res.transaction);
      setDataItems(dataItems);
    }
  }, [transactionDetail]);

  function fetchData() {
    dispatch(getTransactionDetail(id));

    setColumn([
      {
        Header: () => null,
        id: "TransactionOrdered",
        columns: [
          {
            Header: "Product Name",
            accessor: "product_name",
            sortAble: true,
          },
          {
            Header: "Quantity",
            accessor: "qty",
            sortAble: true,
          },
          {
            Header: "Price",
            accessor: "price",
            sortAble: true,
            Cell: ({ row }) =>
              `${row.original.price.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
              })}`,
          },
          {
            Header: "Discount",
            accessor: "discount",
            sortAble: true,
          },
          {
            Header: "Final Price",
            accessor: "final_price",
            sortAble: true,
            Cell: ({ row }) =>
              `${row.original.final_price.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
              })}`,
          },
          {
            Header: "Ready Stock",
            accessor: "ready_stock",
            sortAble: true,
          },
          {
            Header: "PO Stock",
            accessor: "po_stock",
            sortAble: true,
          },
          {
            Header: "Notes",
            accessor: "notes",
            sortAble: true,
          },
        ],
      },
    ]);

    setColumnDoc([
      {
        Header: () => null,
        id: "tableTransDoc",
        columns: [
          {
            Header: "Pick Ticket Number",
            accessor: "ticketNumber",
            sortAble: true,
          },
          {
            Header: "Do Number",
            accessor: "doNumber",
            sortAble: true,
          },
          {
            Header: "SI",
            accessor: "si",
            sortAble: true,
          },
          {
            Header: "Action",
            accessor: "action",
            sortAble: false,
          },
        ],
      },
    ]);
  }

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  function onChangeQTY(id, val) {
    const newQty = parseInt(val);

    setDataItems((prevDataItems) => {
      let updatedItems = prevDataItems.map((item) =>
        item.id === id ? { ...item, qty: newQty } : item
      );
      return updatedItems;
    });
  }

  function handleEdit() {
    setActiveTab(1);
    setOnEdit(true);
    setDataItemsTemp(dataItems);

    setColumn([
      {
        Header: () => null,
        id: "TransactionOrdered",
        columns: [
          {
            Header: "Product Name",
            accessor: "product_name",
            sortAble: true,
          },
          {
            Header: "Quantity",
            accessor: "qty",
            sortAble: false,
            Cell: ({ row }) => (
              <Input
                type="number"
                min={0}
                defaultValue={row.original.qty}
                onChange={(e) => onChangeQTY(row.original.id, e.target.value)}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    onChangeQTY(row.original.id, e.target.value);
                  }
                }}
              />
            ),
          },
          {
            Header: "Price",
            accessor: "price",
            sortAble: true,
            Cell: ({ row }) =>
              `${row.original.price.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
              })}`,
          },
          {
            Header: "Discount",
            accessor: "discount",
            sortAble: true,
          },
          {
            Header: "Final Price",
            accessor: "final_price",
            sortAble: true,
            Cell: ({ row }) =>
              `${row.original.final_price.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
              })}`,
          },
          {
            Header: "Ready Stock",
            accessor: "ready_stock",
            sortAble: true,
          },
          {
            Header: "PO Stock",
            accessor: "po_stock",
            sortAble: true,
          },
          {
            Header: "Notes",
            accessor: "notes",
            sortAble: true,
          },
        ],
      },
    ]);
  }

  function handleCancleEdit() {
    setOnEdit(false);
    setColumn([
      {
        Header: () => null,
        id: "TransactionOrdered",
        columns: [
          {
            Header: "Product Name",
            accessor: "product_name",
            sortAble: true,
          },
          {
            Header: "Quantity",
            accessor: "qty",
            sortAble: true,
          },
          {
            Header: "Price",
            accessor: "price",
            sortAble: true,
            Cell: ({ row }) =>
              `${row.original.price.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
              })}`,
          },
          {
            Header: "Discount",
            accessor: "discount",
            sortAble: true,
          },
          {
            Header: "Final Price",
            accessor: "final_price",
            sortAble: true,
            Cell: ({ row }) =>
              `${row.original.final_price.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
              })}`,
          },
          {
            Header: "Ready Stock",
            accessor: "ready_stock",
            sortAble: true,
          },
          {
            Header: "PO Stock",
            accessor: "po_stock",
            sortAble: true,
          },
          {
            Header: "Notes",
            accessor: "notes",
            sortAble: true,
          },
        ],
      },
    ]);
    setDataItems(dataItemsTemp);
  }

  function handleSubmitEdit() {
    let dataform = new FormData();

    dataform.append("id", id);
    for (let x of dataItems) {
      dataform.append("qty[]", x.qty);
    }
    for (let x of dataItems) {
      dataform.append("cart_item_id[]", x.id);
    }

    dispatch(updateTransItems(dataform)).then((res) => {
      if (res.status !== 200) return;

      setOnEdit(false);
      fetchData();
    });
  }

  return (
    <Fragment>
      <Breadcrumb
        parent="Transaction"
        title="Edit Transaction"
        urlParent={"/dashboard/transaction"}
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader>
                <h5>{`Order ${id} #${data?.invoice_no}`}</h5>
                <span>
                  Status:{" "}
                  <Badge color="light">{`${
                    data.state === 0
                      ? "Order Accepted"
                      : data.state === 1
                      ? "Waiting For Delivery"
                      : data.state === 2
                      ? "Delivered"
                      : data.state === 3
                      ? "Done"
                      : null
                  }`}</Badge>
                  <span> Created at: {data?.created_at}</span>
                </span>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col sm="2">
                    <p className="m-0">Invoice No:</p>
                    <span>{data?.invoice_no}</span>
                  </Col>
                  <Col sm="2">
                    <p className="m-0">SO Number:</p>
                    <span>{data?.nav_id}</span>
                  </Col>
                  <Col sm="2">
                    <p className="m-0">Reciever Address:</p>
                    <span>{data?.user?.address}</span>
                  </Col>
                  <Col sm="2">
                    <p className="m-0">Shop Name:</p>
                    <span>{data?.user?.shop_name}</span>
                  </Col>
                  <Col sm="2">
                    <p className="m-0">Total Price:</p>
                    <span>
                      {data?.total_price?.toLocaleString("id-ID", {
                        style: "currency",
                        currency: "IDR",
                        minimumFractionDigits: 0,
                      })}
                    </span>
                  </Col>
                  <Col sm="2">
                    <p className="m-0">Paid Status:</p>
                    <BadgeStatus
                      status={data?.paid}
                      label={data?.paid === 0 ? "Unpaid" : "Paid"}
                    />
                  </Col>
                </Row>

                <Row className="my-2">
                  <Col sm={2}>
                    <p className="m-0">Catatan:</p>
                    <span>{`${data.note}`}</span>
                  </Col>
                  <Col sm={2}>
                    <p className="m-0">Pembayaran:</p>
                    <span>{`${data?.payment_type}`}</span>
                  </Col>
                  <Col sm={2}>
                    <p className="m-0">Nomor Virtual:</p>
                    <span
                      style={{ cursor: "pointer", color: "#2647E6" }}
                      onClick={() =>
                        navigator.clipboard.writeText(
                          `${data?.virtual_account_id}`
                        )
                      }
                    >{`${
                      data?.payment_type === "BCA"
                        ? data?.virtual_account_id
                        : "-"
                    }`}</span>
                  </Col>
                  <Col sm={2}>
                    <p className="m-0">XP Point:</p>
                    <span>{`${data?.xp_point}`}</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <div className="d-flex flex-row justify-content-end">
                  {!onEdit && (
                    <Button color="primary" onClick={handleEdit}>
                      Edit
                    </Button>
                  )}
                  {onEdit && (
                    <div className="d-flex flex-row">
                      <Button
                        className="mx-1"
                        outline
                        onClick={handleCancleEdit}
                      >
                        Cancel
                      </Button>
                      <Button
                        className="mx-1"
                        color="primary"
                        onClick={handleSubmitEdit}
                      >
                        Submit
                      </Button>
                    </div>
                  )}
                </div>

                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={`${activeTab === 1 ? "active" : ""}`}
                      href="#"
                      onClick={() => toggleTab(1)}
                      style={{ cursor: "Pointer" }}
                    >
                      Transaction Items
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent className="" activeTab={`${activeTab}`}>
                  <TabPane tabId="1">
                    <TableCSI columns={column} data={dataItems} />
                  </TabPane>
                  <TabPane tabId="2">
                    <TableCSI columns={columnDoc} data={dataDoc} />
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TransactionEdit;
