import Brand from "../../pages/dashboard/master/brand";
import BrandAdd from "../../pages/dashboard/master/brand/add";
import BrandEdit from "../../pages/dashboard/master/brand/edit";

export const RouteBrand = [
  {
    path: `/dashboard/brand`,
    Component: Brand,
  },
  {
    path: `/dashboard/brand/add`,
    Component: BrandAdd,
  },
  {
    path: `/dashboard/brand/edit/:id`,
    Component: BrandEdit,
  },
];
