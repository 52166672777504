import PointLayout from "../../pages/dashboard/konfigurasi/point";
import PointAdd from "../../pages/dashboard/konfigurasi/point/add";
import PointEdit from "../../pages/dashboard/konfigurasi/point/edit";

export const RoutePoint = [
  {
    path: `/dashboard/point`,
    Component: PointLayout,
  },
  {
    path: `/dashboard/point/add`,
    Component: PointAdd,
  },
  {
    path: `/dashboard/point/edit/:id`,
    Component: PointEdit,
  },
];
