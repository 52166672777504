import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "@layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap";

import iconSearch from "@assets/images/input-search.png";
import BadgeStatus from "@components/badgeStatus";
import { useDispatch, useSelector } from "react-redux";
import { getListBrand, updateDataBrand } from "@action/brandAction";
import TableCSI from "@components/table";
import PaginationRow from "@components/paginationDataPerPage";
import CustomPagination from "@components/customPagination";
import { Link, useHistory } from "react-router-dom";
import ModalContent from "@components/modal/ModalContent";
import { ModalDialog } from "@components/modal/ModalDialog";

const BrandLayout = (props) => {
  const [data, setData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [isFetchedData, setIsFetchedData] = useState(true);
  const [isAfterSearch, setIsAfterSearch] = useState(false);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [totalResult, setTotalResult] = useState(4);
  const [perPage, setPerPage] = useState(10);
  const [totalPerPage, setTotalPerPage] = useState(1);
  const [inputSearchValue, setInputSearchValue] = useState("");
  const [modalState, setModalState] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const { listBrand } = useSelector((state) => state.BrandReducer);

  useEffect(() => {
    dispatch(getListBrand(10, 1, inputSearchValue));
    setTableColumn(
      [
        {
          Header: () => null,
          id: "Brand",
          columns: [
            {
              Header: "ID",
              accessor: "id",
            },
            {
              Header: "Image",
              accessor: "banner",
              Cell: ({ row }) => (
                <div>
                  <img src={row.original.banner} alt={""} width={150} />
                </div>
              ),
            },
            {
              Header: "Name",
              accessor: "name",
            },
            {
              Header: "Status",
              accessor: "status",
              Cell: ({ row }) => <BadgeStatus status={row.original.status} />,
            },
            {
              Header: "Action",
              accessor: "action",
              Cell: ({ row }) => (
                <div className="d-flex">
                  <Link
                    to={{
                      pathname: `/dashboard/brand/edit/${row.original?.id}`,
                      data: row?.original,
                    }}
                  >
                    <Button outline color="primary">
                      Edit
                    </Button>
                  </Link>
                  <Button
                    className="mx-2"
                    outline
                    onClick={() => showModal(row?.original)}
                  >
                    {row.original.status === 0 ? "Activate" : "Deactive"}
                  </Button>
                </div>
              ),
            },
          ],
        },
      ],
      []
    );
  }, []);

  useEffect(() => {
    if (listBrand.data && !listBrand.loading) {
      let dataBrand = listBrand?.data?.data;
      if (dataBrand === undefined || dataBrand === null) return false;

      let mappedListData = dataBrand.map((item) => ({
        id: item?.id,
        name: item?.name,
        banner: item?.file_url,
        status: item?.active,
      }));
      setData(mappedListData);
      setMaxPageIndex(listBrand?.data?.last_page);
      setTotalResult(listBrand?.data?.total);
      setTotalPerPage(listBrand?.data?.to);
      setIsFetchedData(true);
    }
  }, [listBrand]);

  const handleSubmitSearch = () => {
    if (inputSearchValue !== "") {
      dispatch(getListBrand(perPage, 1, inputSearchValue));
      setIsAfterSearch(true);
    }
  };

  const handleInputSearch = (e) => {
    setInputSearchValue(e.target.value);
  };

  const handleOnEnter = (e) => {
    // if (inputSearchValue !== "") {
    dispatch(getListBrand(perPage, 1, inputSearchValue));
    setIsAfterSearch(true);
    // }
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListBrand(perPage, page, inputSearchValue));
    setIsAfterSearch(true);
  };

  const onChangePaginationRow = (perPage) => {
    setIsFetchedData(false);
    setPerPage(perPage);
    dispatch(getListBrand(perPage, 1, inputSearchValue));
  };

  const showModal = (row) => {
    setModalState((prevState) => ({
      ...prevState,
      openModal: true,
      modalType: "Status",
      modalTitle: "Informasi",
      modalData: row,
      closeIcon: true,
    }));
  };

  const handleAction = (data) => {
    const dataForm = new FormData();
    dataForm.append("id", data?.id);
    dataForm.append("active", data?.status === 0 ? 1 : 0);
    dispatch(updateDataBrand(dataForm, "status"));
    setModalState(null);
    history.push("/brand");
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Dashboard"
        title="Brand"
        urlParent={"/dashboard/analytics"}
      />
      <Container fluid={true}>
        <Row>
          <Col>
            <Card className="p-0">
              <CardBody className="p-3">
                <div className="d-flex justify-content-between my-2">
                  <InputGroup style={{ maxWidth: "280px" }}>
                    <Input
                      placeholder="Search"
                      value={inputSearchValue}
                      onChange={(e) => handleInputSearch(e)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") handleOnEnter(e);
                      }}
                    />
                    <InputGroupAddon addonType="prepend">
                      <Button
                        className="p-2"
                        color="primary"
                        onClick={() => handleSubmitSearch()}
                      >
                        <img src={iconSearch} width={15} height={15} alt={""} />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                  <Link to={`/dashboard/brand/add`}>
                    <Button outline color="primary">
                      Add new Brand
                    </Button>
                  </Link>
                </div>
                {isFetchedData ? (
                  <TableCSI
                    data={data}
                    columns={tableColumn}
                    key={data}
                    initialSortBy={initialSortBy}
                  />
                ) : null}

                <div className="d-flex justify-content-between">
                  <PaginationRow
                    onChangePaginationRow={onChangePaginationRow}
                  />
                  <CustomPagination
                    handlePageClick={handlePageClick}
                    pageCount={maxPageIndex}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                  />
                </div>
              </CardBody>
              <ModalDialog
                open={modalState?.openModal ?? false}
                type={modalState?.modalType ?? ""}
                footer={false}
                title={modalState?.modalTitle ?? ""}
                titleFontSize={18}
                backdropClose={true}
                onClose={() => {
                  setModalState(null);
                }}
                maxWidth={384}
              >
                <ModalContent
                  onClose={() => {
                    setModalState(null);
                  }}
                  onSubmit={() => handleAction(modalState?.modalData)}
                  data={modalState?.modalData ?? null}
                  descClose={"Batal"}
                  descSubmit={"Submit"}
                  description={`${
                    modalState?.modalData?.status === 0
                      ? "Activate"
                      : "Deactive"
                  } data ${modalState?.modalData?.name}?`}
                />
              </ModalDialog>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default BrandLayout;
