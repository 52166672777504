import { Fragment } from "react";
import PropTypes from "prop-types";

import "./styleForm.css";

const InputSwitch = ({ label, value, onChange, isRequired }) => {
  return (
    <Fragment>
      <div className="container-switch">
        <input className="checkbox-custom" type="checkbox" checked={value ? value : false} onChange={onChange} />
      </div>
    </Fragment>
  );
};

InputSwitch.propTypes = {
  label: PropTypes.string,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
};

export default InputSwitch;
