import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import TableReward from "./customTable";
import EntryCounter from "../../../../../../Component/entryCounter";
import {
  fetchParticipantCampaign,
  handleEditCampaignReward,
} from "../../../../../../redux/action/campaignAction";
import iconSearch from "../../../../../../assets/images/input-search.png";

const BtnWhite = styled.button`
  color: black;
  font-size: 14px;
  border: 1px solid #d2d2d4;
  border-radius: 5px;
  cursor: pointer;
  background: white;
  padding: 7px;
  width: 100px;
  font-weight: 500;
`;

const CampaignReward = (props) => {
  const urlParam = useParams();
  const dispatch = useDispatch();
  const [canEditRow, setCanEditRow] = useState(false);
  const [columns, setColumns] = useState([]);
  const [data, setData] = useState([]);
  const [canEditAll, setEditAll] = useState(false);
  const [valReward, setValReward] = useState("");
  const [skipPageReset, setSkipPageReset] = useState(false);
  const [totalResult, setTotalResult] = useState(0);
  const [totalPerPage, setTotalPerPage] = useState(0);

  const { listCampaignParticipant } = useSelector(
    (state) => state.CampaignReducer
  );
  const { alertResponse } = useSelector((state) => state.AlertReducer);

  useEffect(() => {
    dispatch(fetchParticipantCampaign(urlParam.id, 1));
    setColumns([
      {
        Header: () => null,
        id: "tableRewardCampaign",
        columns: [
          {
            Header: `ID`,
            accessor: "id",
            width: 40,
            sortAble: true,
			isHidden: false
          },
          {
            Header: `Name`,
            accessor: "name",
            width: 100,
            sortAble: true,
          },
          {
            Header: "Reward",
            accessor: "reward",
            width: 180,
            sortAble: true,
          },
        ],
      },
    ]);
  }, []);

  //hook watcher for data list
  useEffect(() => {
    if (listCampaignParticipant.data && !listCampaignParticipant.loading) {
      let newData = listCampaignParticipant.data.map((item) => ({
		id: item.id,
        name: item.user.shop_name,
        reward: item.reward,
      }));
      setData(newData);
      setTotalPerPage(listCampaignParticipant.data.length);
      setTotalResult(listCampaignParticipant.total);
    }
  }, [listCampaignParticipant]);

  //hook watcher for alert
  useEffect(() => {
    if (alertResponse && alertResponse.status === 200) {
      dispatch(fetchParticipantCampaign(urlParam.id, 1));
    }
  }, [alertResponse]);

  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return row;
      })
    );

    let dataForm = new FormData();
    dataForm.append("all", false);
    dataForm.append("id", columnId);
    dataForm.append("reward", value);
    dispatch(handleEditCampaignReward(dataForm));
  };

  const toggleEdit = () => {
    setCanEditRow(!canEditRow);
  };

  const toggleModalEditAll = () => {
    setEditAll(!canEditAll);
  };

  const handleEditRewardAll = () => {
    if (valReward !== "") {
      let dataForm = new FormData();
      dataForm.append("all", true);
      dataForm.append("id", urlParam.id);
      dataForm.append("reward", valReward);
      dispatch(handleEditCampaignReward(dataForm));
      setValReward("");
      setEditAll(!canEditAll);
    }
  };

  return (
    <Fragment>
      <div>
        <div className="d-flex justify-content-between my-2">
          <div className="d-flex">
            <InputGroup style={{ maxWidth: "280px" }}>
              <Input placeholder="Search" />
              <InputGroupAddon addonType="prepend">
                <Button className="p-2" color="primary">
                  <img src={iconSearch} width={15} height={15} />
                </Button>
              </InputGroupAddon>
            </InputGroup>
			<EntryCounter totalPerPage={totalPerPage} totalResult={totalResult} />
          </div>
          <div>
            <Button onClick={() => toggleModalEditAll()} color="primary">
              Edit Massal
            </Button>
          </div>
        </div>

        <Modal isOpen={canEditAll} toggle={toggleModalEditAll}>
          <ModalHeader toggle={toggleModalEditAll}>
            Edit Reward Content for All
          </ModalHeader>
          <ModalBody>
            <p>Reward</p>
            <textarea
              name="reward_content"
              id="rewardContent"
              rows="4"
              value={valReward}
              onChange={(e) => setValReward(e.target.value)}
              style={{ width: "100%" }}
            ></textarea>
          </ModalBody>
          <ModalFooter>
            <BtnWhite onClick={toggleModalEditAll}>Cancel</BtnWhite>
            <Button color="primary" onClick={handleEditRewardAll}>
              Submit
            </Button>{" "}
          </ModalFooter>
        </Modal>

        <TableReward
          data={data}
          columns={columns}
          setData={setData}
          updateMyData={updateMyData}
          skipPageReset={skipPageReset}
        />
      </div>
    </Fragment>
  );
};

export default CampaignReward;
