import React, { Fragment, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ArrowRight, ArrowLeft } from "react-feather";
import { translate } from "react-switch-lang";
import { useHistory, Link } from "react-router-dom";
import Cookies from "universal-cookie";

import configDB from "../../data/customizer/config";
import { MENUITEMS } from "./menu";
import { toLogout } from "../../redux/action/loginAction";

//svg and icon
import csi_logo_small from "../../assets/images/logo/logo_csi_small.png";
import csi_logo_big from "../../assets/images/logo/logo_csi.png";
import menu_expander from "../../assets/images/menu-expander.svg";
import icon_sign_out from "../../assets/images/log-out.svg";

const imgExpandStyle = {
  margin: 0,
  padding: 0,
};

const Sidebar = (props) => {
  const history = useHistory();
  const cookies = new Cookies();

  const [mainmenu, setMainMenu] = useState([]);
  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [sidebartoogle, setSidebartoogle] = useState(true);
  const [isExpandSidebar, setIsExpandSidebar] = useState(false);
  const [currNavActive, setCurrNavActive] = useState("Analytics");

  const dispatch = useDispatch();
  const wrapper =
    useSelector((content) => content.Customizer.sidebar_types.type) ||
    configDB.data.settings.sidebar.type;

  useEffect(() => {
    const userRoles = Number(cookies.get("role"));
    const filteredItems = MENUITEMS.filter((item) => {
      return item.roles && item.roles.includes(userRoles);
    });
	setMainMenu(filteredItems);
  }, []);

  useEffect(() => {
    document.querySelector(".left-arrow").classList.add("d-none");
    window.addEventListener("resize", handleResize);

    handleResize();

    setSidebartoogle(false);

    document.querySelector(".page-header").className = "page-header close_icon";
    document.querySelector(".sidebar-wrapper").className =
      "sidebar-wrapper close_icon ";

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  };

  const setNavActive = (item) => {
    //if (currNavActive !== item.title) setCurrNavActive(item.title);
    mainmenu.map((menuItems) => {
      menuItems.Items.filter((Items) => {
        if (Items !== item) {
          Items.active = false;
          document.querySelector(".bg-overlay1").classList.remove("active");
        }
        if (Items.children && Items.children.includes(item)) {
          Items.active = true;
          document.querySelector(".sidebar-link").classList.add("active");
        }
        if (Items.children) {
          Items.children.filter((submenuItems) => {
            if (submenuItems.children && submenuItems.children.includes(item)) {
              Items.active = true;
              submenuItems.active = true;
              return true;
            } else {
              return false;
            }
          });
        }
        return Items;
      });
      return menuItems;
    });

    item.active = !item.active;
    setMainMenu(MENUITEMS);
  };

  const toggletNavActive = (item) => {
    setIsExpandSidebar(false);
    if (currNavActive !== item.title) setCurrNavActive(item.title);

    if (window.innerWidth <= 991) {
      document.querySelector(".page-header").className =
        "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className =
        "sidebar-wrapper close_icon ";

      if (item.type === "sub") {
        document.querySelector(".page-header").className = "page-header ";
        document.querySelector(".sidebar-wrapper").className =
          "sidebar-wrapper ";
      }
    }

    if (!item.active) {
      MENUITEMS.map((a) => {
        a.Items.filter((Items) => {
          if (a.Items.includes(item)) Items.active = false;
          if (!Items.children) return false;
          Items.children.forEach((b) => {
            if (Items.children.includes(item)) {
              b.active = false;
            }
            if (!b.children) return false;
            b.children.forEach((c) => {
              if (b.children.includes(item)) {
                c.active = false;
              }
            });
          });
          return Items;
        });
        return a;
      });
    }
    item.active = !item.active;
  };

  const scrollToRight = () => {
    if (margin <= -2598 || margin <= -2034) {
      if (width === 492) {
        setMargin(-3570);
      } else {
        setMargin(-3464);
      }
      document.querySelector(".right-arrow").classList.add("d-none");
      document.querySelector(".left-arrow").classList.remove("d-none");
    } else {
      setMargin((margin) => (margin += -width));
      document.querySelector(".left-arrow").classList.remove("d-none");
    }
  };

  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0);
      document.querySelector(".left-arrow").classList.add("d-none");
      document.querySelector(".right-arrow").classList.remove("d-none");
    } else {
      setMargin((margin) => (margin += width));
      document.querySelector(".right-arrow").classList.remove("d-none");
    }
  };

  const closeOverlay = () => {
    document.querySelector(".bg-overlay1").classList.remove("active");
    document.querySelector(".sidebar-link").classList.remove("active");
  };

  const openCloseSidebar = (toggle) => {
    setIsExpandSidebar(!isExpandSidebar);
    if (toggle) {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className =
        "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className =
        "sidebar-wrapper close_icon ";
    } else {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className = "page-header";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper ";
    }
  };

  const responsiveSidebar = () => {
    document.querySelector(".page-header").className = "page-header close_icon";
    document.querySelector(".sidebar-wrapper").className =
      "sidebar-wrapper close_icon";
  };

  const signOut = () => {
    dispatch(toLogout());
    history.push(`/login`);
  };

  return (
    <Fragment>
      <div
        className={`bg-overlay1`}
        onClick={() => {
          closeOverlay();
        }}
      ></div>
      <div className="sidebar-wrapper">
        <div className="logo-wrapper">
          <Link to={`/dashboard/analytics`}>
            <img
              className="img-fluid for-light img-logo-csi"
              src={csi_logo_big}
              alt=""
            />
          </Link>
          <div className="back-btn" onClick={() => responsiveSidebar()}>
            <i className="fa fa-angle-left"></i>
          </div>
          {!isExpandSidebar ? (
            <img
              className="toggle-sidebar"
              src={menu_expander}
              style={imgExpandStyle}
              onClick={() => openCloseSidebar(sidebartoogle)}
            />
          ) : (
            <span
              className="toggle-sidebar my-1 mx-2"
              onClick={() => openCloseSidebar(sidebartoogle)}
            >
              <b>X</b>
            </span>
          )}
        </div>

        <div className="logo-icon-wrapper">
          <Link to={`/dashboard/analytics`}>
            <img className="img-fluid" src={csi_logo_small} alt="" />
          </Link>
        </div>
        <nav className="sidebar-main">
          <div className="left-arrow" onClick={scrollToLeft}>
            <ArrowLeft />
          </div>
          <div
            id="sidebar-menu"
            style={
              wrapper.split(" ").includes("horizontal-wrapper")
                ? { marginLeft: margin + "px" }
                : { margin: "0px" }
            }
          >
            <ul
              className="sidebar-links custom-scrollbar"
              style={{ paddingBottom: 50 }}
            >
              <li className="back-btn">
                <div className="mobile-back text-right">
                  <span>{"Back"}</span>
                  <i className="fa fa-angle-right pl-2" aria-hidden="true"></i>
                </div>
              </li>
              {mainmenu.map((Item, i) => (
                <Fragment key={i}>
                  {Item.Items.map((menuItem, i) => (
                    <li className="sidebar-list" key={i}>
                      {menuItem.type === "sub" ? (
                        <a
                          href="#"
                          className={`sidebar-link sidebar-title ${
                            menuItem.title === currNavActive ? "active" : ""
                          }`}
                          onClick={(event) => {
                            event.preventDefault();
                            setNavActive(menuItem);
                          }}
                        >
                          <img
                            className="mr-2"
                            src={menuItem.icon}
                            alt=""
                            width={15}
                            height={15}
                          />
                          <span>{props.t(menuItem.title)}</span>
                          <div className="according-menu">
                            {menuItem.active ? (
                              <i className="fa fa-angle-down"></i>
                            ) : (
                              <i className="fa fa-angle-right"></i>
                            )}
                          </div>
                        </a>
                      ) : (
                        ""
                      )}

                      {menuItem.type === "link" ? (
                        <Link
                          to={menuItem.path ? menuItem.path : null}
                          className={`sidebar-link sidebar-title link-nav  ${
                            menuItem.title === currNavActive ? "active" : ""
                          }`}
                          onClick={() => toggletNavActive(menuItem)}
                        >
                          {/* <menuItem.icon /> */}
                          <img
                            className="mr-2"
                            src={menuItem.icon}
                            alt=""
                            width={15}
                            height={15}
                          />
                          <span>{props.t(menuItem.title)}</span>
                          {menuItem.badge ? (
                            <label className={menuItem.badge}>
                              {menuItem.badgetxt}
                            </label>
                          ) : (
                            ""
                          )}
                        </Link>
                      ) : (
                        ""
                      )}

                      {menuItem.children ? (
                        <ul
                          className="sidebar-submenu"
                          style={
                            menuItem.active
                              ? sidebartoogle
                                ? {
                                    opacity: 1,
                                    transition: "opacity 500ms ease-in",
                                  }
                                : { display: "block" }
                              : { display: "none" }
                          }
                        >
                          {menuItem.children.map((childrenItem, index) => {
                            return (
                              <li key={index}>
                                {childrenItem.type === "link" ? (
                                  <Link
                                    to={
                                      childrenItem.path
                                        ? childrenItem.path
                                        : null
                                    }
                                    className={`${
                                      childrenItem.active ? "active" : ""
                                    }`}
                                    onClick={() =>
                                      toggletNavActive(childrenItem)
                                    }
                                  >
                                    {props.t(childrenItem.title)}
                                  </Link>
                                ) : (
                                  ""
                                )}
                              </li>
                            );
                          })}
                        </ul>
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                </Fragment>
              ))}
              <li
                style={{
                  position: "absolute",
                  bottom: "5%",
                  backgroundColor: "#FFF",
                }}
              >
                <a
                  className={`sidebar-link sidebar-title`}
                  onClick={(event) => signOut()}
                  style={{ cursor: "pointer" }}
                >
                  <img src={icon_sign_out} alt="icon logout" width={20} />
                  <span className="mx-2" style={{ color: "red" }}>
                    {"Log Out"}
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div className="right-arrow" onClick={scrollToRight}>
            <ArrowRight />
          </div>
        </nav>
      </div>
    </Fragment>
  );
};

export default translate(Sidebar);
