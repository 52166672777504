import React, { useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Alert,
} from "reactstrap";
import {
  Password,
  SignIn,
  EmailAddress,
  RememberPassword,
  ForgotPassword,
} from "../../constant";
import { getLogin } from "@action/loginAction";

const Signin = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [username, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [togglePassword, setTogglePassword] = useState(false);
  const [isAlerted, setIsAlerted] = useState(false);
  const [isDisableLogin, setDisableLogin] = useState(false);

  const loginWithJwt = (username, password) => {
    if (username !== "" && password !== "") {
      setDisableLogin(true);
      const loginPayload = {
        username: username,
        password: btoa(password),
      };

      dispatch(getLogin(loginPayload)).then((resp) => {
        if (resp.status === 200) {
          setDisableLogin(false);
          history.push(`/dashboard/analytics`);
        }
        setDisableLogin(false);
      });
    }
  };

  return (
    <Container fluid={true} className="p-0">
      <Row>
        <Col xs="12">
          <div className="login-card">
            <div>
              <a className="logo" href={`${process.env.PUBLIC_URL}`}>
                <img
                  className="img-fluid for-light"
                  src={require("../../assets/images/logo/login.png")}
                  alt=""
                />
                <img
                  className="img-fluid for-dark"
                  src={require("../../assets/images/logo/logo_dark.png")}
                  alt=""
                />
              </a>
            </div>
            <div className="login-main login-tab">
              <h4>{"Sign In"}</h4>
              <p>{"Enter your username & password to login"}</p>
              <Alert
                className="my-2"
                color="danger"
                isOpen={isAlerted}
                toggle={() => {
                  setIsAlerted(!isAlerted);
                }}
              >
                Email or password cannot empty.
              </Alert>
              <Form className="theme-form" onSubmit={(e) => e.preventDefault}>
                <FormGroup>
                  <Label className="col-form-label">Username</Label>
                  <Input
                    className="form-control"
                    type="text"
                    required=""
                    onChange={(e) => setEmail(e.target.value)}
                    defaultValue={username}
                  />
                </FormGroup>
                <FormGroup>
                  <Label className="col-form-label">{Password}</Label>
                  <Input
                    className="form-control"
                    type={togglePassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    defaultValue={password}
                    required=""
                    onKeyDown={(e) => {
                      if (e.key === "Enter") loginWithJwt(username, password);
                    }}
                  />
                  <div
                    className="show-hide"
                    onClick={() => setTogglePassword(!togglePassword)}
                  >
                    <span className={togglePassword ? "" : "show"}></span>
                  </div>
                </FormGroup>
                <div className="form-group mb-0">
                  <div className="checkbox ml-3">
                    <Input id="checkbox1" type="checkbox" />
                    <Label className="text-muted" for="checkbox1">
                      {RememberPassword}
                    </Label>
                  </div>
                  <a className="link" href="#javascript">
                    {ForgotPassword}
                  </a>
                  <Button
                    color="primary"
                    className="btn-block"
                    onClick={() => loginWithJwt(username, password)}
                    disabled={isDisableLogin}
                  >
                    {SignIn}
                  </Button>
                </div>
              </Form>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Signin;
