import {
    GET_LIST_BRAND
} from "../../actionTypes";

const initialState = {
    listBrand: {}
};

const BrandReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_BRAND:
            state.listBrand = action.payload;
            return { ...state, listBrand: state.listBrand };
        default:
            return { ...state };
    }
};

export default BrandReducer;
