import apiConfig from "../../api";
import {
  GET_LIST_POINT,
  WATCH_RESPONSE_ALERT,
  GET_POINT_DETAIL,
} from "../actionTypes";

export const getListPoint = (page) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_POINT,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get("/api/admin/loyalty/point-management?page=" + page)
      .then((resp) => {
        dispatch({
          type: GET_LIST_POINT,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LIST_POINT,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const createPoint = (payload , locationKey) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post("/api/admin/loyalty/point-management", payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: true,
            err: false,
			to: locationKey
          },
        });
      })
      .catch((err) => {
        console.log('erorr is',err);
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const getListPointBySearch = (page, param) => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_POINT,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/loyalty/point-management?page=${page}&search=${param}`)
      .then((resp) => {
        dispatch({
          type: GET_LIST_POINT,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_LIST_POINT,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const updateRowPoint = (id, payload, locationKey) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .post(`/api/admin/loyalty/point-management/${id}`, payload)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
			to: locationKey
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};

export const getPointDetail = (id) => {
  return (dispatch) => {
    dispatch({
      type: GET_POINT_DETAIL,
      payload: {
        loading: true,
        data: false,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/loyalty/point-management/${id}`)
      .then((resp) => {
        dispatch({
          type: GET_POINT_DETAIL,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: GET_POINT_DETAIL,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const deleteRowPoint = (id) => {
  return (dispatch) => {
    dispatch({
      type: WATCH_RESPONSE_ALERT,
      payload: {
        loading: true,
        status: null,
        data: {},
        err: false,
      },
    });

    apiConfig
      .delete(`/api/admin/loyalty/point-management/${id}`)
      .then((resp) => {
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            status: resp.status,
            data: resp.data,
            goBack: false,
            err: false,
          },
        });
      })
      .catch((err) => {
        console.log(err);
        dispatch({
          type: WATCH_RESPONSE_ALERT,
          payload: {
            loading: false,
            data: err,
            goBack: false,
            err: true,
          },
        });
      });
  };
};
