import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";

import Breadcrumb from "../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  Label,
  Button,
  Alert,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import { setUpdateProfile } from "../../../redux/action/accountAction";

const AccountPage = (props) => {
  const dispatch = useDispatch();
  const [adminData, setAdminData] = useState({});
  const [accountPayload, setAccountPayload] = useState({
    nama: "",
    avatar: null,
  });
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const userData = useSelector((state) => state.LoginReducer.userLoginData);

  const onSubmitEdit = () => {
    setIsButtonDisabled(true);

    if (accountPayload.nama === "") {
      toast.error("Name cannot be empty.");
    } else {
      const dataImg = new FormData();
      dataImg.append("avatar", accountPayload.avatar);
      dataImg.append("nama", accountPayload.nama);
      dispatch(setUpdateProfile(dataImg));
    }

    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 1000);
  };

  const onChangeFileInput = (e) => {
    if (e.target.files.length > 0) {
      const filesImg = e.target.files[0];
      const isImage = () => {
        const acceptedImageTypes = [
          "image/png",
          "image/jpeg",
          "image/jpg",
          "image/gif",
          "image/svg+xml",
          "image/webp",
        ];
        return acceptedImageTypes.includes(filesImg.type);
      };
      if (!isImage()) toast.error(`File must be image type.`);
      if (filesImg.size > 2000000) toast.error(`Max file size is 2MB`);

      setAccountPayload({ ...accountPayload, avatar: filesImg });
    }
  };

  useEffect(() => {
    if (!userData.data) return false;

    setAdminData({
      ...adminData,
      avatar_url: userData.data.avatar_url,
      username: userData.data.username,
      shop_name: userData.data.shop_name,
    });
    setAccountPayload({
      ...accountPayload,
      nama: userData.data.shop_name,
    });
  }, [userData.data]);

  return (
    <Fragment>
      <Breadcrumb
        parent="Dashboard"
        title="Account"
        urlParent={"/dashboard/analytics"}
      />
      <Container fluid={true}>
        <Card>
          <CardBody>
            <Row>
              <Col sm="3">
                <img
                  className="b-r-10"
                  src={`${adminData.avatar_url}`}
                  alt=""
                  width={150}
                  height={150}
                  loading="lazy"
                  style={{ objectFit: "cover" }}
                />
                <div className="media-body my-2">
                  <span className="my-2">
                    <b>{adminData.username}</b>
                  </span>
                  <p className="mb-0 font-roboto">{adminData.shop_name} </p>
                </div>
              </Col>
              <Col sm="9">
                <div className="my-2">
                  <Label for="formName">Change admin name</Label>
                  <InputGroup>
                    <Input
                      placeholder="Admin name"
                      id="formName"
                      type="text"
                      value={accountPayload.nama || ''}
                      onChange={(e) =>
                        setAccountPayload({
                          ...accountPayload,
                          nama: e.target.value,
                        })
                      }
                    />
                    <InputGroupAddon addonType="prepend"></InputGroupAddon>
                  </InputGroup>
                </div>
                <div className="my-2">
                  <Label for="formAvatar">Upload avatar</Label>
                  <InputGroup>
                    <Input
                      id="formAvatar"
                      type="file"
                      name="formAvatar"
                      accept="image/*"
                      onChange={onChangeFileInput}
                    />
                  </InputGroup>
                </div>
                <div className="d-flex justify-content-between my-3">
                  <Button
                    color="primary"
                    onClick={() => onSubmitEdit()}
                    disabled={isButtonDisabled}
                  >
                    Submit
                  </Button>
                </div>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </Container>
    </Fragment>
  );
};

export default AccountPage;
