import apiConfig from "../../api";
import { CometChat } from "@cometchat-pro/chat";
import {
  GET_LIST_CHAT_USER,
  GET_ADMIN_PRODUCT,
  GET_ADMIN_PROMO,
  GET_ADMIN_INVOICE,
  CLEAR_DATA_LIST,
  SET_LATEST_MESSAGE,
  SET_NOTIF_DATA,
  GET_ADMIN_PROMO_SEARCH,
  GET_ADMIN_PRODUCT_SEARCH,
  SET_ENABLE_FETCH_CHAT,
  SET_USER_INFO,
} from "../actionTypes";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const clearDataList = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_DATA_LIST,
    });
  };
};

export const getListChatUsers = () => {
  return (dispatch) => {
    dispatch({
      type: GET_LIST_CHAT_USER,
      payload: {
        loading: true,
        data: {},
        err: false,
      },
    });

    const cometchatUID = cookies.get("uid");
    let conversationRequest = new CometChat.ConversationsRequestBuilder()
      .setLimit(30)
      .setConversationType("user")
      .build();

    conversationRequest.fetchNext().then(
      (conversationList) => {
        const newListConv = conversationList.filter(
          (newList) => newList.conversationWith.uid !== cometchatUID
        );
        dispatch({
          type: GET_LIST_CHAT_USER,
          payload: {
            loading: false,
            data: newListConv,
            err: false,
          },
        });
      },
      (error) => {
        console.log("Error:", error);
        dispatch({
          type: GET_LIST_CHAT_USER,
          payload: {
            loading: false,
            data: false,
            err: error,
          },
        });
      }
    );
  };
};

export const storeLatestMessage = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_LATEST_MESSAGE,
      payload: {
        loading: true,
        data: data,
        err: false,
      },
    });
  };
};

export const storeNotificationData = (data) => {
  return (dispatch) => {
    dispatch({
      type: SET_NOTIF_DATA,
      payload: {
        loading: false,
        fromNotif: true,
        data: data,
        err: false,
      },
    });
  };
};

export const clearNotificationData = () => {
  return (dispatch) => {
    dispatch({
      type: SET_NOTIF_DATA,
      payload: {
        loading: false,
        fromNotif: false,
        data: {},
        err: false,
      },
    });
  };
};

export const fetchAdminProduct = (param) => {
  return (dispatch) => {
    //fetch data
    apiConfig
      .get("/api/admin/products?page=" + param)
      .then((resp) => {
        dispatch({
          type: GET_ADMIN_PRODUCT,
          payload: {
            loading: false,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ADMIN_PRODUCT,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const fetchAdminPromo = (param) => {
  return (dispatch) => {
    //fetch data
    apiConfig
      .get("/api/admin/promos?page=" + param)
      .then((resp) => {
        dispatch({
          type: GET_ADMIN_PROMO,
          payload: {
            loading: false,
            data: resp.data.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ADMIN_PROMO,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const searchAdminPromo = (param, page) => {
  return (dispatch) => {
    //fetch data
    apiConfig
      .get(`/api/admin/promos?search=${param}&page=${page}`)
      .then((resp) => {
        dispatch({
          type: GET_ADMIN_PROMO_SEARCH,
          payload: {
            loading: false,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ADMIN_PROMO_SEARCH,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const searchAdminProduct = (param, page) => {
  return (dispatch) => {
    //fetch data
    apiConfig
      .get(`/api/admin/products?search=${param}&page=${page}`)
      .then((resp) => {
        dispatch({
          type: GET_ADMIN_PRODUCT_SEARCH,
          payload: {
            loading: false,
            data: resp.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ADMIN_PRODUCT_SEARCH,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const fetchAdminInvoiceList = (param) => {
  return (dispatch) => {
    //fetch data
    apiConfig
      .get("/api/admin/nav/jtcustomer?uuid=" + param)
      .then((resp) => {
        dispatch({
          type: GET_ADMIN_INVOICE,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_ADMIN_INVOICE,
          payload: {
            loading: false,
            data: false,
            err: err.message,
          },
        });
      });
  };
};

export const isEnableFetchChat = () => {
  return (dispatch) => {
    dispatch({
      type: SET_ENABLE_FETCH_CHAT,
      payload: {
        fetch: false,
      },
    });

    dispatch({
      type: SET_ENABLE_FETCH_CHAT,
      payload: {
        fetch: true,
      },
    });
  };
};

export const fetchUserInfo = (param) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_INFO,
      payload: {
        loading: true,
        data: undefined,
        err: false,
      },
    });

    apiConfig
      .get(`/api/admin/users/uuid/${param}`)
      .then((resp) => {
        dispatch({
          type: SET_USER_INFO,
          payload: {
            loading: false,
            data: resp.data.data,
            err: false,
          },
        });
      })
      .catch((err) => {
        dispatch({
          type: SET_USER_INFO,
          payload: {
            loading: false,
            data: undefined,
            err: err,
          },
        });
      });
  };
};
