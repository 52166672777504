import React, { useState } from "react";
import { InputGroup, Input, InputGroupAddon, Button } from "reactstrap";
import PropTypes from "prop-types";

import iconSearch from "@assets/images/input-search.png";

const SearchBar = ({ onSearch, totalEntry, totalEntryPerPage, noentry }) => {
  const [inputSearchValue, setInputSearchValue] = useState("");

  const handleSearch = (e) => {
    setInputSearchValue(e.target.value);

    setTimeout(() => {
      onSearch(e.target.value);
    }, 450);
  };

  const handleOnEnter = (e) => {
    if (e.key === "Enter") {
      onSearch(inputSearchValue);
    }
  };

  const handleSubmitSearch = () => {
    onSearch(inputSearchValue);
  };

  return (
    <div className="d-flex align-items-center">
      <InputGroup style={{ maxWidth: "280px" }}>
        <Input
          className="auto"
          placeholder="Search"
          value={inputSearchValue}
          onChange={handleSearch}
          onKeyDown={handleOnEnter}
        />
        <InputGroupAddon addonType="prepend">
          <Button
            className="rounded-right p-2"
            color="primary"
            onClick={handleSubmitSearch}
          >
            <img src={iconSearch} width={18} />
          </Button>
        </InputGroupAddon>
      </InputGroup>

      {noentry ? (
        ""
      ) : (
        <span className="my-auto text-muted mx-2 ">
          Show {totalEntryPerPage} of {totalEntry} entries
        </span>
      )}
    </div>
  );
};

SearchBar.propTypes = {
  onSearch: PropTypes.func.isRequired,
  totalEntryPerPage: PropTypes.number,
  totalEntry: PropTypes.number,
  noentry: PropTypes.bool,
};

export default SearchBar;
