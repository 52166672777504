import {combineReducers} from 'redux'
import Customizer from './reducer/customizer/reducer'
import LoginReducer from './reducer/login'
import ChatReducer from './reducer/chatReducer';
import AccountReducer from './reducer/accountReducer';
import CampaignReducer from './reducer/campaignReducer';
import AlertReducer from './reducer/alertReducer';
import ConfigReducer from './reducer/configReducer';
import PromoReducer from './reducer/promoReducer';
import BroadcastReducer from './reducer/broadcastReducer';
import LevelReducer from './reducer/levelReducer';
import VoucherReducer from './reducer/voucherReducer';
import PointReducer from './reducer/pointReducer';
import TransactionReducer from './reducer/transactionReducer';
import FlashsaleReducer from './reducer/flashsaleReducer';
import MutasiReducer from './reducer/mutasiReducer';
import UserReducer from './reducer/userReducer';
import ProductReducer from './reducer/productReducer';
import CategoryReducer from './reducer/categoryReducer';
import BrandReducer from './reducer/brandReducer';
import NewsReducer from './reducer/newsReducer';
import SectionReducer from './reducer/sectionReducer';
import NavlogReducer from './reducer/navlogReducer';
import UserAdminReducer from './reducer/userAdminReducer';
import AnalyticReducer from './reducer/analyticReducer';
import HargaCoretReducer from './reducer/hargaCoretReducer';
import FreeBarangReducer from './reducer/freeBarangReducer';
import MasterApprovalReducer from './reducer/masterApprovalReducer'

const reducers = combineReducers({
    Customizer,
	LoginReducer,
	ChatReducer,
	AccountReducer,
	CampaignReducer,
	AlertReducer,
	ConfigReducer,
	PromoReducer,
	BroadcastReducer,
	LevelReducer,
	VoucherReducer,
	PointReducer,
	TransactionReducer,
	FlashsaleReducer,
	MutasiReducer,
	UserReducer,
	ProductReducer,
	CategoryReducer,
	BrandReducer,
	NewsReducer,
	SectionReducer,
	NavlogReducer,
	UserAdminReducer,
	AnalyticReducer,
	HargaCoretReducer,
	FreeBarangReducer,
	MasterApprovalReducer
});

export default reducers;
