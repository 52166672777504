import {
    GET_LIST_MUTASI,
    GET_DETAIL_MUTASI,
    SYNC_MUTASI,
    EXPORT_CSV_MUTASI
} from "../../actionTypes";

const initialState = {
    listMutasi: {},
    detailMutasi: {},
    syncListMutasi: {},
    exportMutasi: {}
};

const MutasiReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_LIST_MUTASI:
            state.listMutasi = action.payload;
            return { ...state, listMutasi: state.listMutasi };
        case GET_DETAIL_MUTASI:
            state.detailMutasi = action.payload;
            return { ...state, detailMutasi: state.detailMutasi };
        case SYNC_MUTASI:
            state.syncListMutasi = action.payload;
            return { ...state, syncListMutasi: state.syncListMutasi };
        case EXPORT_CSV_MUTASI:
            state.exportMutasi = action.payload;
            return { ...state, exportMutasi: state.exportMutasi };

        default:
            return { ...state };
    }
};

export default MutasiReducer;
