import React, { Fragment, useEffect, useState } from "react";
import { CSVLink, CSVDownload } from "react-csv";
import { useParams, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";

import { Button } from "reactstrap";
import TableCSI from "@components/table";
import TableDropdown from "@components/customDropdown";
import BadgeStatus from "@components/badgeStatus";
import TableLayout from "@layout/tableLayout";
import { ButtonAction } from "@components/styledComponents";
import SearchBarWithSelect from "@components/searchBarWithSelect";
import SelectList from "@components/selectList";

import {
  getListTransaction,
  getSyncTransaction,
} from "@action/transactionAction";

const TableDelivered = () => {
  const [mappedListData, setMappedListData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [totalResult, setTotalResult] = useState(0);
  const [totalPerPage, setTotalPerPage] = useState(0);
  const [csvData, setCSVData] = useState([]);

  const dispatch = useDispatch();
  const { listTransactionConfirm, syncedTransaction } = useSelector(
    (state) => state.TransactionReducer
  );

  useEffect(() => {
    fetchTable();
  }, []);

  useEffect(() => {
    if (!syncedTransaction.loading && syncedTransaction.data) {
      const dataSync = syncedTransaction.data;

      //const result = mappedListData.filter((mappedListData) => mappedListData.id === dataSync.id);
      const updatedArray = mappedListData.map((item) => {
        if (item.id === dataSync.id) {
          // Update the data for the item with the specified id
          return {
            ...item,
            invoice: dataSync.invoice_no,
            totalprice: dataSync.total_price,
            navid: dataSync.nav_id,
            date: dataSync.created_at,
            paid_status: dataSync.paid,
          };
        } else {
          return item;
        }
      });
      fetchTable();
      setMappedListData(updatedArray);
    }
  }, [syncedTransaction]);

  useEffect(() => {
    if (listTransactionConfirm.data && !listTransactionConfirm.loading) {
      let dataTrans = listTransactionConfirm.data.data;
      let mappedListData = dataTrans.map((item) => ({
        id: item.id,
        invoice: item.invoice_no,
        shopname: item.user.shop_name,
        totalprice: item.total_price,
        navid: item.nav_id,
        date: item.created_at,
        paid_status: item.paid,
      }));
      setMappedListData(mappedListData);
      setMaxPageIndex(listTransactionConfirm.data.last_page);
      setTotalResult(listTransactionConfirm.data.total);
      setTotalPerPage(listTransactionConfirm.data.to);
      setCSVData(mappedListData);
    }
  }, [listTransactionConfirm]);

  function fetchTable() {
    setTableColumn([
      {
        Header: () => null,
        id: "TransactionShipping",
        columns: [
          {
            Header: "ID",
            accessor: "id",
            sortAble: true,
          },
          {
            Header: "Invoice",
            accessor: "invoice",
            sortAble: true,
          },
          {
            Header: "Shop Name",
            accessor: "shopname",
            sortAble: true,
          },
          {
            Header: "Total Price",
            accessor: "totalprice",
            sortAble: true,
            Cell: ({ row }) =>
              `${row.original.totalprice.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
              })}`,
          },
          {
            Header: "Nav ID",
            accessor: "navid",
            sortAble: true,
          },
          {
            Header: "Date",
            accessor: "date",
            sortAble: true,
            Cell: ({ row }) => (
              <span>{moment.utc(row.original.date).format("DD/MM/YYYY")}</span>
            ),
            sortType: (rowA, rowB, columnId, desc) => {
              let startDate = Date.parse(rowA.values[columnId]);
              let endDate = Date.parse(rowB.values[columnId]);
              if (startDate > endDate) return 1;
              if (startDate < endDate) return -1;
              return 0;
            },
          },
          {
            Header: "Paid Status",
            accessor: "paid_status",
            sortAble: true,
            width: 50,
            Cell: ({ row }) => (
              <BadgeStatus
                status={row.original.paid_status}
                label={row.original.paid_status === 0 ? "Unpaid" : "Paid"}
              />
            ),
          },
          {
            Header: "Action",
            accessor: "action",
            Cell: ({ row }) => (
              <div className="d-flex justify-content-start">
                <ButtonAction className="mx-1">
                  <Link
                    to={`/dashboard/transaction/paidconfirm/${row.original.id}/complete`}
                  >
                    Complete
                  </Link>
                </ButtonAction>
                <TableDropdown
                  dropdownContent={[
                    {
                      label: "Details",
                      type: "link",
                      link: `/dashboard/transaction/detail/${row.original.id}`,
                    },
                    {
                      label: "SRO",
                      type: "link",
                      link: `/dashboard/transaction/paidconfirm/${row.original.id}/sro`,
                    },
                    {
                      label: "Sync",
                      type: "button",
                      onclick: () => handleSyncData(row.original.id),
                    },
                    {
                      label: `${
                        row.original.paid_status === 0 ? `Confirm Paid` : `Paid`
                      }`,
                      type: "link",
                      link: `${
                        row.original.paid_status === 0
                          ? `/dashboard/transaction/paidconfirm/${row.original.id}`
                          : `#`
                      }`,
					  disable: row.original.paid_status === 1 ? true : false,
                    },
                  ]}
                />
              </div>
            ),
          },
        ],
      },
    ]);
  }

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    dispatch(getListTransaction(2, page, ""));
  };

  const handleSyncData = (id) => {
    dispatch(getSyncTransaction(id));
  };

  const headerAction = (
    <div
      className="d-flex flex-row justify-content-between"
      style={{ width: "100%" }}
    >
      <div className="d-flex">
        <SearchBarWithSelect onSearch={() => {}} optionArray={[]} noentry />
        <div className="mx-2" style={{ maxWidth: "100px" }}>
          <SelectList placeholder="Status" onChange={() => {}} values={[]} />
        </div>
        <span className="mx-2 my-auto text-muted">
          Show {totalPerPage} of {totalResult} entries
        </span>
      </div>
      <div>
        <CSVLink className="p-1" data={csvData}>
          <Button color="primary" outline>
            Download CSV
          </Button>
        </CSVLink>
      </div>
    </div>
  );

  return (
    <Fragment>
      <TableLayout
        handleSearch={(val) => dispatch(getListTransaction(2, 1, val, 10))}
        totalEntry={totalResult}
        totalEntryPerPage={totalPerPage}
        pageCount={maxPageIndex}
        handleChangePage={handlePageClick}
        handleChangeRow={(row) => dispatch(getListTransaction(2, 1, "", row))}
		nosearch
        isCustomHeader
        customBarHeader={headerAction}
      >
        <TableCSI
          columns={tableColumn}
          data={mappedListData}
          key={mappedListData}
        />
      </TableLayout>
    </Fragment>
  );
};

export default TableDelivered;
