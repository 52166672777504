import { GET_LIST_MASTER_APPROVAL, GET_LIST_ROLE_APPROVAL } from "../../actionTypes";

const initialState = {
  listMasterApproval: {},
  listRoleApproval: {}
};

const MasterApprovalReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_MASTER_APPROVAL:
      state.listMasterApproval = action.payload;
      return { ...state, listMasterApproval: state.listMasterApproval };
    case GET_LIST_ROLE_APPROVAL:
      state.listRoleApproval = action.payload;
      return { ...state, listRoleApproval: state.listRoleApproval };

    default:
      return { ...state };
  }
};

export default MasterApprovalReducer;
