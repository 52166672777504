import React, { Fragment, useEffect, useState } from "react";
import Breadcrumb from "../../../../layout/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  InputGroup,
} from "reactstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getListProductDiscount,
  deleteDataProductDiscount,
} from "../../../../redux/action/productAction";
import CustomPagination from "../../../../Component/customPagination";
import TableCSI from "../../../../Component/table";
import PaginationRow from "../../../../Component/paginationDataPerPage";
import { ModalDialog } from "../../../../Component/modal/ModalDialog";
import moment from "moment";
import ModalContent from "../../../../Component/modal/ModalContent";

const ProductDiscountLayout = (props) => {
  const [data, setData] = useState([]);
  const [dataHeader, setDataHeader] = useState(null);
  const [rawData, setRawData] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [isFetchedData, setIsFetchedData] = useState(true);
  const [isAfterSearch, setIsAfterSearch] = useState(false);
  const [initialSortBy, setInitialSortBy] = useState([]);
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [totalResult, setTotalResult] = useState(4);
  const [perPage, setPerPage] = useState(10);
  const [totalPerPage, setTotalPerPage] = useState(1);
  const dispatch = useDispatch();
  const { listProductDiscount } = useSelector((state) => state.ProductReducer);
  const [modalState, setModalState] = useState(null);
  const urlParams = useParams();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (listProductDiscount.data && !listProductDiscount.loading) {
      let dataProductDiscount = listProductDiscount?.data?.data;
      setDataHeader(dataProductDiscount?.product);
      let mappedListData = dataProductDiscount?.productDiscounts?.map(
        (item) => ({
          id: item?.id,
          start_date_time: item?.start_date_time
            ? moment(item?.start_date_time).format("DD/MM/YYYY")
            : "-",
          end_date_time: item?.end_date_time
            ? moment(item?.end_date_time).format("DD/MM/YYYY")
            : "-",
          discount_price: item?.discount_price,
          product_id: item?.product_id,
        })
      );
      setRawData(mappedListData);
      setData(mappedListData?.slice(0, perPage));
      setMaxPageIndex(dataProductDiscount?.productDiscounts?.length / perPage);
      setTotalResult(dataProductDiscount?.productDiscounts?.length);
      setTotalPerPage(10);
      setIsFetchedData(true);
    }
  }, [listProductDiscount]);

  const fetchData = () => {
    dispatch(getListProductDiscount(urlParams?.id));
    setTableColumn([
      {
        Header: () => null,
        id: "Product",
        columns: [
          {
            Header: "ID",
            accessor: "id",
          },
          {
            Header: "Start Date",
            accessor: "start_date_time",
          },
          {
            Header: "End Date",
            accessor: "end_date_time",
          },
          {
            Header: "Discount Price",
            accessor: "discount_price",
            Cell: ({ row }) =>
              `${row.original.discount_price.toLocaleString("id-ID", {
                style: "currency",
                currency: "IDR",
                minimumFractionDigits: 0,
              })}`,
          },
          {
            Header: "Action",
            accessor: "action",
            Cell: ({ row }) => (
              <div className="d-flex">
                <Link
                  to={{
                    pathname: `/dashboard/product/discount/${row.original?.id}/edit`,
                    data: row?.original,
                  }}
                >
                  <Button outline color="primary">
                    Edit
                  </Button>
                </Link>
                <Link to="#">
                  <Button
                    className="mx-2"
                    outline
                    onClick={() => showModal(row?.original, "delete")}
                  >
                    Delete
                  </Button>
                </Link>
              </div>
            ),
          },
        ],
      },
    ]);
  };

  const handlePageClick = (data) => {
    let page = data.selected + 1;
    setData(rawData.slice(data.selected * perPage, perPage * page));
    setIsAfterSearch(true);
  };

  const onChangePaginationRow = (perPage) => {
    setIsFetchedData(false);
    setPerPage(perPage);
    dispatch(getListProductDiscount(urlParams?.id));
  };

  const showModal = (row, type) => {
    setModalState((prevState) => ({
      ...prevState,
      openModal: true,
      modalType: type,
      modalTitle: "Informasi",
      modalData: row,
      closeIcon: true,
    }));
  };

  const handleDelete = (data) => {
    dispatch(deleteDataProductDiscount(data?.id))
      .then(() => {
        fetchData()
		setModalState(null);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setModalState(null);
      });
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Dashboard"
        title="Product"
        customTitle={`Product Discount: ${dataHeader?.name || ""}`}
        subTitle={`SKU Code: ${dataHeader?.sku_code || ""}`}
        urlParent={"/dashboard/analytics"}
      />
      <Container fluid={true}>
        <Row>
          <Col>
            <Card className="p-0">
              <CardBody className="p-3">
                <div className="d-flex justify-content-between my-2">
                  <InputGroup style={{ maxWidth: "280px" }}></InputGroup>
                  <Link
                    to={{
                      pathname: `/dashboard/product/discount/${urlParams?.id}/add`,
                    }}
                  >
                    <Button outline color="primary">
                      Add New Discount
                    </Button>
                  </Link>
                </div>
                {isFetchedData ? (
                  <TableCSI
                    data={data}
                    columns={tableColumn}
                    key={data}
                    initialSortBy={initialSortBy}
                  />
                ) : null}

                <div className="d-flex justify-content-between">
                  <PaginationRow
                    onChangePaginationRow={onChangePaginationRow}
                  />
                  <CustomPagination
                    handlePageClick={handlePageClick}
                    pageCount={maxPageIndex}
                    pageRangeDisplayed={2}
                    marginPagesDisplayed={2}
                  />
                </div>
              </CardBody>

              <ModalDialog
                open={modalState?.openModal ?? false}
                type={modalState?.modalType ?? ""}
                footer={false}
                title={modalState?.modalTitle ?? ""}
                titleFontSize={18}
                backdropClose={true}
                onClose={() => setModalState(null)}
                maxWidth={384}
              >
                <ModalContent
                  onClose={() => setModalState(null)}
                  onSubmit={() => handleDelete(modalState?.modalData)}
                  data={modalState?.modalData ?? null}
                  descClose={"Batal"}
                  descSubmit={"Hapus"}
                  description={`Hapus data discount ${modalState?.modalData?.discount_price?.toLocaleString(
                    "id-ID",
                    {
                      style: "currency",
                      currency: "IDR",
                      minimumFractionDigits: 0,
                    }
                  )}?`}
                />
              </ModalDialog>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default ProductDiscountLayout;
