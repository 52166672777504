import { Fragment, useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

//componenst
import { FormGroup, Label, Col } from "reactstrap";
import {
  FormWrapper,
  InputNumber,
  InputDateRange,
  InputArea,
  InputSelectProduct,
  InputMultiSelectProduct,
} from "@components/formComponent";
import Breadcrumbs from "@layout/breadcrumb";

import { getListProduct } from "@action/productAction";
import { createFreeBarang } from "@action/freeBarang";
import { validateDateRange } from "@constant/param";

const AddFreeBarang = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [disableSubmit, setDisableSubmit] = useState(false);
  const [formBundleName, setFormBundleName] = useState("");
  const [formBundleID, setFormBundleID] = useState("");
  const [formStartDate, setStartDate] = useState("");
  const [formEndDate, setEndDate] = useState("");
  const [formPembelian, setFormPembelian] = useState(0);
  const [formKuota, setFormKuota] = useState(0);
  const [formDeskripsi, setFormDeskripsi] = useState("");
  const [listResult, setListResult] = useState([]);
  const [formFreeProduct, setFreeProduct] = useState([]);
  const [isShowResult, setShowResult] = useState(false);
  const [formType, setFormType] = useState("");
  const [formKelipatan, setFormKelipatan] = useState("");

  function handleSearchProduct(val) {
    setTimeout(() => {
      dispatch(getListProduct(1, val)).then((resp) => {
        const data = resp.data.data;
        setListResult(data);
      });
    }, 800);
  }

  function onSelectedProduct(item) {
    setFormBundleID(item.id);
    setFormBundleName(item.name);
    setShowResult(false);
  }

  function handleSelectMultiProduct(item) {
    setFreeProduct(item);
  }

  function handleValidateForm() {
    if (
      formBundleName === "" ||
      formStartDate === "" ||
      formEndDate === "" ||
      formType === "" ||
      formKelipatan === "" ||
      formFreeProduct.length < 1 ||
      formPembelian === 0 ||
      formKuota === 0 ||
      formPembelian === NaN ||
      formKuota === NaN ||
      formDeskripsi === ""
    )
      return false;
    return true;
  }

  function handleSubmit() {
    if (!handleValidateForm()) {
      toast.error("Please fill all required fields.");
      return;
    }
    if (!validateDateRange(formStartDate, formEndDate)) return;

    setDisableSubmit(true);

    let payload = new FormData();
    payload.append("product_id", formBundleID);
    payload.append("start_date", formStartDate);
    payload.append("end_date", formEndDate);
    payload.append("type", formType);
    payload.append("deskripsi", formDeskripsi);
    payload.append("value", formPembelian);
    payload.append("qty_free", formKuota);
    payload.append("berlaku_kelipatan", formKelipatan);

    for (let x of formFreeProduct) {
      payload.append("product_id_free[]", x.id);
    }

    dispatch(createFreeBarang(payload)).then((res) => {
      setDisableSubmit(false);
      if (res.status !== 200) return;
      history.push("/dashboard/promo/promo-free-barang");
    });
  }

  return (
    <Fragment>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <Breadcrumbs
          parent="Promo Free Barang"
          title="Add New Promo Free Barang"
          urlParent={"/dashboard/promo/promo-free-barang"}
        />
      </div>
      <FormWrapper
        disableSubmit={disableSubmit}
        onSubmit={handleSubmit}
        onCancel={() => history.push("/dashboard/promo/promo-free-barang")}
      >
        <InputSelectProduct
          label="Produk Utama"
          options={listResult}
          onInputChange={handleSearchProduct}
          onSelectChange={onSelectedProduct}
          isRequired
        />
        <InputDateRange
          label="Periode Promo"
          startDate={formStartDate}
          endDate={formEndDate}
          onChangeStartDate={(val) => setStartDate(val)}
          onChangeEndDate={(val) => setEndDate(val)}
          isRequired
        />
        <FormGroup row>
          <Label sm={2}>
            <b>
              Jenis minimum pembelian <span style={{ color: "red" }}>*</span>
            </b>
          </Label>
          <Col className="d-flex flex-row" sm={10}>
            <FormGroup className="py-3 m-0">
              <Label className="d-flex flex-row justify-content-center" check>
                <input
                  type="radio"
                  name="radioSwitch"
                  className="mx-2"
                  onChange={(e) => setFormType("qty")}
                  style={{
                    width: "18px",
                    height: "18px",
                    accentColor: "blue",
                  }}
                />{" "}
                <span className="my-0">Qty</span>
              </Label>
            </FormGroup>
            <FormGroup className="py-3 m-0">
              <Label className="d-flex flex-row justify-content-center" check>
                <input
                  type="radio"
                  name="radioSwitch"
                  className="mx-2"
                  onChange={(e) => setFormType("amount")}
                  style={{
                    width: "18px",
                    height: "18px",
                    accentColor: "blue",
                  }}
                />{" "}
                <span className="my-0">Nominal</span>
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
        <InputNumber
          label="Minimum Pembelian"
          value={formPembelian}
          onChange={(e) => setFormPembelian(parseInt(e.target.value))}
          isRequired
        />
        <InputMultiSelectProduct
          label="Produk gratis"
          options={listResult}
          onInputChange={handleSearchProduct}
          onSelectChange={handleSelectMultiProduct}
          isRequired
        />
        <InputNumber
          label="Kuota Promo"
          value={formKuota}
          onChange={(e) => setFormKuota(parseInt(e.target.value))}
          isRequired
        />
        <FormGroup row>
          <Label sm={2}>
            <b>
              Berlaku Kelipatan <span style={{ color: "red" }}>*</span>
            </b>
          </Label>
          <Col className="d-flex flex-row" sm={10}>
            <FormGroup className="py-3 m-0">
              <Label className="d-flex flex-row justify-content-center" check>
                <input
                  type="radio"
                  name="radioSwitchKelipatan"
                  className="mx-2"
                  onChange={(e) => setFormKelipatan("1")}
                  style={{
                    width: "18px",
                    height: "18px",
                    accentColor: "blue",
                  }}
                />{" "}
                <span className="my-0">Ya</span>
              </Label>
            </FormGroup>
            <FormGroup className="py-3 m-0">
              <Label className="d-flex flex-row justify-content-center" check>
                <input
                  type="radio"
                  name="radioSwitchKelipatan"
                  className="mx-2"
                  onChange={(e) => setFormKelipatan("0")}
                  style={{
                    width: "18px",
                    height: "18px",
                    accentColor: "blue",
                  }}
                />{" "}
                <span className="my-0">Tidak</span>
              </Label>
            </FormGroup>
          </Col>
        </FormGroup>
        <InputArea
          label="Deskripsi"
          value={formDeskripsi}
          onChange={(e) => setFormDeskripsi(e.target.value)}
          isRequired
        />
      </FormWrapper>
    </Fragment>
  );
};

export default AddFreeBarang;
