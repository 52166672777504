import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";

//componenst
import {
  FormWrapper,
  InputText,
  InputNumber,
  InputDateRange,
  InputArea,
  InputImage,
} from "@components/formComponent";
import AddProductBundle from "../AddProduk";
import Breadcrumbs from "@layout/breadcrumb";

import { createBundle } from "@action/promoAction";
import { validateDateRange } from "@constant/param";

const AddBundle = () => {
  const dispatch = useDispatch();

  const [disableSubmit, setDisableSubmit] = useState(false);
  const [formBundleName, setFormBundleName] = useState("");
  const [formStartDate, setStartDate] = useState("");
  const [formEndDate, setEndDate] = useState("");
  const [formDataProduct, setFormDataProduct] = useState([]);
  const [formBundlePrice, setFormBundlePrice] = useState(0);
  const [formImages, setFormImages] = useState([]);
  const [formDeskripsi, setFormDeskripsi] = useState("");

  const history = useHistory();

  function handleData(val) {
    setFormDataProduct(val);
  }

  function handleValidateForm() {
    if (
      formBundleName === "" ||
      formStartDate === "" ||
      formEndDate === "" ||
      formDataProduct.length < 1 ||
      formBundlePrice === 0 ||
      formImages.length < 1 ||
      formDeskripsi === ""
    )
      return false;
    return true;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!handleValidateForm()) {
      toast.error("please fill all the fields");
      return false;
    }
    if (!validateDateRange(formStartDate, formEndDate)) {
      return;
    }

    setDisableSubmit(true);

    let formData = new FormData();
    formData.append("name", formBundleName);
    formData.append("start_date", formStartDate);
    formData.append("end_date", formEndDate);
    formData.append("price", formBundlePrice);
    formData.append("deskripsi", formDeskripsi);

    if (formDataProduct.length > 0) {
      for (let x of formDataProduct) {
        formData.append("product_id[]", x.id);
        formData.append("qty[]", x.qty);
      }
    }

    if (formImages.length > 0) {
      for (let x of formImages) {
        formData.append("thumbnail[]", x.file);
      }
    }

    dispatch(createBundle(formData)).then((resp) => {
      if (resp.status !== 200) return;

      setTimeout(() => {
        setDisableSubmit(false);
        history.push("/dashboard/promo/bundle");
      }, 650);
    });
  };

  return (
    <>
      <div className="d-flex flex-row justify-content-between align-items-center">
        <Breadcrumbs
          parent="Bundle"
          title="Add New Bundle"
          urlParent={"/dashboard/promo/bundle"}
        />
      </div>
      <FormWrapper
        disableSubmit={disableSubmit}
        onSubmit={handleSubmit}
        onCancel={() => history.push("/dashboard/promo/bundle")}
      >
        <InputText
          label="Bundle Name"
          value={formBundleName}
          onChange={(e) => setFormBundleName(e.target.value)}
          isRequired
        />
        <InputDateRange
          label="Periode Bundle"
          startDate={formStartDate}
          endDate={formEndDate}
          onChangeStartDate={(val) => setStartDate(val)}
          onChangeEndDate={(val) => setEndDate(val)}
          isRequired
        />
        <AddProductBundle
          label="Daftar Product"
          dataProduct={formDataProduct}
          onUpdatedData={handleData}
          isRequired
        />
        <InputNumber
          label="Harga Bundle"
          value={formBundlePrice}
          onChange={(e) => setFormBundlePrice(parseInt(e.target.value))}
          isRequired
        />
        <InputImage
          label="Foto Thumbnail"
          value={formImages}
          onChange={(images) => setFormImages(images)}
          isRequired
        />
        <InputArea
          label="Deskripsi"
          value={formDeskripsi}
          onChange={(e) => setFormDeskripsi(e.target.value)}
          isRequired
        />
      </FormWrapper>
    </>
  );
};

export default AddBundle;
