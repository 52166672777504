import {
  legacy_createStore as createStore,
  applyMiddleware,
  compose,
} from "redux";
import reducers from "../redux/index";
import { thunk } from "redux-thunk";
import { save, load } from "redux-localstorage-simple";

const initialState = load({ states: ["LoginReducer"] }) || {};

const createStoreWithMiddleware = compose(applyMiddleware(thunk, save({ states: ["LoginReducer"] }))(createStore));

const store = createStoreWithMiddleware(reducers, initialState);

export default store;