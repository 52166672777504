import { Fragment } from "react";
import { FormGroup, Label, Col, Input } from "reactstrap";
import PropTypes from "prop-types";

const InputDate = ({ label, value, onChange, isRequired }) => {
  return (
    <Fragment>
      <FormGroup row>
        <Label className="font-weight-bold" for={`form${label}`} sm={2}>
          {label}
		  {isRequired ? <span className="mx-1" style={{color: 'red'}}>*</span> : null}
        </Label>
        <Col sm={10}>
          <Input
            id={`form${label}`}
            placeholder={`Enter ${label}`}
            value={value}
            onChange={onChange}
			type="date"
          />
        </Col>
      </FormGroup>
    </Fragment>
  );
};

InputDate.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isRequired: PropTypes.bool,
};

export default InputDate;
