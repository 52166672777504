import {
  GET_LIST_POINT,
  GET_POINT_DETAIL,
} from "../../actionTypes";

const initialState = {
  listPoint: {},
  detailPoint: {},
};

const PointReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_POINT:
      state.listPoint = action.payload;
      return { ...state, listPoint: state.listPoint };
    case GET_POINT_DETAIL:
      state.detailPoint = action.payload;
      return { ...state, detailPoint: state.detailPoint };
    default:
      return { ...state };
  }
};

export default PointReducer;
