import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import Breadcrumb from "@layout/breadcrumb";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import TableOrder from "./table/order_accepted";
import TableWaiting from "./table/waiting";
import TableDelivered from "./table/delivered";
import TableDone from "./table/done";

import { getListTransaction } from "@action/transactionAction";

const TransactionLayout = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    dispatch(getListTransaction(activeTab, 1, "", 10));
  }, []);

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
	  dispatch(getListTransaction(tab, 1, "", 10));
      history.push({
        search: "?state=" + tab,
      });
    }
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Dashboard"
        title="Transaction"
        urlParent={"/dashboard/analytics"}
      />
      <Container fluid={true}>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={`${activeTab === 0 ? "active" : ""}`}
                      href="#"
                      onClick={() => toggleTab(0)}
                      style={{ cursor: "Pointer" }}
                    >
                      Order Accepted
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`${activeTab === 1 ? "active" : ""}`}
                      href="#"
                      onClick={() => toggleTab(1)}
                      style={{ cursor: "Pointer" }}
                    >
                      Waiting for Delivery
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`${activeTab === 2 ? "active" : ""}`}
                      href="#"
                      onClick={() => toggleTab(2)}
                      style={{ cursor: "Pointer" }}
                    >
                      Delivered
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`${activeTab === 3 ? "active" : ""}`}
                      href="#"
                      onClick={() => toggleTab(3)}
                      style={{ cursor: "Pointer" }}
                    >
                      Done
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={`${activeTab}`}>
                  <TabPane tabId="0">
                    <Row>
                      <Col className="mx-0 px-0">
                        <TableOrder />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="1">
                    <Row>
                      <Col className="mx-0 px-0">
                        <TableWaiting />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col className="mx-0 px-0">
                        <TableDelivered />
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col className="mx-0 px-0">
                        <TableDone />
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default TransactionLayout;
