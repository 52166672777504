import { Fragment, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";

//components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Button,
  Input,
} from "reactstrap";
import ImgSlider from "@components/imgSlider";
import BadgeStatus from "@components/badgeStatus";
import Breadcrumb from "@layout/breadcrumb";
import TableDropdown from "@components/customDropdown";
import SpinnerLoading from "@components/spinnerLoading";
import TableLayout from "@layout/tableLayout";
import TableCSI from "@components/table";

//css
import {
  cssDropdownReminder,
  cssBtnReminder,
  BtnAction,
} from "../../style/cssStyle";

import {
  sendNotificationPromo,
  setUpdatePromoReminder,
  getDetailPromo,
} from "@action/promoAction";

const PromoDetail = () => {
  const urlParam = useParams();
  const dispatch = useDispatch();
  const [dropdownReminder, setDropdownReminder] = useState(false);
  const [formReminder, setFormReminder] = useState({
    id: urlParam.id,
    remind_start_date: "",
    remind_end_date: "",
  });
  const [isDisableSendNotif, setIsDisableSendNotif] = useState(false);
  const [dataDetailPromo, setDataDetailPromo] = useState({});
  const [maxPageIndex, setMaxPageIndex] = useState(1);
  const [isFetchedData, setIsFetchedData] = useState(false);
  const [totalEntry, setTotalEntry] = useState(0);
  const [totalEntryPerPage, setTotalEntryPerPage] = useState(0);

  useEffect(() => {
    const idPromo = urlParam.id;

    dispatch(getDetailPromo(idPromo)).then((resp) => {
      if (resp.status !== 200) return false;

      const dataPromo = resp.data.data && resp.data.data;
      setDataDetailPromo(dataPromo);

      const startDate = moment
        .utc(dataPromo.remind_start_date)
        .format("YYYY-MM-DD");
      const endDate = moment
        .utc(dataPromo.remind_end_date)
        .format("YYYY-MM-DD");

      setFormReminder({
        ...formReminder,
        remind_start_date: startDate,
        remind_end_date: endDate,
        promo_images: dataPromo.promo_images,
      });
    });
  }, []);

  const sendNotification = () => {
    setIsDisableSendNotif(true);
    let idPromo = urlParam.id;
    let dataForm = new FormData();
    dataForm.append("promo_id", idPromo);

    dispatch(sendNotificationPromo(dataForm)).then((resp) => {
      if (resp.status !== 200) return false;

      setTimeout(() => {
        setIsDisableSendNotif(false);
      }, 650);
    });
  };

  const setReminderPromo = () => {
    if (
      formReminder.remind_start_date !== "" &&
      formReminder.remind_end_date !== ""
    ) {
      const rawData = new URLSearchParams(
        Object.entries(formReminder)
      ).toString();
      dispatch(setUpdatePromoReminder(rawData));
      setDropdownReminder(!dropdownReminder);
    } else return false;
  };

  return (
    <Fragment>
      <Breadcrumb
        parent="Promo"
        title="Promo Detail"
        urlParent="/dashboard/promo"
      />
      <Container fluid>
        <Row>
          <Col sm="12" md="5">
            <Card style={{ minHeight: "500px" }}>
              <CardTitle tag="h5" className="p-3">
                {dataDetailPromo.title}
              </CardTitle>
              <CardBody className="p-3">
                <div className="d-flex">
                  <Link className="w-100 mr-1" to={`/dashboard/product/edit/1`}>
                    <Button outline block color="primary">
                      Edit
                    </Button>
                  </Link>
                  <TableDropdown
                    dropdownContent={[
                      {
                        label: "Products",
                        type: "link",
                        link: `/dashboard/promo/products/${urlParam.id}`,
                      },
                    ]}
                  />
                </div>

                <div className="d-flex my-2" style={cssBtnReminder}>
                  <BtnAction
                    className="mr-1"
                    onClick={() => setDropdownReminder(!dropdownReminder)}
                    style={{ color: "red", border: "1px solid red" }}
                  >
                    Set reminder date
                  </BtnAction>
                  {dropdownReminder ? (
                    <div style={cssDropdownReminder}>
                      <div className="my-2">
                        <span>
                          <b>Reminder Start</b>
                        </span>
                        <Input
                          type="date"
                          name="date"
                          value={formReminder.remind_start_date || ""}
                          onChange={(e) =>
                            setFormReminder({
                              ...formReminder,
                              remind_start_date: e.target.value,
                            })
                          }
                        />
                      </div>
                      <div className="my-2">
                        <span>
                          <b>Reminder End</b>
                        </span>
                        <Input
                          type="date"
                          name="date"
                          value={formReminder.remind_end_date || ""}
                          onChange={(e) =>
                            setFormReminder({
                              ...formReminder,
                              remind_end_date: e.target.value,
                            })
                          }
                        />
                      </div>
                      <Button
                        onClick={() => setReminderPromo()}
                        color="primary"
                        style={{ width: "100%" }}
                      >
                        Save
                      </Button>
                    </div>
                  ) : (
                    ""
                  )}
                  <BtnAction
                    className="mr-1"
                    onClick={() => sendNotification()}
                    disabled={isDisableSendNotif}
                  >
                    {isDisableSendNotif ? (
                      <SpinnerLoading />
                    ) : (
                      "Send Notification"
                    )}
                  </BtnAction>
                </div>

                <hr />

                <div className="my-2">
                  <div className="d-flex my-2">
                    <span>
                      <b>Status : </b>
                    </span>
                    <div className="mx-1">
                      <BadgeStatus
                        status={
                          dataDetailPromo.status ? dataDetailPromo.status : ""
                        }
                      />
                    </div>
                  </div>
                  <div className="d-flex my-2">
                    <span>
                      <b>Start Date : </b>
                    </span>
                    <span className="mx-1">
                      {moment
                        .utc(dataDetailPromo.start_date)
                        .format("DD MMM YYYY")}
                    </span>
                  </div>
                  <div className="d-flex my-2">
                    <span>
                      <b>End Date : </b>
                    </span>
                    <span className="mx-1">
                      {moment
                        .utc(dataDetailPromo.end_date)
                        .format("DD MMM YYYY")}
                    </span>
                  </div>
                  <div className="my-2 py-2">
                    <p className="m-0">
                      <b>Content</b>
                    </p>
                    <span className="my-2">{dataDetailPromo.content}</span>
                  </div>
                  <div className="my-2 py-2">
                    <p className="m-0">
                      <b>Deskripsi 2</b>
                    </p>
                    <span className="my-2">
                      Contoh Text Lorem ipsum dolor sit amet, consectetur
                      adipisicing elit. Libero placeat quam, at, nesciunt hic
                      unde ea ullam expedita aperiam exercitationem ut natus
                      suscipit nobis blanditiis amet voluptates repudiandae et
                      odio!
                    </span>
                  </div>

                  {dataDetailPromo.promo_images &&
                  dataDetailPromo.promo_images.length > 0 ? (
                    <div>
                      <p>
                        <b>Content Images</b>
                      </p>
                      <ImgSlider
                        images={
                          dataDetailPromo.promo_images
                            ? dataDetailPromo.promo_images
                            : []
                        }
                      />
                    </div>
                  ) : null}
                </div>
              </CardBody>
            </Card>
          </Col>

          <Col sm="12" md="7">
            <Card style={{ minHeight: "500px" }}>
              <CardBody className="p-3">
                <div>
                  <p style={{ fontSize: "16px" }}>
                    <b>Banner</b>
                  </p>
                  <img
                    src={dataDetailPromo.file_url}
                    alt="CSI Promo"
                    style={{ width: "100%", minHeight: "300px" }}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col className="p-0">
            <TableLayout
              headerTitle="Product Promo"
              handleSearch={() => {}}
              totalEntry={totalEntry}
              totalEntryPerPage={totalEntryPerPage}
              pageCount={maxPageIndex}
              handleChangePage={() => {}}
              handleChangeRow={(val) => {}}
            >
              <TableCSI
                data={[]}
                columns={[]}
              />
            </TableLayout>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default PromoDetail;
