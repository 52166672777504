import { GET_LIST_LEVEL, GET_LEVEL_DETAIL } from "../../actionTypes";

const initialState = {
  listLevel: {},
  levelDetail: {},
};

const LevelReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_LIST_LEVEL:
      state.listLevel = action.payload;
      return { ...state, listLevel: state.listLevel };
    case GET_LEVEL_DETAIL:
      state.levelDetail = action.payload;
      return { ...state, levelDetail: state.levelDetail };

    default:
      return { ...state };
  }
};

export default LevelReducer;
