import VoucherLayout from "../../pages/dashboard/promo/voucher";
import VoucherAdd from "../../pages/dashboard/promo/voucher/add";
import VoucherEdit from "../../pages/dashboard/promo/voucher/edit";
import VoucherDetail from "pages/dashboard/promo/voucher/detail";

export const RouteVoucher = [
  {
    path: `/dashboard/promo/voucher`,
    Component: VoucherLayout,
  },
  {
    path: `/dashboard/promo/voucher/add`,
    Component: VoucherAdd,
  },
  {
    path: `/dashboard/promo/voucher/edit/:id`,
    Component: VoucherEdit,
  },
  {
    path: `/dashboard/promo/voucher/detail/:id`,
    Component: VoucherDetail,
  },
];
