import { Fragment, useEffect, useState } from "react";
import SwitchAndOr from "./SwitchAndOr";
import PopUpKondisi from "./PopUpKondisi";
import FilterByCustomer from "./filterByCustomer";
import FilterBySales from "./filterBySales";
import FilterByWLarea from "./filterByWLarea";
import FilterByUser from "./filterByUser";

const FilterByKondisiCustomer = ({
  onDeleteFilter,
  item,
  customerCondition,
  setCustomerCondition,
  isAddKondisi,
  setAddKondisi,
}) => {
  const [openModal, setModal] = useState(false);

  //customer
  const [isCustomer, setCustomer] = useState(false);
  const [customerData, setCustomerData] = useState({
    value: "",
    type: "customers",
  });
  const [selectedCustomer, setSelectedCustomer] = useState([]);

  //sales
  const [isSales, setSales] = useState(false);
  const [salesData, setSalesData] = useState({
    value: "",
    type: "sales",
  });
  const [selectedSales, setSelectedSales] = useState([]);

  //WL area
  const [isWLarea, setWLarea] = useState(false);
  const [wlAreaData, setWlAreaData] = useState({
    value: "",
    type: "wl_area",
  });
  const [selectedWlArea, setSelectedWlArea] = useState([]);

  //Jenis Customer
  const [isUser, setUser] = useState(false);
  const [userData, setUserData] = useState({
    value: "",
    type: "jenis_customer",
  });
  const [selectedUser, setSelectedUser] = useState([]);

  function handleAddFilter(val) {
    switch (val) {
      case "customer":
        setCustomer(true);
        break;
      case "sales":
        setSales(true);
        break;
      case "wlarea":
        setWLarea(true);
        break;
      case "user":
        setUser(true);
        break;
      default:
        break;
    }
  }

  const handleChangeItems = (val, data) => {
    setAddKondisi((prevData) =>
      prevData.map((kondisi) => {
        const newData = {
          value: data.value,
          type: data.type,
          items: val.map((item) => item),
        };

        if (kondisi.name === item.name) {
          if (!kondisi?.data || kondisi.data.length === 0) {
            return {
              ...kondisi,
              data: [newData],
            };
          }

          const newConditions = kondisi.data.some(
            (itm) => itm.type === data.type
          )
            ? kondisi.data.map((itm) =>
                itm.type === data.type ? newData : itm
              )
            : [...kondisi.data, newData];

          return {
            ...kondisi,
            data: newConditions,
          };
        }

        return kondisi;
      })
    );
  };

  const handleChangeValue = (newValue, dataType) => {
    setAddKondisi((prevData) =>
      prevData.map((kondisi) => {
        const newConditions =
          kondisi.data && kondisi.data.length > 0
            ? kondisi.data.map((itm) => {
                if (itm.type === dataType) {
                  return {
                    ...itm,
                    value: newValue,
                  };
                }
                return itm;
              })
            : [];

        return {
          ...kondisi,
          data: newConditions,
        };
      })
    );
  };

  const handleChangeAndOr = (value, name) => {
    // setCustomerCondition(value);
    setAddKondisi((prevData) =>
      prevData.map((kondisi) => {
        if (kondisi.name === name) {
          return { ...kondisi, condition: value };
        }
        return kondisi;
      })
    );
  };

  const removeItems = (type) => {
    setAddKondisi((prevData) =>
      prevData.map((kondisi) => {
        const newItems =
          kondisi.data && kondisi.data.length > 0
            ? kondisi.data.filter((itm) => itm.type !== type)
            : [];

        return {
          ...kondisi,
          data: newItems,
        };
      })
    );
  };

  useEffect(() => {
    const customers = item?.data?.length
      ? item.data.find((x) => x.type === "customers")
      : null;
    if (customers) {
      setCustomer(customers ? true : false);
      setCustomerData({ value: customers.value, type: "customers" });
      setSelectedCustomer(customers?.items?.length ? customers.items : []);
    }
    const sales = item?.data?.length
      ? item.data.find((x) => x.type === "sales")
      : null;
    if (sales) {
      setSales(sales ? true : false);
      setSalesData({ value: sales.value, type: "sales" });
      setSelectedSales(sales?.items?.length ? sales.items : []);
    }
    const wlArea = item?.data?.length
      ? item.data.find((x) => x.type === "wl_area")
      : null;
    if (wlArea) {
      setWLarea(wlArea ? true : false);
      setWlAreaData({ value: wlArea.value, type: "wl_area" });
      setSelectedWlArea(wlArea?.items?.length ? wlArea.items : []);
    }
    const user = item?.data?.length
      ? item.data.find((x) => x.type === "jenis_customer")
      : null;
    if (user) {
      setUser(user ? true : false);
      setUserData({ value: user.value, type: "jenis_customer" });
      setSelectedUser(user?.items?.length ? user.items : []);
    }
  }, [item]);

  return (
    <Fragment>
      <div className="bg-light p-3 border rounded-lg text-center text-dark mb-2">
        <div className="d-flex flex-row justify-content-between mb-2">
          <h5>Kondisi {item.name}</h5>
          <div className="d-flex">
            <SwitchAndOr
              // value={customerCondition}
              value={item.condition}
              onChange={(val) => handleChangeAndOr(val, item.name)}
            />
            <button
              type="button"
              className="ml-2 btn btn-primary"
              onClick={() => setModal(!openModal)}
            >
              {"+"} Tambah Kondisi
            </button>
            <button className="border-0 bg-none" onClick={onDeleteFilter}>
              <i
                className="fa fa-trash"
                aria-hidden="true"
                style={{ color: "red" }}
              ></i>
            </button>
          </div>
        </div>

        <div className="d-flex flex-column justify-content-center my-2 p-3">
          {isCustomer && (
            <FilterByCustomer
              onToggle={() => {
                setCustomerData({ value: "", type: "customers" });
                setSelectedCustomer([]);
                setCustomer(!isCustomer);
                removeItems("customers");
              }}
              selectedData={selectedCustomer}
              setSelectedData={(val) => {
                setSelectedCustomer(val);
                handleChangeItems(val, customerData);
              }}
              filterData={customerData}
              setFilterData={(e) => {
                setCustomerData(e);
                handleChangeValue(e.value, customerData.type);
              }}
            />
          )}
          {isSales && (
            <FilterBySales
              onToggle={() => {
                setSalesData({ value: "", type: "sales" });
                setSelectedSales([]);
                setSales(!isSales);
                removeItems("sales");
              }}
              selectedData={selectedSales}
              setSelectedData={(val) => {
                setSelectedSales(val);
                handleChangeItems(val, salesData);
              }}
              filterData={salesData}
              setFilterData={(e) => {
                setSalesData(e);
                handleChangeValue(e.value, salesData.type);
              }}
            />
          )}
          {isWLarea && (
            <FilterByWLarea
              onToggle={() => {
                setWlAreaData({ value: "", type: "wl_area" });
                setSelectedWlArea([]);
                setWLarea(!isWLarea);
                removeItems("wl_area");
              }}
              selectedData={selectedWlArea}
              setSelectedData={(val) => {
                setSelectedWlArea(val);
                handleChangeItems(val, wlAreaData);
              }}
              filterData={wlAreaData}
              setFilterData={(e) => {
                setWlAreaData(e);
                handleChangeValue(e.value, wlAreaData.type);
              }}
            />
          )}
          {isUser && (
            <FilterByUser
              onToggle={() => {
                setWlAreaData({ value: "", type: "jenis_customer" });
                setSelectedWlArea([]);
                setUser(!isUser);
                removeItems("jenis_customer");
              }}
              selectedData={selectedUser}
              setSelectedData={(val) => {
                setSelectedUser(val);
                handleChangeItems(val, userData);
              }}
              filterData={userData}
              setFilterData={(e) => {
                setUserData(e);
                handleChangeValue(e.value, userData.type);
              }}
            />
          )}

          {!isCustomer && !isSales && !isWLarea && !isUser ? (
            <div className="bg-light p-3 rounded-lg text-center text-dark">
              <span>Silahkan untuk menambahkan filter</span>
            </div>
          ) : (
            ""
          )}
        </div>

        <PopUpKondisi
          kondisi="customer"
          isOpen={openModal}
          onToggle={() => setModal(!openModal)}
          onAddFilter={handleAddFilter}
        />
      </div>
    </Fragment>
  );
};

export default FilterByKondisiCustomer;
