import React, { useEffect, useState, Fragment } from "react";
import { Button, UncontrolledAlert, CardBody, CardHeader } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import {
  getAllProductCampaign,
  getAllCategoryCampaign,
  getAllBrandCampaign,
} from "../../../../../../redux/action/campaignAction";
import ModalAdd from "./modalAddProduct";
import TableAddProduct from "./tableProduct";

const AddProduct = (props) => {
  const [showAddProduct, setShowAddProduct] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableColumns, setTableColumns] = useState([]);
  const [typeProduct, setTypeProduct] = useState("");

  const dispatch = useDispatch();
  const { listSelectProductAllCampaign } = useSelector(
    (state) => state.CampaignReducer
  );

  const toggleAddProduct = () => {
    setShowAddProduct(!showAddProduct);
  };

  const validateHavePickedType = (columns, data, pickedType) => {
    if (typeProduct !== pickedType) {
      let tempCol = columns;
      let tempData = data;
      setTableData([]);
      setTableColumns([]);
      setTableData(tempData);
      setTableColumns(tempCol);
    } else return true;
  };

  const handleSubmitProduct = (columns, data, type, isSelectAll, query) => {
    if (!isSelectAll) {
      if (typeProduct === type) {
        const merged = [...new Set([...data ,...tableData])];
		const uniqData = Array.from(merged.reduce((map, obj)=> map.set(obj.id, obj), new Map()).values())
        setTableData(uniqData);
        setTableColumns(columns);
        setTypeProduct(type);
        props.handleOnSubmitProduct(columns, uniqData, type, "");
      } else {
        let tempCol = columns;
        let tempData = data;
        setTableData([]);
        setTableColumns([]);
        setTypeProduct(type);
        setTableData(tempData);
        setTableColumns(tempCol);
		props.handleOnSubmitProduct(columns, tempData, type, "");
      }
    } else {
      switch (type) {
        case "product":
          setTableColumns(columns);
          setTypeProduct(type);
          dispatch(getAllProductCampaign(query));
          break;
        case "category":
          setTableColumns(columns);
          setTypeProduct(type);
          dispatch(getAllCategoryCampaign(query));
          break;
        case "brand":
          setTableColumns(columns);
          setTypeProduct(type);
          dispatch(getAllBrandCampaign(query));
          break;
        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (props.tempProductData.length > 0) {
      setTableColumns(props.tempProductColumn);
      setTableData(props.tempProductData);
    }
  }, []);

  // hook on fetched data for fillter all
  useEffect(() => {
    if (listSelectProductAllCampaign.data) {
      let newData = listSelectProductAllCampaign.data;
      setTableData(newData);
      props.handleOnSubmitProduct(tableColumns, newData, typeProduct, "all");
    }
  }, [listSelectProductAllCampaign]);

  return (
    <Fragment>
      <CardHeader className="p-3">
        <Button onClick={toggleAddProduct} color="primary" outline>
          + Add product
        </Button>
      </CardHeader>
      <CardBody
        className="p-3"
        style={{ maxHeight: "450px", overflow: "auto" }}
      >
        <ModalAdd
          showAddProduct={showAddProduct}
          toggleAddProduct={toggleAddProduct}
          handleSubmitProduct={handleSubmitProduct}
        />
        <TableAddProduct data={tableData} columns={tableColumns} />
      </CardBody>
    </Fragment>
  );
};

export default AddProduct;
