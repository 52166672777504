import React, { Fragment, useState, useEffect } from "react";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";

const ModalPotongan = ({ isVisible, onClose, onSubmit }) => {
  const [inputRupiah, setInputRupiah] = useState("");
  const [isActive, setActive] = useState("rp");

  function onSwitch(param) {
    setActive(param);
    setInputRupiah("");
  }

  const handleChangePercent = (param) => {
    const inputValue = param;
    if (
      inputValue === "" ||
      (parseInt(inputValue) <= 100 && inputValue === param)
    ) {
      setInputRupiah(inputValue);
    }
  };

  function handleSubmit() {
    if (inputRupiah === "") return;

    onSubmit(inputRupiah);
    setInputRupiah("");
    onClose();
  }

  return (
    <Fragment>
      <Modal isOpen={isVisible} backdrop={true} toggle={onClose}>
        <div className="d-flex flex-row justify-content-between border-bottom p-3">
          <p className="font-weight-bolder my-2">Edit Potongan</p>
          <button
            type="button"
            onClick={onClose}
            className="border-0 bg-white p-auto"
          >
            <i className="fa-solid fa-x"></i>
          </button>
        </div>
        <ModalBody className="position-relative">
          <div className="d-flex flex-row justify-content-between">
            <Button
              className="my-0 mx-1"
              color={isActive === "rp" ? "primary" : "secondary"}
              block
              outline
              onClick={() => onSwitch("rp")}
            >
              Rupiah
            </Button>
            <Button
              className="my-0 mx-1"
              color={isActive === "percentage" ? "primary" : "secondary"}
              block
              outline
              onClick={() => onSwitch("percentage")}
            >
              Persentase
            </Button>
          </div>
          <div className="px-1 py-2">
            {isActive === "rp" ? (
              <InputGroup>
                <InputGroupAddon addonType="append">
                  <InputGroupText>Rp</InputGroupText>
                </InputGroupAddon>
                <Input
                  type="number"
                  placeholder="0"
                  min={0}
                  value={inputRupiah}
                  onChange={(e) => setInputRupiah(e.target.value)}
                />
              </InputGroup>
            ) : (
              <InputGroup>
                <Input
                  type="number"
                  placeholder="0"
                  min={0}
                  max={100}
                  value={inputRupiah}
                  onChange={(e) => handleChangePercent(e.target.value)}
                />
                <InputGroupAddon addonType="append">
                  <InputGroupText>%</InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="border" color="white" onClick={onClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </Fragment>
  );
};

export default ModalPotongan;
