import { Fragment } from "react";
import PropTypes from "prop-types";

import { Input } from "reactstrap";

const SelectList = ({ placeholder, values, onChange, currValue }) => {
  return (
    <Fragment>
      <Input
        className="mr-2"
        type="select"
        name="select"
        id="exampleSelect"
        onChange={onChange}
		value={currValue && currValue}
      >
		<option value={""}>{placeholder ? placeholder : ''}</option>
        {values &&
          values.map((item) => (
            <option value={item.val} key={item.val}>
              {item.name}
            </option>
          ))}
      </Input>
    </Fragment>
  );
};

SelectList.propTypes = {
  values: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default SelectList;
